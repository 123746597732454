import { Paper, Skeleton } from "@mui/material"
import { useStyles } from "./styles"

export const SkeletonHeaderBtn = () => {
    const { classes } = useStyles()
    return (
        <Paper className={classes.header} data-testid="bloc-header-btn">
            <Skeleton className="btn-left" variant="rectangular" height={56} />
            <Skeleton className="btn-right" variant="rectangular" height={56} />
        </Paper>
    )
}
