import { IMessage, IPaginationReviews, IRecipients, IReviews } from "@/types/campaign"
import { create } from "zustand"

interface IReviewsDetails {
    recipients: IRecipients[]
    message: IMessage
    pagination: {
        _count: number
        _limit: number
        _page: number
    }
}
export interface ICampaignReviewsStore {
    isLoading: boolean
    listReviewsClient: IReviews[]
    pagination: IPaginationReviews
    selectedReview: IReviews
    reviewDetails: IReviewsDetails
    disablePagination: boolean
}
export interface ICampaignReviewsSetter {
    update: (update: Partial<ICampaignReviewsStore>) => void
    flush: () => void
    updatePagination: (value: Partial<IPaginationReviews>) => void
    resetPagination: () => void
    resetReviews: () => void
}

const initalState: ICampaignReviewsStore = {
    isLoading: false,
    disablePagination: false,
    pagination: {
        page: 0,
        per_page: 9,
        total: 0,
        totalPage: 0,
    },
    selectedReview: null,
    listReviewsClient: [],
    reviewDetails: null,
}

const CampaignReviewsStore = create<ICampaignReviewsStore & ICampaignReviewsSetter>((set) => ({
    ...initalState,
    update: (update) => set(() => ({ ...update })),
    updatePagination: (update) =>
        set((state) => ({
            ...state,
            pagination: {
                ...state.pagination,
                ...update,
            },
        })),
    flush: () => set(initalState),
    resetReviews: () =>
        set(() => ({
            selectedReview: null,
            reviewDetails: null,
        })),
    resetPagination: () =>
        set((state) => ({
            listReviewsClient: [],
            pagination: {
                ...state.pagination,
                page: 0,
                per_page: 9,
                total: 0,
                totalPage: 0,
            },
        })),
}))

export default CampaignReviewsStore
