import { makeStyles } from "tss-react/mui"

export const useStyles = makeStyles()((theme) => ({
    container: {
        "& .list-bloc": {
            flexDirection: "row",
            gap: 16,
            alignItems: "center",
            width: "100%",
            "@media (max-width: 1023px)": {
                flexDirection: "column",
                gap: 8,
            },
        },
    },
}))
