import CHARACTER_REPLACEMENTS from "./sms-gsm7-replacement/gsm7-replacement.json"

const mapNonGsm7Chars = (text: string) => {
    if (!text) return ""
    let replacedText = ""

    for (const char of text) {
        if (char === "\uFEFF" || char === "\u00A0") {
            /*
                Skip the BOM character
                The 0xFEFF character is the Byte Order Mark (BOM), which is often used in Unicode text to indicate the endianness of the encoding. 
            */
            /*
                Skip the NBSP character
                The character 0x00A0 is the non-breaking space (NBSP) in Unicode
            */
            continue
        }
        if (CHARACTER_REPLACEMENTS[char]) {
            replacedText += CHARACTER_REPLACEMENTS[char]
        } else {
            replacedText += char
        }
    }

    return replacedText
}

export { mapNonGsm7Chars }
