import BaseInput from "@/components/base/input"
import RadioTabButton, { DataItem } from "@/components/base/radio-tab-buttons"
import { useDebounce } from "@/hooks/useDebounce"
import { Paper } from "@mui/material"
import { useEffect, useMemo, useState } from "react"
import useStyles, { radioTabButtonSx } from "./styles"

const ListingFilter = ({ t, onChange, onSearchValueChange }) => {
    const { classes } = useStyles()
    const radioTabButtons = useMemo<DataItem[]>(() => {
        return [{ label: t("LISTINGS.PLATFORM") }, { label: t("BUSINESSES.TITLE") }]
    }, [t])
    const [value, setValue] = useState("")
    const searchValue = useDebounce(value, 100)
    useEffect(() => {
        onSearchValueChange(searchValue)
    }, [searchValue, onSearchValueChange])

    return (
        <Paper className={classes.wrapper}>
            <BaseInput
                hiddenLabel
                placeholder={t("LISTINGS.SEARCH")}
                onChange={(event) => setValue(event.target?.value || "")}
            />
            <RadioTabButton
                data={radioTabButtons}
                defaultActive={0}
                label={t("LISTINGS.VIEW_BY")}
                sx={radioTabButtonSx}
                onChange={onChange}
            />
        </Paper>
    )
}

export default ListingFilter
