import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()(() => ({
    formModel: {
        "& .btn-form-model": {
            "@media (max-width: 767px)": {
                button: {
                    width: "50%",
                },
            },
        },
    },
}))

export default useStyles
