export const listItemText = {
    width: "100%",
    margin: 0,
    "& > span": {
        textOverflow: "ellipsis",
        overflow: "hidden",
        textWrap: "wrap",
        display: " -webkit-box",
        webkitBoxOrient: "vertical",
        webkitLineCamp: "2",
        whiteSpace: "pre-wrap",
    },
}

export const iconWrapper = {
    backgroundColor: "background.default",
    borderRadius: 76 / 8,
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
}

export const listSx = () => {
    return {
        padding: 0,
        display: "grid",
        gap: 1,
        "@media (min-width: 2212px)": {
            gap: 2,
            gridTemplateColumns: "repeat(4, minmax(370px, 1fr))",
        },
        "@media (min-width: 768px) and (max-width: 2212px)": {
            gap: 2,
            gridTemplateColumns: "repeat(auto-fill, minmax(370px, 1fr))",
        },
    }
}
