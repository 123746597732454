import Typography from "@mui/material/Typography"
import { useTranslation } from "react-i18next"

export const UploadMessageMedia = () => {
    const { t } = useTranslation()
    return (
        <>
            <Typography
                fontSize={14}
                dangerouslySetInnerHTML={{
                    __html: t("MEDIA.MAX_SIZE", {
                        interpolation: { escapeValue: false },
                    }),
                }}
                component="span"
                className="image-type"
                variant="caption"
            />
            <Typography
                fontSize={14}
                dangerouslySetInnerHTML={{
                    __html: t("MEDIA.MAX_SIZE_IMAGE", {
                        interpolation: { escapeValue: false },
                    }),
                }}
                component="span"
                className="image-type"
                variant="caption"
            />
            <Typography component="span" className="image-type" variant="caption" fontSize={14}>
                <strong>{t("MEDIA.ACCEPTED_FORMAT")}:</strong> JPEG, JPG, PNG, MP4, M4V, MOV
            </Typography>
        </>
    )
}
