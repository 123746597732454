import React from "react"
import { Box } from "@mui/material"
import useStyles from "./styles"

type ContainerProps = {
    children: React.ReactNode
}

const ListContainer = ({ children }: ContainerProps) => {
    const { classes } = useStyles()

    return <Box className={classes.list}>{children}</Box>
}

export default ListContainer
