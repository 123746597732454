import Icon from "@/components/base/Icon"
import { Button, CircularProgress, Paper } from "@mui/material"
import { useTranslation } from "react-i18next"
import useStyles from "./styles"

interface FormActionProps {
    handleCancel: () => void
    handleSubmit?: () => void
    isDisabled?: boolean
    isLoading: boolean
    isUpdating?: boolean
    buttonText?: string
}

const FormAction = ({ handleCancel, isDisabled, isLoading, isUpdating, buttonText }: FormActionProps) => {
    const { t } = useTranslation()
    const { classes } = useStyles()

    return (
        <Paper elevation={6} className={classes.wrapper}>
            <Button color="secondary" onClick={handleCancel}>
                {t("USERS.CANCEL")}
            </Button>
            <Button variant={"contained"} data-testid="create" type="submit" data-e2e="submit" disabled={isDisabled}>
                {isLoading && <CircularProgress size={16} sx={{ marginRight: "8px" }} />}
                {!isLoading && <Icon name={isUpdating ? "save" : "plus"} sx={{ marginRight: "8px" }} />}
                {buttonText ?? (isUpdating ? t("USERS.SAVE") : t("USERS.CREATE.SAVE"))}
            </Button>
        </Paper>
    )
}

export default FormAction
