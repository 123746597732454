import { create } from "zustand"

export interface ICompaniesStore {
    refetchCompanies: boolean
}
export interface ICampaignSetter {
    update: (update: Partial<ICompaniesStore>) => void
    flush: () => void
}

const initalState: ICompaniesStore = {
    refetchCompanies: true,
}

const CompaniesStore = create<ICompaniesStore & ICampaignSetter>((set) => ({
    ...initalState,
    update: (update) => set(() => ({ ...update })),
    flush: () => set(initalState),
}))

export default CompaniesStore
