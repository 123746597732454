import UserSessionStore from "@/store/user-session"
import { checkIsAdminCompany, checkIsSuperAdmin } from "@/utils/auth"
import { useCallback } from "react"

const useProfileRole = () => {
    const { role, profile } = UserSessionStore()

    const isAdmin = useCallback(() => {
        if (profile?.uid) {
            return checkIsSuperAdmin(role)
        }
        return false
    }, [role, profile])

    const isAdminCompany = useCallback(() => {
        if (profile?.uid) {
            return checkIsAdminCompany(role)
        }
        return false
    }, [profile, role])

    return {
        isAdmin,
        isAdminCompany,
    }
}

export default useProfileRole
