import { Divider, Skeleton, Stack } from "@mui/material"
import { useStyles } from "./style"

export const SkeletonBlocStepper = () => {
    const { classes } = useStyles()
    return (
        <Stack className={classes.blocStepper} width="100%">
            <Skeleton variant="rectangular" width="110px" height={24} />
            <Divider className="ligne" />
            <Skeleton variant="rectangular" width="110px" height={24} />
            <Divider className="ligne" />
            <Skeleton variant="rectangular" width="110px" height={24} />
        </Stack>
    )
}
