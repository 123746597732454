import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()((theme) => ({
    drawer: { zIndex: 1 },
    container: {
        "& .overview-user": {
            "& .MuiInputBase-root": {
                "&:before": {
                    borderBottom: "1px solid rgba(91, 125, 216, 0.12) !important",
                },

                "&.MuiInput-underline:after": {
                    borderBottom: "1px solid rgba(91, 125, 216, 0.12)",
                },
            },
            "& .MuiFormLabel-root": {
                marginLeft: 12,
                marginTop: 9,
            },
            "& input": {
                height: 16,
            },
            "& .MuiFormControl-root": {
                padding: "9px 12px 8px 12px",
            },
        },
    },
}))

export default useStyles
