import { SxProps } from "@mui/material"

export const menuBtnRight: SxProps = {
    "& .MuiBackdrop-root": { opacity: "0 !important" },
    "& .MuiPaper-root": {
        padding: 1,
        borderRadius: 1,
        "& ul": {
            display: "flex",
            flexDirection: "column",
            gap: 1,
            padding: 0,
            "& li": {
                padding: 0,
                gap: 1,
                fontSize: 15,
                minHeight: 26,
                "& span": { height: 26 },
            },
        },
    },
}
