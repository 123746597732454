import { BlocNumber } from "@/components/analytics/bloc-number"
import { BlocStar } from "@/components/analytics/bloc-star"
import { FilterAnalytics } from "@/components/analytics/filter"
import useStyles from "@/components/analytics/styles"
import Icon from "@/components/base/Icon"
import { ChartView } from "@/components/base/chart"
import TitleWrapper from "@/components/base/page-container/list-container/title-wrapper"
import { SkeletonStat } from "@/components/skeleton/statistic"
import useLogic from "@/hooks/reputation-review/useLogic"
import { convertBigNumber, convertBigNumberSpace, convertPourcentageString, convertStyleString } from "@/utils"
import { Container, Paper, Stack, Typography } from "@mui/material"
import { yellow } from "@mui/material/colors"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { BusinessCompare } from "@/components/analytics/business-compare"
import { Column } from "@/components/table/partials/custom-table"
import { RenderText } from "@/components/analytics/render-text-table-to-compare"

type ColumnProps = Column & {
    id: string
}

export const StatisticReputation = ({ mockData = null }) => {
    const { classes } = useStyles()
    const { t } = useTranslation()
    const {
        kpi,
        graph,
        handleSelectionChange,
        handleSelectionPeriodChange,
        testKpi,
        graphs,
        loading,
        selectedBusinesses,
        selectedPeriod,
        listBusinessToCompare,
    } = useLogic()

    const columnsBusinessCompare: readonly ColumnProps[] = useMemo(() => {
        return [
            {
                id: t("BUSINESSES.TITLE"),
                label: t("BUSINESSES.TITLE"),
                width: "100px",
                hidden: false,
                component: ({ row }) => {
                    return (
                        <Stack>
                            <Typography className="ellipse">{row.locationName}</Typography>
                            <Typography variant="body2" component="span" color={"#68738D"} className="ellipse">
                                {row.locationAddress}
                            </Typography>
                        </Stack>
                    )
                },
            },
            {
                id: "review_rating_average",
                label: t("REQUEST_OPINION.AVERAGE_GRADE"),
                width: "20%",
                hidden: false,
                sortable: true,
                component: ({ row }) => <RenderText data={row.review_rating_average} type="review_rating_average" />,
            },
            {
                id: "review_count",
                label: t("REQUEST_OPINION.REVIEWS"),
                width: "20%",
                hidden: false,
                sortable: true,
                component: ({ row }) => <RenderText data={row.review_count} />,
            },
            {
                id: "new_review_count",
                label: t("REQUEST_OPINION.YOUR_REQUEST_OPINION"),
                width: "20%",
                hidden: false,
                sortable: true,
                component: ({ row }) => <RenderText data={row.new_review_count} />,
            },
            {
                id: "review_treated",
                label: t("REQUEST_OPINION.RESPONSE_RATE"),
                width: "20%",
                hidden: false,
                sortable: true,
                component: ({ row }) => <RenderText data={row.review_treated} textSupp="%" type="review_treated" />,
            },
        ]
    }, [t])

    const dataKpi = useMemo(() => {
        return {
            review_rating_average: convertBigNumberSpace(
                testKpi() ? kpi?.period?.review_rating_average : undefined,
                t("LOCAL_PRESENCE.NOT_AVAILABLE")
            ),
            review_count: convertBigNumberSpace(
                testKpi() ? kpi?.period?.review_count : undefined,
                t("LOCAL_PRESENCE.NOT_AVAILABLE")
            ),
            review_treated_rate: convertBigNumberSpace(
                testKpi() ? kpi?.period?.review_treated_rate : undefined,
                t("LOCAL_PRESENCE.NOT_AVAILABLE"),
                "%"
            ),
        }
    }, [kpi?.period, testKpi])

    return (
        <Container className={classes.container}>
            <TitleWrapper pageIcon="barChart" title={t("REPUTATION.TITLE")} />
            {loading && <SkeletonStat type="reputation" />}
            {mockData === null && !loading && (
                <FilterAnalytics
                    selectedPeriod={selectedPeriod}
                    selectedBusinesses={selectedBusinesses}
                    handleSelectionChange={handleSelectionChange}
                    handleSelectionPeriodChange={handleSelectionPeriodChange}
                    widthTotal={true}
                />
            )}

            {!loading && (kpi || mockData) && (
                <Stack className={classes.views}>
                    <BlocStar
                        label={t("REQUEST_OPINION.AVERAGE_GRADE")}
                        tooltip={t("REQUEST_OPINION.TOOLTIP_AVERAGE_GRADE")}
                        number={dataKpi.review_rating_average}
                        score={dataKpi.review_rating_average}
                        noData={
                            (!kpi?.period?.review_rating_average && kpi?.period?.review_rating_average != 0) ||
                            (!testKpi() && kpi?.period?.review_rating_average == 0) ||
                            dataKpi.review_rating_average === t("LOCAL_PRESENCE.NOT_AVAILABLE")
                        }
                        pourcentage={
                            kpi?.previous_period?.review_rating_average_variation === 0 && testKpi()
                                ? "+0"
                                : convertPourcentageString(
                                      kpi?.previous_period?.review_rating_average_variation,
                                      testKpi(),
                                      true,
                                      false
                                  )
                        }
                        icon={"star"}
                        color={yellow[700]}
                        type={
                            kpi?.previous_period?.review_rating_average_variation === 0
                                ? "no-data"
                                : convertStyleString(kpi?.previous_period?.review_rating_average_variation)
                        }
                    />
                    <BlocNumber
                        label={t("REQUEST_OPINION.REVIEWS")}
                        tooltip={t("REQUEST_OPINION.TOOLTIP_REVIEWS")}
                        number={dataKpi.review_count}
                        noData={
                            (!kpi?.period?.review_count && kpi?.period?.review_count != 0) ||
                            (!testKpi() && kpi?.period?.review_count == 0) ||
                            dataKpi.review_count === t("LOCAL_PRESENCE.NOT_AVAILABLE")
                        }
                        pourcentage={
                            kpi?.previous_period?.review_count_variation === 0 && testKpi()
                                ? "+0%"
                                : convertPourcentageString(kpi?.previous_period?.review_count_variation, testKpi())
                        }
                        icon={"star"}
                        color={yellow[800]}
                        type={
                            kpi?.previous_period?.review_count_variation === 0
                                ? "no-data"
                                : convertStyleString(kpi?.previous_period?.review_count_variation)
                        }
                    />
                    <BlocNumber
                        label={t("REQUEST_OPINION.RESPONSE_RATE")}
                        tooltip={t("REQUEST_OPINION.TOOLTIP_RESPONSE_RATE")}
                        number={dataKpi.review_treated_rate}
                        noData={
                            (!kpi?.period?.review_treated_rate && kpi?.period?.review_treated_rate != 0) ||
                            (!testKpi() && kpi?.period?.review_treated_rate == 0) ||
                            dataKpi.review_treated_rate === t("LOCAL_PRESENCE.NOT_AVAILABLE")
                        }
                        pourcentage={
                            kpi?.previous_period?.review_treated_rate_variation === 0 && testKpi()
                                ? "+0%"
                                : convertPourcentageString(
                                      kpi?.previous_period?.review_treated_rate_variation,
                                      testKpi()
                                  )
                        }
                        icon={"star"}
                        color={yellow[900]}
                        type={
                            kpi?.previous_period?.review_treated_rate_variation === 0
                                ? "no-data"
                                : convertStyleString(kpi?.previous_period?.review_treated_rate_variation)
                        }
                    />
                </Stack>
            )}
            {graph && (
                <>
                    {listBusinessToCompare?.length > 1 && (
                        <BusinessCompare details={listBusinessToCompare} columns={columnsBusinessCompare} />
                    )}
                    <Paper>
                        <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} gap={2}>
                            <Stack flexDirection={"row"} alignItems={"center"} gap={2} px={1}>
                                <Icon name="star" sx={{ color: "#FF881B" }} />
                                <Typography variant={"h2"} fontSize={"24px"} fontWeight="500" sx={{ color: "inherit" }}>
                                    {t("REQUEST_OPINION.NUMBER_REVIEWS")}
                                </Typography>
                            </Stack>
                        </Stack>
                        <ChartView graphs={graphs?.review_count} />
                    </Paper>
                    <Paper>
                        <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} gap={2}>
                            <Stack flexDirection={"row"} alignItems={"center"} gap={2} px={1}>
                                <Icon name="barChart" sx={{ color: "#68738D" }} />
                                <Typography variant={"h2"} fontSize={"24px"} fontWeight="500" sx={{ color: "inherit" }}>
                                    {t("REQUEST_OPINION.YOUR_REQUEST_OPINION")}
                                </Typography>
                            </Stack>
                        </Stack>
                        <ChartView graphs={graphs?.feedback} />
                    </Paper>
                    <Paper>
                        <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} gap={2}>
                            <Stack flexDirection={"row"} alignItems={"center"} gap={2} px={1}>
                                <Icon name="barChart" sx={{ color: "#68738D" }} />
                                <Typography variant={"h2"} fontSize={"24px"} fontWeight="500" sx={{ color: "inherit" }}>
                                    {t("REQUEST_OPINION.AVERAGE_GRADE")}
                                </Typography>
                            </Stack>
                        </Stack>
                        <ChartView graphs={graphs?.average_grade} />
                    </Paper>
                    <Paper>
                        <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} gap={2}>
                            <Stack flexDirection={"row"} alignItems={"center"} gap={2} px={1}>
                                <Icon name="barChart" sx={{ color: "#68738D" }} />
                                <Typography variant={"h2"} fontSize={"24px"} fontWeight="500" sx={{ color: "inherit" }}>
                                    {t("REQUEST_OPINION.RESPONSE_RATE")}
                                </Typography>
                            </Stack>
                        </Stack>
                        <ChartView graphs={graphs?.review_treated} />
                    </Paper>
                </>
            )}
        </Container>
    )
}
