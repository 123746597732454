import { Fragment, useEffect } from "react"
import CreateUser from "./create-user"
import UserList from "./list-user"
import ShowDetail from "./overview-user"
import EditUser from "./edit-user"
import useUiPageSession from "@/hooks/useUiPageSession"
import UsersStore from "@/store/users"

const UsersPage = () => {
    const { handleDrawerClose, showPage, open } = useUiPageSession({ title: "USERS.TITLE_HEADER", page: "users" })
    const { flush: flushUsersSotre } = UsersStore()

    useEffect(() => {
        return () => {
            flushUsersSotre()
        }
    }, [flushUsersSotre])

    return (
        <Fragment>
            <UserList />
            <CreateUser open={showPage === "create" && open} onClose={handleDrawerClose} />
            <EditUser open={showPage === "edit" && open} onClose={handleDrawerClose} />
            <ShowDetail open={showPage === "detail" && open} onClose={handleDrawerClose} />
        </Fragment>
    )
}

export default UsersPage
