import { ResellerApi } from "@/api"
import useFormater from "@/hooks/useFormater"
import useNotification from "@/hooks/useNotification"
import UiPageSessionStore from "@/store/ui-page-session"
import UserSessionStore from "@/store/user-session"
import { CompanyInfo } from "@/types/companies"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

const useLogic = () => {
    const { profile } = UserSessionStore()
    const [company, setCompany] = useState<CompanyInfo>()
    const { t } = useTranslation()
    const { notif } = useNotification()
    const { selectedItem, updateState: setUiPageSession, menuPage, showPage } = UiPageSessionStore()
    const { parseAddress } = useFormater()
    const address = useMemo(() => {
        if (!company) {
            return ""
        }
        return parseAddress({
            region: company.province_code,
            countryCode: company.country_code,
            address: company.address,
            city: company.city,
            zip: company.zip,
        })
    }, [parseAddress, company])

    const refreshGetCompany = useCallback(
        async (uid: string) => {
            if (!profile?.uid) {
                return
            }
            const response: any = await ResellerApi.fetchCompanyInfo({ id: uid, user_uid: profile.uid })
            if (response.error) {
                notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
            } else if (response) {
                setCompany(response)
            }
        },
        [profile?.uid]
    )

    const handlePreviousButton = useCallback(() => {
        setCompany(null)
        setUiPageSession({ showPage: "list", selectedItem: null })
    }, [])

    useEffect(() => {
        if (selectedItem?.uid && menuPage === "companies" && showPage === "detail") {
            refreshGetCompany(selectedItem.uid)
        }
    }, [showPage])

    return { address, company, t, handlePreviousButton, refreshGetCompany }
}

export default useLogic
