import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()((theme) => ({
    errorContainer: {
        minHeight: "100vh",
        minWidth: "100vw",
        "@media(max-width: 768px)": {
            alignItems: "center",
        },
        "& .notfound": {
            marginLeft: 149,
            "@media(max-width: 768px)": {
                marginLeft: 0,
                maxWidth: "310px",
            },
            "& .error": {
                fontSize: "155.54px",
                lineHeight: "192.09px",
                color: theme.palette.primary.main,
                fontWeight: 500,
                "&.black": {
                    color: theme.palette.secondary.main,
                },
            },
            "& .error-message": {
                fontSize: "34px",
                lineHeight: "41.99px",
                fontWeight: 500,
                color: theme.palette.action.active,
                "&.black": {
                    color: theme.palette.secondary.main,
                },
            },
            "& .error-desc": {
                fontSize: "24px",
                lineHeight: "32.02px",
                fontWeight: 500,
                color: theme.palette.action.active,
                "&.black": {
                    color: theme.palette.secondary.main,
                },
            },
            "& .action-btn": {
                maxWidth: "fit-content",
                padding: "8px 22px",
                height: "42px",
            },
            "&.no-margin": {
                margin: "auto",
            },
        },
    },
}))

export default useStyles
