import UiPageSessionStore from "@/store/ui-page-session"
import UserSessionStore from "@/store/user-session"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

const useRedirect = () => {
    const navigate = useNavigate()
    const { isLoggedIn } = UserSessionStore()
    const { updateState: updatePageSession } = UiPageSessionStore()

    useEffect(() => {
        const isBasePath = window.location.pathname === "/"

        if (isLoggedIn && isBasePath) {
            updatePageSession({
                menuPage: "reputation",
            })
            navigate("/reputation")
            return
        } else if (!isLoggedIn) {
            const url = window.location.pathname

            if (url.includes("/login")) {
                navigate(`/login`)
            } else {
                navigate(`/login?redirectTo=${encodeURIComponent(url)}`)
            }
            return
        }
    }, [isLoggedIn, navigate, updatePageSession])
}

export default useRedirect
