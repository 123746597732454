import { Button, CircularProgress, Paper, SxProps } from "@mui/material"
import { useTranslation } from "react-i18next"
import Icon from "../Icon"
import useStyles from "./styles"

interface FormActionProps {
    handleCancel: () => void
    isDisabled?: boolean
    isLoading: boolean
    isUpdating?: boolean
    isConfirm?: boolean
    buttonText?: string
    sx?: SxProps
    onSubmit?: () => void
}

const FormAction = ({
    handleCancel,
    isDisabled,
    isLoading,
    isUpdating,
    isConfirm,
    buttonText,
    sx,
    onSubmit,
}: FormActionProps) => {
    const { t } = useTranslation()
    const { classes } = useStyles()

    return (
        <Paper className={classes.wrapper} elevation={6} sx={sx}>
            <Button color="secondary" onClick={handleCancel}>
                {t("USERS.CANCEL")}
            </Button>
            <Button variant={"contained"} data-testid="create" type="submit" disabled={isDisabled} onClick={onSubmit}>
                {isLoading && <CircularProgress size={16} sx={{ marginRight: "8px" }} />}
                {!isLoading && !isConfirm && <Icon name={isUpdating ? "save" : "plus"} sx={{ marginRight: "8px" }} />}
                {buttonText ?? (isUpdating ? t("USERS.SAVE") : t("USERS.CREATE.SAVE"))}
            </Button>
        </Paper>
    )
}

export default FormAction
