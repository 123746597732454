import { Box, Button, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import useStyles from "../style"
const ErrorComponent = ({
    code = "404",
    message = "PAGE_ERRORS.NOT_FOUND.MESSAGE",
    buttonText = "PAGE_ERRORS.NOT_FOUND.BACK_TO_REPUTATION",
    navigatePath = "/reputation",
}) => {
    const { classes } = useStyles()
    const { t } = useTranslation()
    const navigate = useNavigate()
    return (
        <Stack className={classes.errorContainer} flexDirection={"column"} justifyContent={"center"}>
            <Stack flexDirection={"column"} className="notfound" gap={3}>
                <Box>
                    <Typography className="error">{code}</Typography>
                    <Typography variant="h1" className="error-message">
                        {t("PAGE_ERRORS.NOT_FOUND.OOPS")}
                    </Typography>
                </Box>

                <Typography variant="h2" className="error-desc">
                    {t(message)}
                </Typography>
                <Button
                    className="action-btn"
                    color="primary"
                    variant="contained"
                    onClick={() => navigate(navigatePath)}
                >
                    {t(buttonText)}
                </Button>
            </Stack>
        </Stack>
    )
}

export default ErrorComponent
