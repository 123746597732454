import useStyles from "@/components/campaign/styles"
import CustomTable from "../partials/custom-table"
import useTableCampaignUploaded from "./useTableCampaignUploaded"
import { Paper, Stack } from "@mui/material"
import useWindowSize from "@/hooks/useWindowSize"
import { useMemo } from "react"
import { CountryCode } from "libphonenumber-js"

type TableCampaignUploadedProps = {
    typeLabel: string
    className?: string
    campaignType?: "sms" | "email" | "text"
    countryCode?: CountryCode
}

const TableCampaignUploaded = ({
    typeLabel,
    className = "",
    campaignType = "sms",
    countryCode = "CA",
}: TableCampaignUploadedProps) => {
    const [width] = useWindowSize()
    const isMobileView = useMemo(() => width < 768, [width])
    const { columns, page, totalCount, rowsPerPage, rows, handleChangePage } = useTableCampaignUploaded({
        typeLabel,
        campaignType,
        isMobileView,
        countryCode,
    })
    const { classes } = useStyles()

    return (
        <Stack className={classes.stepBloc}>
            <Paper sx={{ p: 0, boxShadow: "none" }}>
                <CustomTable
                    columns={columns}
                    rows={rows}
                    showHeader={!isMobileView}
                    pagination={{
                        page,
                        rowsPerPage,
                        totalCount,
                    }}
                    className={className}
                    onPageChange={handleChangePage}
                />
            </Paper>
        </Stack>
    )
}

export default TableCampaignUploaded
