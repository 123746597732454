import { ResellerApi } from "@/api"
import usePhoneInputLogic from "@/hooks/phone-input/useLogic"
import useFormater from "@/hooks/useFormater"
import useLocation from "@/hooks/useLocation"
import useNotification from "@/hooks/useNotification"
import useContactsStore from "@/store/overview/contacts"
import UiStore from "@/store/ui"
import UiPageSessionStore from "@/store/ui-page-session"
import { EditContactFormValues } from "@/types/businesses"
import { isValidEmail } from "@/utils"
import { zodResolver } from "@hookform/resolvers/zod"
import { CountryCode } from "libphonenumber-js"
import cloneDeep from "lodash.clonedeep"
import { useCallback, useEffect, useMemo, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { z } from "zod"
import useBusinessLogic from "../useBusinessLogic"
interface CountryPhones {
    mainPhone: CountryCode
    secondPhone: CountryCode
}

const defaultValidity = {
    mainPhone: false,
    secondPhone: false,
    email: false,
}

const defaultCountry: CountryPhones = {
    mainPhone: "CA",
    secondPhone: "CA",
}

const useLogic = () => {
    const { navigateTo } = useBusinessLogic()
    const { t } = useTranslation()
    const { location } = useLocation()
    const { update: updateContacts, additionalPhone, email } = useContactsStore()
    const { notif } = useNotification()
    const { updatePreviousItem, previousItem, updateState: setUiPageSession } = UiPageSessionStore()
    const { isPhoneValid } = usePhoneInputLogic()
    const { preferredLanguage } = UiStore()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [valueChanged, setValueChanged] = useState(false)
    const [errors, setErrors] = useState(defaultValidity)
    const [countryPhone, setCountryPhone] = useState<CountryPhones>(defaultCountry)
    const { internationalPhone } = useFormater()
    const errorsFrom = useMemo(() => {
        const total = []
        Object.keys(errors).map((error) => errors[error] && total.push(error))
        return total
    }, [errors])

    const schema = useMemo(() => {
        return z.object({
            mainPhone: z.string().optional(),
            secondPhone: z.string().optional(),
            email: z.string().optional(),
        })
    }, [])

    const langOfCountryName = useMemo(() => {
        return preferredLanguage?.toLowerCase() || "fr-ca"
    }, [preferredLanguage])

    const defaultValues = useMemo(() => {
        const data =
            previousItem?.companyId === location?.companyId ? previousItem : cloneDeep({ additionalPhone, email })
        if (data && typeof data === "object") {
            data["mainPhone"] = data?.additionalPhone?.[0] || ""
            data["secondPhone"] = data?.additionalPhone?.[1] || ""
            data["email"] = data?.email || ""
        }
        return data
    }, [previousItem?.additionalPhone, previousItem?.email, additionalPhone, email, location?.companyId])

    const {
        control,
        getValues,
        setValue,
        handleSubmit,
        formState: { isSubmitting },
        reset,
    } = useForm<EditContactFormValues>({
        defaultValues,
        resolver: zodResolver(schema),
        mode: "onTouched",
    })

    const handlePreviousButton = useCallback(() => {
        navigateTo("detail")
        setValueChanged(false)
        const additionalPhone = []
        additionalPhone.push(getValues().mainPhone || "")
        additionalPhone.push(getValues().secondPhone || "")
        updatePreviousItem({
            additionalPhone: additionalPhone,
            email: getValues().email,
            companyId: location?.companyId,
        })
    }, [getValues, location?.companyId, navigateTo, updatePreviousItem])

    const handleCancelButton = useCallback(() => {
        navigateTo("detail")
        reset()
        setErrors(defaultValidity)
        setValueChanged(false)
        updatePreviousItem({
            additionalPhone: additionalPhone,
            email: email,
        })
    }, [additionalPhone, email, navigateTo, reset, updatePreviousItem])

    const handlePhoneChange = useCallback((info: any, type) => {
        if (info?.countryCode && info?.reason === "country") {
            setCountryPhone((previous) => ({ ...previous, [type]: info.countryCode }))
            setValueChanged(true)
        }
    }, [])

    const checkValidityForm = useCallback(
        (data) => {
            let result = true
            if (data.mainPhone && !isPhoneValid(data.mainPhone)) {
                setErrors((prev) => ({ ...prev, mainPhone: true }))
                result = false
            }

            if (data.secondPhone && !isPhoneValid(data.secondPhone)) {
                setErrors((prev) => ({ ...prev, secondPhone: true }))
                result = false
            }

            if (data.email && !isValidEmail(data.email)) {
                setErrors((prev) => ({ ...prev, email: true }))
                result = false
            }
            return result
        },
        [isPhoneValid]
    )

    const onSubmit: SubmitHandler<any> = useCallback(
        async (data) => {
            setErrors(defaultValidity)
            setValueChanged(false)
            setIsLoading(true)
            const body = { ...location, additionalPhone: additionalPhone, email: email }
            const tempAdditionalPhone = []
            if (data.mainPhone && data.mainPhone?.length > 5 && isPhoneValid(data.mainPhone)) {
                tempAdditionalPhone.push(data.mainPhone)
            }

            if (data.secondPhone && data.secondPhone?.length > 5 && isPhoneValid(data.secondPhone)) {
                tempAdditionalPhone.push(data.secondPhone)
            }

            body.additionalPhone = [...tempAdditionalPhone]
            body.email = data.email

            if (checkValidityForm(data)) {
                const response = await ResellerApi.updateLocation({ ...body })
                if (response?.error) {
                    notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
                    setIsLoading(false)
                } else {
                    updateContacts({ additionalPhone: response.additionalPhone, email: response.email })
                    updatePreviousItem({
                        email: body.email,
                        additionalPhone: body.additionalPhone,
                    })
                    setUiPageSession({ refreshItems: true })
                    notif({ message: t("BUSINESSES.EDIT_SUCCESS"), type: "SUCCESS" })
                    navigateTo("detail")
                    setIsLoading(false)
                }
            } else {
                setIsLoading(false)
            }
        },
        [
            location,
            additionalPhone,
            email,
            isPhoneValid,
            checkValidityForm,
            notif,
            t,
            updateContacts,
            setUiPageSession,
            navigateTo,
        ]
    )

    useEffect(() => {
        if (defaultValues) {
            setValue("email", defaultValues?.email)
            setValue("mainPhone", internationalPhone(defaultValues?.mainPhone, countryPhone.mainPhone ?? "CA", true))
            setValue(
                "secondPhone",
                internationalPhone(defaultValues?.secondPhone, countryPhone.mainPhone ?? "CA", true)
            )
            if (!defaultValues?.mainPhone) {
                setCountryPhone((prev) => ({ ...prev, mainPhone: "CA" }))
            }
            if (!defaultValues?.secondPhone) {
                setCountryPhone((prev) => ({ ...prev, secondPhone: "CA" }))
            }
        }
    }, [defaultValues, setValue])

    return {
        handlePreviousButton,
        navigateTo,
        t,
        countryPhone,
        errors,
        errorsFrom,
        control,
        handleCancelButton,
        langOfCountryName,
        handlePhoneChange,
        handleSubmit,
        onSubmit,
        isSubmitting,
        isLoading,
        setErrors,
        setValueChanged,
        valueChanged,
    }
}

export default useLogic
