import { create } from "zustand"

export type SocialDialogType =
    | "alert_exit"
    | "alert_delete"
    | "single_post"
    | "multiple_post"
    | "error_details"
    | "connexion_details"
    | "success_normal"
    | "success_scheduled"
    | ""

export interface ISocialPopupStore {
    openPopup: boolean
    show: SocialDialogType
}
export interface ISocialPopupSetter {
    updatePopup: (update: Partial<ISocialPopupStore>) => void
    showPopupContent: (show: SocialDialogType) => void
    flush: () => void
}

const initalState: ISocialPopupStore = {
    openPopup: false,
    show: "",
}

const SocialPopupStore = create<ISocialPopupStore & ISocialPopupSetter>((set) => ({
    ...initalState,
    showPopupContent: (show: SocialDialogType) => set(() => ({ show })),
    updatePopup: (update) => set(() => ({ ...update })),
    flush: () => set(initalState),
}))

export default SocialPopupStore
