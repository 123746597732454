import { create } from "zustand"

export interface IRegularHours {
    status: string
    regularHours?: object[]
}

export interface IOverviewRegularHoursStoreSetter {
    updateRegularHours: (update: Partial<IRegularHours>) => void
    getInitialState: () => IRegularHours
    flush: () => void
}

const initalState = {
    status: "",
    regularHours: [],
}

const useBusinessRegularHoursStore = create<IRegularHours & IOverviewRegularHoursStoreSetter>((set) => ({
    ...initalState,
    updateRegularHours: (update) => set(() => ({ ...update })),
    getInitialState: () => initalState,
    flush: () => set(initalState),
}))

export default useBusinessRegularHoursStore
