export const localesChart = [
    {
        name: "en",
        options: {
            months: [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ],
            shortMonths: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
            shortDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            toolbar: {
                exportToSVG: "Download SVG",
                exportToPNG: "Download PNG",
                exportToCSV: "Download CSV",
                selection: "Selection",
                selectionZoom: "Selection Zoom",
                zoomIn: "Zoom In",
                zoomOut: "Zoom Out",
                pan: "Panning",
                reset: "Reset Zoom",
            },
        },
    },
    {
        name: "fr",
        options: {
            months: [
                "Janvier",
                "Février",
                "Mars",
                "Avril",
                "Mai",
                "Juin",
                "Juillet",
                "Août",
                "Septembre",
                "Octobre",
                "Novembre",
                "Décembre",
            ],
            shortMonths: ["Jan", "Fév", "Mar", "Avr", "Mai", "Juin", "Juil", "Août", "Sep", "Oct", "Nov", "Déc"],
            days: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
            shortDays: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
            toolbar: {
                exportToSVG: "Télécharger SVG",
                exportToPNG: "Télécharger PNG",
                exportToCSV: "Télécharger CSV",
                selection: "Sélection",
                selectionZoom: "Sélectionner Zoom",
                zoomIn: "Zoom Avant",
                zoomOut: "Zoom Arrière",
                pan: "Déplacement",
                reset: "Réinitialiser Zoom",
            },
        },
    },
]
