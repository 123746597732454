import Icon from "@/components/base/Icon"
import CustomTooltip from "@/components/base/Tooltip"
import NotificationStore from "@/store/notification"
import { Alert, IconButton, Snackbar } from "@mui/material"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"

const SnackbarError = () => {
    const { message, show, update } = NotificationStore()
    const handleOnClose = useCallback(() => {
        update({ message: "" })
    }, [update])
    const { t } = useTranslation()

    return (
        <Snackbar
            open={show}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            onClose={() => handleOnClose()}
            ClickAwayListenerProps={{
                onClickAway: () => null,
            }}
        >
            <Alert
                icon={<Icon name="alert-triangle" />}
                severity="error"
                elevation={6}
                sx={{
                    backgroundColor: "error.main",
                }}
                className="alert-info"
                action={
                    <CustomTooltip title={t("USERS.CLOSE")}>
                        <IconButton sx={{ color: "white" }} onClick={() => handleOnClose()}>
                            <Icon name="x" />
                        </IconButton>
                    </CustomTooltip>
                }
            >
                {message}
            </Alert>
        </Snackbar>
    )
}

export default SnackbarError
