import { MenuItemType } from "@/types/menu"

const MENU_ITEM_LIST: MenuItemType[] = [
    {
        label: "MENU.PROFILE",
        icon: "profile",
        position: "top",
        href: null,
        children: [
            {
                label: "MENU.BUSINESSES",
                icon: "profile",
                position: "top",
                href: "/businesses",
            },
            {
                label: "MENU.LISTINGS",
                icon: "bulletList",
                position: "top",
                href: "/listings",
            },
        ],
    },
    {
        label: "MENU.REPUTATION",
        icon: "star",
        position: "top",
        href: "/reputation",
    },
    {
        label: "MENU.CAMPAIGN",
        icon: "announcement",
        position: "top",
        href: "/campaign",
    },
    {
        label: "MENU.SOCIAL",
        icon: "social",
        position: "top",
        href: "/social",
    },
    {
        label: "MENU.PERFORMANCE",
        icon: "barChart",
        position: "top",
        href: null,
        children: [
            {
                label: "MENU.LOCAL_PRESENCE",
                icon: "profile",
                position: "top",
                href: "/statistics/local",
            },
            {
                label: "MENU.REPUTATION",
                icon: "star",
                position: "top",
                href: "/statistics/reputation",
            },
        ],
    },
    {
        label: "MENU.PARAMETER",
        icon: "setting",
        position: "bottom",
        href: null,
        children: [
            {
                label: "MENU.USERS",
                icon: "user",
                position: "top",
                href: "/users",
            },
            {
                label: "MENU.COMPANIES",
                icon: "company",
                position: "top",
                href: "/companies",
            },
        ],
    },
]

export default MENU_ITEM_LIST
