import { ChangeEvent, useCallback, useMemo, useState } from "react"

const useLogicFile = ({ setFile, close, accept = "" }) => {
    const [error, setError] = useState(false)
    const [isThereStaticFile, setIsThereStaticFile] = useState<boolean>(false)
    const [fileItem, setFileItem] = useState<File>()
    const [label, setLabel] = useState<HTMLLabelElement>()
    const acceptedTypes = useMemo(() => accept.split(","), [accept])

    const labelRefHandler = useCallback((ref: HTMLLabelElement) => {
        if (ref) setLabel(ref)
    }, [])

    const closeModal = useCallback(() => {
        setError(false)
        close()
        setIsThereStaticFile(false)
    }, [])

    const onAdd = useCallback(() => {
        setFile(fileItem)
        closeModal()
    }, [fileItem])

    const inputChangeHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setError(false)
        const imageFile = e.target.files["0"]
        const maxSize = 10 * 1024 * 1024
        if (acceptedTypes.includes(imageFile.type) && imageFile.size <= maxSize) {
            setIsThereStaticFile(true)
            setFileItem(imageFile)
        } else {
            setFileItem(null)
            setIsThereStaticFile(false)
            setError(true)
        }
    }, [])

    const handleFile = useCallback((file: File) => {
        setError(false)
        const imageFile = file
        const maxSize = 10 * 1024 * 1024
        if (acceptedTypes.includes(imageFile.type) && imageFile.size <= maxSize) {
            setIsThereStaticFile(true)
            setFileItem(imageFile)
        } else {
            setFileItem(null)
            setIsThereStaticFile(false)
            setError(true)
        }
    }, [])

    return {
        inputChangeHandler,
        fileItem,
        error,
        closeModal,
        isThereStaticFile,
        onAdd,
        labelRefHandler,
        label,
        handleFile,
    }
}

export default useLogicFile
