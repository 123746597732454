import { ListItem, Skeleton, Stack } from "@mui/material"

export const SkeletonNumberItemStat = ({ color }: { color: string }) => {
    return (
        <ListItem
            sx={{ flexDirection: "column", backgroundColor: color }}
            className="grid-item"
            data-testid="item-stat"
        >
            <Skeleton
                variant="rectangular"
                width={"100%"}
                height={24}
                sx={{ backgroundColor: "rgba(250, 250, 250, 0.3)" }}
            />
            <Stack flexDirection={"row"} gap={2} width={"100%"} alignItems="center">
                <Skeleton
                    variant="rectangular"
                    width={"50%"}
                    height={24}
                    sx={{ backgroundColor: "rgba(250, 250, 250, 0.3)" }}
                />
            </Stack>
        </ListItem>
    )
}
