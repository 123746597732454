import { ResellerApi } from "@/api"
import { UiService } from "@/services"
import ResellerStore, { IResellerSetter, IResellerStore } from "@/store/reseller"

class ResellerService {
    getState = (): IResellerStore & IResellerSetter => {
        return ResellerStore.getState()
    }

    setState = (update: Partial<IResellerStore>): void => {
        const { updateState } = this.getState()
        updateState(update)
    }

    getResellerInfos = async () => {
        const reseller = await ResellerApi.fetchResellerInfos(window.location.hostname)

        const resellerState = {
            resellerLogoDark: reseller?.vendor?.logoDark,
            resellerLogo: reseller?.vendor?.logo,
            resellerFavicon: reseller?.vendor?.favicon,
            resellerName: reseller?.vendor?.name,
            resellerFetched: true,
            googleIdentityClientId: reseller?.vendor?.googleIdentityClientId,
        }

        UiService.setState({ favicon: reseller?.vendor?.favicon })
        ResellerStore.getState().updateState(resellerState)
        return resellerState
    }
}

export default new ResellerService()
