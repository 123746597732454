import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()((theme) => ({
    blocSuggestion: {
        "@media (min-width: 1024px)": {
            width: "auto",
            maxWidth: "600px",
        },
        "& .text-black": {
            color: theme.palette.common.black,
        },
        "& .notification": {
            background: "#E5F7FD",
            padding: 16,
            borderRadius: 8,
            width: "100%",
            boxSizing: "border-box",
        },
        "& .title-wrapper": {
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            "& h2": {
                textAlign: "start",
                width: "100%",
            },
        },
        "& .checkbox": {
            padding: 0,
        },
        "& .action-wrapper": {
            display: "flex",
            flexDirection: "column",
            "@media (min-width: 768px)": {
                flexDirection: "row",
            },
        },
        "& .mention-blue": {
            color: theme.palette.action.active,
            p: {
                margin: 0,
            },
            "& .mention": {
                border: "none",
                color: theme.palette.primary.main,
            },
        },
        "& .bloc-checkbox": {
            alignItems: "flex-start",
            margin: 0,
            gap: 8,
        },
    },
}))

export default useStyles
