import Icon from "@/components/base/Icon"
import { Skeleton, Stack } from "@mui/material"

const SkeletonCampaignOverviewsStatistique = () => {
    return (
        <Stack flexDirection={"row"} justifyContent={"space-between"} padding="8px 0px">
            <Skeleton variant="rectangular" width={32} height={23} sx={{ minWidth: "22px" }} />

            <Icon
                name="chevronRight"
                sx={{
                    color: "#68738DA3",
                }}
            />
            <Skeleton variant="rectangular" width={32} height={23} sx={{ minWidth: "24px" }} />

            <Icon
                name="chevronRight"
                sx={{
                    color: "#68738DA3",
                }}
            />
            <Skeleton variant="rectangular" width={32} height={23} sx={{ minWidth: "24px" }} />
        </Stack>
    )
}

export default SkeletonCampaignOverviewsStatistique
