import { ResellerApi } from "@/api"
import useFormater from "@/hooks/useFormater"
import useLocation from "@/hooks/useLocation"
import { useCallback, useEffect } from "react"
import OverviewMainInfoStore from "."

const useMainInfoStore = () => {
    const { location } = useLocation()
    const { update, ...storeProps } = OverviewMainInfoStore()
    const { parseAddress, internationalPhone } = useFormater()

    const getAddress = useCallback(
        (payload) => {
            if (!payload) {
                return ""
            }
            return parseAddress({
                region: payload.region,
                countryCode: payload.country,
                address: payload.address,
                city: payload.city,
                zip: payload.zip,
            })
        },
        [parseAddress]
    )

    const getPhone = useCallback(
        (payload) => {
            if (!payload?.phone) {
                return ""
            }
            return payload?.countryPhone ? internationalPhone(payload.phone, payload.countryPhone) : payload.phone
        },
        [internationalPhone]
    )

    const fetchCompanyInfo = useCallback(
        async (companyId: string, user_uid: string) => {
            if (companyId && user_uid) {
                const response: any = await ResellerApi.fetchCompanyInfo({ id: companyId, user_uid })
                if (response) {
                    update({ companyName: response.name })
                }
            }
        },
        [update]
    )

    const setMainInfo = useCallback((payload) => {
        update({
            address: getAddress(payload),
            phone: getPhone(payload),
            region: payload.region,
            countryCode: payload.country,
            city: payload.city,
            zip: payload.zip,
            companyId: payload.companyId,
            countryPhone: payload.countryPhone,
            email: payload.email,
            id: payload.id,
            logo: payload.logo,
            name: payload.name,
            placeId: payload.placeId,
            status: payload.status,
            storeCode: payload.storeCode,
            uid: payload.uid,
            isAddressVisible: payload?.serviceArea?.businessType === "CUSTOMER_AND_BUSINESS_LOCATION",
            websiteUrl: payload?.websiteUrl,
        })
    }, [])

    useEffect(() => {
        if (location) {
            setMainInfo(location)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        location?.region,
        location?.country,
        location?.address,
        location?.city,
        location?.zip,
        location?.phone,
        location?.countryPhone,
        location?.companyId,
        location?.email,
        location?.id,
        location?.logo,
        location?.name,
        location?.placeId,
        location?.status,
        location?.storeCode,
        location?.uid,
        location?.serviceArea?.businessType,
        location?.websiteUrl,
        setMainInfo,
    ])

    return {
        ...storeProps,
        update,
        setMainInfo,
        fetchCompanyInfo,
    }
}

export default useMainInfoStore
