import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()((theme) => ({
    list: {
        padding: 0,
        display: "flex",
        gap: 8,
        flexDirection: "column",
        "@media (min-width: 768px)": {
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 16,
        },
        "& .MuiListItem-root": {
            borderRadius: 16,
            display: "flex",
            gap: 8,
            flex: "1 1",
            padding: "16px",
            "@media (min-width: 768px)": {
                padding: "8px 16px",
            },
            "& > span": {
                fontSize: 48,
                color: "inherit",
                lineHeight: "123.5%",
            },
            "& > .MuiBox-root": {
                display: "flex",
                gap: 4,
                alignItems: "center",
                "& > svg": {
                    width: 18,
                    height: 18,
                },
            },
        },
    },
}))

export default useStyles
