import useUiPageSession from "@/hooks/useUiPageSession"
import CompaniesStore from "@/store/companies"
import { Fragment, useEffect } from "react"
import EditCompany from "./edit-company"
import ListCompany from "./list-company"
import { CompanyOverview } from "./overview-company"

import useProfileRole from "@/hooks/useProfileRole"
import { useNavigate } from "react-router-dom"

const CompaniesPage = () => {
    const { handleDrawerClose, showPage, open } = useUiPageSession({
        title: "COMPANIES.TITLE_HEADER",
        page: "companies",
    })

    const navigate = useNavigate()
    const { isAdminCompany } = useProfileRole()

    const { update } = CompaniesStore()

    useEffect(() => {
        return () => {
            update({ refetchCompanies: true })
        }
    }, [update])

    if (!isAdminCompany()) {
        navigate("/404")
        return
    }

    return (
        <Fragment>
            <ListCompany />
            {/* <CreateCompany open={showPage === "create" && open} onClose={handleDrawerClose} /> */}
            <EditCompany open={showPage === "edit" && open} onClose={handleDrawerClose} />
            <CompanyOverview open={showPage === "detail" && open} onClose={handleDrawerClose} />
        </Fragment>
    )
}

export default CompaniesPage
