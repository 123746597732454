import { ResellerApi } from "@/api"
import useLocation from "@/hooks/useLocation"
import useNotification from "@/hooks/useNotification"
import useBusinessSpecialHoursStore from "@/store/overview/special-hours"
import UiStore from "@/store/ui"
import UiPageSessionStore from "@/store/ui-page-session"
import { ExceptionalScheduleItemType } from "@/types/businesses"
import { validateHours } from "@/utils"
import parseSpecialHours from "@/utils/parseSpecialHours"
import { validateDays } from "@/utils/validateDay"
import { zodResolver } from "@hookform/resolvers/zod"
import dayjs from "dayjs"
import { useCallback, useEffect, useMemo, useState } from "react"
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { z } from "zod"
import useBusinessLogic from "../useBusinessLogic"

interface EditScheduleValue {
    specialHours: []
}

const useLogic = () => {
    const { navigateTo } = useBusinessLogic()
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const [isFormValid, setIsFormValid] = useState(false)
    const [isFormSubmitted, setIsFormSubmitted] = useState(false)
    const { location } = useLocation()
    const { notif } = useNotification()
    const { preferredLanguage } = UiStore()
    const { previousItem, updatePreviousItem, updateState: setUiPageSession } = UiPageSessionStore()
    const [scheduleList, setScheduleList] = useState<ExceptionalScheduleItemType[]>([])
    const { updateSpecialHours, specialHours } = useBusinessSpecialHoursStore()
    const defaultValue = useMemo(() => {
        return {
            startDate: null,
            endDate: null,
            isOpen: false,
            isAvailableAllDay: false,
            dateValid: false,
            times: [
                {
                    start: null,
                    end: null,
                    startValid: false,
                    endValid: false,
                },
            ],
        }
    }, [])

    const showAa = useMemo(() => {
        let value = false
        if (preferredLanguage?.toLowerCase() == "en") {
            value = true
        }
        return value
    }, [preferredLanguage])

    const onErrors: SubmitErrorHandler<any> = async (errors) => {
        console.log(errors)
    }
    const schema = useMemo(() => {
        return z.object({
            specialHours: z.string().array().optional(),
        })
    }, [])

    const { handleSubmit } = useForm<EditScheduleValue>({
        defaultValues: {
            specialHours: [],
        },
        resolver: zodResolver(schema),
        mode: "onTouched",
    })

    const handlePreviousButton = () => {
        navigateTo("detail")
        updatePreviousItem({
            specialDays: scheduleList,
        })
        setIsFormSubmitted(false)
    }

    const handleAddNewExceptionHour = () => {
        if (scheduleList?.length > 0) setScheduleList((previous) => [{ ...defaultValue }, ...previous])
        else setScheduleList([defaultValue])

        updatePreviousItem({
            specialDays: [{ ...defaultValue }, ...scheduleList],
        })
    }

    const handleCancelButton = () => {
        navigateTo("detail")
        updatePreviousItem({
            specialDays: parseSpecialHours(specialHours, showAa),
        })
        setScheduleList(parseSpecialHours(specialHours, showAa))
        setIsFormSubmitted(false)
    }

    const pushHours = (day) => {
        const newArrayHours = []
        for (const hour of day.times || []) {
            if (hour.start && hour.end) {
                let tomorrowDayName = dayjs(day.startDate).format("YYYY-MM-DD")
                if (hour.tomorrow) {
                    tomorrowDayName = dayjs(day.startDate).add(1, "day").format("YYYY-MM-DD")
                }
                newArrayHours.push({
                    startDate: dayjs(day.startDate).format("YYYY-MM-DD"),
                    openTime: hour.start.format("HH:mm"),
                    endDate: tomorrowDayName,
                    closeTime: hour.end.format("HH:mm"),
                })
            }
        }
        return newArrayHours
    }

    const onSubmit: SubmitHandler<any> = useCallback(async () => {
        if (isFormValid) {
            setLoading(true)
            const body = { ...location }
            const arrayHours = []
            previousItem.specialDays?.map((day) => {
                if (day.isOpen) {
                    if (day.isAvailableAllDay) {
                        const endDate = dayjs(day.startDate).format("YYYY-MM-DD")

                        arrayHours.push({
                            startDate: dayjs(day.startDate).format("YYYY-MM-DD"),
                            openTime: "00:00",
                            endDate: endDate,
                            closeTime: "00:00",
                        })
                    } else {
                        arrayHours.push(...pushHours(day))
                    }
                } else {
                    arrayHours.push({
                        startDate: dayjs(day.startDate).format("YYYY-MM-DD"),
                        closed: true,
                    })
                }
            })

            body["specialHours"] = [...arrayHours]

            const response = await ResellerApi.updateLocation({ ...body })
            if (response?.error) {
                notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
            } else {
                setUiPageSession({ refreshItems: true })
                notif({ message: t("BUSINESSES.EDIT_SUCCESS"), type: "SUCCESS" })
                navigateTo("detail")
                // updateLocation(response)
                updateSpecialHours({
                    specialHours: response?.specialHours,
                })
            }
            setLoading(false)
        }
        setIsFormSubmitted(true)
    }, [isFormValid, location, previousItem?.specialDays])

    useEffect(() => {
        if (location && Object.keys(location).length > 0) {
            setScheduleList((previousDays) => {
                if (previousItem?.specialDays) {
                    return previousItem.specialDays
                } else {
                    return previousDays
                }
            })
        }
    }, [preferredLanguage, showAa, location, previousItem?.specialDays])

    useEffect(() => {
        setIsFormSubmitted(false)
        const isValid = validateHours(scheduleList)
        const isValidDate = validateDays(scheduleList)
        setIsFormValid(isValid && isValidDate)
    }, [scheduleList])

    return {
        t,
        scheduleList,
        location,
        loading,
        isFormValid,
        isFormSubmitted,
        navigateTo,
        setScheduleList,
        setIsFormValid,
        handleSubmit,
        onSubmit,
        onErrors,
        handlePreviousButton,
        handleCancelButton,
        validateHours,
        handleAddNewExceptionHour,
    }
}

export default useLogic
