import useBusinessLogic from "@/hooks/businesses/useBusinessLogic"
import useLocation from "@/hooks/useLocation"
import useLocationStore from "@/store/location"
import useBusinessRegularHoursStore from "@/store/overview/regular-hours"
import { Box, Paper, Typography } from "@mui/material"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import OverviewTitle from "../title"

const OverviewScheduleStandard = ({ isLoading }) => {
    const { navigateTo } = useBusinessLogic()
    const { t } = useTranslation()
    const { location } = useLocationStore()
    const { scheduleStatus } = useLocation()

    const { status, regularHours, updateRegularHours } = useBusinessRegularHoursStore()
    useEffect(() => {
        if (location?.status || location?.regularHours) {
            updateRegularHours({
                status: location?.status,
                regularHours: location?.regularHours,
            })
        }
    }, [location])
    return (
        <Paper
            elevation={2}
            className="clickable"
            id="regular-hours"
            data-e2e="edit-standard"
            onClick={() => !isLoading && navigateTo("edit-standard-schedule")}
        >
            <Box className="status-schedule-container">
                <OverviewTitle
                    title={t("BUSINESSES.SCHEDULE.BUSINESS_STATUS")}
                    // icon="calendar"
                    isSecondTitle
                />
                <Typography component={"span"}>
                    {status &&
                        scheduleStatus?.find((scheduleStatus) => {
                            if ((regularHours?.length === 0 || !regularHours) && status === "OPEN") {
                                return scheduleStatus?.value === "OPEN_WITHOUT_STANDARD_HOURS"
                            } else {
                                return scheduleStatus?.value === status
                            }
                        })?.label}
                </Typography>
            </Box>
        </Paper>
    )
}

export default OverviewScheduleStandard
