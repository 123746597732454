import { makeStyles } from "tss-react/mui"

export const useStyles = makeStyles()((theme) => ({
    blocStepper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 8,
        "& .ligne": {
            width: "60px",
            "@media (max-width: 767px)": {
                width: "30px",
            },
        },
    },
}))
