import { ResellerApi } from "@/api"
import useNotification from "@/hooks/useNotification"
import useLocationStore from "@/store/location"
import UiPageSessionStore from "@/store/ui-page-session"
import { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"

export const useLogic = ({ callback }) => {
    const [loading, setLoading] = useState(false)
    const { location } = useLocationStore()
    const { notif } = useNotification()
    const { t } = useTranslation()
    const { updateState: setUiPageSession } = UiPageSessionStore()

    const onDisconnect = useCallback(
        async (provider = "facebook") => {
            const { companyId, id: locationId } = location
            setLoading(true)
            let response = null
            if (provider === "facebook") {
                response = await ResellerApi.disconnectFacebook(locationId, companyId)
            } else if (provider === "instagram") {
                response = await ResellerApi.disconnectInstagram(locationId)
            }
            if (!response?.error) {
                notif({ message: t("BUSINESSES.EDIT_SUCCESS"), type: "SUCCESS" })
                setUiPageSession({ refreshItems: true })
            }
            if (typeof callback === "function") {
                callback()
            }
            setLoading(false)
        },
        [callback, location, notif, setUiPageSession, t]
    )

    return {
        loading,
        onDisconnect,
    }
}
