import { ErrorResponse } from "@/api/base"
import { ApiService } from "../.."

export interface FetchReviewProps {
    company_uid: string
}

export interface ReviewBody {
    title: string
    subject: string
    content: string
    lang: string
}

export interface ReviewTemplate {
    id: string
    title: string
    subject: string
    lang: string
    content: string
    created: string
    updated: string
}

export interface ReviewsTemplateResponse {
    has_model: boolean
    data: ReviewTemplate[]
}

interface UpdateReviewsProps {
    company_uid: string
    template_uid: string
    body: ReviewBody
}

interface CreateReviewProps {
    company_uid: string
    body: ReviewBody
}

class EditModelApi {
    fetchReviews = async ({
        company_uid,
    }: FetchReviewProps): Promise<ReviewsTemplateResponse & Partial<ErrorResponse>> => {
        return await ApiService.get(`/template/reviews/${company_uid}`)
    }

    createReview = async ({
        company_uid,
        body,
    }: CreateReviewProps): Promise<ReviewTemplate & Partial<ErrorResponse>> => {
        return await ApiService.post(`/template/reviews/${company_uid}`, body)
    }

    updateReview = async ({
        company_uid,
        template_uid,
        body,
    }: UpdateReviewsProps): Promise<ReviewTemplate & Partial<ErrorResponse>> => {
        return await ApiService.put(`/template/reviews/${company_uid}/${template_uid}`, body)
    }

    deleteReview = async ({
        company_uid,
        template_uid,
    }: {
        company_uid: string
        template_uid: string
    }): Promise<Partial<ErrorResponse>> => {
        return await ApiService.delete(`/template/reviews/${company_uid}/${template_uid}`)
    }
}

export default new EditModelApi()
