import useWindowSize from "@/hooks/useWindowSize"
import { useRef, useState, useEffect } from "react"

const useMediaLogic = ({ imageSize }) => {
    const mediaWrapperRef = useRef<HTMLElement>()
    const [supportedBoxes, setSupportedBoxes] = useState<number>()
    const [windowWidth] = useWindowSize()

    useEffect(() => {
        if (mediaWrapperRef.current) {
            const mediaWrapperWidth = mediaWrapperRef.current.getBoundingClientRect().width
            setSupportedBoxes(Math.floor(mediaWrapperWidth / (imageSize + 18)))
        }
    }, [imageSize, windowWidth])

    return { supportedBoxes, mediaWrapperRef }
}

export default useMediaLogic
