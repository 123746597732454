import { LocationsApi } from "@/api"
import Icon from "@/components/base/Icon"
import CustomTooltip from "@/components/base/Tooltip"
import useAuthInstagram from "@/hooks/oauth02/useAuthInstagram"
import { modalStyle } from "@/pages/users/styles"
import useLocationStore from "@/store/location"
import UserSessionStore from "@/store/user-session"
import { withSxProp } from "@/utils"
import { Box, Button, CircularProgress, IconButton, Modal, Paper, Typography } from "@mui/material"
import { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"

const ConnectInstagramModal = ({ open, onClose }) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const { location } = useLocationStore()
    const { profile } = UserSessionStore()
    const { openInstagramAndFacebookAccount } = useAuthInstagram({ location })
    const onConnect = useCallback(async () => {
        setLoading(true)
        const resp = await LocationsApi.fetchIgAccounts({
            company_uid: location?.companyId,
            extract: true,
        })
        if (!resp?.error) {
            openInstagramAndFacebookAccount({
                companyId: location?.companyId,
                user_id: profile?.uid,
                location_id: location?.id,
                multiples_accounts: resp?.length > 0,
            })
        }
        setLoading(false)
    }, [location])

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Paper elevation={6} sx={withSxProp(modalStyle, {})}>
                <Box className="title-wrapper">
                    <Typography id="modal-modal-title" variant="h2" sx={{ lineBreak: "anywhere" }}>
                        {t("BUSINESSES.INSTAGRAM.CONTINUE_IG")}
                    </Typography>
                    <CustomTooltip title={t("USERS.CLOSE")}>
                        <IconButton onClick={onClose}>
                            <Icon name="x" />
                        </IconButton>
                    </CustomTooltip>
                </Box>
                <Typography
                    sx={{
                        color: "action.active",
                    }}
                    dangerouslySetInnerHTML={{
                        __html: t("BUSINESSES.INSTAGRAM.MODAL_DESCRIPTION"),
                    }}
                ></Typography>
                {/* <Typography
                    sx={{
                        "& a": {
                            display: "block",
                            color: "primary.main",
                        },
                    }}
                >
                    <a href="#">{t("BUSINESSES.INSTAGRAM.CONTACT_US")}</a>
                </Typography> */}
                <Box className="action-wrapper">
                    <Button onClick={onClose}>{t("USERS.CANCEL")}</Button>
                    <Button
                        variant="contained"
                        className="disconnect"
                        onClick={onConnect}
                        data-e2e="disconnect-facebook-button"
                        data-testid="disconnect-facebook-button"
                        disabled={loading}
                    >
                        {loading && (
                            <CircularProgress
                                size={16}
                                sx={{
                                    marginRight: 1,
                                }}
                            />
                        )}
                        {t("CAMPAIGN.CONTINUE")}
                    </Button>
                </Box>
            </Paper>
        </Modal>
    )
}

export default ConnectInstagramModal
