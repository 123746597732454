import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()((theme) => ({
    drawer: { zIndex: 1 },
    container: {
        padding: 0,
        width: "100%",
        boxShadow: "none",
        gap: 16,
        backgroundColor: theme.palette.background.default,
        "@media (min-width: 768px)": {
            width: "calc(100% - 251px)",
        },
        "& .children-wrapper": {
            display: "flex",
            flexDirection: "column",
            gap: 16,
            height: "100%",
            "@media (min-width: 768px)": {
                gap: 24,
            },
            "& form": {
                height: "100%",
                display: "flex",
                flexDirection: "column",
                borderRadius: 8,
                justifyContent: "space-between",
                backgroundColor: "transparent",
                padding: "0px 8px",
                "@media (min-width: 768px)": {
                    gap: 24,
                    padding: "0px 24px ",
                },
            },
            "& .inputs-container": {
                display: "flex",
                flexDirection: "column",
                gap: 16,
                paddingBottom: "16px",
                "@media (min-width: 768px)": {
                    gap: 24,
                },
                "& .accordion": {
                    "& .accordion-header": {
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: 8,
                        "& h2": {
                            margin: "auto 0",
                        },
                    },
                    "& .MuiButton-text": {
                        padding: 0,
                        minWidth: 0,
                    },
                    "& .chips-wrapper": {
                        display: "flex",
                        gap: 8,
                        flexWrap: "wrap",
                        "& .MuiChip-root": {
                            padding: "3px 4px",
                            "& span": {
                                padding: "0 6px",
                            },
                        },
                    },
                    "& .MuiCollapse-root": {
                        "& .MuiCollapse-wrapperInner": {
                            display: "flex",
                            flexDirection: "column",
                            gap: 16,
                            "& .MuiPaper-root": {
                                padding: 8,
                                display: "flex",
                                boxShadow: "none",
                                borderRadius: 8,
                                border: "1px solid var(--Divider, rgba(91, 125, 216, 0.16))",
                                gap: 8,
                                flexDirection: "column",
                                "@media (min-width: 768px)": {
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                },
                                "& p": {
                                    // whiteSpace: "nowrap",
                                    "@media (min-width: 768px)": {
                                        marginTop: 9,
                                    },
                                },
                                "& .MuiFormGroup-root": {
                                    "@media (min-width: 768px)": {
                                        flexDirection: "row",
                                        justifyContent: "flex-end",
                                    },
                                },
                            },
                        },
                    },
                },
            },
            "& h2": {
                fontSize: 24,
                color: theme.palette.common.black,
            },
            "& .title": {
                display: "flex",
                gap: 16,
                alignItems: "center",
                fontSize: 24,
                color: theme.palette.common.black,
                padding: 8,
                fontWeight: 500,
                "@media (min-width: 768px)": {
                    padding: "0 8px",
                },
                "& .title-icon": {
                    color: theme.palette.action.active,
                },
                "& h2": {
                    fontSize: 24,
                    color: theme.palette.common.black,
                    wordBreak: "break-word",
                },
                "& svg": {
                    minWidth: 24,
                    color: theme.palette.action.active,
                },
            },
        },
    },
}))

export default useStyles
