// TODO document why this arrow function is empty

import Icon from "@/components/base/Icon"
import IconNames from "@/types/icons"
import { FormControl, InputLabel, MenuItem, Select, SxProps, Typography } from "@mui/material"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { defaultSx, inputLabelSx } from "./style"

export interface FlagOptionType {
    value?: string
    label?: string
    flag?: IconNames
}

export interface SelectLangProps {
    className?: string
    required?: boolean
    options: FlagOptionType[]
    disabled?: boolean
    formStateError?: boolean
    label?: string
    id?: string
    testId?: string
    defaultValue: string
    sx?: SxProps | any
    onItemClicked?: (item: any) => void
}

const SelectLang = ({
    className,
    required = false,
    options = [],
    disabled = false,
    formStateError,
    label = "",
    id = "",
    defaultValue = "",
    sx,
    onItemClicked,
}: SelectLangProps) => {
    const { t } = useTranslation()
    const sortedOptions = useMemo(
        () =>
            options.sort(function (a, b) {
                return a.label.localeCompare(b.label)
            }),
        [options]
    )

    const defaultSelectedValue = useMemo(() => defaultValue, [defaultValue])

    return (
        <FormControl
            fullWidth
            className={className}
            variant="filled"
            required={required}
            disabled={disabled}
            focused={false}
            aria-label="custom-select"
        >
            <InputLabel sx={{ ...inputLabelSx(formStateError) }} error={formStateError} id={id}>
                {label}
            </InputLabel>
            <Select
                value={defaultSelectedValue}
                labelId={id}
                MenuProps={{ className: "select-menu-wrapper" }}
                sx={{
                    ...defaultSx({
                        noBottomBorder: false,
                        showLabelStar: true,
                    }),
                    ...sx,
                }}
            >
                {(!sortedOptions || sortedOptions?.length === 0) && (
                    <MenuItem value="" sx={{ justifyContent: "center" }}>
                        <Typography component={"span"} sx={{ color: "action.active" }}>
                            {t("AUTOCOMPLETE.NO_OPTIONS")}
                        </Typography>
                    </MenuItem>
                )}
                {sortedOptions?.map((option, key) => (
                    <MenuItem
                        value={option.value}
                        key={`${String(key)}`}
                        sx={{
                            paddingBottom: "8px",
                            paddingTop: "8px",
                            gap: "8px",
                        }}
                        onClick={() => {
                            if (typeof onItemClicked === "function") {
                                onItemClicked(option)
                            }
                        }}
                    >
                        <Icon name={option?.flag}></Icon>
                        {option?.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default SelectLang
