import { EditModelApi } from "@/api"
import { ReviewBody } from "@/api/campaign/edit-model"

class EditModelService {
    fetchReviews = async (company_uid: string) => {
        return await EditModelApi.fetchReviews({
            company_uid,
        })
    }

    createReview = async (company_uid: string, body: ReviewBody) => {
        return await EditModelApi.createReview({
            company_uid,
            body,
        })
    }

    updateReview = async (company_uid: string, template_uid: string, body: ReviewBody) => {
        return await EditModelApi.updateReview({
            company_uid,
            template_uid,
            body,
        })
    }

    deleteReview = async (company_uid: string, template_uid: string) => {
        return await EditModelApi.deleteReview({
            company_uid,
            template_uid,
        })
    }
}

export default new EditModelService()
