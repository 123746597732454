import Notification from "@/components/notification"
import { CookiesService } from "@/services"
import LangService from "@/services/lang"
import ResellerStore from "@/store/reseller"
import UiStore from "@/store/ui"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Outlet } from "react-router-dom"

const GlobalOutlet = () => {
    const { resellerName } = ResellerStore()
    const { title, favicon, preferredLanguage, updateState } = UiStore()
    const { i18n, t, ready } = useTranslation()

    useEffect(() => {
        document.title = title ? `${resellerName ?? ""} - ${t(title)}` : resellerName
    }, [resellerName, t, title])

    useEffect(() => {
        if (favicon) {
            const link = document.querySelector("link#favicon")
            if (link) link.setAttribute("href", favicon + "?v=2")
        }
    }, [favicon])

    useEffect(() => {
        function handleLanguageChange() {
            const language = LangService.preloadLanguage(navigator.languages)
            updateState({ preferredLanguage: language })
        }
        window.addEventListener("languagechange", handleLanguageChange)
        return () => {
            window.removeEventListener("languagechange", handleLanguageChange)
        }
    }, [updateState])

    useEffect(() => {
        const language = LangService.preloadLanguage(navigator.languages)
        document.documentElement.lang = language
        CookiesService.set({ lang: language })
        updateState({ preferredLanguage: language })
    }, [updateState])

    useEffect(() => {
        i18n.changeLanguage(preferredLanguage)
    }, [preferredLanguage, i18n])

    return (
        <React.Fragment>
            {ready && (
                <>
                    <Notification />
                    <Outlet />
                </>
            )}
        </React.Fragment>
    )
}

export default GlobalOutlet
