import { ResellerApi, SocialsApi } from "@/api"
import { PayloadPost } from "@/api/socials"
import usePageLogic from "@/hooks/social/usePageLogic"
import useQuerySocial from "@/hooks/social/useQuerySocial"
import useNotification from "@/hooks/useNotification"
import { SocialService } from "@/services"
import useLocationStore from "@/store/location"
import SocialStore, { initSocialProvider } from "@/store/social"
import SocialPopupStore from "@/store/social-popup"
import { IMedia, IMediaPost, PostData, PostValuesForm, Provider } from "@/types/socials"
import { getMinDateByLocationIds, isDeepEqual, isValidUrlForSocial } from "@/utils"
import { ErrorDuplicate, mediaUploadList } from "@/utils/media"
import { zodResolver } from "@hookform/resolvers/zod"
import dayjs from "dayjs"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useForm, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import { z } from "zod"

const usePostForm = () => {
    const { t } = useTranslation()
    const { notif } = useNotification()
    const { businesses: listBusiness, businessesTimezone } = useLocationStore()
    const [searchParams] = useSearchParams()
    const [isOpenMedia, setIsOpenMedia] = useState(false)
    const [addLink, setAddLink] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingDraft, setLoadingDraft] = useState(false)
    const { closeModal } = useQuerySocial()
    const { update, tempPost, updateTempPost, errorPost, post, selectedBusinesses, postItems } = SocialStore()
    const { fetchPosts } = usePageLogic()
    const [errorUpload, setErrorUpload] = useState<boolean>(false)
    const [isInvalidMedias, setIsInvalidMedias] = useState(false)
    const { showPopupContent } = SocialPopupStore()
    const postId = searchParams.get("edit-post")
    const isNewPost = searchParams.get("create-post")
    const scheduled = searchParams.get("scheduled")

    const isPostError = useMemo(() => {
        return tempPost?.status === "LIVE_W_ERROR"
    }, [tempPost?.status])

    const isModify = useMemo(() => {
        return tempPost?.isModify
    }, [tempPost?.isModify])

    const locationIdSingle = useMemo(() => {
        if (listBusiness?.length === 1) {
            const locationIds = listBusiness.map((item) => String(item?.value))
            return locationIds
        }
        return []
    }, [listBusiness])

    const isEditable = useMemo(() => {
        if (["IN_PROGRESS", "LIVE", "LIVE_W_ERROR"].includes(tempPost?.status)) {
            return false
        }

        if (["SCHEDULED", "DRAFT"].includes(tempPost?.status)) {
            return true
        }

        if (tempPost?.publishAt?.length === 0) {
            return true
        }

        if (isNewPost) {
            return true
        }

        return false
    }, [tempPost, isNewPost])

    const schema = z
        .object({
            provider: z.array(z.string()).nonempty(),
            locationIds: z.array(z.string()).nonempty(),
            description: z.string().optional(),
            addDate: z.boolean().optional(),
            hour: z.string().optional(),
            date: z.string().optional(),
            url: z.string().optional(),
        })
        .superRefine(({ description, addDate, hour, date, locationIds }, { addIssue }) => {
            const isMediaNotEmpty =
                (tempPost?.mediasLocal && tempPost?.mediasLocal.length > 0) ||
                (tempPost?.medias && tempPost.medias.length > 0)

            const isPublishAtDateNotValid = addDate && (!date || !hour)

            if (!isMediaNotEmpty && description === "") {
                addIssue({
                    code: "custom",
                    path: ["description"],
                    message: "Description is required if there are no media items.",
                })
                return true
            }

            if (isPublishAtDateNotValid) {
                addIssue({
                    code: "custom",
                    path: ["date", "hour"],
                    message: "hour and date is required if add date is checked",
                })
                return true
            }

            if (addDate && date !== "") {
                const dateToPublish = dayjs(`${date}T${hour}:00`).format("YYYY-MM-DDTHH:mm:ss")
                const publishAtWithUtc = dayjs(dateToPublish)

                const today = getMinDateByLocationIds(locationIds, listBusiness)
                const formattedDay = dayjs(today).format("YYYY-MM-DDTHH:mm:ss")

                if (publishAtWithUtc.isBefore(formattedDay, "day")) {
                    addIssue({
                        code: "custom",
                        path: ["date"],
                        message: "date is anterior to today",
                    })
                    return true
                }
                if (publishAtWithUtc.isBefore(formattedDay)) {
                    addIssue({
                        code: "custom",
                        path: ["hour"],
                        message: "hour is anterior",
                    })
                    return true
                }
            }
        })

    const { formState, control, setValue, trigger, handleSubmit, reset, getValues, setError } = useForm<PostValuesForm>(
        {
            defaultValues: {
                provider: tempPost?.provider || [],
                locationIds: locationIdSingle?.length > 0 ? locationIdSingle : tempPost?.locationIds ?? [],
                description: tempPost?.description || "",
                addDate: tempPost?.addDate || false,
                date: tempPost?.date || dayjs().format("YYYY-MM-DD"),
                hour: tempPost?.hour || "",
                url: tempPost?.url || tempPost?.callToAction?.value || "",
            },
            resolver: zodResolver(schema),
            mode: "onChange",
        }
    )

    const formValues = useWatch({ control })

    const validDraft = useMemo(() => {
        const checkMedia = tempPost?.mediasLocal?.length > 0 || tempPost?.medias?.length
        const checkDescription = formValues?.description !== ""
        const checkLocations = formValues?.locationIds?.length > 0
        if ((checkMedia || checkDescription) && checkLocations) {
            return true
        }
        return false
    }, [formValues, tempPost?.mediasLocal, tempPost?.medias])

    const isSingleLocation = useMemo(() => {
        return listBusiness?.length === 1
    }, [listBusiness])

    const isEditableDate = useMemo(() => {
        return !isEditable && !isPostError
    }, [isEditable, isPostError])

    const minDate = useMemo(() => {
        if (tempPost?.status === "LIVE") {
            return null
        }
        if (formValues.locationIds.length > 0) {
            return getMinDateByLocationIds(formValues.locationIds, listBusiness)
        }
        if (isEditable) {
            return dayjs()
        }
        return null
    }, [isEditable, formValues?.locationIds, listBusiness, tempPost?.status])

    const selectedDate = useMemo(() => {
        if (formValues?.date && formValues?.locationIds.length > 0) {
            const isSameAsToday = dayjs(formValues?.date).isSame(dayjs(), "day")
            if (isSameAsToday) {
                return minDate
            } else {
                return dayjs(formValues?.date)
            }
        }
        return null
    }, [formValues?.date, minDate])

    const handleModify = (value: boolean) => {
        updateTempPost({ isModify: value })
    }

    const getShowPostAlert = useCallback(
        (locationIds: string[]) => {
            if (locationIds.length === 0) {
                return false
            }
            return listBusiness?.some((item) => locationIds.includes(item.value as string) && item?.showPostAlert)
        },
        [listBusiness]
    )

    const checkProvider = useCallback(
        (filteredLocationIds: string[], providers) => {
            const providerToRemove = []
            const tempSocialProvider = { ...initSocialProvider }

            for (const business of filteredLocationIds) {
                const locationState = listBusiness?.find((item) => item.value === business)?.locationState || {}

                if (locationState.facebookStatus === "CONNECTED") {
                    tempSocialProvider.facebook = true
                    tempSocialProvider.numberFacebookConnected += 1
                } else if (locationState.facebookStatus === "DISCONNECTED") {
                    tempSocialProvider.oneOfFacebookNotConnected = true
                }
                if (locationState.instagramStatus === "CONNECTED") {
                    tempSocialProvider.instagram = true
                    tempSocialProvider.numberInstagramConnected += 1
                } else if (locationState.instagramStatus === "DISCONNECTED") {
                    tempSocialProvider.oneOfInstagramNotConnected = true
                }
                if (locationState.status !== "DISCONNECTED") {
                    tempSocialProvider.google = true
                    tempSocialProvider.numberGoogleConnected += 1
                } else if (locationState.status === "DISCONNECTED") {
                    tempSocialProvider.oneOfGoogleNotConnected = true
                }
            }
            ;["facebook", "google", "instagram"].forEach((provider) => {
                if (!tempSocialProvider[provider]) {
                    providerToRemove.push(provider)
                }
            })

            updateTempPost({ socialProvider: tempSocialProvider })
            //Change Form Provider
            const newProviders = providers.filter((provider) => !providerToRemove.includes(provider))

            setValue("provider", newProviders)
        },
        [listBusiness, updateTempPost, setValue]
    )

    const onBusinessChange = useCallback(
        (data) => {
            const filteredLocationIds = data?.map((item) => item.value) || []
            checkProvider(filteredLocationIds, formValues?.provider)
            setValue("locationIds", filteredLocationIds)
            trigger(["locationIds"])
            const showPostAlert = getShowPostAlert(filteredLocationIds)
            update({ showPostAlert, selectedBusinesses: filteredLocationIds })
        },
        [setValue, trigger, getShowPostAlert, checkProvider, formValues?.provider]
    )

    const setDescription = useCallback(
        (messageText) => {
            setValue("description", messageText)
            trigger("description")
        },
        [setValue, trigger]
    )

    const setUrl = useCallback(
        (url: string) => {
            setValue("url", url)
            trigger("url")
        },
        [setValue, trigger]
    )

    const handleModalMedia = useCallback((open: boolean) => {
        setIsOpenMedia(open)
    }, [])

    const handleDeletePost = () => {
        showPopupContent("alert_delete")
    }

    const handleProvider = useCallback(
        (provider: Provider) => {
            let providers = formValues?.provider ?? []
            if (providers.includes(provider)) {
                providers = providers.filter((item) => item !== provider)
            } else {
                providers.push(provider)
            }

            setValue("provider", providers)
            trigger("provider")
        },
        [setValue, trigger, formValues?.provider, tempPost]
    )

    const includeProvider = useCallback(
        (provider: Provider) => {
            return formValues?.provider?.includes(provider)
        },
        [formValues?.provider]
    )

    const isFormValidForInstagram = useMemo(() => {
        if (formValues?.provider?.includes("instagram")) {
            if (!formValues?.description || formValues?.locationIds?.length === 0) {
                return false
            }
            return true
        }
        return true
    }, [formValues?.description, formValues?.locationIds, formValues?.provider])

    const handleMedias = useCallback(
        async (mediaFiles: FileList) => {
            if (mediaFiles?.length > 0) {
                setErrorUpload(false)
                setIsInvalidMedias(false)
                const mediaList = await mediaUploadList(tempPost?.mediasLocal, mediaFiles)
                if ((mediaList as ErrorDuplicate)?.duplicate) {
                    setIsOpenMedia(false)
                } else if ((mediaList as File[]).length > 0) {
                    if (tempPost?.mediasLocal) {
                        const newTab = [...(tempPost?.mediasLocal ?? []), ...(mediaList as File[])]
                        updateTempPost({ mediasLocal: newTab, formHaveChanged: true })
                    } else {
                        updateTempPost({ mediasLocal: mediaList as File[], formHaveChanged: true })
                    }
                    setIsOpenMedia(false)
                } else {
                    setErrorUpload(true)
                }
            }
        },
        [tempPost?.mediasLocal]
    )

    const haveMisingProvider = useMemo(() => {
        const providers = formValues?.provider || []
        return selectedBusinesses.filter((location) => {
            const business = listBusiness?.find((item) => item.value === location)
            const meta = business?.meta || {}

            return providers.some((provider) => {
                if (provider === "facebook" && !meta.facebook_id) {
                    return true
                }
                if (provider === "instagram" && !meta.instagram_id) {
                    return true
                }
                if (provider === "google" && !meta.gmb_id) {
                    return true
                }
                return false
            })
        })
    }, [formValues?.provider, selectedBusinesses, listBusiness])

    const postShoudHaveCallToAction = useMemo(() => {
        return formValues?.provider?.includes("facebook") || formValues?.provider?.includes("google")
    }, [formValues?.provider])

    const onSubmit = useCallback(
        async (data) => {
            let formInvalid = false
            const checkIfInstagramHaventImage =
                formValues?.provider?.includes("instagram") &&
                (tempPost?.mediasLocal === undefined || tempPost?.mediasLocal?.length === 0) &&
                (tempPost?.medias === undefined || tempPost?.medias?.length === 0)
            setIsInvalidMedias(false)
            setLoading(true)
            if (checkIfInstagramHaventImage) {
                setIsInvalidMedias(true)
                formInvalid = true
            }

            if (formValues?.url && !isValidUrlForSocial(formValues?.url)) {
                setError("url", {
                    type: "custom",
                    message: "url format invalid",
                })
                formInvalid = true
            }
            if (formInvalid) {
                setLoading(false)
                return
            }

            let responseFile = null
            let input: Partial<PayloadPost> = {
                locationIds: data.locationIds,
                description: data.description,
                medias: [],
                provider: data.provider,
                ...(data?.url
                    ? {
                          callToAction: {
                              key: "LEARN_MORE",
                              value: data?.url,
                          },
                      }
                    : {}),
            }

            const date = formValues?.addDate ? dayjs(`${data.date}T${data.hour}:00`) : dayjs()
            const dateToPublish = date.format("YYYY-MM-DDTHH:mm:ss")

            if (tempPost?.status !== "LIVE_W_ERROR") {
                const publishAt = []
                data.locationIds.forEach((locationId) => {
                    data.provider.forEach((provider) => {
                        publishAt.push({
                            locationId,
                            provider,
                            date: dayjs(dateToPublish).tz(businessesTimezone[locationId]).format("YYYY-MM-DDTHH:mm:ss"),
                            originalDate: dateToPublish,
                        })
                    })
                })
                input.publishAt = publishAt
                input.status = tempPost?.status === "LIVE" ? "LIVE" : formValues?.addDate ? "SCHEDULED" : "NOW"
                input.isScheduled = true
            } else {
                const newPublishAt = SocialService.formattedPublishAt({
                    postItems: postItems,
                    publishAtData: tempPost.publishAt,
                    publishDate: dateToPublish,
                    businessesTimezone: businessesTimezone,
                })
                input.publishAt = newPublishAt
                input.withError = formValues?.addDate ? false : true
                input.status = formValues?.addDate ? "SCHEDULED" : "LIVE"
                input.isScheduled = formValues?.addDate ? true : false
            }

            if (tempPost?.mediasLocal && tempPost?.mediasLocal?.length > 0) {
                const filesToUpload = tempPost?.mediasLocal.map((media) => media.file)
                responseFile = await ResellerApi.postFilesPost({ files: filesToUpload })
            }

            if (responseFile?.error) {
                const messageError =
                    responseFile?.error === "SYSTEM_ERROR.FACEBOOK_ACCOUNT_DISCONNECTED"
                        ? responseFile?.error
                        : "SYSTEM_ERROR.INVALID_REQUEST"
                notif({ message: t(messageError), type: "ERROR" })
            } else {
                let res = null
                let mediasUploaded = tempPost?.medias ?? []
                if (responseFile) mediasUploaded = mediasUploaded.concat(SocialService.formattedMedia(responseFile))

                input = {
                    ...input,
                    repost: tempPost?.repost,
                    medias: mediasUploaded,
                    postId: responseFile ? responseFile.postId : "",
                }

                if (tempPost?.status === "LIVE_W_ERROR") {
                    input = { ...input, isRetry: true }
                }

                if (tempPost?.id && !tempPost?.repost) {
                    res = await SocialsApi.updatePost(tempPost?.id, input, (error) => {
                        console.log(error)
                    })
                } else {
                    res = await SocialsApi.sendPost(input, (error) => {
                        console.log(error)
                    })
                }
                if (res?.id) {
                    await fetchPosts()
                    const status = input.status === "SCHEDULED" ? "scheduled" : "normal"
                    showPopupContent(`success_${status}`)
                    update({
                        tempPost: null,
                        errorPost: false,
                    })
                } else {
                    update({
                        errorPost: true,
                    })
                }
            }
            setLoading(false)
        },
        [
            tempPost?.id,
            tempPost?.mediasLocal,
            tempPost?.medias,
            tempPost?.status,
            tempPost?.repost,
            tempPost?.publishAt,
            notif,
            t,
            fetchPosts,
            update,
            closeModal,
            businessesTimezone,
        ]
    )

    const handleSaveInDraft = useCallback(async () => {
        setLoadingDraft(true)
        let responseFile = null
        let input: Partial<PayloadPost> = {
            locationIds: formValues.locationIds,
            description: formValues.description,
            medias: [],
            provider: formValues.provider,
            status: "DRAFT",
            ...(formValues?.url
                ? {
                      callToAction: {
                          key: "LEARN_MORE",
                          value: formValues?.url,
                      },
                  }
                : {}),
        }

        if (formValues?.addDate) {
            const dateToPublish = dayjs(`${formValues.date}T${formValues.hour}:00`).format("YYYY-MM-DDTHH:mm:ss")
            const publishAt = []
            formValues.locationIds.forEach((locationId) => {
                formValues.provider.forEach((provider) => {
                    publishAt.push({
                        locationId,
                        provider,
                        date: dayjs.utc(dateToPublish).tz(businessesTimezone[locationId]).format("YYYY-MM-DDTHH:mm:ss"),
                        originalDate: dateToPublish,
                    })
                })
            })
            input.publishAt = publishAt
            input.isScheduled = true
        } else {
            input.publishAt = null
            input.isScheduled = false
        }

        //upload media if exist or get existing media
        if (tempPost?.mediasLocal && tempPost?.mediasLocal?.length > 0) {
            const filesToUpload = tempPost?.mediasLocal.map((media) => media.file)
            responseFile = await ResellerApi.postFilesPost({ files: filesToUpload })
        }

        if (responseFile?.error) {
            notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
        } else {
            let res = null
            let mediasUploaded = tempPost?.medias ?? []
            if (responseFile) mediasUploaded = mediasUploaded.concat(SocialService.formattedMedia(responseFile))

            input = {
                ...input,
                repost: tempPost?.repost,
                medias: mediasUploaded,
                postId: responseFile ? responseFile.postId : "",
            }

            if (tempPost?.id && !tempPost?.repost) {
                res = await SocialsApi.updatePost(tempPost?.id, input)
            } else {
                res = await SocialsApi.sendPost(input)
            }
            if (res?.id) {
                await fetchPosts()
                reset()
                closeModal()
                update({
                    tempPost: null,
                    errorPost: false,
                })
                notif({ message: t("SOCIALS.SUCCESS_SAVE_DRAFT"), type: "SUCCESS" })
            }
        }
        setLoadingDraft(false)
    }, [
        formValues.locationIds,
        formValues.description,
        formValues.provider,
        tempPost?.mediasLocal,
        tempPost?.medias,
        tempPost?.id,
        tempPost?.repost,
        notif,
        t,
        fetchPosts,
        update,
        closeModal,
    ])

    const onChangeDate = useCallback(
        (date: any) => {
            setValue("date", date.format("YYYY-MM-DD"))
            trigger(["hour", "date"])
        },
        [setValue, trigger]
    )

    const handleRemoveMedia = useCallback(
        (media: Partial<IMedia>) => {
            if (media?.url) {
                updateTempPost({
                    medias: tempPost?.medias?.filter((item: IMediaPost) => item?.url !== media?.url),
                    formHaveChanged: true,
                })
            } else {
                const newMedias = tempPost?.mediasLocal?.filter((item) => item !== media)
                updateTempPost({ mediasLocal: newMedias, formHaveChanged: true })
            }
        },
        [tempPost?.medias, tempPost?.mediasLocal]
    )

    const handleReusePost = (post: Partial<PostData>) => {
        setValue("date", "")
        setValue("addDate", false)
        setValue("hour", "")
        updateTempPost({ ...post, status: "NOW", publishAt: [], date: "", hour: "", repost: true })
    }

    const getOnePost = useCallback(
        async (postId: string) => {
            const resp = await SocialsApi.getPost(postId)

            if (!resp?.error) {
                const post = SocialService.getOriginalPostFormat({ post: resp, locationIdSingle })
                reset(post)
                const showPostAlert = getShowPostAlert(post.locationIds)
                update({ showPostAlert, post, selectedBusinesses: post?.locationIds, errorPost: false })
                updateTempPost({ ...resp, ...post, status: resp?.withError ? "LIVE_W_ERROR" : resp.status })
                checkProvider(post?.locationIds, post?.provider)
            }
        },
        [reset, locationIdSingle, getShowPostAlert, checkProvider]
    )

    useEffect(() => {
        if (formValues) {
            const tempValues = { ...formValues }
            updateTempPost({ ...tempValues })
            trigger(["description", "locationIds", "addDate", "date", "hour"])
        }
    }, [formValues, trigger])

    useEffect(() => {
        if (locationIdSingle?.length > 0) {
            checkProvider(locationIdSingle, formValues?.provider)
            update({ selectedBusinesses: locationIdSingle })
            setValue("locationIds", locationIdSingle)
        }
        trigger("locationIds")
    }, [locationIdSingle])

    useEffect(() => {
        const isFormChanged = !isDeepEqual(getValues(), post, ["isLastPost"])

        //show input link if link is not empty
        if (formValues.url !== "") {
            setAddLink(true)
        }

        const isNewPostChanged =
            formValues.description !== "" || formValues.url !== "" || tempPost?.mediasLocal?.length > 0
        if (tempPost?.id) {
            if (isFormChanged) {
                updateTempPost({ formHaveChanged: true, ...formValues })
            } else {
                updateTempPost({ formHaveChanged: false })
            }
        } else if (isNewPostChanged) {
            updateTempPost({ formHaveChanged: true, ...formValues })
        } else {
            updateTempPost({ formHaveChanged: false })
        }
        update({ selectedBusinesses: formValues?.locationIds ?? [] })
    }, [formValues, updateTempPost, post, tempPost?.id, tempPost?.mediasLocal])

    useEffect(() => {
        trigger("description")
    }, [tempPost?.medias, tempPost?.mediasLocal, trigger])

    useEffect(() => {
        if (scheduled) {
            setValue("addDate", true)
            setValue("date", scheduled)
        }
    }, [scheduled])

    useEffect(() => {
        if (postId && postId !== tempPost?.id && listBusiness) {
            getOnePost(postId)
        }
    }, [postId, tempPost?.id, getOnePost, listBusiness])

    const handleRemoveLink = () => {
        setValue("url", "")
        setAddLink(false)
    }

    return {
        formState,
        formValues,
        haveMisingProvider,
        isEditable,
        postShoudHaveCallToAction,
        control,
        isOpenMedia,
        loading,
        loadingDraft,
        tempPost,
        errorUpload,
        isFormValidForInstagram,
        validDraft,
        isInvalidMedias,
        onBusinessChange,
        setDescription,
        setUrl,
        handleProvider,
        includeProvider,
        handleMedias,
        onChangeDate,
        handleSubmit,
        onSubmit,
        handleRemoveMedia,
        handleModalMedia,
        setErrorUpload,
        handleSaveInDraft,
        errorPost,
        handleModify,
        isModify,
        handleReusePost,
        isPostError,
        handleDeletePost,
        socialProvider: tempPost?.socialProvider ?? initSocialProvider,
        isSingleLocation,
        setAddLink,
        handleRemoveLink,
        addLink,
        minDate,
        locationIdSingle,
        selectedDate,
        isEditableDate,
    }
}

export default usePostForm
