import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()((theme) => ({
    overviewContact: {
        "& .image-frame": {
            width: 124,
            minWidth: 124,
            height: 124,
            background: "rgba(104, 115, 141, 0.12)",
            borderRadius: 8,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            gap: 8,
            alignItems: "center",
            "& span": {
                fontSize: 14,
                color: theme.palette.action.active,
            },
            "&.image-preview": {
                backgroundColor: "transparent",
                "& img": {
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    borderRadius: 8,
                },
            },
            "&.without-logo": {
                border: `2px solid ${theme.palette.error.main}`,
                span: {
                    color: theme.palette.error.main,
                },
                svg: {
                    path: {
                        stroke: theme.palette.error.main,
                    },
                },
            },
        },
        "& .image-container": {
            display: "flex",
            flexDirection: "column",
            gap: 8,
            "@media (min-width:768px)": {
                flexDirection: "row",
                gap: 16,
            },
            "& .contact-wrapper": {
                display: "flex",
                flexDirection: "column",
                gap: 8,
                width: "100%",
                "body & .title-wrapper": {
                    padding: 0,
                    gap: 8,
                    "& h3": {
                        fontSize: 24,
                        fontWeight: 500,
                    },
                    "& svg": {
                        borderRadius: 0,
                        color: theme.palette.action.active,
                    },
                },
            },
        },
    },
}))

export default useStyles
