import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()((theme) => ({
    container: {
        top: "0",
        position: "absolute",
        "& .MuiModal-backdrop": {
            top: "0",
        },
        ".btn": {
            gap: 1,
            width: "100%",
            border: "none",
            outline: "none",
            "&:hover": {
                border: "none",
            },
        },
    },
}))

export default useStyles
