import BlocSkeleton from "@/components/base/bloc-skeleton"
import useStyles from "@/components/campaign/styles"
import { Skeleton, Stack } from "@mui/material"

export default function SmsSkeleton() {
    const { classes } = useStyles()
    return (
        <Stack className={`${classes.smsBloc} bloc-sms-preview`} gap={3}>
            <Stack gap={1} alignItems="center">
                <Stack className="rectangle"></Stack>
            </Stack>
            <Stack gap={2} sx={{ width: "100%" }}>
                <BlocSkeleton width="70%" totalSkeleton={2} />
                <Stack className="bloc-message">
                    {[...Array(5).keys()].map((x) => (
                        <Skeleton key={x} width="100%" animation={false} />
                    ))}
                </Stack>
                <BlocSkeleton width="50%" totalSkeleton={2} />
            </Stack>
        </Stack>
    )
}
