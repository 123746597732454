import { create } from "zustand"

export interface ISpecialHours {
    specialHours?: object[]
}

export interface IOverviewSpecialHoursStoreSetter {
    updateSpecialHours: (update: Partial<ISpecialHours>) => void
    getInitialState: () => ISpecialHours
    flush: () => void
}

const initalState = {
    specialHours: [],
}

const useBusinessSpecialHoursStore = create<ISpecialHours & IOverviewSpecialHoursStoreSetter>((set) => ({
    ...initalState,
    updateSpecialHours: (update) => set(() => ({ ...update })),
    getInitialState: () => initalState,
    flush: () => set(initalState),
}))

export default useBusinessSpecialHoursStore
