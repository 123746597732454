import { Paper, Skeleton, Stack } from "@mui/material"

export const SkeletonBlocLink = () => {
    return (
        <Paper elevation={2} sx={{ flex: 1 }}>
            <Skeleton variant="rectangular" width={200} height={20} />
            <Stack gap={1} width="100%">
                <Skeleton variant="rectangular" width="40%" height={16} />
                <Skeleton variant="rectangular" width="40%" height={16} />
                <Skeleton variant="rectangular" width="40%" height={16} />
            </Stack>
        </Paper>
    )
}
