import { TextField } from "@mui/material"
import { Fragment, forwardRef, useEffect, useRef } from "react"
import { NumericFormat, NumericFormatProps } from "react-number-format"
import { defaultSx } from "@/components/base/input/style"

interface IPriceInput {
    label: string
    onChange?: (event: { target: { name: string; value: string } }) => void
    value?: string | number
    error?: boolean
    currency?: string
    showLabelStar?: boolean
    noBottomBorder?: boolean
    thousandSeparator?: string
    disabled?: boolean
    focus?: boolean
}

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void
    name: string
    currency: string
    thousandSeparator?: string
}

const NumericFormatCustom = forwardRef<NumericFormatProps, CustomProps>(function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                })
            }}
            thousandSeparator={props.thousandSeparator || ","}
            valueIsNumericString
            suffix={` ${props.currency || ""}`}
        />
    )
})

const InputPrice = ({
    label,
    onChange,
    value,
    error,
    currency,
    showLabelStar = false,
    noBottomBorder = false,
    thousandSeparator = ",",
    disabled = false,
    focus = false,
}: IPriceInput) => {
    const ref = useRef<any>()
    useEffect(() => {
        if (focus && ref) {
            ref?.current?.focus()
        }
    }, [focus, ref])

    return (
        <Fragment>
            <TextField
                label={label}
                variant="filled"
                InputProps={{
                    inputProps: { currency, thousandSeparator },
                    inputComponent: NumericFormatCustom as any,
                }}
                placeholder=""
                onChange={onChange}
                value={value || ""}
                error={error}
                sx={{
                    ...defaultSx({ noBottomBorder, showLabelStar, error }),
                }}
                disabled={disabled}
                autoComplete="off"
                inputRef={ref}
            />
        </Fragment>
    )
}

export default InputPrice
