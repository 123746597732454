const ENV = import.meta.env

export default {
    APP_VERSION: ENV.VITE_VERSION,
    APP_NAME: ENV.VITE_APP_NAME,
    ENV: ENV.VITE_ENV,
    APP_NAME: ENV.VITE_APP_NAME,
    AUTH_URL: ENV.VITE_AUTH_URL,
    GW_BASE_URL: ENV.VITE_GW_BASE_URL,
    DOCS_LINK: ENV.VITE_DOCS_LINK,
    APPBACK_API_PATH: `${ENV.VITE_GW_BASE_URL}/${ENV.VITE_APPBACK_API_PATH}`,
    DISPATCHR_API_PATH: `${ENV.VITE_GW_BASE_URL}/${ENV.VITE_DISPATCHR_API_PATH}`,
    JARVIS_API_PATH: `${ENV.VITE_GW_BASE_URL}/${ENV.VITE_JARVIS_API_PATH}`,
    SENDR_API_PATH: `${ENV.VITE_GW_BASE_URL}/${ENV.VITE_SENDR_API_PATH}`,
    WEBSOCKET_HANDSHAKE_PATH: ENV.VITE_WEBSOCKET_HANDSHAKE_PATH,
    GBP_SCOPE: ENV.VITE_GBP_SCOPE,
    FACEBOOK_SCOPE: ENV.VITE_FACEBOOK_SCOPE,
    INSTAGRAM_SCOPE: `${ENV.VITE_FACEBOOK_SCOPE},${ENV.VITE_INSTAGRAM_SCOPE}`,
    COOKIE_NAME: ENV.VITE_COOKIE_NAME ?? "DILYPSE_APP",
    NOTIFICATION_API_PATH: `${ENV.VITE_NOTIFICATION_API_PATH}`,
    NOTIFICATION_PUBLIC_KEY: ENV.VITE_NOTIFICATION_PUBLIC_KEY,
    FACEBOOK_APP_ID: ENV.VITE_FACEBOOK_APP_ID,
    FEEDBACK_PLATFORM_ID: ENV.VITE_FEEDBACK_PLATFORM_ID,
    GBP_CLIENT_ID: ENV.VITE_GBP_CLIENT_ID,
    GOOGLE_MAPS_API_KEY: ENV.VITE_GOOGLE_MAPS_API_KEY,
    MUIX_API_KEY: ENV.VITE_MUIX_API_KEY,

    IS_STAGING: ENV.VITE_ENV === "staging",
    IS_PROD: ENV.VITE_ENV === "prod",
    IS_DEV: ENV.VITE_ENV === "dev",

    SENTRY_DSN: ENV.VITE_SENTRY_DSN,
    SENTRY_TRACE_SAMPLE_RATE: Number(ENV.VITE_SENTRY_TRACE_SAMPLE_RATE),
    SENTRY_REPLAYS_SESSION_SAMPLE_RATE: Number(ENV.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE),
    SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE: Number(ENV.SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE),
}
