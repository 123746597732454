import { create } from "zustand"

export interface IDescriptionType {
    short?: string
}
export interface IDescriptionStore {
    description?: IDescriptionType
}

const initalState: IDescriptionStore = {
    description: {
        short: "",
    },
}

export interface IDescriptionSetter {
    update?: (update: Partial<IDescriptionStore>) => void
    flush?: () => void
}

const OverviewDescriptionStore = create<IDescriptionStore & IDescriptionSetter>((set) => ({
    ...initalState,
    update: (update) => set(() => ({ ...update })),
    getInitialState: () => initalState,
    flush: () => set(initalState),
}))

export default OverviewDescriptionStore
