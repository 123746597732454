import { convertBigNumber } from "@/utils"
import { Typography } from "@mui/material"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"

export const RenderText = ({ data, type, textSupp }: { data: number; type?: string; textSupp?: string }) => {
    const { t } = useTranslation()
    const isNull = useMemo(() => (!data && data !== 0) || data === undefined, [data])
    const value = useMemo(
        () =>
            data === 0 && ["review_rating_average", "review_treated"].includes(type)
                ? "-"
                : `${convertBigNumber(data)} ${textSupp ?? ""}`,
        [data, type, textSupp]
    )

    return (
        <Typography color={(isNull || value === "-") && "#68738D"}>
            {!isNull ? value : t("LOCAL_PRESENCE.NOT_AVAILABLE")}
        </Typography>
    )
}
