import useBusinessLogic from "@/hooks/businesses/useBusinessLogic"
import useLocationStore from "@/store/location"
import useBusinessServicesAreaStore from "@/store/overview/services-area"
import { Box, Paper } from "@mui/material"
import { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import BoxChip from "../../box-chip"
import OverviewTitle from "../title"

const OverviewServiceArea = ({ serviceAreaRef, isLoading, data }) => {
    const { navigateTo } = useBusinessLogic()
    const { location } = useLocationStore()
    const { t } = useTranslation()
    const { servicesArea, updateServicesArea } = useBusinessServicesAreaStore()

    const servicesAreaMap = useMemo(() => {
        const items = data?.serviceArea || location?.serviceArea?.places?.placeInfos

        if (items?.length > 0) {
            return items.map((element) => {
                return {
                    id: element?.placeId,
                    label: element.name,
                }
            })
        }
        return []
    }, [data?.serviceArea, servicesArea])

    useEffect(() => {
        if (data?.serviceArea || location?.serviceArea) {
            updateServicesArea({
                servicesArea: data?.serviceArea || location?.serviceArea,
            })
        }
    }, [location])
    return (
        <Paper
            elevation={2}
            className="clickable"
            id="services-area"
            data-testid="services-area"
            ref={serviceAreaRef}
            onClick={() => !isLoading && navigateTo("edit-zone")}
            data-e2e="areas-served"
        >
            <OverviewTitle
                title={t("BUSINESSES.AREAS_SERVED")}
                // icon="tag"
                isSecondTitle
                tooltipText={t("BUSINESSES.EDIT_AREAS_SERVED.MAIN_TOOLTIP")}
            />
            {servicesAreaMap?.length > 0 && !isLoading && (
                <Box className="chip-wrapper" data-e2e="area-business" data-testid="area-business">
                    <BoxChip items={servicesAreaMap} isAction={false} />
                </Box>
            )}
        </Paper>
    )
}

export default OverviewServiceArea
