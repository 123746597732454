import { Stack } from "@mui/material"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"

export const PlaceholderQuill = ({ type, editorRef, handleToggleModal }) => {
    const { t } = useTranslation()
    const text = useMemo(() => {
        if (type === "email") {
            return `${t("CAMPAIGN.PLACEHOLDER_EMAIL")} ${t("CAMPAIGN.OR")} `
        } else if (type === "sms") {
            return `${t("CAMPAIGN.PLACEHOLDER_SMS")} ${t("CAMPAIGN.OR")} `
        }
    }, [type])

    return (
        <Stack className={`fake-placeholder ${type}`}>
            <p>
                <span onClick={() => editorRef.current.focus()}>{text}</span>
                <span className="link" onClick={() => handleToggleModal()}>
                    {t("CAMPAIGN.USE_MODEL")}
                </span>
            </p>
        </Stack>
    )
}
