import NotificationStore from "@/store/notification"
import { captureException } from "@sentry/react"
import { AxiosError, isAxiosError } from "axios"
import i18next from "i18next"

class ErrorHandlerRequestService {
    asyncHandlerRequestError = async (callback: any, onError?: any) => {
        try {
            const resp = await callback()
            return resp
        } catch (error) {
            if (typeof onError === "function") {
                return onError(error?.response)
            }
            if (error?.response) {
                if (typeof error.response?.data === "object") {
                    return {
                        ...error.response?.data,
                        status_code: error?.response?.status,
                    }
                }
                return error.response?.data ?? { error: "SYSTEM_ERROR.INVALID_REQUEST" }
            }
            this.unHandledRequestError(error)
            return { error: "SYSTEM_ERROR.INVALID_REQUEST" }
        }
    }

    unHandledRequestError = (error) => {
        NotificationStore.setState({ type: "ERROR", message: i18next.t("SYSTEM_ERROR.INVALID_REQUEST"), show: true })
        if (isAxiosError(error)) {
            const ignoreErrors = [
                AxiosError.ECONNABORTED,
                AxiosError.ERR_CANCELED,
                AxiosError.ERR_NETWORK,
                AxiosError.ETIMEDOUT,
            ]
            if (ignoreErrors.includes(error.code)) {
                return
            }
            captureException(error, { level: "error" })
        }
    }
}

export default new ErrorHandlerRequestService()
