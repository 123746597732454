import { CampaignApi } from "@/api"
import CampaignStore from "@/store/campaign"
import UiStore from "@/store/ui"
import UserSessionStore from "@/store/user-session"
import { useCallback } from "react"

const useCampaignCredit = () => {
    const { update } = CampaignStore()
    const { profile } = UserSessionStore()
    const { preferredLanguage } = UiStore()

    const closeCreditModal = useCallback(() => {
        update({
            noCredit: false,
            creditAsked: false,
        })
    }, [update])

    const askCreditModal = useCallback(
        ({ open = false }) => {
            update({ creditAsked: open })
        },
        [update]
    )

    const sendRequestCredit = useCallback(
        async ({ companyId, type }) => {
            update({
                noCredit: true,
                creditAsked: true,
            })
            if (companyId && profile?.uid && type) {
                const body = {
                    company_uid: companyId,
                    user_uid: profile?.uid,
                    type,
                    lang: preferredLanguage.toLowerCase(),
                }
                await CampaignApi.requestCredit({ body })
            }
        },
        [update, profile?.uid, preferredLanguage]
    )

    const fetchCredit = useCallback(
        async (company_uid: string) => {
            update({
                creditFetched: false,
                credits: [],
            })
            const response = await CampaignApi.getCredit({ company_uid })
            if (response) {
                update({
                    credits: response,
                    creditFetched: true,
                })
            }
        },
        [update]
    )

    return {
        closeCreditModal,
        sendRequestCredit,
        fetchCredit,
        askCreditModal,
    }
}

export default useCampaignCredit
