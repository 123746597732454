import { Drawer, DrawerProps } from "@mui/material"
import { PropsWithChildren, ReactNode } from "react"
import useStyles from "./styles"

interface PropsDrawerBloc extends DrawerProps {
    children: PropsWithChildren<ReactNode>
    classPaper?: any
    classDrawer?: any
}

export const DrawerBloc = ({ children, classPaper, classDrawer, ...props }: PropsDrawerBloc) => {
    const { classes } = useStyles()

    return (
        <Drawer
            PaperProps={{
                className: `${classes.container} ${classPaper}`,
            }}
            className={`${classes.drawer} ${classDrawer}`}
            {...props}
        >
            {children}
        </Drawer>
    )
}
