import { Stack } from "@mui/material"
import useStyles from "../styles"
import EmailEdit from "../view/EmailEdit"
import SmsEdit from "../view/SmsEdit"
import { useCallback } from "react"

type SecondStepCampaignProps = {
    hasModel: boolean
    listReviews: any
    type: string
    atValues: any
    data: any
    register: any
    defaultValue: any
    feedback: boolean
    hasNewUrl: boolean
    formValue: any
    onChange: (e: any) => void
    onCloseReviews: () => void
    setValue: (name: string, value: any) => void
}

const SecondStepCampaign = ({
    hasModel,
    listReviews,
    type,
    atValues,
    data,
    register,
    defaultValue,
    feedback,
    hasNewUrl,
    formValue,
    onChange,
    onCloseReviews,
    setValue,
}: SecondStepCampaignProps) => {
    const { classes } = useStyles()

    const updateFeedback = useCallback(() => {
        setValue("feedback", !formValue?.feedback)
    }, [formValue?.feedback, setValue])

    return (
        <Stack className={classes.stepBloc} gap={2}>
            {type === "email" && (
                <EmailEdit
                    onChange={onChange}
                    onCloseReviews={onCloseReviews}
                    register={register}
                    setValue={setValue}
                    hasModel={hasModel}
                    listReviews={listReviews}
                    atValues={atValues}
                    data={data}
                    feedback={feedback}
                    defaultValue={defaultValue}
                    handleFeedback={updateFeedback}
                    hasNewUrl={hasNewUrl}
                />
            )}
            {type === "sms" && (
                <SmsEdit
                    onChange={onChange}
                    onCloseReviews={onCloseReviews}
                    atValues={atValues}
                    setValue={setValue}
                    hasModel={hasModel}
                    listReviews={listReviews}
                    defaultValue={defaultValue}
                    feedback={feedback}
                    handleFeedback={updateFeedback}
                    hasNewUrl={hasNewUrl}
                />
            )}
        </Stack>
    )
}

export default SecondStepCampaign
