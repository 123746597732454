import Icon from "@/components/base/Icon"
import useWindowSize from "@/hooks/useWindowSize"
import { Stack, Typography } from "@mui/material"
import { useMemo } from "react"
import BusinessSocial from "../social"
import { LocationType } from "@/types/businesses"
import { isGoogleConnected } from "@/utils"

type BusinessAddressProps = {
    location: LocationType
    address: string
}

const BusinessAddress = ({ location, address }: BusinessAddressProps) => {
    const [width] = useWindowSize()
    //TODO: cette variable a été commenté suite au DLP-1735, EN ATTENTE D UN UPDATE AU NIVEAU DE L APP
    // const showFacebookChip = useMemo(() => {
    //     return !item?.meta?.facebook_id
    // }, [item])

    const googleConnected = useMemo(() => {
        return isGoogleConnected(location)
    }, [location])

    const showGoogleChip = useMemo(() => {
        return (
            !location?.locationState?.locationNotFound &&
            !location?.locationState?.needsReconnection &&
            !googleConnected
        )
    }, [location, googleConnected])

    const hideAddress = useMemo(() => {
        if (width <= 480) {
            if (
                // showFacebookChip &&
                showGoogleChip
            ) {
                return true
            }
        }
        return false
    }, [showGoogleChip, width])
    // showFacebookChip
    return (
        <>
            {address && (
                <Stack flexDirection={"row"} width={"100%"} alignItems={"center"} className="address">
                    {!hideAddress && (
                        <>
                            <Icon
                                className="eye"
                                name={
                                    location?.serviceArea?.businessType === "CUSTOMER_AND_BUSINESS_LOCATION"
                                        ? "eye"
                                        : "eyeOff"
                                }
                            />
                            <Typography component="span" className="text-ellipsis text-address">
                                {address}
                            </Typography>
                        </>
                    )}
                    <BusinessSocial showFacebookChip={false} needsReconnection={!googleConnected} />
                </Stack>
            )}
        </>
    )
}

export default BusinessAddress
