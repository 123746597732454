import { UserApi } from "@/api"
import FormContainer from "@/components/base/page-container/form-container"
import { TitleWrapper } from "@/components/base/title-wrapper"
import UiPageSessionStore from "@/store/ui-page-session"
import UserSessionStore from "@/store/user-session"
import { PageProps } from "@/types/page"
import { UserType } from "@/types/user"
import { useTranslation } from "react-i18next"
import UserForm from "../../../components/user/form"
import { useCallback } from "react"

export interface FormValues {
    firstname: string
    lastname: string
    username: string
    email: string
    role: string
    roleByAdminType: any
}

const EditUser = ({ onClose, open }: PageProps) => {
    const { updateState: setUiPageSession } = UiPageSessionStore()
    const { profile, updateProfile } = UserSessionStore()
    const { t } = useTranslation()

    const handleCallApi = useCallback(
        (userObject: UserType) => {
            if (userObject?.uid === userObject.user_uid) {
                updateProfile({
                    firstname: userObject.firstname,
                    lastname: userObject.lastname,
                })
            }
            return UserApi.updateUser(userObject, profile?.uid)
        },
        [profile?.uid, updateProfile]
    )

    const handlePreviousButton = useCallback(() => {
        setUiPageSession({ showPage: "list" })
    }, [setUiPageSession])

    const onCancelForm = useCallback(() => {
        setUiPageSession({ showPage: "list", previousItem: null })
    }, [setUiPageSession])

    return (
        <FormContainer open={open} onClose={onClose}>
            <TitleWrapper
                title={t("USERS.EDITION")}
                tooltipText={t("USERS.BACK_TOOLTIP")}
                handlePreviousButton={handlePreviousButton}
            />
            <UserForm callApi={handleCallApi} onCancelForm={onCancelForm} isUpdate />
        </FormContainer>
    )
}

export default EditUser
