import { SocialsApi } from "@/api"
import SocialStore from "@/store/social"
import UserSessionStore from "@/store/user-session"
import dayjs from "dayjs"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import useNotification from "../useNotification"

const usePageLogic = () => {
    const { profile } = UserSessionStore()
    const { update: updateSocial, businesses, currentMonth } = SocialStore()
    const { notif } = useNotification()
    const { t } = useTranslation()

    const groupPostsByDay = (posts) => {
        return posts.reduce((acc, post) => {
            const currentPost = post?.publishAt ?? []
            // Extract the date part from the publishAt field (YYYY-MM-DD)
            if (currentPost && currentPost.length && typeof currentPost === "object") {
                let publishAt = dayjs(currentPost[0]?.originalDate).utc().format("YYYY-MM-DDTHH:mm:ss")

                const [year, month, day] = publishAt.split("T")[0].split("-")

                const formattedDate = `${year}-${parseInt(month)}-${parseInt(day)}`

                if (!acc[formattedDate]) {
                    acc[formattedDate] = []
                }

                //Verify if post is live or inprogress and group by originalDate
                if (["LIVE", "IN_PROGRESS"].includes(post?.status)) {
                    const uniqueOriginalDates = [...new Set(currentPost.map((item) => item.originalDate))]
                    if (uniqueOriginalDates?.length > 0) {
                        uniqueOriginalDates.forEach((originalDate: string) => {
                            const [year, month, day] = originalDate.split("T")[0].split("-")
                            const formattedOriginalDate = `${year}-${parseInt(month)}-${parseInt(day)}`
                            if (!acc[formattedOriginalDate]) {
                                acc[formattedOriginalDate] = []
                            }
                            acc[formattedOriginalDate].push(post)
                        })
                    }
                } else {
                    acc[formattedDate].push(post)
                }

                // Sort the posts for the current date
                acc[formattedDate].sort((a, b) => {
                    const statusOrder = { LIVE_W_ERROR: 0, DRAFT: 1, SCHEDULED: 2, IN_PROGRESS: 2, LIVE: 2 }

                    // Compare by status
                    const statusComparison = statusOrder[a.status] - statusOrder[b.status]
                    if (statusComparison !== 0) {
                        return statusComparison
                    }

                    // Compare by publishAt
                    return dayjs(a.publishAt[0]?.originalDate).diff(dayjs(b.publishAt[0]?.originalDate))
                })
            }

            return acc
        }, {})
    }

    const fetchPosts = useCallback(async () => {
        const dateFormatted = currentMonth.split("-")
        const year = parseInt(dateFormatted?.[0])
        const month = parseInt(dateFormatted?.[1])

        if (profile?.uid && businesses) {
            const date = new Date()
            const currentMonth = month ?? date.getMonth() + 1
            const currentYear = year ?? date.getFullYear()
            const resp = await SocialsApi.fetchPosts({
                month: currentMonth,
                year: currentYear,
                locationIds: businesses,
                fields: "",
            })
            if (!resp?.error) {
                const formattedPost = resp?.map((post) => {
                    return {
                        ...post,
                        status: post?.withError ? "LIVE_W_ERROR" : post?.status,
                    }
                })

                updateSocial({
                    posts: groupPostsByDay(formattedPost),
                    draftPosts: formattedPost.filter((data) => data.status === "DRAFT"),
                })
            } else {
                notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
            }
        }
    }, [profile, businesses, currentMonth])

    return {
        fetchPosts,
        groupPostsByDay,
    }
}

export default usePageLogic
