import FormContainer from "@/components/base/page-container/form-container"
import { TitleWrapper } from "@/components/base/title-wrapper"
import { PageProps } from "@/types/page"
import { useTranslation } from "react-i18next"
import { useCallback } from "react"
import { ResellerApi } from "@/api"
import UserSessionStore from "@/store/user-session"
import UiPageSessionStore from "@/store/ui-page-session"
import CompanyForm from "@/components/company/form"

const EditCompany = ({ onClose, open }: PageProps) => {
    const { t } = useTranslation()
    const { profile } = UserSessionStore()
    const { selectedItem, updateState: setUiPageSession } = UiPageSessionStore()

    const handleSubmitForm = useCallback(
        async (data) => {
            return ResellerApi.updateCompany({
                company: data,
                user_uid: profile.uid,
                company_uid: selectedItem.uid,
            })
        },
        [profile?.uid, selectedItem?.uid]
    )
    const handlePreviousButton = useCallback(() => {
        setUiPageSession({ showPage: "list" })
    }, [setUiPageSession])

    return (
        <FormContainer onClose={onClose} open={open}>
            <TitleWrapper handlePreviousButton={handlePreviousButton} title={t("COMPANIES.EDIT.TITLE")} />
            <CompanyForm callApi={handleSubmitForm} type={"edit"} />
        </FormContainer>
    )
}

export default EditCompany
