import { create } from "zustand"
export interface IScoreStore {
    completionScore: number
    missingInformation: string[]
}

export interface IScoreSetter {
    update?: (update: Partial<IScoreStore>) => void
    flush?: () => void
}

const initalState: Partial<IScoreStore> = {
    completionScore: 0,
    missingInformation: [],
}

const OverviewScoreStore = create<Partial<IScoreStore> & IScoreSetter>((set) => ({
    ...initalState,
    update: (update) => set(() => ({ ...update })),
    flush: () => set(initalState),
}))

export default OverviewScoreStore
