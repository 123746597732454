import env from "@/config/env"
import { useCallback, useEffect } from "react"

const useLoadGoogleMap = () => {
    const loadScript = useCallback((src: string, position: HTMLElement | null, id: string) => {
        if (!position) {
            return
        }
        const script = document.createElement("script")
        script.setAttribute("async", "false")
        script.setAttribute("id", id)
        script.src = src
        position.appendChild(script)
    }, [])

    useEffect(() => {
        if (!document.querySelector("#google-maps")) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${env.GOOGLE_MAPS_API_KEY}&libraries=places&callback=initMap`,
                document.querySelector("head"),
                "google-maps"
            )
            ;(window as any).initMap = () => {
                console.log("Init google places")
            }
        }
    }, [loadScript])
}

export default useLoadGoogleMap
