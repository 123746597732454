import { ChangeEvent } from "react"

type VideoLoadParams = {
    naturalSize: {
        width: number
        height: number
    }
}

type VideoLoadResponse = {
    width: string
    height: string
    ratio: string
}

const computeRatio = (width: number, height: number): string => {
    if (width <= 0 || height <= 0) {
        return ""
    }

    let num1 = width
    let num2 = height

    while (num2 !== 0) {
        const temp = num1 % num2
        num1 = num2
        num2 = temp
    }

    const gcd = num1

    // Calculate the ratio
    return `${width / gcd}:${height / gcd}`
}

const getMediaRatio = ({ naturalSize }: VideoLoadParams): VideoLoadResponse => {
    const { width, height } = naturalSize
    return {
        width: `${width}px`,
        height: `${height}px`,
        ratio: computeRatio(width, height),
    }
}

const getMediaFileRatioSync = (media: File) => {
    return new Promise((resolve) => {
        const file = media
        const url = URL.createObjectURL(file)

        // check media type if it's a video or image
        if (file?.type?.includes("image")) {
            const img = new Image()
            img.onload = () => {
                // Revoke when you don't need the url any more to release any reference
                URL.revokeObjectURL(url)
                const data = getMediaRatio({
                    naturalSize: {
                        width: img.width,
                        height: img.height,
                    },
                })
                resolve(data)
            }
            img.src = url
        } else if (file?.type?.includes("video")) {
            // Create a video element to get the metadata
            const video = document.createElement("video")
            video.onloadedmetadata = () => {
                // Revoke when you don't need the url any more to release any reference
                URL.revokeObjectURL(url)
                const data = getMediaRatio({
                    naturalSize: {
                        width: video.videoWidth,
                        height: video.videoHeight,
                    },
                })
                resolve(data)
            }
            video.src = url
            video.load() // fetches metadata
        } else {
            resolve(null)
        }
    })
}

export { computeRatio, getMediaRatio, getMediaFileRatioSync }
