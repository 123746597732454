import { ResellerApi } from "@/api"
import { MAX_INPUT } from "@/data/limiter"
import useAuthFacebook from "@/hooks/oauth02/useAuthFacebook"
import useLocation from "@/hooks/useLocation"
import useNotification from "@/hooks/useNotification"
import useWindowSize from "@/hooks/useWindowSize"
import MediaService from "@/services/media"
import useMediaStore from "@/store/overview/media"
import UiPageSessionStore from "@/store/ui-page-session"
import { MediaFormValues } from "@/types/businesses"
import { blobToFile, getProvider } from "@/utils/media"
import { zodResolver } from "@hookform/resolvers/zod"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Crop, PixelCrop, convertToPixelCrop } from "react-image-crop"
import { z } from "zod"
import useBusinessLogic from "../useBusinessLogic"
import { useDebounceEffect } from "./useDebounceEffect"
interface DimensionImage {
    height: number
    width: number
    ratio: number
}

const defaultCrop: Crop = {
    unit: "%",
    x: 5,
    y: 5,
    width: 90,
    height: 90,
}

const useLogic = () => {
    const { navigateTo } = useBusinessLogic()
    const refboxImage = useRef(null)
    const { media, update: updateMedia } = useMediaStore()
    const { location, needReconnexion } = useLocation()
    const { updatePreviousItem, showPage, previousItem } = UiPageSessionStore()
    const { isLoggedFacebook } = useAuthFacebook({ location })
    const [showCropPage, setShowCropPage] = useState(false)
    const [googleChecked, setGoogleChecked] = useState(false)
    const [fbChecked, setFbChecked] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [lock, setLock] = useState(false)
    const [alignment, setAlignment] = useState("default")
    const { notif } = useNotification()
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const [rotation, setRotation] = useState(0)
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>(null)
    const [heightCropBloc, setHeightCropBloc] = useState("100%")
    const [width, height] = useWindowSize()
    const [imageDimensions, setImageDimensions] = useState<DimensionImage>(null)
    const imgRef = useRef<HTMLImageElement>(null)
    const previewCanvasRef = useRef<HTMLCanvasElement>(null)
    const [scale, setScale] = useState(1)
    const [aspect, setAspect] = useState<number | undefined>(undefined)
    const refBtnEdit: any = useRef(null)

    const schema = useMemo(() => {
        return z.object({
            label: z.string().optional(),
        })
    }, [location, t, MAX_INPUT.TEXT])

    const [crop, setCrop] = useState<Crop>(defaultCrop)

    useEffect(() => {
        if (showPage === "edit-media") {
            updateMedia({ media: null })
            if (
                previousItem !== null &&
                ((previousItem.mediaItem?.file && previousItem.mediaItem?.type) ||
                    (previousItem.mediaItem?.id && previousItem.mediaItem?.saveChange))
            ) {
                if (!previousItem.mediaItem?.saveChange) {
                    saveChange(previousItem)
                }
                updateMedia({ media: previousItem.mediaItem })
            } else if (previousItem?.mediaItem?.id && !previousItem.mediaItem?.saveChange) {
                handleGetOne()
            } else {
                updateMedia({ media: null })
            }
        }
    }, [previousItem?.mediaItem, showPage])

    const saveChange = useCallback((previousItem) => {
        setValue("label", previousItem.mediaItem?.label ? previousItem.mediaItem?.label : "")
        setGoogleChecked(previousItem.mediaItem?.googleChecked)
        setFbChecked(previousItem.mediaItem?.fbChecked)
    }, [])

    const handlePreviousButton = useCallback(() => {
        if (previousItem?.mediaItem) {
            previousItem.mediaItem.label = getValues().label
            updatePreviousItem({
                mediaItem: {
                    ...previousItem.mediaItem,
                    saveChange: true,
                },
            })
            updateMedia({ media: null })
            navigateTo("list-media")
        }
    }, [previousItem?.mediaItem])

    const handleGetOne = useCallback(async () => {
        setGoogleChecked(false)
        setFbChecked(false)
        const response: any = await ResellerApi.getOneMedia({ id: previousItem.mediaItem.id })
        if (response?.label) {
            setValue("label", response.label ? response.label : "")
        }
        setGoogleChecked(false)
        setFbChecked(false)
        if (response?.provider?.length > 0) {
            if (response.provider.includes("google")) {
                setGoogleChecked(true)
            }
            if (response.provider.includes("facebook")) {
                setFbChecked(true)
            }
        }
        updateMedia({ media: response })
    }, [previousItem])

    const handleCrop = useCallback(() => setShowCropPage(!showCropPage), [showCropPage])
    const handleDelete = useCallback(
        async (media) => {
            if (media?.id) {
                setIsLoading(true)
                const response = await ResellerApi.deleteMedia({ idMedia: media.id })
                setIsLoading(false)
                if (response?.error) {
                    notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
                } else {
                    updatePreviousItem({ mediaItem: null })
                    setShowCropPage(false)
                    navigateTo("list-media")
                }
            } else {
                const index = previousItem?.mediaItems.findIndex((prevMedia) => prevMedia.file.name === media.file.name)
                if (index >= 0) {
                    previousItem.mediaItems.splice(index, 1)
                    updatePreviousItem({ mediaItems: previousItem.mediaItems })
                    updatePreviousItem({ mediaItem: null })
                    updateMedia({ media: null })
                    setShowCropPage(false)
                    navigateTo("list-media")
                } else {
                    notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
                }
            }
        },
        [showCropPage, previousItem]
    )

    const { handleSubmit, setValue, getValues, trigger, reset, control } = useForm<MediaFormValues>({
        defaultValues: {
            label: "",
        },
        resolver: zodResolver(schema),
        mode: "onTouched",
    })

    const handleMediaData = useCallback(
        (data) => {
            const provider = getProvider(googleChecked, fbChecked)
            return {
                locationId: location?.id,
                label: data.label,
                type: media?.type ?? null,
                url: null,
                provider: provider,
            }
        },
        [fbChecked, googleChecked, location?.id, media?.type]
    )

    const onSubmit: SubmitHandler<any> = useCallback(
        async (data: MediaFormValues) => {
            setLoading(true)
            const mediaPayload = handleMediaData(data)
            if (showCropPage) {
                const imageCrop = await getCroppedImg()
                if (media.file && !previousItem?.mediaItem?.id) {
                    fileLocal(imageCrop)
                } else {
                    const responseFile = await ResellerApi.postFile({
                        location_id: location.id,
                        file: imageCrop,
                    })
                    fileOnServer(mediaPayload, responseFile.url)
                }
            } else if (media?.file && !previousItem?.mediaItem?.id) {
                fileLocal(null)
            } else {
                fileOnServer(mediaPayload, media?.url)
            }
        },
        [
            fbChecked,
            googleChecked,
            previousItem?.mediaItem,
            media,
            showCropPage,
            imgRef,
            completedCrop,
            setCompletedCrop,
            crop,
            previewCanvasRef,
        ]
    )

    const fileLocal = useCallback(
        async (imageCrop) => {
            const index = previousItem?.mediaItems.findIndex((prevMedia) => prevMedia.file.name === media.file.name)
            if (index >= 0) {
                if (imageCrop) {
                    media.fileCrop = imageCrop
                }
                media.label = getValues().label
                media.fbChecked = fbChecked
                media.googleChecked = googleChecked
                previousItem.mediaItems[index] = media
                updatePreviousItem({ mediaItem: null })
                updatePreviousItem({ mediaItems: previousItem.mediaItems })
                setShowCropPage(false)
                setRotation(0)
                updateMedia({ media: null })
                navigateTo("list-media")
                setLoading(false)
            } else {
                notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
                setLoading(false)
            }
        },
        [media, previousItem?.mediaItems, fbChecked, googleChecked]
    )

    const fileOnServer = useCallback(
        async (mediaPayload, urlFile) => {
            mediaPayload.url = urlFile
            mediaPayload.type = media?.type
            const response = await ResellerApi.updateMedia({ idMedia: media?.id, payload: mediaPayload })
            if (response?.error) {
                notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
                setLoading(false)
            } else {
                notif({ message: t("MEDIA.SUCCESS_UPDATE"), type: "SUCCESS" })
                updatePreviousItem({ mediaItem: null })
                setShowCropPage(false)
                setRotation(0)
                navigateTo("list-media")
                setLoading(false)
            }
        },
        [media]
    )

    const onErrors: SubmitErrorHandler<any> = async (errors) => {
        if (errors) {
            console.log(errors)
        }
    }

    const handleCancelButton = useCallback(() => {
        setGoogleChecked(false)
        setFbChecked(false)
        if (!media.id) {
            const index = previousItem?.mediaItems.findIndex((prevMedia) => prevMedia.file.name === media.file.name)
            if (index >= 0) {
                previousItem.mediaItems[index].saveChange = false
                updatePreviousItem({ mediaItems: previousItem.mediaItems })
            }
        }
        updatePreviousItem({
            mediaItem: null,
        })
        reset()
        setAlignment("default")
        setLock(false)
        setAspect(undefined)
        setCrop(defaultCrop)
        updateMedia({ media: null })
        setShowCropPage(false)
        setRotation(0)
        navigateTo("list-media")
    }, [previousItem, lock, aspect, crop, media])

    const getCroppedImg = useCallback(async () => {
        const image = imgRef.current
        const previewCanvas = previewCanvasRef.current
        if (!image || !previewCanvas || !completedCrop) {
            throw new Error("Crop canvas does not exist")
        }
        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height
        const pixelRatio = window.devicePixelRatio
        const offscreen = new OffscreenCanvas(
            completedCrop.width * scaleX * pixelRatio,
            completedCrop.height * scaleY * pixelRatio
        )

        const ctx = offscreen.getContext("2d")

        if (!ctx) {
            throw new Error("No 2d context")
        }

        ctx.drawImage(
            previewCanvas,
            0,
            0,
            previewCanvas.width,
            previewCanvas.height,
            0,
            0,
            offscreen.width,
            offscreen.height
        )
        const blob = await offscreen.convertToBlob({
            type: "image/jpeg",
        })
        const file = blobToFile(blob, `name${Date.now()}.jpeg`)
        return file
    }, [completedCrop, previewCanvasRef, imgRef])

    const rotatePlus = useCallback(() => {
        let newRotation = rotation + 45
        if (newRotation >= 360) {
            newRotation = -360
        }
        setRotation(newRotation)
    }, [rotation])

    const rotateMinus = useCallback(() => {
        let newRotation = rotation - 45
        if (newRotation >= 360) {
            newRotation = -360
        }
        setRotation(newRotation)
    }, [rotation])

    const handleChange = useCallback(
        (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
            if (imgRef?.current) {
                const { width, height } = imgRef.current
                let newCrop: any = {}
                setLock(false)
                switch (newAlignment) {
                    case "default":
                        setAspect(undefined)
                        newCrop = defaultCrop
                        break
                    case "one":
                        setAspect(1)
                        newCrop = MediaService.centerAspectCrop(width, height, 1)
                        break
                    case "two":
                        setAspect(4 / 5)
                        newCrop = MediaService.centerAspectCrop(width, height, 4 / 5)
                        break
                    case "three":
                        setAspect(9 / 18)
                        newCrop = MediaService.centerAspectCrop(width, height, 9 / 18)
                        break
                }
                setCrop(newCrop)
                setCompletedCrop(convertToPixelCrop(newCrop, width, height))
                setAlignment(newAlignment)
            }
        },
        [imgRef?.current, crop, completedCrop, setCompletedCrop]
    )

    const loadImage = (image) => {
        let fileAsDataURL = ""
        if (image?.file) {
            fileAsDataURL = window.URL.createObjectURL(image?.file)
        } else {
            fileAsDataURL = image?.url
        }
        const img = new Image()
        img.src = fileAsDataURL
        img.onload = () => {
            let aspectRatio

            if (img.width > img.height) {
                aspectRatio = img.width / img.height
            } else {
                aspectRatio = img.height / img.width
            }

            setImageDimensions({
                height: img.height,
                width: img.width,
                ratio: aspectRatio,
            })
        }
        img.onerror = (err) => {
            setImageDimensions(null)
        }
    }

    useEffect(() => {
        if (media && showCropPage) {
            loadImage(media)
        }
    }, [media, showCropPage])

    const handleImageLoad = useCallback(() => {
        // Access image dimensions after it's loaded
        let value = "calc(100% - 160px)"
        const imageCropHeight = imgRef.current?.getBoundingClientRect()?.height
        if (imgRef.current && imageCropHeight && showCropPage) {
            if (width < 768) {
                value = "100%;"
                if (imageCropHeight + 280 > height) {
                    value = String(height - 250) + "px"
                }
            }
            if (!media?.id && width > 0 && height > 0) {
                value = "calc(100% - 160px)"
            }
            setHeightCropBloc(value)
        }
        if (imgRef.current.width > 0) {
            handleChange(null, "default")
        }
    }, [imgRef?.current, showCropPage, imageDimensions?.height, imageDimensions?.width, height, width])

    useDebounceEffect(
        () => {
            if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
                MediaService.canvasPreview(imgRef.current, previewCanvasRef?.current, completedCrop, scale, rotation)
            }
        },
        100,
        [completedCrop, scale, rotation, imgRef.current, previewCanvasRef.current]
    )
    return {
        handlePreviousButton,
        navigateTo,
        aspect,
        showCropPage,
        handleCrop,
        handleDelete,
        mediaValue: media,
        needReconnexion,
        isLoggedFacebook,
        googleChecked,
        setGoogleChecked,
        previousItem,
        isLoading,
        loading,
        handleSubmit,
        onSubmit,
        onErrors,
        control,
        handleCancelButton,
        fbChecked,
        setFbChecked,
        crop,
        setCrop,
        rotateMinus,
        rotatePlus,
        rotation,
        alignment,
        handleChange,
        lock,
        imgRef,
        previewCanvasRef,
        completedCrop,
        setCompletedCrop,
        location,
        handleImageLoad,
        heightCropBloc,
        refBtnEdit,
        setScale,
        refboxImage,
    }
}

export default useLogic
