import Icon from "@/components/base/Icon"
import useMediaLogic from "@/hooks/businesses/overview-business/useMediaLogic"
import useBusinessLogic from "@/hooks/businesses/useBusinessLogic"
import { Box, Paper, Typography } from "@mui/material"
import classNames from "classnames"
import { useTranslation } from "react-i18next"
import OverviewTitle from "../title"
import useStyles from "./styles"

const imageSize = 132

const OverviewMedia = ({ mediasList, totalMediaCount, mediaRef, test = false }: any) => {
    const { classes } = useStyles()
    const { t } = useTranslation()
    const { navigateTo } = useBusinessLogic()
    const { mediaWrapperRef, supportedBoxes } = useMediaLogic({ imageSize })

    return (
        <Paper
            elevation={2}
            className={classNames("clickable", classes.media)}
            id="medias"
            data-e2e="medias"
            ref={mediaRef}
            onClick={() => navigateTo("list-media")}
        >
            <OverviewTitle
                title={t("MEDIA.TITLE")}
                data-testid="title-media"
                // icon="imagePlaceholder"
                isSecondTitle
                tooltipText={t("MEDIA.MODIFY_TITLE")}
            />
            <Box className={"media-content--wrapper"} ref={mediaWrapperRef}>
                {(Boolean(supportedBoxes) || test) && mediasList && totalMediaCount > 0 && (
                    <>
                        {mediasList.map((image, k) => (
                            <Box
                                className="img-wrapper"
                                minWidth={imageSize}
                                width={imageSize}
                                height={imageSize}
                                sx={{ ...(k + 1 > supportedBoxes && { display: "none" }) }}
                                key={k}
                            >
                                <img
                                    data-testid={`media-img${k}`}
                                    src={image.thumbnailUrl ? image.thumbnailUrl : image.url}
                                    alt="media"
                                />

                                {image.type === "VIDEO" && <Icon name="play" />}
                            </Box>
                        ))}
                        {totalMediaCount >= 7 &&
                            totalMediaCount - supportedBoxes > 0 &&
                            supportedBoxes < totalMediaCount && (
                                <Typography variant="h2" component={"span"}>
                                    + {totalMediaCount - supportedBoxes}
                                </Typography>
                            )}

                        {totalMediaCount > 10 && supportedBoxes >= totalMediaCount && (
                            <Typography variant="h2" component={"span"}>
                                + {totalMediaCount - 10}
                            </Typography>
                        )}
                    </>
                )}
            </Box>
        </Paper>
    )
}

export default OverviewMedia
