import useLocationStore from "@/store/location"
import SocialStore from "@/store/social"
import { useMemo } from "react"
import SocialsModalTableCheck from "../table-check"

const SocialsModalConnexionDetail = () => {
    const { selectedBusinesses } = SocialStore()
    const { businesses } = useLocationStore()
    const provider = useMemo(() => ["facebook", "instagram", "google"], [])

    const rows = useMemo(() => {
        const businessMap = businesses.reduce((acc, business) => {
            acc[business.value] = business
            return acc
        }, {})

        const _provider = []

        const getProvider = (meta) => {
            if (meta?.facebook_id) return "facebook"
            if (meta?.instagram_id) return "instagram"
            if (meta?.gmb_id) return "google"
            return null
        }

        const rows = selectedBusinesses.map((selectedBusiness) => {
            const business = businessMap[selectedBusiness] || {}
            const provider = getProvider(business.meta)
            if (!_provider.includes(provider)) {
                _provider.push(provider)
            }
            return {
                name: business.label,
                address: business.shortAddress,
                facebook: business.locationState?.facebookStatus === "CONNECTED",
                instagram: business.locationState?.instagramStatus === "CONNECTED",
                google: !["DISCONNECTED", "NOT_CONNECTED"].includes(business.locationState?.status),
            }
        })

        return rows
    }, [businesses, selectedBusinesses])

    return <SocialsModalTableCheck rows={rows} providers={provider} />
}

export default SocialsModalConnexionDetail
