import NotificationStore from "@/store/notification"
import { useEffect } from "react"

const useNotification = () => {
    const { update, message } = NotificationStore()

    useEffect(() => {
        update({ show: !!message })
    }, [message, update])

    return { notif: update }
}

export default useNotification
