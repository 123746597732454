import { DayItem } from "@/types/businesses"
import cloneDeep from "lodash.clonedeep"

const validateHours = (data: DayItem[]): boolean => {
    let isValid = true
    let breakOutSide = false
    for (const day of cloneDeep(data)) {
        if (day.isOpen && day.times?.length > 0 && !day.isAvailableAllDay) {
            for (const time of day.times) {
                if (!time.start || !time.end || !time.startValid || !time.endValid) {
                    isValid = false
                    breakOutSide = true
                    break
                }
            }
        }
        if (breakOutSide) {
            break
        }
    }
    return isValid
}

export default validateHours
