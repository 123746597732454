import { TableBody } from "@mui/material"

type TableBodyWrapperProps = {
    children: React.ReactNode
}

const TableBodyWrapper = ({ children }: TableBodyWrapperProps) => {
    return <TableBody>{children}</TableBody>
}

export default TableBodyWrapper
