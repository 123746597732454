import Icon from "@/components/base/Icon"
import BaseInput from "@/components/base/input"
import QuillEditor, { IMention } from "@/components/base/input-editor"
import CustomTooltip from "@/components/base/Tooltip"
import useFormater from "@/hooks/useFormater"
import { CookiesService } from "@/services"
import { Box, Divider, FormLabel, IconButton, Stack, Switch, Typography } from "@mui/material"
import { useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import useStyles from "../styles"

type EmailEditOptionnalProps = {
    register: any
    data: any
    feedback: boolean
    hasNewUrl: boolean
    handleFeedback: () => void
}
type EmailEditProps = {
    hasModel: boolean
    listReviews: any
    setValue: any
    atValues: IMention[]
    defaultValue: string
    onChange: (value: { html: string; delta: any; rawText: string }) => void
    onCloseReviews: () => void
} & Partial<EmailEditOptionnalProps>

export default function EmailEdit({
    hasModel,
    listReviews,
    setValue,
    atValues,
    data,
    register,
    defaultValue,
    feedback,
    hasNewUrl,
    onChange,
    onCloseReviews,
    handleFeedback,
}: EmailEditProps) {
    const { t } = useTranslation()
    const { classes } = useStyles()

    const toolbar = useMemo(() => {
        return [
            ["bold", "italic", "underline"], // toggled buttons
            [{ list: "bullet" }],
        ]
    }, [])

    const { parseAddress, translateString } = useFormater()
    const atProps = useMemo(() => atValues, [atValues])
    const [subject, setSubject] = useState<string>("")
    const emailSpotconnect = CookiesService.get("emailSpotconnect")

    const handleChangeSubject = useCallback(
        (value: string) => {
            setSubject(value ?? "")
            setValue("subject", value ?? "")
        },
        [setValue]
    )

    return (
        <Box className={classes.boxMessage}>
            <Stack gap={2}>
                <Stack>
                    <Typography component="span" color={"action.active"}>
                        {t("CAMPAIGN.FROM")}:
                    </Typography>
                    <Typography component="span" color={"action.active"}>
                        {emailSpotconnect}
                    </Typography>
                </Stack>
                <Stack data-e2e="subject">
                    <BaseInput
                        required
                        shrink={subject && subject !== ""}
                        showLabelStar={true}
                        label={t("CAMPAIGN.SUBJECT")}
                        register={register("subject")}
                    />
                </Stack>
                <Stack data-e2e="email-editor">
                    <QuillEditor
                        onChange={onChange}
                        onCloseReviews={onCloseReviews}
                        handleSubject={(subject) => handleChangeSubject(subject)}
                        listReviews={listReviews}
                        hasModel={hasModel}
                        placeholder={""}
                        atValues={atProps}
                        toolbar={toolbar}
                        className="email-editor"
                        defaultValue={defaultValue}
                        hasNewUrl={hasNewUrl}
                        editorType="email"
                        feedback={feedback}
                    />
                </Stack>
                <Stack gap={2}>
                    {feedback && (
                        <Stack
                            sx={{
                                maxWidth: "400px",
                                textAlign: "center",
                                margin: "auto",
                                alignItems: "center",
                                gap: 2,
                                width: "100%",
                            }}
                        >
                            <Typography sx={{ maxWidth: "220px" }} variant="h6">
                                {data?.lang && translateString("CAMPAIGN.EXPERIENCE", data?.lang)}
                            </Typography>
                            <Stack flexDirection="row" gap={2}>
                                <Stack className="btn-review good">
                                    <Icon name="successHand" />
                                    <Typography className="text-review good">
                                        {data?.lang && translateString("CAMPAIGN.SATISFIED", data?.lang)}
                                    </Typography>
                                </Stack>
                                <Stack className="btn-review bad">
                                    <Icon name="warningHand" sx={{ color: "#FFC431", width: 42, height: 42 }} />
                                    <Typography className="text-review bad">
                                        {data?.lang && translateString("CAMPAIGN.NOT_SATISFIED", data?.lang)}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    )}
                    <Stack flexDirection="row" gap={2}>
                        <Stack flexDirection={"row"} alignItems={"center"} margin="auto" gap={1}>
                            <Switch checked={feedback} onChange={handleFeedback} />
                            <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                                <FormLabel sx={{ color: "action.active" }}>{t("CAMPAIGN.ACTIVE_FILTER")}</FormLabel>
                                <CustomTooltip title={t("CAMPAIGN.FILTER")}>
                                    <IconButton className="edit-btn">
                                        <Icon name="info" sx={{ color: "action.active" }} />
                                    </IconButton>
                                </CustomTooltip>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
                <Divider className="separate" orientation="horizontal" flexItem />
                <Stack gap={1} paddingX={1}>
                    {data?.logo && (
                        <img
                            src={data?.logo}
                            alt="Logo"
                            style={{
                                width: "72px",
                                height: "72px",
                                borderRadius: "8px",
                            }}
                        />
                    )}
                    <Stack gap={0.5}>
                        <Typography variant={"h6"}>{data?.name}</Typography>
                        {data?.serviceArea?.businessType === "CUSTOMER_AND_BUSINESS_LOCATION" && (
                            <Stack>
                                <Typography component={"span"} color="action.active">
                                    {parseAddress({
                                        region: data?.region,
                                        countryCode: data?.country,
                                        address: data?.address,
                                        city: data?.city,
                                        zip: data?.zip,
                                    })}
                                </Typography>
                            </Stack>
                        )}
                    </Stack>
                </Stack>
            </Stack>
        </Box>
    )
}
