import { ExceptionalScheduleItemType } from "../types/businesses"
import dayjs from "dayjs"

export const validateDays = (data: ExceptionalScheduleItemType[]): boolean => {
    let isValid = true
    let breakOutSide = false
    for (const day of data) {
        if (!day?.startDate || !dayjs(day?.startDate).isValid()) {
            isValid = false
            breakOutSide = true
            break
        }
        const duplicateDay = data.filter((item) => item?.startDate === day.startDate)

        if (duplicateDay?.length > 1) {
            isValid = false
            breakOutSide = true
            break
        }

        if (breakOutSide) {
            break
        }
    }

    return isValid
}

export const validateDay = (data: ExceptionalScheduleItemType[], date): boolean => {
    let isValid = true
    if (!date?.startDate || !dayjs(date?.startDate).isValid()) {
        isValid = false
    }
    const duplicateDay = data.filter((item) => item?.startDate === date.startDate)

    if (duplicateDay?.length > 1) {
        isValid = false
    }

    return isValid
}

export default validateDay
