import Icon from "@/components/base/Icon"
import CustomTooltip from "@/components/base/Tooltip"
import { Box, Button, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import useStyles from "./styles"

interface TitleWrapperProps {
    handlePreviousButton: () => void
    title: string
    tooltipText?: string
    dataTestId?: string
    className?: string
    isSingleBusiness?: boolean
}

export const TitleWrapper = ({
    handlePreviousButton,
    title,
    tooltipText,
    dataTestId,
    className,
    isSingleBusiness,
}: TitleWrapperProps) => {
    const { classes } = useStyles()
    const { t } = useTranslation()

    return (
        <Box className={`${classes.titleWrapper} ${className ? className : ""}`}>
            {!isSingleBusiness ? (
                <CustomTooltip title={tooltipText || t("USERS.BACK_TOOLTIP")}>
                    <Button data-testid={dataTestId} onClick={handlePreviousButton}>
                        <Icon name="back" sx={{ width: 32, height: 32 }} />
                    </Button>
                </CustomTooltip>
            ) : (
                <Icon name={"profile"} sx={{ fontSize: "24px !important", width: 32, height: 32 }} />
            )}
            <Typography data-testid="title" variant="h1">
                {title}
            </Typography>
        </Box>
    )
}
