import Icon from "@/components/base/Icon"
import { IconButton, Stack, Typography } from "@mui/material"
import { Column } from "../custom-table"

type TableHeadSortOptionalProps = {
    onSort: (columnId: string, sorted: boolean) => void
}

type TableHeadSortProps = {
    label: string
    column: Column
    activeSort: {
        sort_by: string
        sort_order: "asc" | "desc"
    }
} & Partial<TableHeadSortOptionalProps>

const TableHeadSort = ({ label, column, activeSort, onSort }: TableHeadSortProps) => {
    const sorted = activeSort?.sort_by === column.id
    const sortOrder = activeSort?.sort_order

    return (
        <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} width={"100%"}>
            <Typography>{label}</Typography>
            <IconButton
                onClick={() => {
                    if (typeof onSort === "function") {
                        if (sorted && sortOrder === "asc") {
                            onSort(column.id, false)
                        } else {
                            onSort(column.id, true)
                        }
                    }
                }}
                sx={{
                    rotate: sorted && sortOrder === "asc" ? "0" : "180deg",
                }}
            >
                <Icon name="arrowUp" />
            </IconButton>
        </Stack>
    )
}

export default TableHeadSort
