import Icon from "@/components/base/Icon"
import { TitleWrapper } from "@/components/base/title-wrapper"
import EditExceptionalScheduleContainer from "@/components/business/form-container/edit/edit-exceptional-schedule"
import FormAction from "@/components/business/form-container/edit/form-action"
import ExceptionalScheduleItem from "@/components/business/schedule/exceptional-item"
import useLogic from "@/hooks/businesses/edit-exceptional-schedule/useLogic"
import useBusinessRegularHoursStore from "@/store/overview/regular-hours"
import { PageProps } from "@/types/page"
import { Alert, AlertTitle, Box, Button, Paper, Typography } from "@mui/material"

const EditExceptionalSchedule = ({ onClose, open, testUnit = false }: PageProps) => {
    const {
        handleCancelButton,
        handlePreviousButton,
        t,
        handleAddNewExceptionHour,
        scheduleList,
        setScheduleList,
        isFormSubmitted,
        onSubmit,
        onErrors,
        handleSubmit,
        isFormValid,
        loading,
    } = useLogic()
    const { regularHours, status } = useBusinessRegularHoursStore()
    return (
        <EditExceptionalScheduleContainer open={open} onClose={onClose}>
            <TitleWrapper
                handlePreviousButton={handlePreviousButton}
                title={t("BUSINESSES.SCHEDULE.EDIT_EXCEPTIONAL_OPENING")}
            />
            <form onSubmit={handleSubmit(onSubmit, onErrors)}>
                <Box className="inputs-container">
                    <Paper className="inputs-wrapper">
                        <Box className="title-wrapper">
                            <Box className="title">
                                <Icon name="calendar" />
                                <Typography variant="h2">{t("BUSINESSES.SCHEDULE.EXCEPTIONAL_OPENING")}</Typography>
                            </Box>
                            {((regularHours?.length === 0 && status === "OPEN") || testUnit) && (
                                <Alert severity="info" className="alert-info">
                                    <AlertTitle>{t("BUSINESSES.SCHEDULE.HIDDEN_GOOGLE_TITLE")}</AlertTitle>
                                    {t("BUSINESSES.SCHEDULE.HIDDEN_GOOGLE_CONTENT")}
                                </Alert>
                            )}
                            <Button
                                data-e2e="add-exceptional-opening"
                                color="secondary"
                                className="btn-add"
                                onClick={handleAddNewExceptionHour}
                            >
                                <Icon name="plus" />
                                {t("BUSINESSES.SCHEDULE.ADD_EXCEPTIONAL_OPENING")}
                            </Button>
                        </Box>
                        {scheduleList?.map((day, key: number) => (
                            <ExceptionalScheduleItem
                                // list={scheduleList}
                                // setDays={setScheduleList}
                                key={`${String(key)}`}
                                day={day}
                                scheduleIndex={key}
                                isFormSubmitted={isFormSubmitted}
                            />
                        ))}
                    </Paper>
                </Box>
                <FormAction
                    isUpdating
                    handleCancel={handleCancelButton}
                    isLoading={loading}
                    isDisabled={(isFormSubmitted && !isFormValid) || loading}
                />
            </form>
        </EditExceptionalScheduleContainer>
    )
}

export default EditExceptionalSchedule
