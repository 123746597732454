import { create } from "zustand"

interface ILinkNetwork {
    [key: string]: { id: string; value: string }
}
export interface ILinksStore {
    websiteUrl: string
    linksNetwork: ILinkNetwork
}

const initalState: ILinksStore = {
    websiteUrl: "",
    linksNetwork: {},
}

export interface ILinksSetter {
    update?: (update: Partial<ILinksStore>) => void
    flush?: () => void
}

const useLinksStore = create<ILinksStore & ILinksSetter>((set) => ({
    ...initalState,
    update: (update) => set(() => ({ ...update })),
    getInitialState: () => initalState,
    flush: () => set(initalState),
}))

export default useLinksStore
