import { Control, Controller } from "react-hook-form"
import SelectBase from "../select-base"
import { SelectProps } from "@mui/material"

type SelectBaseControllerProps = {
    control: Control<any>
    onSelectionChange?: (value: string | string[]) => void
    options: {
        value: string | number
        label: string
    }[]
}

const SelectBaseController = ({ ...props }: SelectBaseControllerProps & SelectProps) => {
    return (
        <Controller
            name={props.name}
            control={props.control}
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            render={({ fieldState, field: { ref, ...field } }) => (
                <SelectBase
                    {...field}
                    onChange={(event) => {
                        props?.onSelectionChange(event.target.value as string | string[])
                        field.onChange(event.target.value)
                    }}
                    error={!!fieldState.error}
                    id={props.name}
                    options={props.options}
                    {...props}
                />
            )}
        />
    )
}

export default SelectBaseController
