import { LocationType } from "@/types/businesses"
import { SelectType } from "@/types/select"
import { create } from "zustand"

export interface PrimaryCategoryType {
    id?: string
    label?: string
    language?: string
    name?: string
    serviceTypes?: any
}

export interface ServiceSection {
    label: string
    description?: string
    categoryId: string
    items: ServiceSectionItem[]
}

export interface ServiceSectionItem {
    label: string
    description?: string
    serviceTypeId?: string
    price?: string
    state?: string
    free?: boolean
    priceType?: string
}

interface BusinessesTimezone {
    [key: string]: string
}

export interface ILocationStore {
    location: LocationType
    formatedAddress: string
    phone: string
    needReconnexion: boolean
    currentLocationId: string
    isLoading: boolean
    businesses: SelectType[]
    businessesTimezone: BusinessesTimezone
}

export interface ILocationSetter {
    update?: (update: Partial<ILocationStore>) => void
    flush?: () => void
}

const initalState: ILocationStore = {
    currentLocationId: "",
    location: {},
    formatedAddress: "",
    phone: "",
    needReconnexion: false,
    isLoading: false,
    businesses: null,
    businessesTimezone: null,
}

const useLocationStore = create<ILocationStore & ILocationSetter>((set) => ({
    ...initalState,
    update: (update) => set(() => ({ ...update })),
    flush: () => set(initalState),
}))

export default useLocationStore
