import useLocationStore from "@/store/location"
import { getCountries } from "libphonenumber-js"
import { matchIsValidTel } from "mui-tel-input"
import { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
const usePhoneInputLogic = () => {
    const { location } = useLocationStore()
    const { t } = useTranslation()
    const callingCodes = useMemo(() => ["CA", "US", "FR"], [])

    const includedCountries = useMemo(
        () => getCountries().filter((countryCode) => !callingCodes.includes(countryCode)),
        [callingCodes]
    )

    const isPhoneValid = useCallback((phoneValue: string) => {
        if (phoneValue === "" || /^\+\d{1,4}$/.test(phoneValue) || phoneValue === undefined) {
            return true
        }
        return phoneValue && !/^\+\d{1,4}$/.test(phoneValue) && matchIsValidTel(String(phoneValue))
    }, [])

    const isPhoneRequired = useCallback(
        (phoneValue: string) => {
            return !location.websiteUrl && (!phoneValue || /^\+\d{1,4}$/.test(phoneValue))
        },
        [location]
    )

    return {
        isPhoneValid,
        isPhoneRequired,
        t,
        includedCountries,
    }
}

export default usePhoneInputLogic
