import { useMemo } from "react"
import useWindowSize from "../useWindowSize"

const useResponsive = () => {
    const [width] = useWindowSize()

    const isMobile = useMemo(() => {
        return width < 786
    }, [width])

    return {
        isMobile,
    }
}

export default useResponsive
