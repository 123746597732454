import { DispatchrService } from "@/api"
import { PostFile } from "@/api/reseller"
import { PostData } from "@/types/socials"
import { imgListExtension } from "@/utils/media"
import { getMediaRatio } from "@/utils/media-ratio"
import dayjs from "dayjs"

class SocialService {
    haveUnsavedDataPost(post: PostData) {
        if ((post?.description && post?.description !== "") || (post?.mediasLocal && post?.mediasLocal?.length > 0)) {
            return true
        }
        return false
    }

    formattedMedia(postMedia: PostFile) {
        const newMedias = []
        postMedia?.medias?.forEach((media) => {
            const metadata = getMediaRatio({
                naturalSize: { width: media.dimensions.width, height: media.dimensions.height },
            })
            const mediaExtension = media?.url?.split(".").pop().toLowerCase()
            newMedias.push({
                url: media.url,
                metadata,
                type: imgListExtension.includes(mediaExtension) ? "PHOTO" : "VIDEO",
            })
        })
        return newMedias
    }

    formattedPublishAt({ postItems, publishAtData, publishDate, businessesTimezone }) {
        const postsError = postItems.filter((item) => item.status === "FAILED")
        const publishAtError = []
        const publishAtWithoutError = []

        if (postsError.length > 0) {
            postsError.forEach((postError) => {
                let data = publishAtData.find(
                    (item) => item.locationId === postError?.locationId && item.provider === postError?.provider
                )

                if (data) {
                    data = {
                        ...data,
                        date: dayjs(publishDate).tz(businessesTimezone[data.locationId]).format("YYYY-MM-DDTHH:mm:ss"),
                        originalDate: publishDate,
                    }

                    publishAtError.push(data)
                }
            })
        }

        // Collect all publishAt that are not in publishAtError
        publishAtData.forEach((publishAtItem) => {
            const hasError = publishAtError.some(
                (errorItem) =>
                    errorItem.locationId === publishAtItem.locationId && errorItem.provider === publishAtItem.provider
            )

            if (!hasError) {
                publishAtWithoutError.push(publishAtItem)
            }
        })

        return publishAtError.concat(publishAtWithoutError)
    }

    hidePostAlert(locationIds: string[]) {
        return DispatchrService.put("location-state", {
            locationIds,
            showPostAlert: false,
        })
    }

    formatRows(_rows = []) {
        const rowMap = {}
        const rows = Array.isArray(_rows) ? _rows : []
        const _provider = []

        for (const row of rows) {
            rowMap[row.locationId] ??= {
                name: row.locationName,
                address: `${row.locationAddress}, ${row.locationCity}`,
            }
            rowMap[row.locationId][row.provider] = row.status !== "FAILED"
            if (!_provider.includes(row.provider)) {
                _provider.push(row.provider)
            }
        }

        return {
            provider: _provider,
            rows: Object.values(rowMap),
        }
    }

    getOriginalPostFormat({ post, locationIdSingle }) {
        const isScheduled = post?.isScheduled
        const publishAtWithUtc = dayjs(post?.publishAt?.[0]?.originalDate).utc()
        const date = isScheduled ? publishAtWithUtc.format("YYYY-MM-DD") : dayjs().format("YYYY-MM-DD")
        const hour = isScheduled ? publishAtWithUtc.format("HH:mm") : ""
        const addDate = isScheduled ? true : false
        const isLastDate =
            (publishAtWithUtc.isSame(dayjs(), "day") || publishAtWithUtc.isBefore(dayjs(), "day")) &&
            publishAtWithUtc.isBefore(dayjs(), "hour")

        return {
            provider: post?.provider,
            description: post?.description,
            locationIds: locationIdSingle?.length > 0 ? locationIdSingle : post?.locationIds,
            addDate: addDate,
            hour: hour ?? "",
            date: date ?? "",
            url: post?.callToAction?.value ?? "",
            isLastPost: isLastDate,
        }
    }
}

export default new SocialService()
