import BaseAppBar from "@/components/base/app-bar"
import { EditContainerProps } from "@/types/page"
import { Box, Drawer } from "@mui/material"
import { PropsWithChildren } from "react"
import { DrawerBloc } from "../../drawer-bloc"
import useStyles from "./styles"

const ListMediaContainer = ({ children, onClose, open }: PropsWithChildren<any> & EditContainerProps) => {
    const { classes } = useStyles()
    return (
        <DrawerBloc
            disableScrollLock
            hideBackdrop
            anchor={"right"}
            open={open}
            onClose={onClose}
            transitionDuration={600}
            disableEscapeKeyDown
            classPaper={classes.container}
        >
            <BaseAppBar />
            <Box className="children-wrapper">{children}</Box>
        </DrawerBloc>
    )
}

export default ListMediaContainer
