import CampaignSuccess from "@/components/base/success/campaign"
import { PageProps } from "@/types/page"
import { Drawer } from "@mui/material"
import useStyles from "../styles"

const SuccessPage = ({ onClose, open }: PageProps) => {
    const { classes } = useStyles()

    return (
        <Drawer
            disableScrollLock
            hideBackdrop
            anchor={"right"}
            open={open}
            onClose={onClose}
            transitionDuration={600}
            disableEscapeKeyDown
            PaperProps={{
                className: classes.containerSuccess,
            }}
            sx={{
                zIndex: 1,
                ".campaign-success": {
                    margin: " auto",
                    display: "flex",
                },
            }}
        >
            <CampaignSuccess onClose={onClose} />
        </Drawer>
    )
}

export default SuccessPage
