import Icon from "@/components/base/Icon"
import useLogic from "@/hooks/businesses/edit-attribute/accordion/useLogic"
import { ListEditedAttributes } from "@/store/overview/attributes"
import {
    Box,
    Button,
    Checkbox,
    Collapse,
    FormControlLabel,
    FormGroup,
    Paper,
    Radio,
    RadioGroup,
    Typography,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import AttributeChips from "./chips"
import { ExpandMore } from "./expand-more"

interface AttributeAccordionProps {
    item: ListEditedAttributes
    itemKey: number
}

const AttributeAccordion = ({ item, itemKey }: AttributeAccordionProps) => {
    const { getSelected, handleExpandClick, onRadioChange, onCheckBoxChange, expanded } = useLogic({ itemKey })
    const { t } = useTranslation()
    return (
        <Paper className="clickable accordion" sx={{ gap: !expanded && 0 }}>
            <Box onClick={handleExpandClick}>
                <Box className="accordion-header">
                    <Typography variant="h2">{item.title}</Typography>
                    <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <Icon name="chevronDown" />
                    </ExpandMore>
                </Box>
                {!expanded && getSelected(item.attributes).length === 0 && (
                    <Button variant="text" data-e2e="add-attributes">
                        {t("BUSINESSES.EDIT_ATTRIBUTES.ADD")}
                    </Button>
                )}
                <AttributeChips expanded={expanded} selected={getSelected(item.attributes)} />
            </Box>
            <Collapse in={expanded}>
                {item.attributes.map((attribute, key) => (
                    <Paper key={key}>
                        <Typography>{attribute.title}</Typography>
                        {attribute.type === "RADIO" && (
                            <RadioGroup
                                onChange={(event) => onRadioChange(event.target.value, key)}
                                defaultValue={() => attribute.options.findIndex((el) => el.checked)}
                                aria-labelledby="radio-buttons-group-label"
                                name="radio-buttons-group"
                            >
                                {attribute.options.map((option, kkey) => (
                                    <FormControlLabel
                                        key={kkey}
                                        value={kkey}
                                        control={<Radio />}
                                        label={option.display}
                                    />
                                ))}
                            </RadioGroup>
                        )}
                        {attribute.type === "CHECKBOX" && (
                            <FormGroup aria-labelledby="form-group-label">
                                {attribute.options.map((option, kkey) => (
                                    <FormControlLabel
                                        key={kkey}
                                        value={kkey}
                                        control={
                                            <Checkbox
                                                onChange={(event) =>
                                                    onCheckBoxChange(event.target.value, key, event.target.checked)
                                                }
                                                checked={option.checked}
                                            />
                                        }
                                        label={option.display}
                                    />
                                ))}
                            </FormGroup>
                        )}
                    </Paper>
                ))}
            </Collapse>
        </Paper>
    )
}

export default AttributeAccordion
