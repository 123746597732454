import { Box, Button, SxProps, Typography } from "@mui/material"
import { useRef, useState } from "react"

export type DataItem = {
    label: string
    id?: string
}

interface RadioTabButtonProps {
    /**
     * List of all items
     */
    data: DataItem[]
    /**
     * onChange handler
     */
    onChange?: (active: number) => void
    /**
     * Component title
     */
    label?: string
    /**
     * html classes for buttons wrapper
     */
    btnWrapperClassNames?: string
    /**
     * Default active
     */
    defaultActive?: number
    sx?: SxProps
}

/**
 * Radio button that looks like a tab with a little transition
 */
const RadioTabButton = ({ data, onChange, label, btnWrapperClassNames, defaultActive, sx }: RadioTabButtonProps) => {
    const tabsRef = useRef<HTMLButtonElement[]>([])
    const [activeTabIndex, setActiveTabIndex] = useState(
        defaultActive && defaultActive < data.length ? defaultActive : 0
    )

    return (
        <Box sx={sx}>
            {label && (
                <Typography component={"span"} sx={{ mx: 0.75, my: 0.25 }}>
                    {label}
                </Typography>
            )}
            <Box sx={{ position: "relative" }}>
                <Box
                    sx={{
                        display: "flex",
                        borderRadius: "48px",
                        backgroundColor: "background.default",
                        "& button": {
                            boxShadow: "none",
                        },
                    }}
                    className={btnWrapperClassNames}
                >
                    {data.length > 1 &&
                        data.map((tab, idx: number) => (
                            <Button
                                key={idx}
                                ref={(el) => {
                                    if (el) tabsRef.current[idx] = el
                                }}
                                sx={{
                                    padding: "8px 16px",
                                    borderRadius: "45px",
                                    zIndex: activeTabIndex === idx ? 2 : 1,
                                    color: activeTabIndex !== idx ? "text.primary" : "white",
                                    width: "100%",
                                    whiteSpace: "nowrap",
                                    minWidth: "fit-content",
                                    // transition: "color .6s linear",
                                }}
                                onClick={() => {
                                    setActiveTabIndex(idx)
                                    onChange && onChange(idx)
                                }}
                            >
                                {tab?.label}
                            </Button>
                        ))}
                </Box>
                {tabsRef?.current?.length > 0 && (
                    <Box
                        component={"span"}
                        sx={{
                            position: "absolute",
                            bottom: 0,
                            display: "block",
                            backgroundColor: "text.primary",
                            transition: "all 300ms ease",
                            height: "40px",
                            zIndex: 1,
                            borderRadius: "45px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            width: tabsRef?.current[activeTabIndex]?.offsetWidth ?? 0,
                            left: tabsRef?.current[activeTabIndex]?.offsetLeft ?? 0,
                        }}
                    />
                )}
            </Box>
        </Box>
    )
}

export default RadioTabButton
