import env from "@/config/env"
import { ApiService } from "../base"

class DispacthrService extends ApiService {
    constructor() {
        super({
            baseURL: `${env.DISPATCHR_API_PATH}`,
        })
    }
}

export default new DispacthrService()
