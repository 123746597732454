import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()((theme) => ({
    container: {
        gap: 16,
        "@media (min-width: 768px)": {
            gap: 24,
        },
        "& .bloc": {
            "@media (max-width: 767px)": {
                padding: 8,
                gap: 8,
            },
            "& .section": {
                gap: "16px",
                "@media (max-width: 767px)": {
                    gap: "8px",
                },
            },
            "& .section-header": {
                flexDirection: "row",
                justifyContent: "space-between",
                "@media (max-width: 767px)": {
                    gap: "8px",
                },
            },
            "& .row-item": {
                flexDirection: "row",
                alignItems: "center",
                gap: 8,
                "@media (max-width: 767px)": {
                    "&.fixed-left": {
                        width: "100%",
                    },
                },
                "& .trash": {
                    gap: 16,
                    color: "#F46259",
                    marginLeft: 15,
                },
            },

            "& .section-content": {
                "& .section-user": {
                    "& .section-name": {
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: 16,
                        "& .name": {
                            fontWeight: "500",
                        },
                    },
                    "& .stars": {
                        flexDirection: "row",
                    },
                    "& .chip-info": {
                        width: "fit-content",
                        span: {
                            color: theme.palette.action.active,
                        },
                    },
                },
            },
        },
        ".open-campaign span": {
            color: "white",
        },
    },
    blocResponse: {
        position: "relative",
        display: "flex",
        flexDirection: "row",
        gap: 8,
        "& .divider-left": {
            borderRightWidth: "0px",
            borderWidth: "1px",
            alignSelf: "stretch",
            margin: "0px 4px",
        },
        "& .section-title": {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            "& .response-title-date": {
                flexDirection: "row",
                alignItems: "center",
                gap: 16,
                width: "100%",
                "@media (max-width: 767px)": {
                    gap: 8,
                },
            },
            "& .menu-right": {
                "& .MuiIconButton-root ": {
                    padding: "0px",
                },
            },
        },
    },
    pagination: {
        display: "flex",
        justifyContent: "flex-end",
        "& .MuiToolbar-root": {
            gap: 26,
        },
        "& .table-pagination-actions-wrapper": {
            display: "flex",
            "& button": {
                padding: 12,
            },
        },
    },
    wrapperBloc: {
        flexWrap: "wrap-reverse",
        alignItems: "center",
        flexDirection: "row",
        padding: "8px",
        "@media (min-width: 1024px)": {
            justifyContent: "space-between",
            padding: "8px 16px",
            flexWrap: "nowrap",
        },

        "& .filter-btn": {
            width: "calc(100% - 290px)",
            flexWrap: "nowrap",
            "@media (max-width: 1023px)": {
                flexWrap: "wrap",
                width: "100%",
            },
        },

        "& .MuiFormControl-root": {
            width: "100%",
            maxWidth: "320px",
            justifyContent: "space-between",
            alignContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
            "@media (max-width: 1023px)": {
                maxWidth: "100%",
                minWidth: "auto",
                "& .MuiFilledInput-root": {
                    width: "100%",
                },
            },
            "& .MuiFilledInput-input": {
                paddingRight: "0px !important",
            },
        },
        "& .input-business": {
            width: "100%",
            "@media (min-width: 768px)": {
                maxWidth: 350,
                "& .MuiAutocomplete-root": {
                    maxWidth: "100%",
                },
            },
        },
        "& .checkbox-confirm": {
            marginRight: 0,
            "@media (max-width: 767px)": {
                alignItems: "flex-start",
            },
        },
        "& .MuiFormControlLabel-label ": {
            color: theme.palette.action.active,
        },
        "& .MuiFormControlLabel-asterisk": {
            color: "#0180FF",
        },
        "& .check-condition": {
            "@media (max-width: 767px)": {
                alignItems: "flex-start",
            },
        },
        "& .open-campaign": {
            width: "100%",
            padding: "8px 16px",
            gap: "8px",
            "@media (min-width: 1024px)": {
                width: "auto",
                height: 42,
            },
        },
    },
}))

export default useStyles
