import TitleWrapper from "@/components/base/page-container/list-container/title-wrapper"
import { ListReputation } from "@/components/reputation/list-reputation"
import { Container } from "@mui/material"

import useStyles from "@/components/reputation/styles"
import { SkeletonHeaderBtn } from "@/components/skeleton/header-btn-left-right"
import { SkeletonReputationItem } from "@/components/skeleton/reputation/bloc-item-reputation"
import useLogic from "@/hooks/reputation/list/useLogic"
import { Fragment } from "react"
import { useTranslation } from "react-i18next"

const ReputationPage = ({ mockData = null }) => {
    const { classes } = useStyles()
    const { t } = useTranslation()
    const {
        loading,
        items,
        total,
        page,
        setPage,
        refBloc,
        alert,
        handleSelectionChange,
        handleSelectionRateChange,
        handleDeleteChange,
        isLimitExceeded,
    } = useLogic()

    return (
        <Container className={classes.container}>
            <TitleWrapper pageIcon="star" title={t("REPUTATION.TITLE")} alert={alert} loading={loading} />
            {loading && (
                <Fragment>
                    <SkeletonHeaderBtn />
                    {[...Array(5)].map((value, key) => (
                        <SkeletonReputationItem key={`${String(key)}`} />
                    ))}
                </Fragment>
            )}
            {items && !loading && (
                <ListReputation
                    items={items}
                    total={total}
                    refBloc={refBloc}
                    handleSelectionChange={handleSelectionChange}
                    handleSelectionRateChange={handleSelectionRateChange}
                    handleDeleteChange={handleDeleteChange}
                    page={page}
                    setPage={setPage}
                    isLimitExceeded={isLimitExceeded}
                />
            )}

            {mockData && (
                <ListReputation
                    mockData={mockData}
                    total={10}
                    page={0}
                    handleSelectionChange={handleSelectionChange}
                    handleSelectionRateChange={handleSelectionRateChange}
                    handleDeleteChange={handleDeleteChange}
                    isLimitExceeded={false}
                />
            )}
        </Container>
    )
}

export default ReputationPage
