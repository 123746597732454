import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()((theme) => ({
    drawer: { zIndex: 1 },
    container: {
        "& .title-wrapper": {
            padding: 0,
            display: "flex",
            gap: 8,
            flexDirection: "column",
            alignItems: "center",
            "@media (min-width: 768px)": {
                gap: 16,
            },
            ".select-status-wrapper": {
                width: "100%",
            },
            ".select-status-schedule": {
                width: "100%",
            },

            "@media (min-width: 992px)": {
                flexDirection: "row",
                justifyContent: "space-between",
                padding: "0 8px",
                width: "100%",
                ".select-status-wrapper": {
                    width: "auto",
                },
                "& .select-status-schedule": {
                    width: "320px",
                    marginRight: "8px",
                },
            },
            "& .title": {
                display: "flex",
                justifyContent: "start",
                gap: 16,
                width: "100%",
                padding: 0,
                "& h2": {
                    fontSize: 24,
                    color: theme.palette.common.black,
                },
                "& svg": {
                    color: theme.palette.action.active,
                },
            },
        },
        "& .paper-wrapper": {
            display: "flex",
            flexDirection: "column",
            gap: 8,
            "@media (min-width: 768px)": {
                gap: 16,
            },
        },
        "& .error": {
            textAlign: "start",
        },
        "& .wrapper": {
            display: "flex",
            flexDirection: "column",
            gap: 8,
        },
        "& .has-adornment .MuiInputBase-root": {
            gap: 8,
            "& svg": {
                margin: "auto",
                marginBottom: 8,
            },
        },
        "& .switch-button-wrapper": {
            display: "flex",
            alignItems: "center",
            gap: 8,
            "& .MuiIconButton-root": {
                padding: 0,
            },
            "& svg": {
                color: theme.palette.action.active,
            },
        },
        "& .inputs": {
            display: "flex",
            flexDirection: "column",
            gap: 8,
            "@media (min-width: 768px)": {
                gap: 16,
                flexDirection: "row",
                flexWrap: "wrap",
            },
            "& > *": {
                "@media (min-width: 768px)": {
                    width: "calc(50% - 8px)",
                    "&:nth-of-type(3)": {
                        width: "100%",
                    },
                },
            },
        },
    },
}))

export default useStyles
