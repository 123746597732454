import { Stack } from "@mui/material"
import { styled } from "@mui/material/styles"

type InputProps = {
    $width: number
}

export const CustomBlocSkeleton = styled(Stack, {
    shouldForwardProp: (prop) => prop !== "inputCssProps",
    name: "CustomBlocSkeleton",
})<{ inputCssProps: InputProps }>(({ theme, inputCssProps }) => ({
    borderRadius: "16px 16px 16px 4px",
    background: "rgba(104, 115, 141, 0.42)",
    padding: "8px",
    width: inputCssProps.$width ?? "70%",
    "& .MuiSkeleton-root ": {
        backgroundColor: "white",
        opacity: "0.6",
        "&:first-of-type": {
            width: "80%",
        },
    },
}))
