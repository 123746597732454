import { ReputationApi } from "@/api"
import { decode64AndParse } from "@/utils"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

const errorCases = ["ANSWER_ALREADY_PUBLISHED", "TOKEN_NOT_FOUND", "RECONNECTION_REQUIRED"]

const useLogic = () => {
    const [searchParams] = useSearchParams()
    const [sendingReply, setSendingReply] = useState(true)
    const [sendingSuccess, setSendingSuccess] = useState(false)
    const [answerFailed, setAnswerFailed] = useState("")
    const navigate = useNavigate()
    const getParams = useCallback(() => {
        const inf = searchParams.get("inf")
        if (!inf) setAnswerFailed("TOKEN_NOT_FOUND")
        return decode64AndParse(inf || "")
    }, [searchParams, navigate, decode64AndParse])

    const sendReply = useCallback(async () => {
        const params = getParams()
        if (params?.token) {
            setSendingReply(true)
            setSendingSuccess(false)

            ReputationApi.publishReview(params?.token)
                .then((resp) => {
                    if (!resp?.error) {
                        setSendingReply(false)
                        setSendingSuccess(true)
                    } else {
                        setSendingReply(false)
                        setAnswerFailed(errorCases.includes(resp?.error) ? resp?.error : "INTERNAL_SERVER_ERROR")
                    }
                })
                .catch(() => {
                    setSendingReply(false)
                    setSendingSuccess(false)
                    setAnswerFailed("INTERNAL_SERVER_ERROR")
                })
        } else {
            setSendingReply(false)
            setAnswerFailed("TOKEN_NOT_FOUND")
        }
    }, [getParams, setSendingReply])

    const redirectToCampaign = useCallback(() => {
        navigate("/campaign")
    }, [navigate])

    const errorStatuses = useMemo(
        () => ({
            answerAlreadySent: answerFailed === "ANSWER_ALREADY_PUBLISHED",
            tokenInvalid: answerFailed === "TOKEN_NOT_FOUND",
            locationDisconnected: answerFailed === "RECONNECTION_REQUIRED",
            internalError: answerFailed === "INTERNAL_SERVER_ERROR",
        }),
        [answerFailed]
    )
    useEffect(() => {
        sendReply()
    }, [sendReply])

    return {
        sendingReply,
        sendingSuccess,
        answerFailed,
        ...errorStatuses,
        redirectToCampaign,
        setSendingReply,
        setSendingSuccess,
    }
}

export default useLogic
