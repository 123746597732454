import { create } from "zustand"

type Loading = {
    attribute: boolean
    locationInfo: boolean
    media: boolean
    firstLoaded: boolean
}

export type IOverviewStore = {
    loading: Loading
}

export interface IOverviewStoreSetter {
    updateState: (update: Partial<IOverviewStore>) => void
    setLoading: (key: keyof Loading, value: boolean) => void
    resetLoading: () => void
    flush: () => void
}

const initalState = {
    loading: {
        attribute: true,
        locationInfo: true,
        media: true,
        firstLoaded: false,
    },
}

const useOverviewStore = create<IOverviewStore & IOverviewStoreSetter>((set) => ({
    ...initalState,
    updateState: (update) => set(() => ({ ...update })),
    setLoading: (key: keyof Loading, value: boolean) =>
        set((previousState) => ({
            loading: {
                ...previousState?.loading,
                [key]: value,
            },
        })),
    resetLoading: () => set(() => ({ loading: initalState.loading })),
    flush: () => set(initalState),
}))

export default useOverviewStore
