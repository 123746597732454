import { CustomBaseInput } from "../autocomplete/styles"
import BaseInput from "../input"

const RenderInputBloc = ({
    label,
    multiple,
    field,
    required,
    helperText,
    helperTextPosition,
    type,
    otherParams,
    options,
    value,
    max = 255,
}) => {
    switch (type) {
        case "checkbox":
            return (
                <CustomBaseInput
                    {...otherParams}
                    label={label}
                    inputCssProps={{
                        $multiple: multiple && field?.length > 0,
                    }}
                    showLabelStar={required}
                    helperText={helperText}
                    helperTextPosition={helperTextPosition}
                    required={!!required}
                    inputProps={{
                        ...otherParams.inputProps,
                        required: value.length === 0,
                    }}
                />
            )
        case "category":
            return (
                <CustomBaseInput
                    inputCssProps={{
                        $multiple: multiple && Array.isArray(value) && value?.length > 0,
                    }}
                    showLabelStar
                    label={label}
                    helperText={helperText || ""}
                    helperTextPosition="end"
                    inputProps={{
                        required: options?.length === 0,
                    }}
                    required={!!required}
                    value={value}
                    {...otherParams}
                />
            )
        case "google-place":
            return (
                <BaseInput
                    helperText={helperText || ""}
                    helperTextPosition={helperTextPosition || "end"}
                    max={max || 255}
                    required={!!required}
                    label={label}
                    sx={{
                        "& .MuiInputBase-root.MuiFilledInput-root": {
                            ...(value?.length > 0 && { paddingTop: 3 }),
                        },
                        "& .MuiButtonBase-root.MuiChip-root.MuiChip-filled": {
                            backgroundColor: value?.length > 0 && "secondary.main",
                        },
                    }}
                    showLabelStar={!!required}
                    {...otherParams}
                />
            )
        case "google":
            return (
                <BaseInput showLabelStar={!!required} required={!!required} label={label} max={max} {...otherParams} />
            )

        default:
            return <></>
    }
}

export default RenderInputBloc
