import UiStore, { IUiSetter, IUiStore } from "@/store/ui"

class UiService {
    getState = (): IUiStore & IUiSetter => {
        return UiStore.getState()
    }

    setState = (update: Partial<IUiStore>): void => {
        const { updateState } = this.getState()
        updateState(update)
    }
}

export default new UiService()
