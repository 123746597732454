import SerializedString from "@/components/serialized-string"
import { Paper } from "@mui/material"
import OverviewTitle from "../title"
import useBusinessLogic from "@/hooks/businesses/useBusinessLogic"
import { useTranslation } from "react-i18next"

const OverviewAttribute = ({ attributRef, data }: { attributRef?: any; data: any }) => {
    const { navigateTo } = useBusinessLogic()
    const { t } = useTranslation()
    return (
        <Paper
            ref={attributRef}
            elevation={2}
            className="clickable"
            id="attributs"
            data-e2e="attribute-business"
            onClick={() => navigateTo("edit-attribute")}
        >
            <OverviewTitle
                title={t("BUSINESSES.ATTRIBUTE_TITLE")}
                // icon="bulletList"
                data-testid="title"
                isSecondTitle
                tooltipText={t("BUSINESSES.EDIT_ATTRIBUTES.TITLE_TOOLTIP")}
            />
            {data && <SerializedString text={data} />}
        </Paper>
    )
}

export default OverviewAttribute
