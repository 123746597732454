import { CountryCode } from "libphonenumber-js"
import { create } from "zustand"
export interface IOveriewMainInfoStore {
    id: string
    uid: string
    name: string
    address: string
    city: string
    companyId: string
    countryCode: string
    logo: string
    phone: string
    placeId: string
    region: string
    status: string
    storeCode: string
    countryPhone: CountryCode
    zip: string
    email: string
    companyName: string
    isAddressVisible: boolean
    websiteUrl: string
}

export interface IOveriewMainInfoSetter {
    update?: (update: Partial<IOveriewMainInfoStore>) => void
    flush?: () => void
}

const initalState: Partial<IOveriewMainInfoStore> = {
    name: "",
    logo: "",
    id: "",
    companyName: "",
    phone: "",
    address: "",
    isAddressVisible: false,
    countryCode: "",
    city: "",
    companyId: "",
    zip: "",
    region: "",
}

const OverviewMainInfoStore = create<Partial<IOveriewMainInfoStore> & IOveriewMainInfoSetter>((set) => ({
    ...initalState,
    update: (update) => set(() => ({ ...update })),
    flush: () => set(initalState),
}))

export default OverviewMainInfoStore
