import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()((theme) => ({
    header: {
        backgroundColor: theme.palette.background.default,
        padding: "8px 0",
        zIndex: 2,
        position: "sticky",
        top: 0,
        boxShadow: "initial",
        "& .MuiToolbar-root": {
            minHeight: "fit-content",
            padding: "0 12px",
        },
        "@media (min-width: 768px)": {
            display: "none",
        },
        "& .mobile-menu-btn": {
            justifyContent: "flex-start",
            position: "sticky",
            top: 0,
            // backgroundColor: theme.palette.background.default,
            width: "fit-content",
            padding: 12,
            borderRadius: 16,
            "@media (min-width: 768px)": {
                display: "none",
            },
        },
    },
}))

export default useStyles
