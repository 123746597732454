import { List, Stack } from "@mui/material"
import { SkeletonHeaderBtn } from "../header-btn-left-right"
import { SkeletonListingTopNumber } from "./bloc-top-number"
import { SkeletonListingItem } from "./item"
import { v4 as uuidv4 } from "uuid"
import { useStyles } from "./styles"
import { listSx } from "@/components/listings/websites-list/styles"

export const SkeletonListing = () => {
    const { classes } = useStyles()
    return (
        <Stack className={classes.container}>
            <List className={classes.headerList}>
                {[...Array(4)].map((key) => (
                    <SkeletonListingTopNumber key={uuidv4()} />
                ))}
            </List>
            <SkeletonHeaderBtn />
            <List sx={listSx()}>
                {[...Array(12)].map((key) => (
                    <SkeletonListingItem key={uuidv4()} />
                ))}
            </List>
        </Stack>
    )
}
