import UiStore from "@/store/ui"
import dayjs from "dayjs"
import localeEn from "dayjs/locale/en"
import localeFr from "dayjs/locale/fr"
import localeFrCa from "dayjs/locale/fr-ca"
import isTodayPlugin from "dayjs/plugin/isToday"
import objectPlugin from "dayjs/plugin/toObject"
import weekdayPlugin from "dayjs/plugin/weekday"
import { useCallback, useEffect, useState } from "react"

dayjs.extend(weekdayPlugin)
dayjs.extend(objectPlugin)
dayjs.extend(isTodayPlugin)

const useCalendar = ({ defaultMonth }: { defaultMonth?: string }) => {
    const [now, setNow] = useState(dayjs().locale(localeFr))
    const [currentMonth, setCurrentMonth] = useState(defaultMonth ? dayjs(defaultMonth).locale(localeFr) : now)
    const [arrayOfDays, setArrayOfDays] = useState([])
    const { preferredLanguage } = UiStore()

    const nextMonth = useCallback(() => {
        const plus = currentMonth.add(1, "month")
        setCurrentMonth(plus)
    }, [currentMonth])

    const prevMonth = useCallback(() => {
        const minus = currentMonth.subtract(1, "month")
        setCurrentMonth(minus)
    }, [currentMonth])

    const getHeaderDays = useCallback(() => {
        const dateFormat = "dddd"
        const days = []
        for (let i = 0; i < 7; i++) {
            days.push(now.weekday(i).format(dateFormat))
        }
        return days
    }, [now])

    const formateDateObject = useCallback(
        (date) => {
            const clonedObject = { ...date.toObject() }
            const formatedObject = {
                day: clonedObject.date,
                month: clonedObject.months,
                year: clonedObject.years,
                isCurrentMonth: clonedObject.months === currentMonth.month(),
                isCurrentDay: date.isToday(),
            }

            return formatedObject
        },
        [currentMonth]
    )

    const getAllDays = useCallback(() => {
        let currentDate = currentMonth.startOf("month").weekday(0)
        const nextMonth = currentMonth.add(1, "month").month()
        const allDates = []
        let weekDates = []
        let weekCounter = 1
        while (currentDate.weekday(0).toObject().months !== nextMonth) {
            const formated = formateDateObject(currentDate)
            weekDates.push(formated)
            if (weekCounter === 7) {
                allDates.push({ dates: weekDates })
                weekDates = []
                weekCounter = 0
            }
            weekCounter++
            currentDate = currentDate.add(1, "day")
        }
        setArrayOfDays(allDates)
    }, [currentMonth, formateDateObject])

    useEffect(() => {
        getAllDays()
    }, [getAllDays])

    useEffect(() => {
        switch (preferredLanguage) {
            case "fr":
                setNow(dayjs().locale(localeFr))
                setCurrentMonth((prev) => dayjs(prev).locale(localeFr))
                break
            case "fr-ca":
                setNow(dayjs().locale(localeFrCa))
                setCurrentMonth((prev) => dayjs(prev).locale(localeFrCa))
                break
            case "en":
                setNow(dayjs().locale(localeEn))
                setCurrentMonth((prev) => dayjs(prev).locale(localeEn))
                break
            default:
                setNow(dayjs().locale(localeFr))
                setCurrentMonth((prev) => dayjs(prev).locale(localeFr))
                break
        }
    }, [preferredLanguage])

    return {
        currentMonth,
        arrayOfDays,
        nextMonth,
        prevMonth,
        getHeaderDays,
        formateDateObject,
        getAllDays,
    }
}

export default useCalendar
