import { create } from "zustand"

export interface IResellerStore {
    resellerLogoDark: string
    resellerLogo: string
    resellerName: string
    resellerFavicon: string
    resellerUid: string
    resellerFetched: boolean
    googleIdentityClientId: string
}

export interface IResellerSetter {
    updateState?: (update: Partial<IResellerStore>) => void
    flush?: () => void
}

const initalState = {
    resellerLogo: "",
    resellerLogoDark: "",
    resellerName: "",
    resellerFavicon: "",
    resellerUid: "",
    resellerFetched: false,
    googleIdentityClientId: null,
}

const ResellerStore = create<IResellerStore & IResellerSetter>((set) => ({
    ...initalState,
    updateState: (update) => set(() => ({ ...update })),
    flush: () => set(initalState),
}))

export default ResellerStore
