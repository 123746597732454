import { makeStyles } from "tss-react/mui"

const useStyles = ({ withSearch, showActionButton }) =>
    makeStyles()((theme) => ({
        searchWrapper: {
            gap: "8px",
            backgroundColor: theme.palette.common.white,
            flexDirection: "column",
            padding: "8px",
            "@media (min-width: 1024px)": {
                padding: "8px 16px",
                flexDirection: withSearch && showActionButton ? "row-reverse" : "column",
            },
            "& .action-btn": {
                padding: "8px 22px",
                width: withSearch ? "100%" : "fit-content",

                display: "flex",
                gap: 1,
                height: "fit-content",
                margin: "auto",
                marginRight: 0,
                "@media (max-width: 1023px)": {
                    maxWidth: "100%",
                },
                "& span": {
                    letterSpacing: 0.46,
                    fontSize: 15,
                },
            },
        },
    }))

export default useStyles

export const actionBtnSx = {
    maxWidth: { md: "fit-content" },
    width: { xs: "fit-content", md: "100%" },
}

export const btnTextSx = { whiteSpace: "nowrap", color: "common.white" }

export const baseInputSx = {
    width: "100%",
    "@media (min-width: 1024px)": {
        maxWidth: 320,
    },
}

export const iconBtnSx = { p: 0, color: "inherit" }
