export const sx = {
    "& .apexcharts-tooltip-series-group": {
        justifyContent: "flex-end",
    },
    "& .apexcharts-tooltip-text": {
        width: "calc(100% - 20px)",
    },
    "& .apexcharts-tooltip-y-group": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 1,
    },
    "& .apexcharts-tooltip-text-y-value": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 1,
        "& .red": {
            color: "#F46259",
        },
        "& .green": {
            color: "#03CC6C",
        },
        "& .blue": {
            color: "#309DDB",
        },
    },
    "& .apexcharts-legend.apexcharts-align-left .apexcharts-legend-series": {
        display: "flex",
    },
    "& .apexcharts-menu": {
        fontFamily: "Poppins",
    },
    // "& .apexcharts-marker": {
    //     r: "7",
    // },

    "& .apexcharts-tooltip-series-group:last-child": {
        display: "none",

        "& .apexcharts-tooltip-marker": {
            display: "none",
        },
    },
}
