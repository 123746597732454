import CampaignStore from "@/store/campaign"
import { isValidEmail } from "@/utils"
import { CountryCode, isValidNumberForRegion } from "libphonenumber-js"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { v4 as uuidv4 } from "uuid"
import { read, utils } from "xlsx"
import useFormater from "../useFormater"

const useCampaignUpload = () => {
    const { update, pagination } = CampaignStore()
    const { t } = useTranslation()
    const { internationalPhone } = useFormater()

    const showUpload = useCallback(() => {
        update({ openUpload: true })
    }, [update])

    const closeUpload = useCallback(() => {
        update({ openUpload: false })
    }, [update])

    const updateFile = useCallback(
        (newFile: File) => {
            update({ file: newFile })
        },
        [update]
    )

    const findAndOrganizeTableByType = (json: any, type = "sms", country = "CA" as CountryCode) => {
        let dataTypeIndex = -1
        // Determine the index of the column that contains the required data type
        if (type === "email") {
            for (let i = 0; i < json?.length; i++) {
                const contact = Object.values(json[i])
                if (isValidEmail(contact[2]?.toString())) {
                    dataTypeIndex = 2
                    break
                } else if (contact[3] && isValidEmail(contact[3]?.toString())) {
                    dataTypeIndex = 3
                    break
                }
            }
        } else if (type === "sms") {
            for (let i = 0; i < json?.length; i++) {
                const contact = Object.values(json[i])
                if (isValidNumberForRegion(contact[2]?.toString(), country)) {
                    dataTypeIndex = 2
                    break
                } else if (contact[3] && isValidNumberForRegion(contact[3]?.toString(), country)) {
                    dataTypeIndex = 3
                    break
                }
            }
        }

        // Filter the JSON to return only the columns 0, 1 and the determined data type column
        const organizedJson = [...json].map((row) => {
            const arr = row
            arr[2] = row[dataTypeIndex]
            delete arr[3]
            return row
        })

        return organizedJson
    }

    const formatFileData = useCallback(
        ({ fileJson, type = "sms", typeLabel = "", country = "CA" as CountryCode }) => {
            const valeursUniques = {}
            const jsonFile = fileJson.map((json) => Object.values(json))

            const filteredJson = findAndOrganizeTableByType(jsonFile, type, country)

            const data = filteredJson
                ?.filter((contact: string[]) => {
                    if (!contact[2]) return false
                    if (type === "sms") {
                        const formatedPhone = internationalPhone(contact[2]?.toString() || "", country)
                        return isValidNumberForRegion(formatedPhone, country)
                    } else {
                        return isValidEmail(contact[2]?.toString())
                    }
                })
                ?.map((contact: string[]) => {
                    const formatedPhone = internationalPhone(contact[2]?.toString() || "", country)

                    return {
                        uid: uuidv4(),
                        [t("CAMPAIGN.FIRSTNAME")]: contact[0],
                        [t("CAMPAIGN.LASTNAME")]: contact[1],
                        [typeLabel]: type === "sms" && contact[2] ? formatedPhone : contact[2],
                    }
                })
                ?.filter((objet) => {
                    if (Object.prototype.hasOwnProperty.call(valeursUniques, objet[typeLabel])) {
                        return false
                    }
                    valeursUniques[objet[typeLabel]] = true
                    return true
                })

            return data
        },
        [internationalPhone, t]
    )

    const readFile = useCallback(
        async ({ file, type, typeLabel, country }) => {
            const workbook = read(await file.arrayBuffer())
            const fileJson = utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { defval: false })
            if (fileJson.length) {
                const finalJson = formatFileData({
                    fileJson,
                    type,
                    typeLabel,
                    country,
                })
                const rows = [...finalJson].slice(
                    pagination.page_number * pagination.page_size,
                    pagination.page_number * pagination.page_size + pagination.page_size
                )

                update({
                    contacts: finalJson,
                    pagination: {
                        total_count: finalJson.length,
                        page_number: 0,
                        page_size: 10,
                        rows,
                    },
                })
            } else {
                update({
                    contacts: [],
                    pagination: {
                        total_count: 0,
                        page_number: 0,
                        page_size: 10,
                        rows: [],
                    },
                })
            }
        },
        [update, formatFileData, pagination]
    )

    return {
        showUpload,
        closeUpload,
        updateFile,
        readFile,
    }
}

export default useCampaignUpload
