import { Container, Paper, Skeleton } from "@mui/material"
import { SkeletonBlocStepper } from "../bloc-stepper"
import { SkeletonFormCampaign } from "./form"
import { SkeletonHeaderBtnCampaign } from "./header-btn"

export const SkeletonCampaign = () => {
    return (
        <Container>
            <SkeletonBlocStepper />
            <SkeletonHeaderBtnCampaign />
            <SkeletonFormCampaign />
            <Paper>
                <Skeleton variant="rectangular" height={30} />
            </Paper>
        </Container>
    )
}
