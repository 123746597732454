import BlocSkeleton from "@/components/base/bloc-skeleton"
import SmsSkeleton from "@/components/skeleton/bloc-sms"
import useFormater from "@/hooks/useFormater"
import { urlify } from "@/utils"
import { Stack, Typography } from "@mui/material"
import { useMemo } from "react"
import useStyles from "../styles"

type SmsPreviewProps = {
    html: string
    contacts: any
    businessName: string
    isLoading?: boolean
}

export default function SmsPreview({ html, contacts, businessName, isLoading }: SmsPreviewProps) {
    const { classes } = useStyles()
    const { parseVariables } = useFormater()

    const text = useMemo(() => {
        return urlify(parseVariables(html ?? "", contacts, businessName))
    }, [businessName, contacts, html, parseVariables])

    return (
        <>
            {isLoading ? (
                <SmsSkeleton />
            ) : (
                <Stack className={`${classes.smsBloc} bloc-sms-preview`} gap={3}>
                    <Stack gap={1} alignItems="center">
                        <Stack className="rectangle"></Stack>
                    </Stack>
                    <Stack gap={2} sx={{ width: "100%" }}>
                        <BlocSkeleton width="70%" totalSkeleton={2} />
                        <Stack className="bloc-message">
                            <Typography
                                component="div"
                                color="white"
                                sx={{ wordBreak: "normal", whiteSpace: "pre-line" }}
                                dangerouslySetInnerHTML={{ __html: text }}
                            ></Typography>
                        </Stack>
                        <BlocSkeleton width="50%" totalSkeleton={2} />
                    </Stack>
                </Stack>
            )}
        </>
    )
}
