import { Autocomplete } from "@mui/material"
import { styled } from "@mui/material/styles"
import BaseInput from "../input"

type InputProps = {
    $requiredelement?: boolean
    $multipleelement?: boolean
    $keepiconeotationonopen?: boolean
    $multiple?: boolean
}

type InputBox = {
    $multiple?: boolean
    $isdisabled?: boolean
}

export const CustomAutocomplete = styled(Autocomplete, {
    shouldForwardProp: (prop) => prop !== "inputCssProps",
    name: "CustomAutocomplete",
})<{ inputCssProps: InputProps }>(({ theme, inputCssProps }) => ({
    borderRadius: "8px",
    backgroundColor: "transparent",
    "& fieldset": {
        border: "none",
    },
    "& .MuiChip-root": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        "& .MuiChip-deleteIcon": {
            color: theme.palette.common.white,
            opacity: 0.7,
            "&:hover": {
                color: theme.palette.common.white,
            },
        },
    },
    "& .MuiFilledInput-root": {
        paddingBottom: inputCssProps.$multipleelement ? "8px" : 0,
        paddingTop: inputCssProps.$multipleelement ? "24px" : "19px",
    },

    "& .MuiFormLabel-asterisk": {
        color: theme.palette.primary.main,
        display: inputCssProps.$requiredelement ? "initial" : "none",
    },
}))

export const CustomBaseInput = styled(BaseInput, {
    shouldForwardProp: (prop) => prop !== "inputCssProps",
    name: "CustomBaseInput",
})<{ inputCssProps: InputBox }>(({ inputCssProps, theme }) => ({
    "& .MuiInputBase-root.MuiFilledInput-root": {
        paddingTop: inputCssProps.$multiple && "24px",
        paddingBottom: inputCssProps.$multiple && "8px",
    },
    "& .MuiButtonBase-root.MuiChip-root.MuiChip-filled": {
        backgroundColor: inputCssProps.$multiple && theme.palette.secondary.main,
    },
}))

export const componentProps = (paddingOption) => {
    const res = {
        paper: {
            sx: {
                padding: paddingOption,
                "& li.MuiAutocomplete-option": {
                    padding: paddingOption,
                },
                "& .MuiAutocomplete-noOptions": {
                    opacity: 1,
                    color: "action.active",
                    justifyContent: "center",
                    padding: 2,
                    textAlign: "center",
                },
            },
        },
    }
    return res
}
