import CampaignStore from "@/store/campaign"
import { SelectType } from "@/types/select"
import { ensureArray } from "@/utils"
import { useCallback, useEffect, useMemo, useState } from "react"

const useLogic = ({ setValue }) => {
    const { credits, locations, resetPagination } = CampaignStore()
    const [options, setOptions] = useState<SelectType[]>([])

    const creditSms = useMemo(() => {
        return ensureArray(credits)?.find((credit) => credit?.type === "sms_rb")?.remaining || 0
    }, [credits])

    const creditEmail = useMemo(() => {
        return ensureArray(credits)?.find((credit) => credit?.type === "email_rb")?.remaining || 0
    }, [credits])

    const handleViewChange = useCallback(
        (value: number) => {
            const reviewType = value === 1 ? "email" : "sms"
            setValue("type", reviewType)
            if (reviewType === "sms") {
                setValue("phone", "")
            } else {
                setValue("email", "")
            }
            // trigger("type")
            // trigger("phone")
            // trigger("email")
            // resetPagination()
        },
        [setValue, resetPagination]
    )

    useEffect(() => {
        const mappedEntities = locations?.map((location) => ({
            label: location.name,
            value: location.id,
            shortAddress: `${location.address}, ${location.city}`,
        }))

        setOptions(mappedEntities)
        if (mappedEntities?.length === 1) {
            setValue("business", mappedEntities[0]?.value)
        }
    }, [locations])

    return {
        options,
        creditSms,
        creditEmail,
        handleViewChange,
    }
}

export default useLogic
