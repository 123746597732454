import { checkHavelink } from "@/utils"
import Delta from "quill-delta"
import { useCallback, useEffect, useMemo, useState } from "react"

export const useInputEditor = ({ atValues, hashValues, maxLength, onChange, editorRef, toolbar, defaultValue }) => {
    const [lengthText, setLengthText] = useState<number>(0)
    const [linkIsAdded, setLinkIsAdded] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = useMemo(() => Boolean(anchorEl), [anchorEl])

    const getValues = useCallback(
        (mentionChar: string) => {
            if (mentionChar === "@") {
                return atValues
            } else {
                return hashValues
            }
        },
        [hashValues, atValues]
    )

    const getMentionList = useCallback(() => {
        return {
            mention: {
                allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
                mentionDenotationChars: ["@", "#", "{{"],
                source: function (searchTerm: any, renderList: any, mentionChar: string) {
                    const values = getValues(mentionChar)

                    if (searchTerm.length === 0) {
                        renderList(values, searchTerm)
                    } else if (values && values.length > 0) {
                        const matches = []
                        for (let i = 0; i < values.length; i++)
                            if (~values[i]?.value?.toLowerCase()?.indexOf(searchTerm?.toLowerCase()))
                                matches.push(values[i])
                        renderList(matches, searchTerm)
                    }
                },
            },
        }
    }, [getValues])

    const modules = useMemo(() => {
        const res = {
            toolbar: toolbar,
            clipboard: {
                matchVisual: false,
            },
            ...getMentionList(),
        }
        return res
    }, [toolbar])

    const previewMessageEqualContent = useMemo(() => {
        return lengthText >= maxLength
    }, [maxLength, lengthText])

    const handleClose = useCallback(() => {
        setAnchorEl(null)
    }, [])

    useEffect(() => {
        setLinkIsAdded(checkHavelink(defaultValue))
    }, [defaultValue])

    const handleChangeValue = useCallback(
        (html, delta, source, editor) => {
            if (editorRef.current) {
                const quillEditor = editorRef?.current?.getEditor()
                quillEditor.on("text-change", () => {
                    if (editorRef?.current?.editor?.getText().trim().length - 1 >= maxLength) {
                        quillEditor.updateContents(new Delta().retain(maxLength).delete(maxLength + 1))
                    }
                })

                quillEditor.clipboard.addMatcher("IMG", () => {
                    return new Delta().insert("")
                })

                quillEditor.clipboard.addMatcher("PICTURE", () => {
                    return new Delta().insert("")
                })

                // quillEditor.clipboard.addMatcher(Node.ELEMENT_NODE, function (node, delta) {
                //     const plaintext = node.innerText
                //     return new Delta().insert(plaintext)
                // })
                if (!checkHavelink(html)) {
                    setLinkIsAdded(false)
                }
                onChange({
                    html: html,
                    delta: delta,
                    rawText: editorRef?.current?.editor?.getText().trim(),
                    editorText: editor?.getText().trim(),
                })
                setLengthText(editor?.getText().length)
            }
        },
        [onChange, maxLength]
    )

    const toggleMenu = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            if (maxLength - lengthText > 1 || !previewMessageEqualContent) {
                setAnchorEl(!open ? event.currentTarget : null)
            }
        },
        [open, previewMessageEqualContent, lengthText, maxLength]
    )

    const handleChangeInput = useCallback(
        (value, type = "text") => {
            if (editorRef.current) {
                const refEditor = editorRef.current.getEditor()
                refEditor.focus()

                const range = editorRef.current.getEditorSelection()
                    ? editorRef.current.getEditorSelection()?.index
                    : lengthText > 0
                    ? lengthText - 1
                    : 0

                const newCursorPositionAfterUpdating = range + 1
                if (type === "mention") {
                    editorRef.current.editor.insertEmbed(range, "mention", {
                        index: "2",
                        denotationChar: "@",
                        id: value,
                        value: value,
                    })
                    setLinkIsAdded(true)
                    refEditor.updateContents(new Delta().retain(range + 1).insert(" "))
                    refEditor.setSelection(editorRef.current.getEditorSelection()?.index + 1)
                } else {
                    refEditor
                        .updateContents(new Delta().retain(range).insert(value))
                        // Remove deltas before inserting otherwise we duplicate deltas.
                        .delete(newCursorPositionAfterUpdating)
                }

                if (editorRef.current.getEditorSelection()) {
                    const moreIndex = type === "emoji" ? 2 : 1
                    refEditor.setSelection(editorRef.current.getEditorSelection()?.index + moreIndex)
                }
            }
        },
        [editorRef, lengthText]
    )

    const textLength = useCallback(() => {
        if (editorRef?.current) {
            try {
                const text = editorRef?.current?.editor?.getText().trim()
                return text.length
            } catch {
                return 0
            }
        }
        return 0
    }, [editorRef])

    return {
        editorRef,
        modules,
        handleChangeValue,
        toggleMenu,
        previewMessageEqualContent,
        handleChangeInput,
        open,
        anchorEl,
        handleClose,
        textLength,
        linkIsAdded,
        getMentionList,
    }
}
