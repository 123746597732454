import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material"
import { IconButton, Tooltip } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"

interface TablePaginationActionsProps {
    count: number
    page: number
    rowsPerPage: number
    onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void
    disablePagination?: boolean
}

const TablePaginationActions = (props: TablePaginationActionsProps) => {
    const { count, page, rowsPerPage, onPageChange, disablePagination } = props

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1)
    }

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1)
    }
    const { t } = useTranslation()

    return (
        <>
            <Tooltip title={t("USERS.PREVIOUS_PAGE_TOOLTIP")}>
                <span>
                    <IconButton
                        onClick={handleBackButtonClick}
                        disabled={page === 0 || disablePagination}
                        aria-label="previous page"
                    >
                        <KeyboardArrowLeft />
                    </IconButton>
                </span>
            </Tooltip>
            <Tooltip title={t("USERS.NEXT_PAGE_TOOLTIP")}>
                <span>
                    <IconButton
                        onClick={handleNextButtonClick}
                        disabled={page >= Math.ceil(count / rowsPerPage) - 1 || disablePagination}
                        aria-label="next page"
                    >
                        <KeyboardArrowRight />
                    </IconButton>
                </span>
            </Tooltip>
        </>
    )
}

export default TablePaginationActions
