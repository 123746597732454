import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()((theme) => ({
    exceptionalItem: {
        flexDirection: "row",
        "@media (min-width: 1200px)": {
            padding: 16,
            justifyContent: "space-between",
        },
        "& .inputs": {
            display: "flex",
            flexDirection: "column",
            gap: 8,
            width: "100%",
            "@media (min-width: 1200px)": {
                flexDirection: "row",
                alignItems: "baseline",
            },
            "& .MuiTextField-root": {
                width: "100%",
                backgroundColor: "transparent",
                "@media (min-width: 1200px)": {
                    width: 240,
                },
                "& input": {
                    padding: 12,
                },
            },
            "& .whole-day": {
                "@media (min-width: 1200px)": {
                    order: 3,
                    height: 48,
                    justifyContent: "center",
                    marginBottom: "auto",
                },
            },
            "& .switch-and-time": {
                display: "flex",
                flexDirection: "column",
                gap: 8,
                alignItems: "flex-start",
                "@media (min-width: 768px)": {
                    width: "100%",
                },
                "@media (min-width: 1200px)": {
                    flexDirection: "row",
                },
                "& .MuiFormGroup-root": {
                    "@media (min-width: 768px)": {
                        height: 48,
                    },
                },
            },
            "& .inputs-hours": {
                display: "flex",
                gap: 8,
                flexDirection: "column",
                "@media (min-width: 1200px)": {
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                },
            },

            "& .time-container": {
                display: "flex",
                flexDirection: "column",
                gap: 8,
                "& .time-wrapper": {
                    display: "flex",
                    gap: 8,
                    alignItems: "center",
                    "& .separator": {
                        color: theme.palette.action.active,
                    },

                    "& .MuiFormControl-root": {
                        width: "auto",
                        "@media (min-width: 1200px)": {
                            width: 96,
                        },
                        "& .MuiInputBase-root": {
                            width: "auto",
                        },
                    },
                },
                "& .time-error": {
                    maxWidth: "225px",
                    marginLeft: "10px",
                },
            },
        },
        "& button": {
            padding: 12,
            height: "fit-content",
            "@media (min-width: 1024px)": {
                margin: 0,
            },
            "&.plus-or-delete": {
                margin: 0,
                color: theme.palette.primary.main,
            },
        },
    },
}))

export default useStyles
