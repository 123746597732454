import env from "@/config/env"
import { ApiService } from "../base"

class SendrService extends ApiService {
    constructor() {
        super({
            baseURL: `${env.SENDR_API_PATH}`,
        })
    }
}

export default new SendrService()
