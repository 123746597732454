import { ResellerApi } from "@/api"
import { CategoryListResponse } from "@/api/reseller"
import UiStore from "@/store/ui"
import { debounce } from "@mui/material/utils"
import { useEffect, useMemo, useState } from "react"
import BaseAutocomplete from "../../base/autocomplete"
import { CategoryProps, GoogleCategoriesOptionsType } from "@/types/categories"

export default function CategoryAutocomplete(props: Readonly<CategoryProps>) {
    const {
        label = "",
        defaultValue = props.multiple ? [] : { title: "", value: "" },
        multiple = false,
        required = false,
        filteredCategory = [],
        categoryType = "default",
        helperText = "",
        maxItems = 20,
        setCategory,
    } = props
    const [open, setOpen] = useState(false)
    const [options, setOptions] = useState<GoogleCategoriesOptionsType[]>([])
    const { preferredLanguage } = UiStore()
    const [value, setValue] = useState<GoogleCategoriesOptionsType | GoogleCategoriesOptionsType[]>(defaultValue)
    const [currentItems, setCurrentItems] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (defaultValue) {
            setValue(defaultValue)
            setCurrentItems(defaultValue as any)
        }
    }, [defaultValue])

    const fetch = useMemo(
        () =>
            debounce(async (input: string) => {
                if (input && input !== undefined && input !== "undefined" && input.length > 3) {
                    if (categoryType === "facebook") {
                        const { data }: CategoryListResponse = await ResellerApi.searchFacebookCategory({
                            lang: preferredLanguage,
                            searchText: input,
                        })
                        if (data) {
                            setOptions(data.map((item) => ({ title: item.label, value: item.id })))
                        } else {
                            setOpen(false)
                            setOptions([])
                        }
                        setLoading(false)
                    } else if (categoryType === "default") {
                        const resp: CategoryListResponse = await ResellerApi.fetchGoogleCategories({
                            lang: preferredLanguage,
                            label: input,
                        })

                        let newOptions: GoogleCategoriesOptionsType | GoogleCategoriesOptionsType[] = []
                        setOptions(newOptions)

                        if (resp?.data) {
                            const uniqueValues = new Set()
                            const uniqueArray = []

                            resp.data.forEach((obj) => {
                                if (!uniqueValues.has(obj.id) && !uniqueValues.has(obj.label)) {
                                    uniqueValues.add(obj.id)
                                    uniqueValues.add(obj.label)
                                    uniqueArray.push(obj)
                                }
                            })

                            newOptions = uniqueArray
                                .filter((category) =>
                                    typeof filteredCategory === "string"
                                        ? category.id !== filteredCategory
                                        : Array.isArray(filteredCategory)
                                        ? !filteredCategory.includes(category.id)
                                        : true
                                )
                                .map((item) => {
                                    return { title: item.label, value: item.id }
                                })
                        }
                        setOptions(newOptions)
                        setLoading(false)
                    }
                } else {
                    setOptions([])
                    setLoading(false)
                    setOpen(false)
                }
            }, 400),
        [preferredLanguage, filteredCategory, categoryType]
    )

    return (
        <BaseAutocomplete
            type="category"
            label={label}
            required={required}
            helperText={helperText}
            value={value}
            open={open}
            getOptionDisabled={() => multiple && currentItems?.length >= maxItems}
            onOpen={(event: React.ChangeEvent<HTMLInputElement>) => {
                setOpen(event.target.value?.length > 3 || (options.length > 0 && event.target.value?.length > 3))
            }}
            onClose={() => {
                setOpen(false)
            }}
            placeholder={label}
            multiple={multiple}
            isOptionEqualToValue={(option: GoogleCategoriesOptionsType, value: GoogleCategoriesOptionsType) =>
                option.title === value.title
            }
            getOptionLabel={(option: GoogleCategoriesOptionsType) => option.title}
            options={options}
            loading={loading}
            onInputChange={(_, newInputValue) => {
                if (newInputValue?.length < 3) {
                    setOpen(false)
                    setOptions([])
                } else {
                    setLoading(true)
                    fetch(newInputValue)
                }
            }}
            onChange={(_: any, newValue: (GoogleCategoriesOptionsType & GoogleCategoriesOptionsType[]) | null) => {
                if (multiple) {
                    setCurrentItems(newValue)
                    setValue(newValue)
                    setOptions([])
                } else {
                    setValue(newValue ?? { title: "", value: "" })
                    setOptions([])
                    setOpen(false)
                }
                setCategory(newValue)
            }}
        />
    )
}
