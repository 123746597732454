import { UserApi } from "@/api"
import useNotification from "@/hooks/useNotification"
import UiPageSessionStore from "@/store/ui-page-session"
import UserSessionStore from "@/store/user-session"
import { UserType } from "@/types/user"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

const useLogic = () => {
    const { profile } = UserSessionStore()
    const { notif } = useNotification()
    const { t } = useTranslation()
    const { selectedItem, updateState: setStateUiPageSession, showPage, menuPage } = UiPageSessionStore()
    const [user, setUser] = useState<UserType>(null)
    const typeUser = useMemo(() => {
        if (user?.roles?.includes("ROLE_ADMIN_COMPANY")) return t("USERS.ADMIN_COMPANY_ROLE")
        else if (user?.roles?.includes("ROLE_ADMIN_LOCATION")) return t("USERS.ADMIN_BUSINESS_ROLE")
        else return ""
    }, [user])

    const refreshUser = useCallback(
        async (id: string) => {
            try {
                setUser(null)
                const response = await UserApi.fetchUserInfo({
                    id,
                    user_uid: profile.uid,
                })
                setUser({ ...response })
            } catch (error) {
                notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
            }
        },
        [profile]
    )

    useEffect(() => {
        if (selectedItem && showPage === "detail" && menuPage === "users") {
            refreshUser(selectedItem.uid)
        }
    }, [selectedItem, showPage, menuPage])

    return { user, setStateUiPageSession, t, typeUser, setUser }
}

export default useLogic
