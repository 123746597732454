import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()((theme) => ({
    container: {
        "& .inputs-wrapper": {
            height: "fit-content",
            "@media (min-width: 768px)": {
                width: "100%",
            },
            "& .error": {
                textAlign: "center",
            },
            "& h2": {
                fontSize: 24,
                color: "black",
                fontWeight: 500,
            },
            "& .MuiBox-root": {
                display: "flex",
                flexDirection: "column",
                gap: 8,
            },
            "& .title": {
                display: "flex",
                gap: 8,
                alignItems: "center",
                "& .title-icon": {
                    color: theme.palette.action.active,
                },
            },
        },
        "& .inputs-container": {
            "&.container-row": {
                "@media (min-width: 1024px)": {
                    flexDirection: "row",
                },
            },
            "& .title": {
                display: "flex",
                gap: 8,
                alignItems: "center",
                "& .title-icon": {
                    color: theme.palette.action.active,
                },
            },
        },
    },
}))

export default useStyles
