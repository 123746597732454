import { UserSessionService } from "@/services"
import UiPageSessionStore from "@/store/ui-page-session"
import UserSessionStore from "@/store/user-session"
import { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

const useRedirect = () => {
    const navigate = useNavigate()
    const { isLoggedIn } = UserSessionStore()
    const { updateState: updatePageSession } = UiPageSessionStore()
    const [searchParams] = useSearchParams()

    useEffect(() => {
        const isBasePath = window.location.pathname === "/"
        const isLoginPage = window.location.pathname === "/login"
        const token_bo = searchParams.get("token")
        const semiPrivateRoutes = ["/forgot-password", "/reset-password"]
        const isSemiPrivateRoute = semiPrivateRoutes.some((route) => window.location.pathname.includes(route))
        const hasGoogleAccessToken = window.location.hash.includes("access_token")

        if (isSemiPrivateRoute && isLoggedIn) {
            navigate("/reputation")
            return
        }

        if (token_bo) {
            const company_uid = searchParams.get("company_uid")
            if (!company_uid) {
                navigate("/404")
                return
            }
            const logUser = async () => {
                const switchAccount = await UserSessionService.loginSwitch({
                    token: token_bo,
                    company_uid,
                })
                if (switchAccount) {
                    navigate("/reputation")
                    return
                }
                await UserSessionService.logout()
                navigate("/404")
                return
            }
            logUser()
        } else if (isLoggedIn && (isBasePath || isLoginPage)) {
            updatePageSession({
                menuPage: "reputation",
            })
            navigate("/reputation")
            return
        } else if (!isLoggedIn && isBasePath) {
            if (!hasGoogleAccessToken) {
                navigate("/login")
            } else {
                const hashParams = new URL(window.location.href).hash.substring(1)
                const params = new URLSearchParams(hashParams)
                const accessToken = params.get("access_token")
                navigate(`/login?access_token=${accessToken}`)
            }
            return
        }
    }, [isLoggedIn, navigate, searchParams, updatePageSession])
}

export default useRedirect
