import CampaignStore from "@/store/campaign"
import UiPageSessionStore from "@/store/ui-page-session"
import { zodResolver } from "@hookform/resolvers/zod"
import { useMemo } from "react"
import { useForm, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { z } from "zod"

const useCampaignForm = () => {
    const { t } = useTranslation()
    const { previousItem } = UiPageSessionStore()
    const { contacts } = CampaignStore()

    const MESSAGE_TEXT = useMemo(() => {
        return {
            FIELD_REQUIRED: t("USERS.FIELD_REQUIRED"),
            INVALID_FORMAT_EMAIL: t("USERS.INVALID_FORMAT_EMAIL"),
        }
    }, [t])

    const schema = z
        .object({
            business: z.string().nonempty(MESSAGE_TEXT.FIELD_REQUIRED),
            type: z.string(),
            firstname: z.string().optional(),
            lastname: z.string().optional(),
            email: z.string().optional(),
            phone: z.string().optional(),
            confirmation: z.boolean(),
            subject: z.string().optional(),
            message: z.string().optional(),
            step: z.number(),
            feedback: z.boolean(),
            country: z.string().nullable().optional(),
            lang: z.string(),
        })
        .superRefine(({ type, phone, email }, refinementContext) => {
            if (contacts.length === 0) {
                if (type === "sms" && !phone) {
                    return refinementContext.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: MESSAGE_TEXT.FIELD_REQUIRED,
                        path: ["phone"],
                    })
                }
                if (type === "email" && !email) {
                    return refinementContext.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: MESSAGE_TEXT.FIELD_REQUIRED,
                        path: ["email"],
                    })
                }
            }
        })

    type SchemaValuesType = z.infer<typeof schema>
    const defaultValues = useMemo(() => {
        let data = {
            business: "",
            type: "sms",
            message: "",
            step: 0,
            feedback: true,
            country: "CA",
            firstname: "",
            lastname: "",
            lang: "en",
        }

        if (previousItem?.campaign) {
            data = previousItem?.campaign
        }

        return data
    }, [previousItem])

    const { handleSubmit, formState, control, setValue, register, reset, trigger } = useForm<SchemaValuesType>({
        defaultValues,
        resolver: zodResolver(schema),
        mode: "onTouched",
    })

    const formValue = useWatch({ control })

    return {
        formValue,
        formState,
        control,
        handleSubmit,
        setValue,
        register,
        reset,
        trigger,
    }
}

export default useCampaignForm
