import Icon from "@/components/base/Icon"
import { Box, Typography } from "@mui/material"
import { imgSx, newSx, withoutImageSx, wrapperSx } from "./styles"
import { useTranslation } from "react-i18next"

type BusinessLogoProps = {
    uid: string
    logo: string
    isNew: boolean
}

const BusinessLogo = ({ uid, logo, isNew }: BusinessLogoProps) => {
    const { t } = useTranslation()
    return (
        <>
            {logo && (
                <Box sx={wrapperSx({ isNew })}>
                    <Box sx={imgSx} component={"img"} src={logo} alt="Logo" key={uid} />
                    {isNew && (
                        <Typography sx={newSx} component={"span"}>
                            {t("COMMON.NEW")}
                        </Typography>
                    )}
                </Box>
            )}
            {!logo && (
                <Box sx={withoutImageSx({ isNew })}>
                    <Icon name="imagePlaceholder" />
                    <Typography component="span">Logo</Typography>
                    {isNew && (
                        <Typography sx={newSx} component={"span"} className="new">
                            {t("COMMON.NEW")}
                        </Typography>
                    )}
                </Box>
            )}
        </>
    )
}

export default BusinessLogo
