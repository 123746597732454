import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()((theme) => ({
    media: {
        "@media (min-width: 768px)": {
            gridColumn: "span 2",
        },
        "& .media-content--wrapper": {
            display: "flex",
            gap: 8,
            alignItems: "center",
            "& img": {
                objectFit: "cover",
                borderRadius: 8,
                width: "100%",
                height: "100%",
            },
            "& span": {
                fontSize: 20,
                color: theme.palette.primary.main,
            },
            "& .img-wrapper": {
                position: "relative",
                "& svg": { position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" },
            },
        },
    },
}))

export default useStyles
