import { ApiService } from "@/api"
import Icon from "@/components/base/Icon"
import CustomTooltip from "@/components/base/Tooltip"
import env from "@/config/env"
import useProfileRole from "@/hooks/useProfileRole"
import { modalStyle } from "@/pages/users/styles"
import { isGoogleConnected, withSxProp } from "@/utils"
import { openGoogleAccount } from "@/utils/auth"
import { Box, Button, IconButton, Modal, Paper, Stack, Typography } from "@mui/material"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import useStyles from "./styles"

const ReconnectGoogleModal = ({ popupContainer, open = false, location, user, onClose, onDelete }) => {
    const [openModal, setOpenModal] = useState(false)
    const { t } = useTranslation()
    const { classes } = useStyles()
    const { isAdmin } = useProfileRole()
    const needsReconnection = useMemo(() => {
        return location?.locationState?.needsReconnection
    }, [location?.locationState?.needsReconnection])

    const linkGoogleDoc = useMemo(() => {
        return `${env.DOCS_LINK}/${t("BUSINESSES.RECONNECT_GOOGLE.DOC_LINK")}`
    }, [t])

    const handleConnectButton = async () => {
        openGoogleAccount({
            companyId: location?.companyId,
            user_id: user?.uid,
            gmb_access_id: location?.gmbAccessId,
            yext_id: location?.meta?.yext_id,
            locationName: location?.meta?.gmb_id,
            bearer: ApiService.getToken()?.token,
        })
    }

    useEffect(() => {
        setOpenModal(open)
    }, [open])

    return (
        <Modal className={classes.container} open={openModal} container={() => popupContainer?.current}>
            <Paper sx={withSxProp(modalStyle, { maxWidth: 440, boxSizing: "border-box" })}>
                {needsReconnection && !isGoogleConnected(location) && (
                    <Stack gap={1}>
                        <Box className="title-wrapper">
                            <Typography data-e2e="popup-need-reco" variant="h2">
                                {t("BUSINESSES.RECONNECT_GOOGLE.TITLE_ONE")}
                            </Typography>
                            <CustomTooltip title={t("USERS.CLOSE")}>
                                <IconButton onClick={onClose}>
                                    <Icon name="x" />
                                </IconButton>
                            </CustomTooltip>
                        </Box>

                        <Typography sx={{ color: "action.active", fontSize: 14 }}>
                            {t("BUSINESSES.RECONNECT_GOOGLE.MANDATORY")}
                        </Typography>
                        {isAdmin() && (
                            <Button
                                variant="text"
                                color="error"
                                sx={{
                                    boxShadow: "none",
                                    width: "100%",
                                    svg: {
                                        marginRight: "8px",
                                    },
                                }}
                                onClick={onDelete}
                            >
                                <Icon name="delete1" sx={{ color: "error.main" }} />

                                {t("BUSINESSES.DELETE_BUSINESS")}
                            </Button>
                        )}
                        <Stack sx={{ width: "100%", gap: 1 }}>
                            <Button
                                onClick={handleConnectButton}
                                variant="contained"
                                size="large"
                                sx={{
                                    svg: {
                                        marginRight: "8px",
                                    },
                                }}
                            >
                                <Icon name="unicolorGoogle" />
                                {t("BUSINESSES.RECONNECT_GOOGLE.TITLE_BUTTON")}
                            </Button>
                        </Stack>
                    </Stack>
                )}

                {!needsReconnection && !isGoogleConnected(location) && (
                    <Stack gap={1}>
                        <Box className="title-wrapper">
                            <Typography data-e2e="popup-reco-failed" variant="h2">
                                {t("BUSINESSES.RECONNECT_GOOGLE.ERROR")}
                            </Typography>
                            <CustomTooltip title={t("USERS.CLOSE")}>
                                <IconButton onClick={onClose}>
                                    <Icon name="x" />
                                </IconButton>
                            </CustomTooltip>
                        </Box>

                        <Typography sx={{ color: "action.active" }}>{t("BUSINESSES.RECONNECT_GOOGLE.FAQ")}</Typography>
                        <Stack sx={{ width: "100%", gap: 1 }}>
                            {isAdmin() && (
                                <Button
                                    variant="text"
                                    color="error"
                                    sx={{
                                        boxShadow: "none",
                                        svg: {
                                            marginRight: "8px",
                                        },
                                    }}
                                    onClick={onDelete}
                                >
                                    <Icon name="delete1" sx={{ color: "error.main" }} />
                                    {t("BUSINESSES.DELETE_BUSINESS")}
                                </Button>
                            )}
                            <Button color="secondary" size="large" className="btn" onClick={handleConnectButton}>
                                {t("BUTTON.RETRY")}
                            </Button>
                        </Stack>
                        <Stack className="action-wrapper" flexDirection={"column"} gap={1}>
                            <Button
                                href={linkGoogleDoc}
                                target="blank"
                                variant="contained"
                                size="large"
                                sx={{ width: "100%", boxSizing: "border-box" }}
                            >
                                {t("BUSINESSES.RECONNECT_GOOGLE.DOC")}
                            </Button>
                        </Stack>
                    </Stack>
                )}
            </Paper>
        </Modal>
    )
}

export default ReconnectGoogleModal
