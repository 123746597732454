import { Typography } from "@mui/material"

interface SerializedStringPropsType {
    text: string
}

const SerializedString = ({ text }: SerializedStringPropsType) => {
    return (
        <Typography color={"action.active"} className="ellipse-three-line" sx={{ whiteSpace: "pre-wrap" }}>
            {text}
        </Typography>
    )
}

export default SerializedString
