import Icon from "@/components/base/Icon"
import { Box, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

const errorSx = { display: "flex", gap: 1, mt: 1 }

const ErrorMessage = () => {
    const { t } = useTranslation()
    return (
        <Box sx={errorSx} data-e2e="business-error-mandatory">
            <Icon name="alert-triangle" sx={{ color: "error.main" }} />
            <Typography component={"span"} className="error">
                {t("BUSINESSES.SCORE.MISSING_INFO")}
            </Typography>
        </Box>
    )
}
export default ErrorMessage
