import Icon from "@/components/base/Icon"
import { SocialService } from "@/services"
import useLocationStore from "@/store/location"
import SocialStore from "@/store/social"
import SocialPopupStore from "@/store/social-popup"
import { Alert, AlertTitle, Button, Collapse, IconButton, Stack, Typography } from "@mui/material"
import { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

const SocialsAlertAccountDisconnected = () => {
    const [open, setOpen] = useState(true)
    const { t } = useTranslation()
    const { selectedBusinesses, update } = SocialStore()
    const { showPopupContent } = SocialPopupStore()
    const { update: updateLocation, businesses } = useLocationStore()

    const handleShowPostAlert = useCallback(async () => {
        await SocialService.hidePostAlert(selectedBusinesses)
        update({ showPostAlert: false })
        updateLocation({
            businesses: businesses.map((business) => {
                if (selectedBusinesses.includes(business.value)) {
                    return {
                        ...business,
                        locationState: {
                            ...business.locationState,
                            facebookStatus:
                                business.locationState.facebookStatus === "DISCONNECTED"
                                    ? "NOT_CONNECTED"
                                    : business.locationState.facebookStatus,
                            instagramStatus:
                                business.locationState.instagramStatus === "DISCONNECTED"
                                    ? "NOT_CONNECTED"
                                    : business.locationState.instagramStatus,
                            status:
                                business.locationState.status === "DISCONNECTED"
                                    ? "NOT_CONNECTED"
                                    : business.locationState.status,
                        },
                        showPostAlert: false,
                    }
                }
                return business
            }),
        })
        setOpen(false)
    }, [selectedBusinesses, update, updateLocation, businesses])

    return (
        <Collapse in={open}>
            <Alert
                sx={{ flexDirection: "row", boxShadow: "none", gap: 1 }}
                severity="error"
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setOpen(false)
                        }}
                    >
                        <Icon name="close" />
                    </IconButton>
                }
            >
                <Stack gap={1}>
                    <AlertTitle
                        sx={{
                            marginBottom: 0,
                        }}
                    >
                        {t("SOCIALS.ACCOUNT_DISCONNECTED_WARNING")}
                    </AlertTitle>
                    <Stack>
                        <Typography variant="body2" color={"#663C00"}>
                            {t("SOCIALS.ACCOUNT_CAN_BE_RECONNECTED")}
                        </Typography>
                    </Stack>
                    <Stack gap={2} flexDirection={"row"} flexWrap={"wrap"}>
                        <Button
                            sx={{
                                backgroundColor: "#663C0011",
                                color: "#663C00",
                                "&:hover": {
                                    backgroundColor: "#663C0011",
                                },
                            }}
                            onClick={() => {
                                showPopupContent("connexion_details")
                            }}
                        >
                            {t("SOCIALS.SHOW_DISCONNECTED_ACCOUNT")}
                        </Button>
                        <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                            <Typography variant="body2" color={"#663C00"}>
                                {t("SOCIALS.IT_S_NORMAL")},
                            </Typography>
                            <Link
                                to={"#"}
                                style={{ color: "#663C00" }}
                                onClick={async (e) => {
                                    e.preventDefault()
                                    handleShowPostAlert()
                                }}
                            >
                                <Typography variant="body2" color={"#663C00"}>
                                    {t("SOCIALS.DO_NOT_DISPLAY_ANYMORE")?.toLowerCase()}
                                </Typography>
                            </Link>
                        </Stack>
                    </Stack>
                </Stack>
            </Alert>
        </Collapse>
    )
}

export default SocialsAlertAccountDisconnected
