import { Paper, Skeleton, Stack } from "@mui/material"

export const ContentText = () => {
    return (
        <Paper sx={{ flex: 1, width: "100%" }} elevation={2}>
            <Skeleton variant="rectangular" width={200} height={20} />
            <Stack gap={1} width="100%" height="100%">
                <Skeleton variant="rectangular" width="100%" height={10} />
                <Skeleton variant="rectangular" width="100%" height={10} />
                <Skeleton variant="rectangular" width="90%" height={10} />
            </Stack>
        </Paper>
    )
}
