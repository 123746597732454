import axios from "axios"
import { ApiService } from ".."
import env from "@/config/env"
import { UserLoginResponse } from "../user"

class AccountApi {
    checkAccessCompany = async ({ user_uid, company_uid }: { user_uid: string; company_uid: string }) => {
        return ApiService.get(`/accounts`, {
            user_uid,
            q: company_uid,
        })
    }

    userRedirect = async ({ access_token, company_uid }: { access_token: string; company_uid: string }) => {
        const client = axios.create({
            baseURL: `${env.APPBACK_API_PATH}`,
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        })
        return client.post<UserLoginResponse>(`/user-redirect`, {
            company_uid,
        })
    }
}

export default new AccountApi()
