import { alpha } from "@mui/material"
import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()((theme) => ({
    drawer: { zIndex: 1 },
    container: {
        "& .edit-media": {
            margin: "0px",
            padding: "0px 8px 8px 8px",
            "@media (min-width: 768px)": {
                padding: "0px 24px 8px 24px",
            },
        },
        "& .container": {
            display: "flex",
            gap: 16,
            marginBottom: "15px",
            height: "100%",
            "@media (min-width: 768px)": {
                padding: "8px 24px",
                marginBottom: "0px",
            },
            "& img": {
                width: "100%",
                height: "calc(100vh - 200px)",
                objectFit: "contain",
                "@media (max-width: 900px)": {
                    height: "100%",
                },
            },

            "& .inputs-wrapper": {
                "@media (min-width: 900px)": {
                    height: "fit-content",
                    minWidth: 300,
                    maxWidth: 377,
                    width: "100%",
                },
                "& .MuiButton-containedError": {
                    boxShadow: "none",
                    backgroundColor: theme.palette.error.light,
                    color: theme.palette.error.main,
                },
                "& button": {
                    display: "flex",
                    gap: 8,
                },
            },
        },
        "& .form-stack": {
            justifyContent: "space-between",
            height: "100%",
            "& .form-stack--inputs": {
                backgroundColor: theme.palette.text.primary,
                padding: 8,
                height: "calc(100% - 25px)",
                "@media (min-width: 768px)": {
                    borderRadius: "8px",
                    width: "100%",
                    boxSizing: "border-box",
                },
                // gap: 8,
                "& .bloc-crop": {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "calc(100% - 60px)",
                    "& .ReactCrop": {
                        height: "100%",
                        "& .ReactCrop__child-wrapper": {
                            height: "100%",
                        },
                    },
                },
                "& img": {
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                },
            },
            "& .action-wrapper": {
                padding: "8px 0",
                gap: 8,
                justifyContent: "center",
                overflowX: "scroll",
                msOverflowStyle: "none" /* IE and Edge */,
                scrollbarWidth: "none" /* Firefox */,
                "&::-webkit-scrollbar": {
                    display: "none",
                },
                "@media (min-width: 768px)": {
                    justifyContent: "center",
                    overflowX: "auto",
                },
                "& button": {
                    color: theme.palette.common.white,
                    backgroundColor: alpha(theme.palette.common.white, 0.16),
                    padding: 8,

                    borderRadius: 8,
                    minWidth: 41,
                },

                "& .Mui-selected": {
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.common.white,
                },

                "& .MuiToggleButtonGroup-root": {
                    gap: "8px",
                },
            },
        },
    },
}))

export default useStyles
