import { makeStyles } from "tss-react/mui"

export const useStyles = makeStyles()((theme) => ({
    header: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 8,
        padding: "8px 16px",
        flexWrap: "wrap",
        "@media (min-width: 1024px)": {
            flexWrap: "nowrap",
        },
        "& .btn-left": {
            width: "100%",

            "@media (min-width: 1024px)": {
                width: "320px",
            },
        },
        "& .items-right": {
            display: "flex",
            flexDirection: "row",
            gap: 8,
        },
    },
}))
