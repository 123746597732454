import { RegularHourItemType } from "@/types/businesses"
import { sortDateException } from "@/utils"
import { Table, TableBody, TableCell, TableRow } from "@mui/material"
import dayjs from "dayjs"
import { TFunction } from "i18next"
import { useMemo } from "react"
import BusinessScheduleRow from "./row"

interface BusinessScheduleTableType {
    list: RegularHourItemType[]
    t: TFunction<"translation", undefined>
    type?: string
}

const BusinessScheduleTable = ({ t, list, type = "standard" }: BusinessScheduleTableType) => {
    const { listComingDate, otherItems } = useMemo(() => {
        let otherItems = 0
        let allComingDate: any = [...list]
        if (type === "exceptional" && list?.length > 0) {
            allComingDate = list?.filter((value: any, index) => {
                let test = false
                const newTimeList = []
                const times = value?.times || []
                // Determine the last end time
                const lastTimes = times.length > 1 ? times[1]?.endTxt : times[0]?.endTxt
                const beforeDate = dayjs().isBefore(dayjs(`${value.startDate} ${lastTimes}`))
                const sameDate = dayjs().isSame(`${value.startDate} ${lastTimes}`)
                for (const val of value?.times || []) {
                    if (
                        dayjs(value?.startDate).isAfter(dayjs()) ||
                        dayjs().isBefore(dayjs(`${value.startDate} ${val?.endTxt}`)) ||
                        (sameDate && value?.isAvailableAllDay)
                    ) {
                        newTimeList.push(val)
                        test = true
                    }
                }
                if (beforeDate || sameDate) {
                    test = true
                }
                allComingDate[index].times = [...newTimeList]
                return test
            })
            if (type === "exceptional") {
                otherItems = allComingDate.length - 5
                allComingDate = sortDateException(allComingDate).splice(0, 5)
            }
        }

        return {
            listComingDate: allComingDate,
            otherItems,
        }
    }, [list, type])
    return (
        <>
            {listComingDate?.length > 0 && (
                <Table data-testid="business-schedule-table">
                    <TableBody>
                        {listComingDate?.map((v: any, index: number) => (
                            <BusinessScheduleRow key={`${String(index)}`} v={v} index={index} t={t} type={type} />
                        ))}
                        {otherItems > 0 && (
                            <TableRow className="flex justify-between">
                                <TableCell
                                    className="schedule-wrapper number-other-items"
                                    data-testid="business-schedule-row"
                                >
                                    +{otherItems}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            )}
        </>
    )
}

export default BusinessScheduleTable
