const chartWrapperSx = {
    "& text": { fill: "#68738D", fontSize: 24, fontFamily: "Poppins !important" },
    "& g": { transform: "none" },
}

const chipWrapperSx = {
    display: "flex",
    flexWrap: "wrap",
    gap: 1,
}

const missingInfoSx = {
    flexWrap: "wrap",
    height: "fit-content",
}

const titleWrapperSx = { py: 0, display: "flex", gap: 2, color: "action.active", alignItems: "center" }

export { chartWrapperSx, chipWrapperSx, missingInfoSx, titleWrapperSx }
