const chartData: any = {
    chart: { type: "donut" },
    fill: { colors: ["#03CC6C"] },
    legend: { show: false },
    dataLabels: { enabled: false },
    tooltip: { enabled: false },
    states: {
        hover: { filter: { type: "none", value: 0 } },
        active: { filter: { type: "none", value: 0 } },
    },
    stroke: { width: 0 },
    plotOptions: {
        pie: {
            expandOnClick: false,
            donut: {
                size: "75%",
                labels: {
                    show: true,
                    name: {
                        show: false,
                    },
                    total: {
                        show: true,
                        showAlways: true,
                        color: "#FF0000",
                        fontSize: "40px",
                        formatter(w: { globals: { series: number[] } }) {
                            return w.globals.series[0] + "%"
                        },
                        label: "test",
                    },
                },
            },
        },
    },
}

export { chartData }
