import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()((theme) => ({
    login: {
        flexDirection: "column",
        position: "relative",
        backgroundColor: "white",
        width: "100%",
        height: "100%",
        "& .back-btn": {
            position: "absolute",
            top: "16px",
            left: "16px",
            zIndex: 1,
            color: "#0180FF",
            gap: "8px",
        },
        "& .login--container": {
            display: "flex",
            width: "100%",
            height: "100%",
            "& .wrapper": {
                display: "flex",
                backgroundColor: "white",
                width: "100%",
                alignItems: "center",
                flexDirection: "column-reverse",
                overflow: "hidden",
                "@media (min-width: 768px)": {
                    flexDirection: "column",
                    paddingBottom: 16,
                },
                "& .form-wrapper": {
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: "24px",
                    "&__link": {
                        textDecoration: "none",
                        color: theme.palette.action.active,
                        textAlign: "center",
                        fontSize: 14,
                    },
                    "&__inputs-wrapper": {
                        gap: "18px",
                        maxWidth: 361,
                        width: "100%",
                        "&--input": {
                            borderRadius: "8px",
                            backgroundColor: "rgba(172, 182, 229, 0.16)",
                            "& input": {
                                paddingLeft: "8px",
                            },
                            "& fieldset": {
                                border: "none",
                            },
                            "body & .MuiFilledInput-root": {
                                backgroundColor: "transparent",
                            },
                        },
                    },
                    "&__form-divider": {
                        display: "flex",
                        width: "100%",
                        gap: "12px",
                        maxWidth: 361,
                        alignItems: "center",
                        color: theme.palette.action.active,
                        "& hr": {
                            width: "100%",
                            height: 2,
                            border: "none",
                            backgroundColor: "rgba(91, 125, 216, 0.16)",
                        },
                    },
                    "&__google-btn": {
                        gap: "8px",
                        border: "1px solid rgba(33, 150, 243, 0.5)",
                        borderRadius: 8,
                        maxWidth: 361,
                        width: "100%",
                        color: "#0180FF",
                        fontSize: 15,
                    },
                },
                "& .select-language": {
                    borderRadius: "8px",
                    backgroundColor: "rgba(172, 182, 229, 0.16);",
                    gap: 8,
                    "& fieldset": {
                        border: "none",
                    },
                },
            },
            "& .slide-wrapper": {
                backgroundColor: "#0180FF",
                // width: '50%',
                height: "100%",
                display: "none",
                alignItems: "center",
                justifyContent: "space-evenly",
                flexDirection: "column",
                textAlign: "center",
                color: "white",
                transition: "all .3s ease",
                // animation: 'slide-close',
                "& h5": {
                    maxWidth: 396,
                    fontSize: 20,
                },
                "& h6": { fontSize: 18 },
                "& .pagination-wrapper": {
                    display: "flex",
                },
                "& .carousel": {
                    width: "100%",
                    ".swiper-slide ": {
                        width: "100%",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        "& img": {
                            maxWidth: "572px",
                            width: "100%",
                        },
                    },
                },
                "@media (min-width:768px)": {
                    display: "flex",
                },
            },
        },
    },
    loginWrapper: {
        backgroundColor: "white",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: 24,
        margin: "auto",
        paddingLeft: 16,
        paddingRight: 16,
        "& a": {
            textAlign: "center",
            marginTop: 8,
            textDecoration: "none",
        },
        "& img": {
            width: 200,
            height: 80,
            objectFit: "contain",
        },
        "& form": {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxWidth: 360,
            "& .error": { marginTop: 18 },
            "& .MuiFormControl-root": {
                width: "100%",
                "&:not(:first-of-type)": {
                    marginTop: 18,
                },
                "& svg": {
                    padding: "25px 0 8px",
                    marginRight: 8,
                    width: 23,
                    height: 24,
                },
            },
            "& .MuiTypography-root": {
                fontSize: 14,
                textAlign: "center",
            },
            "& button": {
                marginTop: 24,
                marginBottom: 6,
                padding: "8px 22px",
                display: "flex",
                gap: 8,
                "& .MuiCircularProgress-root": {
                    color: "inherit",
                },
            },
        },
        "& h1": {
            fontSize: 24,
            fontWeight: 500,
            color: theme.palette.action.active,
            textAlign: "center",
            "&.title-header": {
                fontSize: 20,
            },
        },
        "& .MuiTypography-subtitle1": {
            fontSize: 14,
            color: theme.palette.action.active,
            textAlign: "center",
        },
        "& .MuiTypography-body1": {
            textAlign: "center",
        },
        "& .MuiTypography-body2": {
            textAlign: "center",
            fontSize: 14,
            color: theme.palette.action.active,
            "& span": {
                fontSize: "inherit",
                color: theme.palette.primary.main,
                cursor: "pointer",
            },
        },
    },
}))

export default useStyles
