import TablePaginationActions from "@/components/base/table/PaginationActions"
import SkeletonCampaignOverviews from "@/components/skeleton/campaign/overviews"
import CampaignReviewsStore from "@/store/campaign-reviews"
import { Paper, Stack, TablePagination, Typography } from "@mui/material"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import OverviewsItem from "./item"
import useStyles from "./styles"
import { v4 as uuidv4 } from "uuid"
import { IReviews } from "@/types/campaign"

const Overviews = ({
    handleShowDetails,
    handleGetAllReviews,
    isSingleLocation = false,
}: {
    handleShowDetails: (review: IReviews) => void
    handleGetAllReviews: (page: number) => void
    isSingleLocation: boolean
}) => {
    const { t } = useTranslation()
    const { classes } = useStyles({})()
    const { listReviewsClient, pagination, isLoading } = CampaignReviewsStore()

    const onPageChange = useCallback((page: number) => {
        handleGetAllReviews(page)
    }, [])

    return (
        <>
            {isLoading ? (
                <SkeletonCampaignOverviews />
            ) : (
                <Paper elevation={0} className={classes.blocReviews}>
                    <Typography variant="h2">{t("CAMPAIGN.REVIEW_ASKED")}</Typography>
                    <Stack className="bloc-review">
                        {listReviewsClient?.map((review) => (
                            <OverviewsItem
                                key={uuidv4()}
                                review={review}
                                handleShowDetails={() => handleShowDetails(review)}
                                isSingleLocation={isSingleLocation}
                            />
                        ))}
                    </Stack>
                    <TablePagination
                        labelDisplayedRows={({ from, to, count }) => {
                            return `${from}-${to} ${t("USERS.PAGING")} ${count}`
                        }}
                        count={pagination?.total}
                        rowsPerPage={pagination?.per_page}
                        page={pagination?.page}
                        onPageChange={(__, newPage: number) => onPageChange(newPage)}
                        rowsPerPageOptions={[]}
                        ActionsComponent={TablePaginationActions}
                        sx={{ border: "none" }}
                    />
                </Paper>
            )}
        </>
    )
}

export default Overviews
