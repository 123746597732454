import BaseInputPhone from "@/components/base/BaseInputPhone"
import BaseInput from "@/components/base/input"
import usePhoneInputLogic from "@/hooks/phone-input/useLogic"
import { isValidEmail } from "@/utils"

interface PropsInputEdit {
    value: string
    index: string
    type?: "sms" | "email" | "text"
    langOfCountryName: string
    setData: (value: any) => void
}
export const RenderInputEdit = ({ value, index, langOfCountryName, type = "text", setData }: PropsInputEdit) => {
    const { isPhoneValid } = usePhoneInputLogic()

    if (type === "sms") {
        return (
            <BaseInputPhone
                control={null}
                name="p"
                required
                value={value}
                label={index}
                onlyCountries={["CA", "US", "FR", "BE"]}
                lang={langOfCountryName}
                onChange={(info) => {
                    setData((prev) => ({
                        ...prev,
                        [index]: info?.numberValue,
                        code: info?.countryCode,
                    }))
                }}
                error={!isPhoneValid(value)}
            />
        )
    } else {
        return (
            <BaseInput
                type={type}
                label={index}
                value={value}
                className="edited-cell"
                onChange={(e) => {
                    setData((prev) => ({
                        ...prev,
                        [index]: e.target.value,
                    }))
                }}
                error={type === "email" && !isValidEmail(value)}
            />
        )
    }
}
