import Icon from "@/components/base/Icon"
import CustomTooltip from "@/components/base/Tooltip"
import QuillEditor, { IMention } from "@/components/base/input-editor"
import { MAX_INPUT } from "@/data/limiter"
import { FormLabel, IconButton, Stack, Switch } from "@mui/material"
import { useTranslation } from "react-i18next"
import "react-quill/dist/quill.snow.css"
import useStyles from "../styles"
import { SetFieldValue } from "react-hook-form"

type SmsOptionnalProps = {
    feedback: boolean
    hasNewUrl: boolean
    data: any
    handleFeedback: () => void
}

type OnChangeParams = {
    html: string
    delta: any
    rawText: string
}

type SmsProps = {
    hasModel: boolean
    listReviews: any
    setValue: SetFieldValue<any>
    atValues: IMention[]
    defaultValue: string
    onChange: (value: OnChangeParams) => void
    onCloseReviews: () => void
} & Partial<SmsOptionnalProps>

export default function SmsEdit({
    hasModel,
    listReviews,
    setValue,
    atValues,
    defaultValue = "",
    feedback,
    hasNewUrl,
    onChange,
    onCloseReviews,
    handleFeedback,
}: SmsProps) {
    const { t } = useTranslation()
    const { classes } = useStyles()

    return (
        <Stack className={classes.smsBloc} gap={1}>
            <Stack className="rectangle"></Stack>
            <QuillEditor
                hasModel={hasModel}
                onChange={onChange}
                onCloseReviews={onCloseReviews}
                handleSubject={setValue}
                listReviews={listReviews}
                atValues={atValues}
                placeholder={""}
                toolbar={null}
                editorType="sms"
                className="sms-editor"
                maxLength={MAX_INPUT.SMS}
                defaultValue={defaultValue}
                hasNewUrl={hasNewUrl}
            />
            <Stack flexDirection="row" gap={2}>
                <Stack flexDirection={"row"} alignItems={"center"} margin="auto" gap={1}>
                    <Switch checked={feedback} onChange={() => handleFeedback()} />
                    <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                        <FormLabel sx={{ color: "action.active" }}>{t("CAMPAIGN.ACTIVE_FILTER")}</FormLabel>
                        <CustomTooltip title={t("CAMPAIGN.FILTER")}>
                            <IconButton className="edit-btn">
                                <Icon name="info" sx={{ color: "action.active" }} />
                            </IconButton>
                        </CustomTooltip>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    )
}
