import { TableCell } from "@mui/material"
import { CSSProperties } from "react"

type TableHeadCellWrapperProps = {
    style?: CSSProperties
    children: React.ReactNode
    align?: "left" | "center" | "right" | "justify" | "inherit"
}

const TableHeadCellWrapper = (props: TableHeadCellWrapperProps) => {
    return (
        <TableCell align={props.align} style={props.style}>
            {props.children}
        </TableCell>
    )
}

export default TableHeadCellWrapper
