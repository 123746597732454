import Icon from "@/components/base/Icon"
import { CircularProgress, IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material"
import { useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { menuBtnRight } from "./styles"

export const BtnDotsHorizontalDelete = ({
    handleRemove,
    isLoading,
    className,
    closeMenuDelete,
    buttonText = "REPUTATION.REMOVE",
}: {
    handleRemove: () => void
    isLoading?: boolean
    className?: string
    closeMenuDelete?: boolean
    buttonText?: string
}) => {
    const { t } = useTranslation()
    const [anchorElDelete, setAnchorElDelete] = useState<null | HTMLElement>(null)
    const openDelete = useMemo(() => Boolean(anchorElDelete), [anchorElDelete])

    const handleCloseDelete = () => {
        setAnchorElDelete(null)
    }

    const toggleDeleteMenu = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorElDelete(!openDelete ? event.currentTarget : null)
        },
        [setAnchorElDelete, openDelete]
    )

    return (
        <Stack
            sx={{
                "& .MuiIconButton-root ": {
                    padding: "0px",
                },
            }}
            className={className}
        >
            <IconButton onClick={toggleDeleteMenu} sx={{ padding: 0 }}>
                <Icon className="three-dots" name="threeDotsHorizontal" />
            </IconButton>
            <Menu
                elevation={6}
                id={"basic-menu"}
                open={openDelete}
                onClose={handleCloseDelete}
                anchorEl={anchorElDelete}
                sx={menuBtnRight}
            >
                <MenuItem
                    disabled={isLoading}
                    onClick={() => {
                        if (closeMenuDelete) handleCloseDelete()
                        handleRemove()
                    }}
                    className="remove clickable"
                >
                    {isLoading && <CircularProgress size={16} sx={{ marginRight: "8px" }} />}
                    {!isLoading && <Icon name="delete1" sx={{ color: "error.main" }} />}
                    <Typography fontSize={"12px"} color={"error.main"}>
                        {t(buttonText)}
                    </Typography>
                </MenuItem>
            </Menu>
        </Stack>
    )
}
