import { Paper, Skeleton, Stack } from "@mui/material"

export const SkeletonBusinessItemMainInfo = () => {
    return (
        <Paper
            sx={{
                display: "flex",
                gap: 2,
                flex: 1,
                width: "100%",
            }}
            data-testid="bloc-main-info"
        >
            <Skeleton variant="rectangular" width={200} height={20} />
            <Stack flexDirection={"row"} gap={2} alignItems={"center"} width="100%">
                <Skeleton variant="rectangular" width={124} height={124} sx={{ minWidth: 124 }} />
                <Skeleton variant="rectangular" width={200} height={20} />
            </Stack>
        </Paper>
    )
}
