import { ResellerApi } from "@/api"
import useLocation from "@/hooks/useLocation"
import useNotification from "@/hooks/useNotification"
import useAttributesStore, { AttributChecked, AttributeType, ListEditedAttributes } from "@/store/overview/attributes"
import useScoreStore from "@/store/overview/score/useScoreStore"
import UiStore from "@/store/ui"
import UiPageSessionStore from "@/store/ui-page-session"
import UserSessionStore from "@/store/user-session"
import { zodResolver } from "@hookform/resolvers/zod"
import { useCallback, useEffect, useMemo, useState } from "react"
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { z } from "zod"
import useBusinessLogic from "../useBusinessLogic"

interface LoggedAttribute {
    [key: string]: boolean | string[]
}

const useLogic = () => {
    const { navigateTo } = useBusinessLogic()
    const { t } = useTranslation()
    const { businessId } = useParams()
    const { profile } = UserSessionStore()
    const [loading, setLoading] = useState(false)
    const { notif } = useNotification()
    const { refreshScoring } = useScoreStore({})
    const { editedAttributes, attributIds, update, listAttributes } = useAttributesStore()
    const { menuPage, showPage, previousItem, updatePreviousItem } = UiPageSessionStore()
    const { location } = useLocation()
    const { preferredLanguage } = UiStore()

    const getAttributes = useCallback(async () => {
        const attributes = []
        const locationAttrSelect = Object.keys(attributIds ?? {})
        let selected: any = null
        listAttributes?.map((attribute) => {
            if (attribute.type === "RADIO" || attribute.type === "CHECKBOX") {
                const opt = []
                selected = null
                if (attribute.type === "CHECKBOX") {
                    selected = []
                }
                attribute.values.map((val) => {
                    opt.push({
                        display: val.displayName,
                        value: val.value,
                        checked:
                            locationAttrSelect.includes(attribute.id) &&
                            ((attribute.type === "RADIO" && attributIds[attribute.id] === val.value) ||
                                (attribute.type === "CHECKBOX" &&
                                    (attributIds[attribute.id] as Array<string>).includes(String(val.value)))),
                    })
                    if (
                        locationAttrSelect.includes(attribute.id) &&
                        attribute.type === "RADIO" &&
                        attributIds[attribute.id] === val.value
                    ) {
                        selected = opt[opt.length - 1]
                    }
                    if (
                        locationAttrSelect.includes(attribute.id) &&
                        attribute.type === "CHECKBOX" &&
                        (attributIds[attribute.id] as Array<string>).includes(String(val.value))
                    ) {
                        selected.push(opt[opt.length - 1])
                    }
                })
                if (attribute.type === "RADIO") {
                    const findOpt = opt.findIndex((el) => el.checked)
                    opt.push({
                        display: t("BUSINESSES.EDIT_ATTRIBUTES.UNDEFINED"),
                        value: "NONE",
                        checked: findOpt < 0,
                    })
                    if (findOpt === -1) {
                        selected = opt[opt.length - 1]
                    }
                }
                const option = {
                    title: attribute.groupLabel,
                    attributes: [
                        {
                            id: attribute.id,
                            type: attribute.type,
                            title: attribute.label,
                            options: [...opt],
                            selected: selected,
                        },
                    ],
                }

                const findAttribute = attributes.findIndex((el) => el.title === attribute.groupLabel)
                if (findAttribute >= 0) {
                    attributes[findAttribute].attributes.push(option.attributes[0])
                } else {
                    attributes.push(option)
                }
            }
        })
        update({ editedAttributes: attributes })
    }, [businessId, attributIds, listAttributes])

    useEffect(() => {
        if (previousItem && previousItem.attributes?.length > 0) {
            update({ editedAttributes: [...previousItem.attributes] })
        }
        if (
            profile.uid &&
            businessId &&
            menuPage === "businesses" &&
            showPage === "edit-attribute" &&
            (!previousItem || (previousItem && !previousItem.attributes))
        ) {
            getAttributes()
        }
    }, [showPage, location, preferredLanguage, getAttributes, profile?.uid, businessId])

    const handlePreviousButton = useCallback(() => {
        navigateTo("detail")
        updatePreviousItem({
            attributes: [...editedAttributes],
        })
    }, [updatePreviousItem, editedAttributes])

    const handleCancelButton = useCallback(() => {
        navigateTo("detail")
        update({ editedAttributes: [] })
        updatePreviousItem({
            attributes: null,
            isOpenWithoutHour: false,
        })
    }, [updatePreviousItem])

    const onErrors: SubmitErrorHandler<any> = async (errors) => {
        console.log(errors)
    }
    const schema = useMemo(() => {
        return z.object({
            attributes: z.string().array().optional(),
        })
    }, [])

    const { handleSubmit } = useForm<ListEditedAttributes>({
        defaultValues: {
            attributes: [],
        },
        resolver: zodResolver(schema),
        mode: "onTouched",
    })

    const handleCheckboxAttribute = (attr: AttributeType, arrayAttributes: LoggedAttribute) => {
        if (attr.type === "CHECKBOX" && attr.selected && attr.selected.value !== "NONE") {
            const arrayBox = attr.selected.map((sel: AttributChecked) => sel.value)
            if (arrayBox.length > 0) {
                arrayAttributes[attr.id] = [...arrayBox]
            } else {
                arrayAttributes[attr.id] = []
            }
        }
    }
    const onSubmit: SubmitHandler<any> = useCallback(async () => {
        setLoading(true)
        const body: any = { ...location }
        const newAttributIds: LoggedAttribute = {}
        const currentAttributIds = { ...attributIds }

        editedAttributes.map((section) => {
            section.attributes.map((attr: AttributeType) => {
                if (attr.type === "RADIO") {
                    if (attr.selected && attr.selected.value != "NONE") {
                        newAttributIds[attr.id] = attr.selected.value
                    } else {
                        newAttributIds[attr.id] = null
                    }
                    delete currentAttributIds[attr.id]
                }
                handleCheckboxAttribute(attr, newAttributIds)
            })
        })

        body["attributIds"] = { ...currentAttributIds, ...newAttributIds }

        const response = await ResellerApi.updateLocation({ ...body })
        if (response?.error) {
            notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
        } else {
            notif({ message: t("BUSINESSES.EDIT_SUCCESS"), type: "SUCCESS" })
            navigateTo("detail")
            update({ editedAttributes: [] })
            updatePreviousItem({
                attributes: null,
            })
            await refreshScoring()
            update({ attributIds: response.attributIds })
        }
        setLoading(false)
    }, [location, attributIds, editedAttributes, notif, t, navigateTo, update, updatePreviousItem, refreshScoring])

    return {
        handleSubmit,
        onSubmit,
        onErrors,
        handleCancelButton,
        handlePreviousButton,
        t,
        loading,
        editedAttributes,
    }
}

export default useLogic
