import { Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

const UploadMessage = () => {
    const { t } = useTranslation()

    return (
        <>
            <Typography
                dangerouslySetInnerHTML={{
                    __html: t("BUSINESSES.EDIT_LOGO.MAX_SIZE", {
                        maxSize: "<strong>10 Mo</strong>",
                        interpolation: { escapeValue: false },
                    }),
                }}
                component="span"
                className="image-type"
                variant="caption"
            />
            <Typography component="span" className="image-type" variant="caption">
                {t("BUSINESSES.EDIT_LOGO.ACCEPTED_EXTENSION")}: <strong>XSLX, CSV</strong>
            </Typography>
        </>
    )
}

export default UploadMessage
