import { IMediaPost, PostCallToActionType, Provider } from "@/types/socials"
import { DispatchrService } from "../"
import { ErrorResponse } from "../base"
import { AxiosError } from "axios"

export interface PublishAtValue {
    locationId: string
    provider: string
    date: string
    originalDate: string
}

export interface PostsResponse {
    id: string
    provider: Provider[]
    status: string
    publishAt: PublishAtValue[]
    description: string
    thumbnail: string
    locationIds: string[]
    isScheduled: boolean
    medias: IMediaPost[]
    callToAction: PostCallToActionType
    withError?: boolean
}
export interface PayloadPost {
    provider: Provider[]
    locationIds: string[]
    callToAction?: PostCallToActionType
    description: string
    medias: Partial<IMediaPost>[]
    status: "SCHEDULED" | "DRAFT" | "NOW" | "LIVE"
    publishAt: PublishAtValue[]
    postId: string
    isScheduled: boolean
    repost: boolean
    isRetry: boolean
    withError: boolean
}

export interface FetchPostBodyType {
    month: number
    year: number
    locationIds: string[]
    fields: string
}

class SocialApi {
    fetchPosts = async (body: FetchPostBodyType): Promise<PostsResponse[] & Partial<ErrorResponse>> => {
        return DispatchrService.post(`/social/posts`, body)
    }

    sendPost = async (
        payload: Partial<PayloadPost>,
        onError?: (error: AxiosError) => void
    ): Promise<PostsResponse & Partial<ErrorResponse>> => {
        return await DispatchrService.post("/social/post", payload, { onError })
    }

    updatePost = async (
        postId: string,
        payload: Partial<PayloadPost>,
        onError?: (error: AxiosError) => void
    ): Promise<PostsResponse & Partial<ErrorResponse>> => {
        return await DispatchrService.put(`/social/post/${postId}`, payload, { onError })
    }

    removePost = async (postId: string): Promise<PostsResponse & Partial<ErrorResponse>> => {
        return await DispatchrService.delete(`/social/post/${postId}`)
    }

    getPost = async (id: string): Promise<PostsResponse & Partial<ErrorResponse>> => {
        return await DispatchrService.get(`/social/post/${id}`)
    }

    getPostItems = async (id: string): Promise<any & Partial<ErrorResponse>> => {
        return await DispatchrService.get(`/social/post/${id}/items`)
    }
}

export default new SocialApi()
