import ResellerStore from "@/store/reseller"
import { objectToQueryString } from "@/utils"

const useAuthGoogle = () => {
    const { googleIdentityClientId } = ResellerStore()

    const openGoogleAccount = () => {
        const query = objectToQueryString({
            client_id: googleIdentityClientId,
            redirect_uri: location.origin,
            response_type: "token",
            scope: "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email openid",
            prompt: "consent",
        })

        const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?${query}`

        window.location.href = authUrl
    }

    return {
        openGoogleAccount,
    }
}

export default useAuthGoogle
