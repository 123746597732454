import { Control, Controller } from "react-hook-form"
import { SelectProps } from "@mui/material"
import SelectCheckbox from "../select-checkbox"

type SelectCheckboxControllerProps = {
    control: Control<any>
    onClear?: () => void
    options: {
        value: string | number
        label: string
        description?: string
    }[]
}

const SelectCheckboxController = ({ ...props }: SelectCheckboxControllerProps & SelectProps) => {
    return (
        <Controller
            name={props.name}
            control={props.control}
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            render={({ fieldState, field: { ref, ...field } }) => {
                return (
                    <SelectCheckbox
                        {...field}
                        value={field.value || []}
                        error={!!fieldState.error}
                        id={props.name}
                        options={props.options}
                        {...props}
                    />
                )
            }}
        />
    )
}

export default SelectCheckboxController
