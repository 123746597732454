import i18n from "@/i18n"
import { IKpiLocalPresenceStore, PayloadFilter } from "@/types/statistic"
import { create } from "zustand"

export interface IKpiLocalPresenceSetter {
    update?: (update: Partial<IKpiLocalPresenceStore>) => void
    updatePayload: (update: Partial<PayloadFilter>) => void
    updatePeriod: (update: Partial<IKpiLocalPresenceStore>) => void
    flush?: () => void
}

const initalState: IKpiLocalPresenceStore = {
    graph: null,
    kpi: null,
    selectedBusinesses: [],
    selectedPeriod: {
        label: i18n.t("LOCAL_PRESENCE.YEAR"),
        value: 3,
    },
    loading: false,
    payloadFilter: {
        locationsId: [],
        start: "today",
        type: "year",
        previous_period: true,
    },
}

const KpiLocalPresenceStore = create<IKpiLocalPresenceStore & IKpiLocalPresenceSetter>((set) => ({
    ...initalState,
    update: (update) => set(() => ({ ...update })),
    updatePayload: (update) => set((state) => ({ payloadFilter: { ...state.payloadFilter, ...update } })),
    updatePeriod: (update) => set((state) => ({ selectedPeriod: { ...state.selectedPeriod, ...update } })),
    flush: () => set(initalState),
}))

export default KpiLocalPresenceStore
