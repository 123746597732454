import { useCallback } from "react"
import { useLocation, useNavigate } from "react-router-dom"

const useQueryParams = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const removeAllQueryParams = () => {
        // Navigate to the same path but without any search query
        navigate(location.pathname, { replace: true })
    }

    const setQueryParams = useCallback(
        (paramsToUpdate: { [key: string]: string }) => {
            const params = new URLSearchParams(location.search)

            // Ajouter ou mettre à jour plusieurs paramètres de requête
            Object.keys(paramsToUpdate).forEach((key) => {
                params.set(key, paramsToUpdate[key])
            })

            navigate(`${location.pathname}?${params.toString()}`, { replace: true })
        },
        [navigate, location.pathname, location.search]
    )

    const removeQueryParams = useCallback(
        (keysToRemove: string[]) => {
            const params = new URLSearchParams(location.search)

            // Supprimer plusieurs paramètres de requête
            keysToRemove.forEach((key) => {
                params.delete(key)
            })

            navigate(`${location.pathname}?${params.toString()}`, { replace: true })
        },
        [navigate, location.pathname, location.search]
    )

    return { setQueryParams, removeQueryParams, removeAllQueryParams }
}

export default useQueryParams
