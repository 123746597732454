import { Chip, Stack, Typography } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { v4 as uuidv4 } from "uuid"

const BoxChip = ({ items, type = "single", isAction = true }: any) => {
    const muiBoxRef = useRef<HTMLDivElement>(null)
    const [count, setCount] = useState(0)
    const [renderedList, setRenderList] = useState([])

    useEffect(() => {
        const muiBoxRoot = muiBoxRef.current
        if (muiBoxRoot) {
            const muiBoxRect = muiBoxRoot.getBoundingClientRect()
            let exceededCount = 0
            Array.from(muiBoxRoot.children).forEach((child: any) => {
                const childRect = child.getBoundingClientRect()
                // Check if the child element exceeds the parent block
                if (childRect.right > muiBoxRect.right || childRect.bottom > muiBoxRect.bottom) {
                    exceededCount++
                }
            })

            setCount(exceededCount)
            if (exceededCount > 0) setRenderList(items.slice(0, -exceededCount))
            else setRenderList(items)
        }
    }, [items])

    return (
        <Stack position={"relative"}>
            <Stack
                key={uuidv4()}
                ref={(el) => {
                    muiBoxRef.current = el
                }}
                flexDirection={"row"}
                gap={1}
                position={"absolute"}
                width="100%"
                visibility={"hidden"}
                alignItems={"center"}
                flexWrap={type === "multiple" ? "wrap" : "nowrap"}
                sx={{ height: type === "multiple" ? "calc(3 * 38px)" : "38px" }}
                overflow={"hidden"}
                data-testid="box-chip-item"
            >
                {items?.map((v) => (
                    <Chip key={uuidv4()} label={v.label} color={isAction ? "primary" : "secondary"} />
                ))}
            </Stack>

            <Stack key={uuidv4()} flexDirection={"row"} gap={1} alignItems={"center"} flexWrap={"wrap"}>
                {renderedList?.map((v) => (
                    <Chip key={uuidv4()} label={v.label} color={isAction ? "primary" : "secondary"} />
                ))}
                {count > 0 && <Typography className="text-count">+{count}</Typography>}
            </Stack>
        </Stack>
    )
}

export default BoxChip
