import Icon from "@/components/base/Icon"
import FormAction from "@/components/base/form-action"
import BaseInput from "@/components/base/input"
import EditMediaContainer from "@/components/business/form-container/edit/edit-media"

import { TitleWrapper } from "@/components/base/title-wrapper"
import { MAX_INPUT } from "@/data/limiter"
import useLogic from "@/hooks/businesses/edit-media/useLogic"
import { PageProps } from "@/types/page"
import {
    Box,
    Button,
    CardMedia,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    IconButton,
    Paper,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
} from "@mui/material"
import { useMemo } from "react"
import { Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"
import ReactCrop from "react-image-crop"
import "react-image-crop/dist/ReactCrop.css"
const EditMedia = ({ open, onClose }: PageProps) => {
    const {
        mediaValue,
        control,
        googleChecked,
        setGoogleChecked,
        handlePreviousButton,
        showCropPage,
        handleDelete,
        handleCrop,
        previousItem,
        isLoading,
        handleSubmit,
        onSubmit,
        onErrors,
        handleCancelButton,
        loading,
        fbChecked,
        setFbChecked,
        crop,
        setCrop,
        rotateMinus,
        rotatePlus,
        rotation,
        alignment,
        aspect,
        lock,
        handleChange,
        imgRef,
        previewCanvasRef,
        completedCrop,
        setCompletedCrop,
        heightCropBloc,
        refBtnEdit,
        refboxImage,
        handleImageLoad,
    } = useLogic()
    const { t } = useTranslation()
    const urlLocalFile = useMemo(() => {
        if (mediaValue?.file) {
            return URL.createObjectURL(mediaValue.file)
        }
        return ""
    }, [mediaValue?.file])

    return (
        <EditMediaContainer open={open} onClose={onClose}>
            <TitleWrapper
                handlePreviousButton={showCropPage ? handleCancelButton : handlePreviousButton}
                title={t("MEDIA.MODIFY")}
            />
            <Stack
                id="edit-media"
                height={heightCropBloc}
                className="edit-media"
                onSubmit={handleSubmit(onSubmit, onErrors)}
                component="form"
            >
                {!showCropPage && (
                    <Stack className="container" height={{ md: "100%" }}>
                        {mediaValue && (
                            <Stack gap={2} direction={{ md: "row" }} height={"100%"} width={"100%"}>
                                <Stack width={"100%"} margin="auto">
                                    {!previousItem?.mediaItem?.id && mediaValue.file && mediaValue.type === "PHOTO" && (
                                        <img
                                            crossOrigin="anonymous"
                                            src={URL.createObjectURL(
                                                mediaValue.fileCrop ? mediaValue.fileCrop : mediaValue.file
                                            )}
                                            alt="media"
                                        />
                                    )}

                                    {!previousItem?.mediaItem?.id && mediaValue.file && mediaValue.type === "VIDEO" && (
                                        <CardMedia
                                            component="video"
                                            image={URL.createObjectURL(
                                                mediaValue.fileCrop ? mediaValue.fileCrop : mediaValue.file
                                            )}
                                            controls
                                            autoPlay
                                        />
                                    )}
                                    {previousItem?.mediaItem?.id && mediaValue.url && mediaValue.type === "PHOTO" && (
                                        <img src={mediaValue.url} alt="media" />
                                    )}
                                    {previousItem?.mediaItem?.id && mediaValue.url && mediaValue.type === "VIDEO" && (
                                        <CardMedia
                                            className="video"
                                            component="video"
                                            image={mediaValue.url}
                                            controls
                                            autoPlay
                                        />
                                    )}
                                </Stack>
                                <Paper className="inputs-wrapper">
                                    <Stack gap={1}>
                                        <Controller
                                            render={({ field }) => (
                                                <BaseInput
                                                    max={MAX_INPUT.TEXT}
                                                    label={t("MEDIA.NAME")}
                                                    size="small"
                                                    {...field}
                                                />
                                            )}
                                            control={control}
                                            name="label"
                                        />
                                        <FormGroup>
                                            {/* {isGoogleConnected(location) && ( */}
                                            <FormControlLabel
                                                checked={googleChecked}
                                                //disabled={!needReconnexion && !isLoggedFacebook}
                                                control={
                                                    <Checkbox
                                                        onChange={(event) => {
                                                            setGoogleChecked(event.target.checked)
                                                        }}
                                                    />
                                                }
                                                label="Google"
                                            />
                                            {/* )} */}

                                            {/* {isLoggedFacebook && ( */}
                                            <FormControlLabel
                                                checked={fbChecked}
                                                // disabled={!isGoogleConnected(location)}
                                                control={
                                                    <Checkbox
                                                        onChange={(event) => {
                                                            setFbChecked(event.target.checked)
                                                        }}
                                                    />
                                                }
                                                label="Facebook"
                                            />
                                            {/* )} */}
                                        </FormGroup>
                                    </Stack>
                                    <Stack gap={2} ref={refBtnEdit}>
                                        {mediaValue.type === "PHOTO" && !mediaValue.fileCrop && (
                                            <Button onClick={handleCrop} color="primary" variant="contained">
                                                <Icon name="frame" />
                                                {t("MEDIA.CROP")}
                                            </Button>
                                        )}
                                        <Button
                                            onClick={() => handleDelete(mediaValue)}
                                            color="error"
                                            variant="contained"
                                            disabled={isLoading}
                                        >
                                            {isLoading && <CircularProgress size={16} sx={{ marginRight: "8px" }} />}
                                            {!isLoading && <Icon name="delete1" sx={{ color: "error.main" }} />}
                                            {t("USERS.DELETE.BUTTON")}
                                        </Button>
                                    </Stack>
                                </Paper>
                            </Stack>
                        )}
                    </Stack>
                )}

                {showCropPage && mediaValue?.type && (
                    <Stack className="form-stack">
                        <Box ref={refboxImage} className="form-stack--inputs">
                            <Stack className="action-wrapper" direction={"row"}>
                                <IconButton onClick={rotatePlus}>
                                    <Icon name="cornerUpLeft" />
                                </IconButton>
                                <IconButton onClick={rotateMinus}>
                                    <Icon name="cornerUpRight" />
                                </IconButton>
                                <ToggleButtonGroup
                                    value={alignment}
                                    exclusive
                                    onChange={handleChange}
                                    aria-label="Platform"
                                >
                                    <ToggleButton value="default">{t("MEDIA.CUSTOM")}</ToggleButton>
                                    <ToggleButton value="one">1:1</ToggleButton>
                                    <ToggleButton value="two">4:5</ToggleButton>
                                    <ToggleButton value="three">9:18</ToggleButton>
                                </ToggleButtonGroup>
                            </Stack>
                            <Box className="bloc-crop">
                                <ReactCrop
                                    crop={crop}
                                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                                    onComplete={(c) => setCompletedCrop(c)}
                                    locked={lock}
                                    aspect={aspect}
                                >
                                    {mediaValue?.url && (
                                        <img
                                            ref={imgRef}
                                            crossOrigin="anonymous"
                                            style={{ transform: `rotate(${rotation}deg)` }}
                                            src={mediaValue.url}
                                            onLoad={handleImageLoad}
                                            alt="media"
                                        />
                                    )}
                                    {mediaValue?.file && (
                                        <img
                                            ref={imgRef}
                                            crossOrigin="anonymous"
                                            style={{ transform: `rotate(${rotation}deg)` }}
                                            src={urlLocalFile}
                                            onLoad={handleImageLoad}
                                            alt="media"
                                        />
                                    )}
                                </ReactCrop>
                            </Box>
                            {!!completedCrop && (
                                <canvas
                                    ref={previewCanvasRef}
                                    style={{
                                        position: "fixed",
                                        visibility: "hidden",
                                    }}
                                />
                            )}
                        </Box>
                    </Stack>
                )}

                <FormAction
                    handleCancel={handleCancelButton}
                    isLoading={loading}
                    isDisabled={loading}
                    isUpdating={previousItem?.mediaItem?.id ? true : false}
                    isConfirm
                    buttonText={!previousItem?.mediaItem?.id ? t("MEDIA.CONFIRM") : null}
                />
            </Stack>
        </EditMediaContainer>
    )
}

export default EditMedia
