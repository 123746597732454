import { makeStyles } from "tss-react/mui"

interface WrapperSxProps {
    isNew?: boolean
}

export const wrapperSx = (arg: WrapperSxProps = { isNew: false }) => ({
    width: 72,
    minWidth: 72,
    height: 72,
    borderRadius: 1,
    position: "relative",
    ...(arg.isNew && {
        "&::after": {
            content: '""',
            width: "100%",
            position: "absolute",
            height: "100%",
            top: 0,
            left: 0,
            background: "linear-gradient(0deg, rgba(0, 4, 31, 0.42) 0%, rgba(0, 4, 31, 0.42) 100%)",
            borderRadius: 1,
        },
    }),
})

export const imgSx = {
    objectFit: "cover",
    width: "100%",
    height: "100%",
    borderRadius: 1,
}

export const newSx = {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    color: "common.white",
    fontSize: 14,
    backgroundColor: "info.main",
    px: 0.5,
    zIndex: 1,
    borderRadius: 0.5,
}

export const withoutImageSx = (arg: WrapperSxProps = { isNew: false }) => ({
    width: 72,
    minWidth: 72,
    height: 72,
    background: "rgba(104, 115, 141, 0.12)",
    borderRadius: 1,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    gap: 1,
    alignItems: "center",
    position: "relative",
    ...(arg.isNew && {
        "&::after": {
            content: '""',
            width: "100%",
            position: "absolute",
            height: "100%",
            top: 0,
            left: 0,
            background: "linear-gradient(0deg, rgba(0, 4, 31, 0.42) 0%, rgba(0, 4, 31, 0.42) 100%)",
            borderRadius: 1,
        },
    }),
    "& span:not(.new)": {
        fontSize: 14,
        color: "action.active",
    },
    "&.image-preview": {
        backgroundColor: "transparent",
        "& img": {
            width: "100%",
        },
    },
})
