import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()((theme) => ({
    drawer: { zIndex: 1 },
    container: {
        "& .inputs-wrapper": {
            height: "fit-content",
            "& .error": {
                textAlign: "center",
            },
            "& h3": {
                fontSize: 20,
                color: "black",
                fontWeight: 500,
            },
            "& .MuiBox-root": {
                display: "flex",
                flexDirection: "column",
                gap: 8,
            },
            "& .title": {
                display: "flex",
                gap: 8,
                alignItems: "center",
                "& .title-icon": {
                    color: theme.palette.action.active,
                },
            },
        },
        "& .inputs-container": {
            display: "flex",
            flexDirection: "column",
            gap: 16,
            "@media (min-width: 768px)": {
                flexDirection: "row",
            },
            "& .title": {
                display: "flex",
                gap: 8,
                alignItems: "center",
                "& .title-icon": {
                    color: theme.palette.action.active,
                },
            },
        },
    },
}))

export default useStyles
