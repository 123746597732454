import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()((theme) => ({
    paper: {
        display: "flex",
        flexDirection: "column",
        gap: 8,
        borderRadius: 10,
        "@media (min-width: 1024px)": {
            flexDirection: "row",
            gap: 32,
        },
        "body & .align-center": {
            "@media (min-width: 1024px)": {
                alignItems: "center",
            },
        },
        "body & .align-start": {
            "@media (min-width: 1024px)": {
                alignItems: "flex-start",
            },
        },
        "body & .align-baseline": {
            "@media (min-width: 1024px)": {
                alignItems: "baseline",
            },
        },
        "& .paper-header": {
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            "@media (min-width: 1024px)": {
                gap: 32,
            },
        },
        "& .day": {
            fontSize: 20,
            fontWeight: 500,
            "@media (min-width: 1024px)": {
                width: 132,
            },
        },
        "& .MuiSwitch-root + .MuiFormControlLabel-label": {
            color: theme.palette.action.active,
        },
        "& .paper-content": {
            display: "flex",
            flexDirection: "column",
            gap: 8,
            "@media (min-width: 1024px)": {
                width: "100%",
                flexDirection: "row-reverse",
                alignItems: "center",
                justifyContent: "space-between",
            },
            "& .schedule-list": {
                display: "flex",
                flexDirection: "column",
                gap: 8,
                "& .time-error": {
                    maxWidth: "225px",
                    marginLeft: "10px",
                },
            },
        },
        "& .separator": {
            color: theme.palette.action.active,
            marginLeft: "16px",
        },
        "& .schedule-input-wrapper": {
            display: "flex",
            gap: 12,
            alignItems: "center",
            "& button": {
                padding: 12,
                "&.plus": {
                    color: theme.palette.primary.main,
                },
                "& svg": { width: 20, height: 20 },
            },
            "& > div": {
                width: 96,
            },
        },
    },
}))

export default useStyles
