import Icon from "@/components/base/Icon"
import Filter from "@/components/base/page-container/list-container/title-wrapper/filter"
import { SearchPropsType } from "@/hooks/businesses/list/useLogic"
import IconNames from "@/types/icons"
import { Button, CircularProgress, Paper, Typography } from "@mui/material"
import { Dispatch, MouseEventHandler, SetStateAction } from "react"
import useStyles, { actionBtnSx, btnTextSx } from "./styles"

interface HeaderListProps {
    buttonIcon?: IconNames
    buttonText?: string
    onClickButton?: MouseEventHandler
    showActionButton?: boolean
    withSearch?: SearchPropsType
    setWithSearch?: Dispatch<SetStateAction<SearchPropsType>>
    loadingCheckLicence?: boolean
}

export const HeaderWrapper = ({
    onClickButton,
    buttonIcon,
    buttonText,
    showActionButton,
    withSearch,
    setWithSearch,
    loadingCheckLicence,
}: HeaderListProps) => {
    const { classes } = useStyles({ withSearch, showActionButton })()
    return (
        <Paper className={classes.searchWrapper}>
            {showActionButton && (
                <Button
                    className="action-btn"
                    color="primary"
                    sx={withSearch && actionBtnSx}
                    variant="contained"
                    onClick={onClickButton}
                >
                    {loadingCheckLicence && <CircularProgress size={16} sx={{ marginRight: "8px" }} />}
                    {!loadingCheckLicence && <Icon name={buttonIcon ?? "plus"} />}
                    <Typography data-testid="user-button" component="span" sx={btnTextSx}>
                        {buttonText}
                    </Typography>
                </Button>
            )}

            {withSearch && <Filter setWithSearch={setWithSearch} withSearch={withSearch} />}
        </Paper>
    )
}
