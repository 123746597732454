import { makeStyles } from "tss-react/mui"

interface StyleProps {
    unique: boolean
    error: boolean
}

export const useStyles = makeStyles<{ props: StyleProps }>()((theme, { props }) => ({
    imageFrame: {
        minWidth: props.unique ? "100%" : 98,
        height: props.unique ? "auto" : 98,
        minHeight: props.unique ? 26 : 98,
        width: props.unique ? "100%" : 98,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 8,
        gap: 8,
        padding: 16,
        boxSizing: "border-box",
        "& span": {
            color: theme.palette.primary.main,
            textAlign: "center",
        },
        border: `1px solid ${props.error ? theme.palette.error.main : theme.palette.primary.main}`,
        span: {
            color: props.error ? theme.palette.error.main : theme.palette.primary.main,
        },
        svg: {
            path: {
                stroke: props.error ? theme.palette.error.main : theme.palette.primary.main,
            },
        },
    },
}))
