import { MAX_INPUT } from "@/data/limiter"
import CampaignStore from "@/store/campaign"
import useLocationStore from "@/store/location"
import { isValidEmail } from "@/utils"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import usePhoneInputLogic from "../phone-input/useLogic"

const useCampaignMemo = ({ formValue }) => {
    const { contacts, credits, locations, editorText } = CampaignStore()
    const { businesses } = useLocationStore()
    const { isPhoneValid } = usePhoneInputLogic()
    const { t } = useTranslation()

    const atValues = useMemo(() => {
        return [
            { id: "firstname", value: t("CAMPAIGN.FIRSTNAME") },
            { id: "lastname", value: t("CAMPAIGN.LASTNAME") },
            { id: "business", value: t("CAMPAIGN.BUSINESS") },
            { id: "url", value: "url" },
        ]
    }, [t])

    const step2isValid = useMemo(() => {
        if (formValue?.step != 1) return true
        else return formValue?.step === 1 && formValue?.message && formValue?.message !== "<p><br></p>"
    }, [formValue?.step, formValue?.message])

    const step1isValid = useMemo(() => {
        if (formValue?.step != 0) return true
    }, [formValue?.step])

    const phoneIsValid = useMemo(() => {
        if (formValue?.type !== "sms") return true
        if (contacts?.length !== 0) return true
        else return !!formValue?.phone && !/^\+\d{1,4}$/.test(formValue?.phone)
    }, [formValue?.type, formValue?.phone, contacts?.length])

    const isThereNewContact = useMemo(() => {
        if (contacts?.length === 0) return false
        return (
            contacts?.length > 0 &&
            (!!formValue?.firstname ||
                !!formValue?.lastname ||
                !!formValue?.email ||
                (!!formValue?.phone && !/^\+\d{1,4}$/.test(formValue?.phone)))
        )
    }, [contacts?.length, formValue])

    const currentLocation = useMemo(
        () => (locations || [])?.find((item) => item.id === formValue?.business),
        [formValue?.business, locations]
    )

    const infos = useMemo(() => {
        if (contacts?.length > 0) {
            return {
                lang: formValue?.lang,
                subject: formValue?.subject,
                ...contacts[0],
            }
        }
        return formValue
    }, [contacts, formValue])

    const typeLabel = useMemo(() => {
        return formValue?.type === "sms" ? t("CAMPAIGN.PHONE_NUMBER") : t("LABEL.EMAIL")
    }, [formValue?.type, t])

    const remainingCredits = useMemo(() => {
        if (!credits?.length) return 0
        const type = formValue?.type === "sms" ? "sms_rb" : "email_rb"
        const credit = credits?.filter((item) => item?.type === type)

        return credit?.length && credit?.[0]?.remaining
    }, [formValue, credits])

    const hasCredit = useMemo(() => {
        return remainingCredits > 0
    }, [remainingCredits])

    const isDisabled = useMemo(() => {
        let isFormInvalid = !formValue?.business || !formValue?.confirmation
        if (!isFormInvalid) {
            if (formValue?.type === "sms") {
                if (formValue?.step === 1) {
                    isFormInvalid = editorText?.length > MAX_INPUT.SMS
                } else {
                    isFormInvalid = !isPhoneValid(formValue?.phone || "")
                }
            } else if (formValue?.step === 1) {
                if (formValue?.type === "email") {
                    isFormInvalid = !formValue?.subject
                } else {
                    isFormInvalid = !formValue?.message
                }
            } else {
                isFormInvalid =
                    contacts.length === 0 &&
                    (!formValue?.email || formValue?.email === undefined || !isValidEmail(formValue?.email))
            }
        }
        return isFormInvalid
    }, [
        formValue?.business,
        formValue?.confirmation,
        formValue?.type,
        formValue?.step,
        formValue?.phone,
        formValue?.subject,
        formValue?.message,
        formValue?.email,
        editorText?.length,
        isPhoneValid,
        contacts?.length,
    ])

    const hasNewUrl = useMemo(() => {
        return (
            currentLocation?.locationState?.newReviewUri !== "" &&
            currentLocation?.locationState?.newReviewUri !== undefined
        )
    }, [currentLocation?.locationState?.newReviewUri])

    return {
        hasNewUrl,
        isDisabled,
        hasCredit,
        remainingCredits,
        typeLabel,
        infos,
        currentLocation,
        isThereNewContact,
        phoneIsValid,
        step1isValid,
        step2isValid,
        atValues,
    }
}

export default useCampaignMemo
