import BaseSelect from "@/components/base/select"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { styleSx } from "./style"

const SelectRates = ({ selected, onClear, selectionChange }) => {
    const { t } = useTranslation()
    const selectNoteLabel = useMemo(() => {
        if (selected.length === 0 || selected.length > 1) {
            return t("REPUTATION.FILTEREDS")
        }
        return `${t("REPUTATION.FILTERED")}`
    }, [selected.length, t])

    const listStars = useMemo(() => {
        return [
            {
                label: t("REPUTATION.ONE_STAR"),
                value: "1",
            },
            {
                label: t("REPUTATION.TWO_STARS"),
                value: "2",
            },
            {
                label: t("REPUTATION.THREE_STARS"),
                value: "3",
            },
            {
                label: t("REPUTATION.FOUR_STARS"),
                value: "4",
            },
            {
                label: t("REPUTATION.FIVE_STARS"),
                value: "5",
            },
        ]
    }, [t])

    const listAnswer = useMemo(() => {
        return [
            {
                label: t("REPUTATION.NOT_ANSWERED_MESSAGE"),
                value: "6",
            },
        ]
    }, [t])

    return (
        <BaseSelect
            options={listStars || []}
            secondOptions={listAnswer || []}
            listSubheaderOne={t("REPUTATION.NOTE")}
            listSubheaderTwo={t("REPUTATION.TITLE_ANSWER")}
            label={selectNoteLabel}
            onSelectionChange={(event) => {
                selectionChange(event?.target?.value ?? [])
            }}
            selected={selected}
            defaultValue={selected}
            sx={styleSx}
            mobileWidth={768}
            id="select-notes-reputation"
            multiSelect
            onClearClicked={onClear}
        />
    )
}

export default SelectRates
