import { getMediaFileRatioSync } from "./media-ratio"

export interface MediaObject {
    file: File
    type: string
    googleChecked: boolean
    fbChecked: boolean
}

export interface ErrorDuplicate {
    duplicate: boolean
}

export const blobToFile = (theBlob: Blob, fileName: string): File => {
    const b: any = theBlob
    b.lastModifiedDate = new Date()
    b.name = fileName
    Object.setPrototypeOf(b, File.prototype)
    return theBlob as File
}
export const imgListExtension = ["jpg", "jpeg", "png"]
export const videoListExtension = ["mp4", "mov", "m4v"]

const lowerBoundImage = 0.8 // 4:5
const upperBoundImage = 1.91 // 1.91:1
const headerListType = [
    "image/png",
    "image/jpeg",
    "image/jpg",
    "video/mp4",
    "video/mov",
    "video/m4v",
    "video/quicktime",
]

export const getProvider = (googleChecked, fbChecked): any[] => {
    const provider = []
    if (googleChecked && fbChecked) {
        provider.push("google", "facebook")
    }
    if (!googleChecked && fbChecked) {
        provider.push("facebook")
    }
    if (googleChecked && !fbChecked) {
        provider.push("google")
    }
    return provider
}

const isFileValid = (mediaFile: File, mediaExtension: string, size: { image: number; video: number }): boolean => {
    const isImageValid =
        imgListExtension.includes(mediaExtension.toLowerCase()) && mediaFile.size <= size.image * 1024 * 1024
    const isVideoValid =
        videoListExtension.includes(mediaExtension.toLowerCase()) && mediaFile.size <= size.video * 1024 * 1024
    const isSizeValid = mediaFile.size >= 10 * 1024
    return (isImageValid || isVideoValid) && isSizeValid
}

const isMediaFileUnique = (mediasLocal: any, mediaFile: File): boolean => {
    return !mediasLocal?.some((media) => media.file?.name === mediaFile.name)
}

const createMediaObject = (
    mediaFile: File,
    mediaExtension: string,
    googleChecked?: boolean,
    fbChecked?: boolean
): MediaObject => {
    return {
        file: mediaFile,
        type: imgListExtension.includes(mediaExtension) ? "PHOTO" : "VIDEO",
        googleChecked,
        fbChecked,
    }
}

export const isRatioWithinRange = (ratioMedia: string) => {
    const [numerator, denominator] = ratioMedia.split(":").map(Number)
    const ratio = numerator / denominator

    return ratio >= lowerBoundImage && ratio <= upperBoundImage
}

export const mediaUploadListWithNetwork = async (
    mediasLocal: any,
    mediaFiles: FileList,
    googleChecked: boolean,
    fbChecked: boolean
): Promise<MediaObject[] | ErrorDuplicate> => {
    const preFileList = []
    for (const mediaFile of Array.from(mediaFiles)) {
        const mediaExtension = mediaFile?.name?.split(".").pop().toLowerCase()
        if (!isMediaFileUnique(mediasLocal, mediaFile) || !headerListType.includes(mediaFile?.type)) {
            return { duplicate: true }
        }
        if (!isFileValid(mediaFile, mediaExtension, { image: 5, video: 50 })) {
            continue
        }
        if (imgListExtension.includes(mediaExtension.toLowerCase())) {
            const img = new Image()
            img.src = URL.createObjectURL(mediaFile)

            await new Promise((resolve, reject) => {
                img.onload = resolve
                img.onerror = reject
            })

            if (img.height >= 250 && img.width >= 250) {
                const mediaObject = createMediaObject(mediaFile, mediaExtension, googleChecked, fbChecked)
                preFileList.push(mediaObject)
            }
        } else {
            const mediaObject = {
                file: mediaFile,
                type: imgListExtension.includes(mediaExtension) ? "PHOTO" : "VIDEO",
                googleChecked,
                fbChecked,
            }
            preFileList.push(mediaObject)
        }
    }

    return preFileList.length === mediaFiles.length ? preFileList : []
}

export const mediaUploadList = async (mediasLocal: any, mediaFiles: FileList): Promise<File[] | ErrorDuplicate> => {
    const preFileList = []
    for (const mediaFile of Array.from(mediaFiles)) {
        const mediaExtension = mediaFile?.name?.split(".").pop().toLowerCase()
        if (!isMediaFileUnique(mediasLocal, mediaFile) || !headerListType.includes(mediaFile?.type)) {
            return { duplicate: true }
        }
        if (!isFileValid(mediaFile, mediaExtension, { image: 10, video: 50 })) {
            continue
        }
        if (imgListExtension.includes(mediaExtension.toLowerCase())) {
            const img = new Image()
            img.src = URL.createObjectURL(mediaFile)

            await new Promise((resolve, reject) => {
                img.onload = resolve
                img.onerror = reject
            })

            if (img.height >= 250 && img.width >= 250) {
                const mediaObject = createMediaObject(mediaFile, mediaExtension)
                preFileList.push(mediaObject)
            }
        } else {
            const mediaObject = {
                file: mediaFile,
                type: imgListExtension.includes(mediaExtension) ? "PHOTO" : "VIDEO",
            }
            preFileList.push(mediaObject)
        }
    }

    return preFileList.length === mediaFiles.length ? preFileList : []
}
