import { makeStyles } from "tss-react/mui"

export const useStyles = makeStyles()((theme) => ({
    containerSocial: {
        "& .header-social": {
            flexDirection: "column-reverse",
            justifyContent: "space-between",
            padding: "8px 8px 0px 8px",
            "@media (min-width: 819px)": {
                flexDirection: "row",
                padding: "16px 16px 0px 16px",
            },
            "& .btn-post": {
                padding: "8px 22px",
                height: "fit-content",
                width: "100%",
                "@media (min-width: 819px)": {
                    width: "fit-content",
                },
            },
            "& .select-business": {
                width: "100%",
                "@media (min-width: 819px)": {
                    width: "269px",
                },
                "& .MuiAutocomplete-root": {
                    maxWidth: "100%",
                },
            },
        },
        "& .btn-plus": {
            span: {
                color: theme.palette.primary.main,
            },
            "&:hover": {
                backgroundColor: "rgba(1, 128, 255, 0.16)",
                borderRadius: 8,
            },
        },
    },
}))
