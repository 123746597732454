export const deleteModal = {
    textAlign: "left",
    alignItems: "start",
    "& .action-wrapper": {
        flexDirection: "row",
        gap: 2,
        // "@media (min-width: 768px)": {
        //     flexDirection: "row",
        // },
        "& button": {
            width: "50%",
            "@media (max-width: 767px)": {
                width: "100%",
            },
        },
        "& .btn-remove": {
            display: "flex",
            gap: 1,
        },
    },
    "& p": {
        width: "100%",
        textAlign: "left",
        color: "action.active",
    },
    "& .title-wrapper": {
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        "& h2": {
            width: "100%",
            textAlign: "left",
        },
    },
}
