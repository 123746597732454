import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()((theme) => ({
    snackbarsWrapper: {
        position: "fixed",
        bottom: 8,
        height: 56,
        width: "calc(100% - 16px)",
        left: "8px",
        "&.shown": {
            zIndex: 2,
        },
        "@media (min-width: 768px)": {
            width: "calc(100% - 251px - 48px - 16px)",
            right: "calc(24px + 16px)",
            bottom: 24,
            left: "initial",
            transform: "none",
        },
        "&.fullPage": {
            "@media (min-width: 768px)": {
                width: "100%",
                right: 0,
                bottom: 24,
                left: 0,
                transform: "none",
            },
        },
        "& .MuiSnackbar-root": {
            position: "absolute",
            width: "100%",
            maxWidth: 655,
            bottom: 0,
            left: 0,
            "@media (min-width: 768px)": {
                left: "50%",
                transform: "translateX(-50%)",
            },
            "& .MuiPaper-root": {
                padding: 16,
                width: "100%",
                justifyContent: "space-between",
                color: "white",
                "& .MuiAlert-message": {
                    fontSize: 16,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    width: "100%",
                    padding: 0,
                },
                "& .MuiAlert-icon": {
                    color: "white",
                    alignItems: "center",
                    padding: 0,
                },
                "& .MuiAlert-action": {
                    padding: 0,
                    "& button": {
                        padding: 0,
                    },
                },
            },
        },
    },
}))

export default useStyles
