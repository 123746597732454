import useNotification from "@/hooks/useNotification"
import { EditModelService } from "@/services"
import CampaignStore from "@/store/campaign"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

const useLogic = () => {
    const { t } = useTranslation()
    const [showEdit, setShowEdit] = useState<boolean>(false)
    const [loadingDelete, setLoadingDelete] = useState<boolean>(false)
    const { notif } = useNotification()
    const { businessSelect, update, hasModel } = CampaignStore()

    const onCloseNewEdit = () => {
        setShowEdit(false)
    }

    const fetchReviews = useCallback(async () => {
        if (businessSelect) {
            const response = await EditModelService.fetchReviews(businessSelect.business)
            if (response) {
                update({ listReviews: response.data, hasModel: response.has_model })
            }
        }
    }, [businessSelect])

    const handleDelete = useCallback(async (templateId: string) => {
        if (businessSelect) {
            setLoadingDelete(true)
            const response = await EditModelService.deleteReview(businessSelect.business, templateId)
            if (response) {
                setLoadingDelete(false)
                fetchReviews()
            } else {
                notif({ message: t("CAMPAIGN.MODEL.ERROR"), type: "ERROR" })
            }
        }
    }, [])

    useEffect(() => {
        fetchReviews()
    }, [fetchReviews])

    return {
        hasModel,
        showEdit,
        setShowEdit,
        onCloseNewEdit,
        handleDelete,
        loadingDelete,
        fetchReviews,
    }
}

export default useLogic
