import Typography from "@mui/material/Typography"
import { useTranslation } from "react-i18next"
const UploadMediaMessageSocial = () => {
    const { t } = useTranslation()
    return (
        <>
            <Typography
                dangerouslySetInnerHTML={{
                    __html: t("SOCIALS.MAX_SIZE"),
                }}
                component="span"
                className="image-type"
                variant="caption"
            />
            <Typography component="span" className="image-type" variant="caption">
                <strong>{t("MEDIA.ACCEPTED_FORMAT")}</strong>: JPEG, JPG, PNG, MP4, M4V, MOV
            </Typography>
        </>
    )
}

export default UploadMediaMessageSocial
