import useStyles from "@/components/business/schedule/standard-item/styles"
import UiStore from "@/store/ui"
import { DayItem } from "@/types/businesses"
import { validateTimes } from "@/utils"
import { Dayjs } from "dayjs"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

const useLogic = ({ day, setDays }: any) => {
    const { t } = useTranslation()
    const { classes } = useStyles()
    const { preferredLanguage } = UiStore()
    const [data, setData] = useState<DayItem>()

    const showAa = useMemo(() => {
        let value = false
        switch (preferredLanguage?.toLowerCase()) {
            case "en":
                value = true
                break
        }
        return value
    }, [preferredLanguage])

    const onChangeSchedule = (actionType: "add" | "delete", index) => {
        const updated = { ...data }
        const hasTomorrow = updated?.times?.some((time) => time.tomorrow)
        if (actionType === "add" && !hasTomorrow) {
            updated?.times?.push({
                start: null,
                end: null,
                startValid: false,
                endValid: false,
                tomorrow: false,
            })
        } else if (actionType === "delete") {
            updated?.times?.splice(index, 1)
        }
        setData({ ...updated })
    }

    const handleSelectTimefield = (value: Dayjs, index: number, type: string) => {
        setData((previous) => {
            if (previous?.times) {
                previous.times[index][type] = value
                const validated = validateTimes(previous, showAa)
                return validated
            }
            return null
        })
    }

    const handleChangeClose = () => {
        setData((previous) => ({
            ...previous,
            times: [
                {
                    start: null,
                    end: null,
                    startValid: false,
                    endValid: false,
                },
            ],
            isOpen: !data.isOpen,
        }))
    }

    const handleChange24 = () => {
        setData((previous) => ({
            ...previous,
            times: [
                {
                    start: null,
                    end: null,
                    startValid: false,
                    endValid: false,
                    tomorrow: false,
                },
            ],
            isAvailableAllDay: !data.isAvailableAllDay,
        }))
    }

    useEffect(() => {
        const validated = validateTimes(day, showAa)
        setData({ ...validated })
    }, [])

    useEffect(() => {
        if (data?.name && typeof setDays === "function") {
            setDays((previous) => {
                return previous.map((value) => {
                    if (value.name === data.name) {
                        return { ...data }
                    }
                    return value
                })
            })
        }
    }, [data])

    return {
        classes,
        data,
        t,
        handleChangeClose,
        handleChange24,
        handleSelectTimefield,
        onChangeSchedule,
        validateTimes,
        setData,
    }
}

export default useLogic
