import { makeStyles } from "tss-react/mui"

interface StyleProps {
    isMobile: boolean
}

export const useStyles = makeStyles<{ props: StyleProps }>()((theme, { props }) => ({
    schedulerContent: {
        width: "100%",
        minWidth: props.isMobile ? "auto" : "1024px",

        "& .bloc-date": {
            width: "100%",
            borderTop: "1px solid #E5EAF9",
            borderRight: "1px solid #E5EAF9",
            padding: props.isMobile ? 0 : "6px",
            gap: 1,
            minHeight: props.isMobile ? "64px" : "80px",
            "@media (min-width: 786px)": {
                maxWidth: "calc(100% / 7 - 13px)",
            },
            "&:hover": {
                cursor: "pointer",
            },
            "& .btn-add-post": {
                width: "22px",
                minWidth: "22px",
                padding: 0,
                height: "22px",
                borderRadius: "100%",
                background: "rgba(1, 128, 255, 0.08)",
                boxShadow: "none",
                "& svg": {
                    width: 18,
                    height: 18,
                    "& *": {
                        color: theme.palette.primary.main,
                    },
                },
            },
        },
        "& .date-label": {
            width: "22px",
            height: "22px",
            borderRadius: "38px",
            gap: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
    },
}))
