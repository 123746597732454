import Icon from "@/components/base/Icon"
import useBusinessLogic from "@/hooks/businesses/useBusinessLogic"
import useContactsStore from "@/store/overview/contacts"
import { Box, Paper, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import OverviewTitle from "../title"

const BusinessAdditionalContact = ({ isLoading, linksRef }) => {
    const { t } = useTranslation()
    const { navigateTo } = useBusinessLogic()
    const { additionalPhone, email } = useContactsStore()

    return (
        <Paper
            elevation={2}
            className="clickable"
            id="contacts"
            data-e2e="contacts"
            data-testid="contacts"
            ref={linksRef}
            onClick={() => !isLoading && navigateTo("edit-contact")}
        >
            <OverviewTitle
                title={t("BUSINESSES.EDIT_CONTACT.TITLE.OVERVIEW")}
                // icon="link"
                isSecondTitle
                tooltipText={t("BUSINESSES.EDIT_LINKS.MAIN_TOOLTIP")}
            />
            {additionalPhone?.length > 0 && (
                <Box className="line-wrapper">
                    <Icon name="phone" />
                    <Box className="item-wrapper">
                        <Typography component={"p"} className="link-url">
                            {additionalPhone && additionalPhone[0]}
                        </Typography>
                        <Typography component={"p"} className="link-url">
                            {additionalPhone && additionalPhone[1]}
                        </Typography>
                    </Box>
                </Box>
            )}
            {email && (
                <Box className="line-wrapper">
                    <Icon name="mail" />
                    <Typography component={"span"} className="link-url">
                        {email}
                    </Typography>
                </Box>
            )}
        </Paper>
    )
}

export default BusinessAdditionalContact
