import { Paper, Skeleton, Stack } from "@mui/material"

export const SkeletonBlocChip = () => {
    return (
        <Paper elevation={2} sx={{ flex: 1, width: "100%" }}>
            <Skeleton variant="rectangular" width={100} height={20} />
            <Stack flexDirection={"row"} gap={1} alignItems={"center"} width="100%" flexWrap={"wrap"}>
                <Skeleton variant="rectangular" width={80} height={24} sx={{ borderRadius: 16 }} />
                <Skeleton variant="rectangular" width={80} height={24} sx={{ borderRadius: 16 }} />
            </Stack>
        </Paper>
    )
}
