import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()((theme) => ({
    drawer: { zIndex: 1 },
    container: {
        "& .title-wrapper": {
            display: "flex",
            flexDirection: "column",
            gap: 8,
            "@media (min-width: 768px)": {
                gap: 24,
            },
            "& .btn-add": {
                wordBreak: "break-word",
                display: "flex",
                gap: 8,
                fontSize: 15,
                padding: "8px 22px",
            },
        },
    },
}))

export default useStyles
