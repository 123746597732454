import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()((theme) => ({
    drawer: { zIndex: 1 },
    container: {
        padding: 0,
        width: "100%",
        boxShadow: "none",
        gap: 16,
        backgroundColor: theme.palette.background.default,
        "@media (min-width: 768px)": {
            height: "100%",
            width: "calc(100% - 251px)",
        },
        "& .children-wrapper": {
            padding: 8,
            display: "flex",
            flexDirection: "column",
            gap: 16,
            height: "100%",
            "@media (min-width: 768px)": {
                padding: 24,
                gap: 24,
            },
            "& form": {
                display: "flex",
                flexDirection: "column",
                borderRadius: 8,
                padding: 0,
                gap: 32,
                height: "100%",
                "& .inputs-wrapper": {
                    "& .MuiFormControl-root": {
                        padding: 0,
                        "& .MuiFormLabel-root": {
                            margin: 0,
                        },
                    },
                    "& .error": {
                        textAlign: "center",
                    },
                    "& h3": {
                        fontSize: 20,
                        color: "black",
                        fontWeight: 500,
                    },
                    "& .MuiBox-root": {
                        display: "flex",
                        flexDirection: "column",
                        gap: 8,
                    },
                },
                "& .MuiInputBase-root": {
                    "&:before": {
                        borderBottom: "1px solid rgba(91, 125, 216, 0.12) !important",
                    },

                    "&.MuiInput-underline:after": {
                        borderBottom: "1px solid rgba(91, 125, 216, 0.12)",
                    },
                },
                "& .MuiFormLabel-root": {
                    marginLeft: 12,
                    marginTop: 9,
                },
                "& input": {
                    height: 16,
                },
                "& .MuiFormControl-root": {
                    padding: "9px 12px 8px 12px",
                },
            },
        },
    },
}))

export default useStyles
