export const deleteModal = {
    textAlign: "left",
    "& .action-wrapper": {
        flexDirection: "row",
        gap: 2,
        "& button": {
            width: "50%",
            "@media (max-width: 767px)": {
                width: "100%",
            },
        },
    },
    "& p": {
        width: "100%",
        textAlign: "left",
        color: "action.active",
    },
    "& .title-wrapper": {
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        "& h2": {
            width: "100%",
            textAlign: "left",
        },
    },
}
