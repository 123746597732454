import { makeStyles } from "tss-react/mui"

export const mediaModalSx = {
    alignItems: "start",
    "& .title-wrapper": {
        "& button": {
            p: 0,
        },
    },
    "& .action-wrapper": {
        "& .primary": {
            display: "flex",
            gap: 1,
        },
    },
}

const useStyles = makeStyles()((theme) => ({
    drawer: { zIndex: 1 },
    container: {
        "& .search-wrapper": {
            padding: "8px",
            flexDirection: "row",
            "@media (min-width: 768px)": {
                padding: "8px 16px",
                justifyContent: "end",
            },
            "& .add-btn": {
                minWidth: "auto",
                height: 42,
                "@media (min-width: 992px)": {
                    padding: "8px 22px",
                    gap: 8,
                    maxWidth: 259,
                },
                "& span": {
                    display: "initial",
                },
            },
            "& .filter-btn": {
                padding: 0,
            },
            "& .MuiTextField-root": {
                width: "100%",
                "@media (min-width: 768px)": {
                    maxWidth: 411,
                },
            },
        },
        "& h2": {
            fontSize: 24,
        },
        "& .media-container": {
            "& .add-media": {
                width: 172,
                height: 172,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                "& span": {
                    color: theme.palette.primary.main,
                },
            },
            "& img": {
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: 8,
            },
            "& .img-wrapper": {
                position: "relative",
                "& .image-actions-local": {
                    display: "flex",
                    position: "absolute",
                    top: 8,
                    right: 8,
                    gap: 4,
                    zIndex: 1,
                    "& svg": { cursor: "pointer" },
                    "& .edit": {
                        color: theme.palette.common.white,
                    },
                    "& button": {
                        padding: 0,
                    },
                },
                "& .image-actions": {
                    display: "none",
                    position: "absolute",
                    top: 8,
                    right: 8,
                    gap: 4,
                    zIndex: 1,
                    "& svg": { cursor: "pointer" },
                    "& .edit": {
                        color: theme.palette.common.white,
                    },
                    "& button": {
                        padding: 0,
                    },
                },
                "&:hover": {
                    "& .image-actions": {
                        display: "flex",
                    },
                    "&::after": {
                        content: "''",
                        background: "linear-gradient(0deg, rgba(0, 4, 31, 0.48) 0%, rgba(0, 4, 31, 0.48) 100%)",
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        left: 0,
                        top: 0,
                        borderRadius: 8,
                    },
                },
                "& span.MuiTypography-root": {
                    position: "absolute",
                    left: 4,
                    top: 4,
                    fontSize: 13,
                    padding: "3px 10px",
                    backgroundColor: theme.palette.text.primary,
                    color: theme.palette.common.white,
                    borderRadius: 100,
                },
                "& .play": {
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                    // zIndex: 1,
                },
            },
        },
    },
}))

export default useStyles
