import { DispatchrService } from "../"
import { ErrorResponse } from "../base"

interface ResponsePlateform {
    metadata: Metadata
    data: PlatformInfo[]
}

export interface PlatformInfo {
    meta?: Meta | Meta2 | Meta3 | Meta4 | any[]
    name?: string
    url?: string
    countries?: string[]
    entityTypes?: string[]
    createdAt?: string
    updatedAt?: string
    logoUrl?: string
    id?: string
    delay?: string
    infos?: Info
    statusFlow?: any
    status?: string
    order?: number
    parentId?: string
    country?: string[]
}

interface Info {
    locationInfos: string[] | LocationInfos2
    opening: boolean
    pictures: boolean
    posts: boolean
}

interface LocationInfos2 {
    Name: string
    Address: string
    telephone: string
    categories: string
    description: string
}

interface Meta4 {
    facebook_id: string
    source: string
}

interface Meta3 {
    source: string
}

interface Meta2 {
    yext_id: string
    source: string
}

interface Meta {
    dilypse_id: string
    source: string
}

interface Metadata {
    total: number
}

class PlatformApi {
    fetchAll = async (ids: string[]): Promise<ResponsePlateform & Partial<ErrorResponse>> => {
        return await DispatchrService.post(`/platform`, { ids })
    }
}

export default new PlatformApi()
