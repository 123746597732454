import { makeStyles } from "tss-react/mui"

const useStyles = ({ isClickable = false }: { isClickable?: boolean }) =>
    makeStyles()((theme) => ({
        blocReviews: {
            display: "flex",
            justifyContent: "flex-end",
            "& .bloc-review": {
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gap: 16,

                "@media (max-width: 1080px)": {
                    display: "grid",
                    gridTemplateColumns: "calc(50% - 8px) calc(50% - 8px)",
                    gap: 16,
                },
                "@media (max-width: 600px)": {
                    display: "flex",
                    gap: 16,
                },
            },
            "& .MuiToolbar-root": {
                gap: 26,
            },
            "& .table-pagination-actions-wrapper": {
                display: "flex",
                "& button": {
                    padding: 12,
                },
            },
        },
        paperOverviews: {
            display: "flex",
            justifyContent: "space-between",
            padding: "0px 16px",
            "@media (max-width: 767px)": {
                padding: "0px 8px",
            },
            "&:hover": {
                cursor: isClickable ? "pointer" : "not-allowed",
                boxShadow:
                    isClickable && "0px 1px 18px 0px rgba(0, 4, 31, 0.16), 0px 6px 10px 0px rgba(0, 4, 31, 0.16)",
            },

            "& .separator": {
                borderWidth: 1,
                margin: "0px -8px",
                "@media (min-width: 768px)": {
                    margin: "0px -16px",
                },
            },
        },
    }))

export default useStyles
