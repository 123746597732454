import Icon from "@/components/base/Icon"
import { Chip } from "@mui/material"

const ScoreChip = ({ icon, label, onClick }) => (
    <Chip
        sx={{
            p: 0.5,
            cursor: "pointer",
            color: "action.active",
            "& .MuiChip-label": {
                p: "3px 6px",
                color: "text.primary",
            },
            "& .MuiChip-icon": { mx: 0, color: "inherit", width: 24, height: 24 },
        }}
        data-testid="chip-item"
        icon={<Icon name={icon} />}
        label={label}
        onClick={onClick}
    />
)

export default ScoreChip
