import { makeStyles } from "tss-react/mui"

export const useStyles = makeStyles()((theme) => ({
    formBloc: {
        "& .bloc-inputs": {
            display: "flex",
            flexDirection: "row",
            gap: 8,
            "@media (max-width: 767px)": {
                flexDirection: "column",
            },
        },
        "& .import-bloc": {
            width: "200px",
            "@media (max-width: 767px)": {
                width: "100%",
            },
        },
    },
}))
