import { ServiceSection } from "@/store/location"

import { create } from "zustand"
interface Meta {
    gmb_id: string
}
interface ServiceType {
    meta?: Meta
    locationId?: string
    label?: string
    title?: string
    description?: string
    language?: string
    currency?: string
    sections?: ServiceSection[]
    createdAt?: string
    id?: string
    updatedAt?: any
}

export interface IServices {
    services: ServiceType
}

export interface IOverviewServicesStoreSetter {
    updateServices: (update: Partial<IServices>) => void
    getInitialState: () => IServices
    flush: () => void
}

const initalState = {
    services: {} as ServiceType,
}

const useBusinessServicesStore = create<IServices & IOverviewServicesStoreSetter>((set) => ({
    ...initalState,
    updateServices: (update) => set(() => ({ ...update })),
    getInitialState: () => initalState,
    flush: () => set(initalState),
}))

export default useBusinessServicesStore
