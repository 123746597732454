import { ResellerApi } from "@/api"
import { chartData } from "@/data/pie"
import useLocation from "@/hooks/useLocation"
import UserSessionStore from "@/store/user-session"
import { Ref, useCallback, useEffect, useMemo, useState } from "react"
import OverviewScoreStore from "."

type UseScoreStoreProps = {
    mainInfoRef: Ref<any>
    descriptionRef: Ref<any>
    attributRef: Ref<any>
    categoriesRef: Ref<any>
    servicesRef: Ref<any>
    serviceAreaRef: Ref<any>
    regularHoursRef: Ref<any>
    specialHoursRef: Ref<any>
    linksRef: Ref<any>
    contactRef: Ref<any>
    mediaRef: Ref<any>
}

const useScoreStore = ({
    mainInfoRef,
    descriptionRef,
    attributRef,
    categoriesRef,
    servicesRef,
    serviceAreaRef,
    regularHoursRef,
    specialHoursRef,
    linksRef,
    contactRef,
    mediaRef,
}: Partial<UseScoreStoreProps>) => {
    const { location } = useLocation()
    const [missingItem, setMissingItem] = useState([])
    const [series, setSeries] = useState([0, 0])
    const [missings, setMissings] = useState([])
    const [chartDonutData, setChartDonutData] = useState(chartData)
    const { profile } = UserSessionStore()
    const { completionScore, missingInformation, update: updateScore } = OverviewScoreStore()

    const sections = useMemo(() => {
        return [
            {
                icon: "annotationInfo",
                id: "mainInfo",
                ref: mainInfoRef,
                items: ["name", "country", "address", "phone", "logo"],
                missing: 0,
                label: "USERS.MAIN_INFO",
            },
            {
                icon: "profile",
                id: "description",
                ref: descriptionRef,
                items: ["description"],
                missing: 0,
                label: "BUSINESSES.DESCRIPTION",
            },
            {
                icon: "bulletList",
                id: "attributs",
                ref: attributRef,
                items: ["attributeIds"],
                missing: 0,
                label: "BUSINESSES.ATTRIBUTE_TITLE",
            },
            {
                icon: "category",
                id: "categories",
                ref: categoriesRef,
                items: ["primary_category", "secondary_category"],
                missing: 0,
                label: "BUSINESSES.CATEGORIES",
            },
            {
                icon: "coinsHand",
                id: "services",
                ref: servicesRef,
                items: ["service"],
                missing: 0,
                label: "BUSINESSES.SERVICES",
            },
            {
                icon: "tag",
                id: "services-area",
                ref: serviceAreaRef,
                items: ["serviceArea"],
                missing: 0,
                label: "BUSINESSES.AREAS_SERVED",
            },
            {
                icon: "calendar",
                id: "regular-hours",
                ref: regularHoursRef,
                items: ["regularHours"],
                missing: 0,
                label: "BUSINESSES.SCHEDULE.STANDARD_OPENING",
            },
            {
                icon: "calendar",
                id: "special-hours",
                ref: specialHoursRef,
                items: ["specialHours"],
                missing: 0,
                label: "BUSINESSES.SCHEDULE.EXCEPTIONAL_OPENING",
            },
            {
                icon: "link",
                id: "links",
                ref: linksRef,
                items: ["websiteUrl", "urlAppointment", "socialNetwork"],
                missing: 0,
                label: "BUSINESSES.LINK",
            },
            {
                icon: "phone",
                id: "contacts",
                ref: contactRef,
                items: ["additionalPhone", "email"],
                missing: 0,
                label: "BUSINESSES.EDIT_CONTACT.TITLE.OVERVIEW",
            },
            {
                icon: "imagePlaceholder",
                id: "medias",
                ref: mediaRef,
                items: ["media"],
                missing: 0,
                label: "MEDIA.TITLE",
            },
        ]
    }, [
        attributRef,
        categoriesRef,
        contactRef,
        descriptionRef,
        linksRef,
        mainInfoRef,
        mediaRef,
        regularHoursRef,
        serviceAreaRef,
        servicesRef,
        specialHoursRef,
    ])

    const scoreMapping = useCallback((missingItems = [], _sections) => {
        if (missingItems.includes) {
            return _sections.map((section) => {
                section.missing = section.items.filter((element) => missingItems.includes(element)).length
                return section
            })
        }
    }, [])

    const refreshScoring = useCallback(async () => {
        if (location?.id && profile?.uid) {
            const response = await ResellerApi.fetchLocationInfo({
                uid: location.id,
                user_uid: profile.uid,
            })
            updateScore({
                completionScore: response?.locationState?.completionScore || 0,
                missingInformation: response?.locationState?.missingInformation,
            })
            return response
        }
    }, [location?.id, profile?.uid, updateScore])

    const resetState = useCallback(() => {
        setSeries([0, 0])
        setMissingItem([])

        const newData = {
            ...chartData,
            ...{
                fill: {
                    colors: ["fff", "#fff"],
                },
            },
        }
        setMissings([])
        setChartDonutData(newData)
    }, [])

    const getColorScore = useCallback((score: number) => {
        let color = ""
        if (score <= 33) {
            color = "#F46259"
        } else if (score > 33 && score <= 66) {
            color = "#FFC431"
        } else {
            color = "#03CC6C"
        }
        return color
    }, [])

    useEffect(() => {
        updateScore({
            completionScore: location?.locationState?.completionScore || 0,
            missingInformation: location?.locationState?.missingInformation,
        })
    }, [location?.locationState?.completionScore, location?.locationState?.missingInformation, updateScore])

    useEffect(() => {
        if (completionScore) {
            setMissingItem(missingInformation || [])
            if (missingInformation) {
                const missingScore = scoreMapping(Object.values(missingInformation) || [], sections)
                setMissings(missingScore)
            }
            setSeries([completionScore, 100 - completionScore])

            const newData = {
                ...chartData,
                ...{
                    fill: {
                        colors: [getColorScore(completionScore), "#fff"],
                    },
                },
            }
            setChartDonutData(newData)
        }
    }, [getColorScore, completionScore, missingInformation, scoreMapping, sections])

    return {
        scoreMapping,
        getColorScore,
        missingItem,
        series,
        missings,
        chartDonutData,
        refreshScoring,
        setSeries,
        setMissingItem,
        resetState,
        sections,
    }
}

export default useScoreStore
