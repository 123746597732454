import LocationStore, { ILocationSetter, ILocationStore } from "@/store/location"
class LocationService {
    getState = (): ILocationStore & ILocationSetter => {
        return LocationStore.getState()
    }

    setState = (up: Partial<ILocationStore>): void => {
        const { update } = this.getState()
        update(up)
    }

    clearLocation = () => {
        this.setState({
            location: null,
            currentLocationId: "",
        })
    }
}

export default new LocationService()
