import { SxProps } from "@mui/material"
import { makeStyles } from "tss-react/mui"

export const menuSx: SxProps = {
    "& .MuiBackdrop-root": { opacity: "0 !important" },
    "& .MuiPaper-root": {
        padding: 1,
        "& ul": {
            display: "flex",
            flexDirection: "column",
            gap: 1,
            padding: 0,
            "& li": {
                padding: 0,
                gap: 1,
                fontSize: 15,
                minHeight: 26,
                "& span": { height: 26 },
            },
        },
    },
}

const useStyles = makeStyles()((theme) => ({
    container: {
        gap: 16,
        "@media (min-width: 768px)": {
            width: "100%",
            gap: 24,
        },
    },
    wrapperBloc: {
        flexWrap: "wrap",
        alignItems: "center",
        flexDirection: "row",
        padding: "8px",
        "@media (min-width: 768px)": {
            justifyContent: "space-between",
            padding: "8px 16px",
        },
        "& .btn-filter-left": {
            minWidth: "320px",
            flexWrap: "nowrap",
            "@media (max-width: 1023px)": {
                flexWrap: "wrap",
                width: "100%",
            },
        },
        "& .btn-filter-right": {
            flexDirection: "row",
            gap: 8,
            minWidth: "250px",
            flexWrap: "nowrap",
            "@media (max-width: 1023px)": {
                flexWrap: "wrap",
                minWidth: "100%",
                width: "100%",
            },
        },
        "& .MuiFormControl-root": {
            width: "100%",
            maxWidth: "320px",
            justifyContent: "space-between",
            alignContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
            "@media (max-width: 1023px)": {
                maxWidth: "100%",
                minWidth: "auto",
                "& .MuiFilledInput-root": {
                    width: "100%",
                },
            },
            "& .MuiFilledInput-input": {
                paddingRight: "0px !important",
            },
            "& .MuiOutlinedInput-root": {
                width: "100%",
            },
        },
    },
    views: {
        display: "grid",
        gap: 16,
        "@media (min-width: 1024px)": {
            gridTemplateColumns: "repeat(3, 1fr)",
        },
        "@media (min-width: 768px)": {
            gap: 24,
        },
        "& .item-view:nth-child(1)": {
            gridColumn: "auto / span 2",
            "@media (min-width: 1024px)": {
                gridColumn: "auto",
            },
        },
        "& .item-view": {
            color: theme.palette.common.white,
            gap: 8,
            padding: "8px 16px",
            "& .text-number": {
                color: "inherit",
                fontWeight: 400,
                fontFamily: "Poppins",
                width: "fit-content",
            },
            "& .text-nodata": {
                color: "inherit",
                fontWeight: 600,
                fontFamily: "Poppins",
                width: "fit-content",
                fontSize: 24,
            },
            "& .pourcentage": {
                width: "fit-content",
                height: "fit-content",
                borderRadius: 8,
                padding: "4px 8px",
                background: "#03CC6C",
                "&.no-data": {
                    background: "rgba(255, 255, 255, 0.24)",
                },
                "&.inferior": {
                    background: "#F46259",
                },
            },
        },
    },
}))

export default useStyles
