import Icon from "@/components/base/Icon"
import CustomTable from "@/components/table/partials/custom-table"
import { Box, SxProps, Typography, useTheme } from "@mui/material"
import { useMemo } from "react"

type IconStateProps = {
    checked: boolean
}

const IconState = ({ checked }: IconStateProps) => {
    const { palette } = useTheme()

    return (
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
            {checked && <Icon name="check" sx={{ color: palette.success.main }} />}
            {!checked && <Icon name="alert-triangle" sx={{ color: palette.error.main }} />}
        </Box>
    )
}

type TableSocialCheckProps = {
    rows: {
        name: string
        address: string
        facebook: boolean
        instagram: boolean
        google: boolean
    }[]
    providers?: string[]
}

const SocialsModalTableCheck = ({ rows, providers }: TableSocialCheckProps) => {
    const columns = useMemo(() => {
        const cols = [
            {
                label: "",
                id: "fiche",
                sx: {
                    padding: "8px 0 0 0",
                    maxWidth: "248px",
                } as SxProps,
                component: ({ row }) => {
                    return (
                        <>
                            <Typography
                                variant="body1"
                                color="text.primary"
                                whiteSpace={"nowrap"}
                                textOverflow={"ellipsis"}
                                overflow={"hidden"}
                            >
                                {row.name}
                            </Typography>
                            <Typography
                                variant="overline"
                                fontWeight={600}
                                color="text.secondary"
                                whiteSpace={"nowrap"}
                                textOverflow={"ellipsis"}
                                overflow={"hidden"}
                            >
                                {row.address}
                            </Typography>
                        </>
                    )
                },
            },
            {
                label: <Icon name="facebookSocial" />,
                id: "provider-facebook",
                width: "60px",
                component: ({ row }) => {
                    return <IconState checked={row.facebook} />
                },
            },
            {
                label: <Icon name="instagramSocial" />,
                id: "provider-instagram",
                width: "60px",
                component: ({ row }) => {
                    return <IconState checked={row.instagram} />
                },
            },
            {
                label: <Icon name="google" />,
                id: "provider-google",
                width: "60px",
                component: ({ row }) => {
                    return <IconState checked={row.google} />
                },
            },
        ]

        return cols.filter((col) => {
            if (col.id.startsWith("provider-")) {
                return providers?.includes(col.id.replace("provider-", ""))
            }
            return true
        })
    }, [providers])

    return (
        <CustomTable
            tableCellRootSx={{
                backgroundColor: "unset",
                padding: 0,
                height: "40px",
                textAlign: "center",
            }}
            columns={columns}
            rows={rows}
        />
    )
}

export default SocialsModalTableCheck
