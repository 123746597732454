import { makeStyles } from "tss-react/mui"

export const useStyles = makeStyles()((theme) => ({
    statistic: {
        gap: 16,
        "@media (min-width: 1024px)": {
            gap: 24,
        },
        "& .bloc-number-stat": {
            display: "grid",
            padding: 0,
            gap: 16,
            "@media (min-width: 1024px)": {
                gap: 24,
                gridTemplateColumns: "repeat(3, 1fr)",
            },
            "& .grid-item:nth-child(1)": {
                gridColumn: "auto / span 2",
                "@media (min-width: 1024px)": {
                    gridColumn: "auto",
                },
            },
            "& .grid-item": {
                gap: 16,
                padding: "8px 16px",
            },
        },
    },
}))
