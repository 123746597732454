import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()((theme) => ({
    container: {
        top: "0",
        position: "absolute",
        "& .MuiModal-backdrop": {
            top: "0",
        },
        "& . MuiPaper-elevation1": {
            maxWidth: 490,
            alignItems: "flex-start",
        },
        "& #close": {
            fill: "#0180FF",
            color: "#0180FF",
            cursor: "pointer",
            width: 35,
            height: 35,
            padding: 0,
            marginLeft: "auto",
            "& svg": {
                width: "100%",
                height: "100%",
            },
        },
        "& .title-wrapper": {
            flexDirection: "row",
            alignItems: "center",
            alignContent: "center",
        },
    },
}))

export default useStyles
