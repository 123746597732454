import Icon from "@/components/base/Icon"
import { Box, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

const BusinessSocial = ({ showFacebookChip, needsReconnection }) => {
    const { t } = useTranslation()

    return (
        <Box className="social">
            {showFacebookChip && (
                <Box className="not-connected" sx={{ "& svg": { width: 24, height: 24 } }}>
                    <Icon name="unicolorFacebook" />
                    <Typography component="span">{t("BUSINESSES.CONNECT")}</Typography>
                </Box>
            )}
            {/* {showGoogleChip && (
                <Box className="not-connected">
                    <Icon name="unicolorGoogle" />
                    <Typography component="span">{t("BUSINESSES.CONNECT")}</Typography>
                </Box>
            )} */}
            {needsReconnection && (
                <Box className="not-connected reconnected">
                    <Icon name="unicolorGoogle" />
                    <Typography component="span" sx={{ color: "inherit" }}>
                        {t("BUSINESSES.RECONNECT_GOOGLE.TITLE_BUTTON")}
                    </Typography>
                </Box>
            )}
        </Box>
    )
}

export default BusinessSocial
