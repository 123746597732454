import { Paper, Skeleton, Stack } from "@mui/material"
import { useStyles } from "./styles"

export const SkeletonHeaderBtnCampaign = () => {
    const { classes } = useStyles()
    return (
        <Paper className={classes.header}>
            <Skeleton className="btn-left" variant="rectangular" height={56} />
            <Stack className="items-right">
                <Skeleton variant="rectangular" width={140} height={56} />
                <Skeleton variant="rectangular" width={100} height={56} />
            </Stack>
        </Paper>
    )
}
