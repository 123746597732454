import UiStore from "@/store/ui"
import { useMemo, useState } from "react"

const useEditMainInfo = () => {
    const [previousItem, setPreviousItem] = useState<any>({})
    const { preferredLanguage } = UiStore()

    const langOfCountryName = useMemo(() => {
        return preferredLanguage?.toLowerCase() || "fr-ca"
    }, [preferredLanguage])

    return {
        previousItem,
        langOfCountryName,
        setPreviousItem,
    }
}

export default useEditMainInfo
