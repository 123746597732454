import { Paper, Skeleton, Stack } from "@mui/material"

export const SkeletonBusinessItemHeader = () => {
    return (
        <Stack gap={3}>
            <Skeleton variant="rectangular" width={300} height={48} />

            <Paper
                sx={{
                    borderRadius: "16px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "end",
                    alignItems: "center",
                    padding: "8px 16px",
                }}
                data-testid="bloc-header"
            >
                <Skeleton variant="rectangular" width={200} height={52} />
            </Paper>
        </Stack>
    )
}
