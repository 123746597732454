import { SxProps } from "@mui/material"
import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()((theme) => ({
    wrapper: {
        "@media (min-width: 768px)": {
            padding: "8px 16px",
            flexDirection: "row",
            justifyContent: "space-between",
        },
        "& > .MuiFormControl-root": {
            width: "100%",
            "@media (min-width: 768px)": {
                maxWidth: 320,
            },
        },
    },
}))

export const radioTabButtonSx: SxProps = {
    display: "flex",
    alignItems: "center",
    gap: 1,
    flexWrap: "wrap",
    "@media (min-width: 768px)": {
        gap: 2,
        flexWrap: "nowrap",
    },
    "& .MuiTypography-root": {
        textWrap: "nowrap",
        margin: 0,
    },
}

export default useStyles
