import Icon from "@/components/base/Icon"
import { TitleWrapper } from "@/components/base/title-wrapper"
import EditModelContainer from "@/components/campaign/edit-model"
import useStyles from "@/components/campaign/styles"
import useLogic from "@/hooks/campaign/edit-model/useLogic"
import CampaignStore from "@/store/campaign"
import { PageProps } from "@/types/page"
import { Button, Paper, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { FormModel } from "../form-model"
import { ViewModel } from "../view-model"

const EditModel = ({ onClose, open, testUnit = false }: PageProps) => {
    const { t } = useTranslation()
    const { classes } = useStyles()
    const { listReviews } = CampaignStore()
    const { hasModel, showEdit, setShowEdit, handleDelete, onCloseNewEdit, loadingDelete } = useLogic()

    return (
        <EditModelContainer open={open} onClose={onClose}>
            <TitleWrapper handlePreviousButton={onClose} title={t("CAMPAIGN.MODEL.TITLE")} className="title-overview" />
            {hasModel && (
                <Paper className={classes.headerBlocAction}>
                    <Stack flexDirection={"row"} gap={1} className="header-btn">
                        <Button
                            className="new-model"
                            color="primary"
                            variant="contained"
                            disabled={showEdit}
                            onClick={() => {
                                setShowEdit(!showEdit)
                            }}
                        >
                            <Icon name={"plus"} />
                            <Typography data-testid="new-model" component="span">
                                {t("CAMPAIGN.MODEL.NEW_REQUEST")}
                            </Typography>
                        </Button>
                    </Stack>
                </Paper>
            )}
            {(hasModel === false || showEdit) && <FormModel onCancel={onCloseNewEdit} />}
            {hasModel &&
                listReviews.map((item) => {
                    return <ViewModel key={item.id} loading={loadingDelete} item={item} handleDelete={handleDelete} />
                })}
        </EditModelContainer>
    )
}

export default EditModel
