import { create } from "zustand"

export interface IContactsStore {
    additionalPhone: string[]
    email: string
}

const initalState: IContactsStore = {
    additionalPhone: [],
    email: "",
}

export interface IContactsSetter {
    update?: (update: Partial<IContactsStore>) => void
    flush?: () => void
}

const useContactsStore = create<IContactsStore & IContactsSetter>((set) => ({
    ...initalState,
    update: (update) => set(() => ({ ...update })),
    getInitialState: () => initalState,
    flush: () => set(initalState),
}))

export default useContactsStore
