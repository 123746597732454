import UiStore from "@/store/ui"
import { checkDisplayOfDate } from "@/utils"
import { Stack, TableCell, TableRow, Typography } from "@mui/material"
import dayjs from "dayjs"
import { TFunction } from "i18next"
import { useMemo } from "react"

interface BusinessScheduleRowType {
    index: number
    t: TFunction<"translation", undefined>
    v: any
    type?: string
}

const BusinessScheduleRow = ({ index, v, t, type = "standard" }: BusinessScheduleRowType) => {
    const { preferredLanguage } = UiStore()
    const isOldDate = useMemo(() => {
        let value = false
        if (type === "exceptional") {
            const sameDate =
                dayjs().isSame(`${v.startDate}`, "y") &&
                dayjs().isSame(`${v.startDate}`, "M") &&
                dayjs().isSame(`${v.startDate}`, "d") &&
                v.isAvailableAllDay
            const beforeDate = dayjs().isBefore(dayjs(`${v.startDate}`)) && !v.isAvailableAllDay
            if (
                (!sameDate || beforeDate) &&
                dayjs(`${v.startDate} ${v.times[v.times.length - 1]?.endTxt}`).isBefore(dayjs())
            ) {
                value = true
            }
        }
        return value
    }, [type, v])

    const { time, otherItems } = useMemo(() => {
        const time = v.times[0]
        let otherItems = 0
        if (v.times?.length > 0) {
            otherItems = v.times.length - 1
        }

        return { time, otherItems }
    }, [v, type])
    return (
        <>
            {!isOldDate && (
                <TableRow className="flex justify-between">
                    <TableCell className="schedule-wrapper">
                        <Typography component={"span"} sx={{ color: "#68738D", textTransform: "capitalize" }}>
                            {type === "exceptional"
                                ? checkDisplayOfDate(v.startDate, preferredLanguage)
                                : t(`BUSINESSES.SCHEDULE.${v?.name?.toUpperCase()}`)}
                        </Typography>
                    </TableCell>
                    <TableCell className={`hours-wrapper ${preferredLanguage === "en" ? "w-en" : "w-fr"}`}>
                        {v.isAvailableAllDay && (
                            <Typography key={index} component={"span"} color="text.primary">
                                {t(`BUSINESSES.SCHEDULE.24H`)}
                            </Typography>
                        )}
                        {!v.isAvailableAllDay && v.isOpen && time?.start && time?.end && (
                            <Stack flexDirection="row">
                                <Typography component={"span"} color="text.primary">
                                    {time.startTxt} - {time.endTxt}{" "}
                                </Typography>
                                {otherItems > 0 && (
                                    <Typography component={"span"} color="primary.main">
                                        +{otherItems}
                                    </Typography>
                                )}
                            </Stack>
                        )}
                        {!v.isOpen && (
                            <Typography key={index} component={"span"}>
                                {t(`BUSINESSES.SCHEDULE.CLOSE`)}
                            </Typography>
                        )}
                    </TableCell>
                </TableRow>
            )}
        </>
    )
}

export default BusinessScheduleRow
