import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()((theme) => ({
    drawer: { zIndex: 1 },
    container: {
        "& .title": {
            display: "flex",
            gap: 8,
            alignItems: "center",
            "& .title-icon": {
                color: theme.palette.action.active,
            },
        },
        "& .inputs-wrapper": {
            height: "fit-content",
            "& .error": {
                textAlign: "center",
            },
            "& .wrapper": {
                display: "flex",
                flexDirection: "column",
                gap: 8,
                "& .input-adorment-fixed": {
                    "& textarea": {
                        padding: "0px 12px",
                    },
                    "& .MuiFilledInput-root": {
                        padding: "16px 12px",
                        gap: 8,
                    },
                    "& input": {
                        paddingTop: "0px",
                        paddingRight: "12px",
                        paddingBottom: "0px",
                        paddingLeft: "0",
                    },
                },
                "& .has-adornment": {
                    "& textarea": {
                        padding: "0px",
                    },
                    "& .MuiFilledInput-root": {
                        padding: "25px 12px 8px",
                    },
                    "& input": {
                        paddingTop: "0px",
                        paddingRight: "12px",
                        paddingBottom: "0px",
                        paddingLeft: "0",
                    },
                },
            },
            "& .title": {
                display: "flex",
                gap: 8,
                alignItems: "center",
                "& .title-icon": {
                    color: theme.palette.action.active,
                },
            },
            "& .has-adornment .MuiInputBase-root": {
                gap: 8,
                "& svg": {
                    width: 23,
                    height: 23,
                    margin: "auto",
                },
                "& .div-img": {
                    width: "24px",
                    height: "23px",
                    display: "flex",
                    alignItems: "center",
                    "& img": {
                        borderRadius: "2px",
                    },
                    "&.youtube": {
                        marginBottom: 12,
                    },
                },
            },
            "& .switch-button-wrapper": {
                display: "flex",
                alignItems: "center",
                gap: 8,
                "& .MuiIconButton-root": {
                    padding: 0,
                },
                "& svg": {
                    color: theme.palette.action.active,
                },
            },
            "& .inputs": {
                display: "flex",
                flexDirection: "column",
                gap: 8,
                "@media (min-width: 768px)": {
                    gap: 16,
                    flexDirection: "row",
                    flexWrap: "wrap",
                },
                "& > *": {
                    "@media (min-width: 768px)": {
                        width: "calc(50% - 8px)",
                        "&:nth-of-type(3)": {
                            width: "100%",
                        },
                    },
                },
            },
            "&.address": {
                "& .title-wrapper": {
                    display: "flex",
                    flexDirection: "column",
                    gap: 8,
                    "@media (min-width: 768px)": {
                        alignItems: "center",
                        justifyContent: "space-between",
                        flexDirection: "row",
                    },
                },
                "& .inputs": {
                    "@media (min-width: 768px)": {
                        gap: 16,
                        flexDirection: "row",
                        flexWrap: "wrap",
                    },
                    "& > *": {
                        "@media (min-width: 768px)": {
                            width: "calc(50% - 8px)",
                            "&:nth-of-type(3), &:nth-of-type(4), &:nth-of-type(5)": {
                                width: "calc((100% - 32px) / 3)",
                            },
                        },
                    },
                    "&.half-each > *": {
                        "@media (min-width: 768px)": {
                            width: "calc(50% - 8px)",
                        },
                    },
                },
            },
        },
        "& h2": {
            display: "flex",
            gap: 16,
            alignItems: "center",
            fontSize: 24,
            color: theme.palette.common.black,
            padding: "0 8px",
            fontWeight: 500,
            "& svg": {
                color: theme.palette.action.active,
            },
        },
    },
}))

export default useStyles
