import { create } from "zustand"
import { UserType } from "@/types/user"

interface IUsersStore {
    selectedUser: UserType
    users: UserType[]
    refreshUsers: boolean
    companies: { label: string; value: string }[]
    locations: { label: string; value: string }[]
    companiesFetched: boolean
    locationsFetched: boolean
}

export interface IUsersStoreSetter {
    update: (value: Partial<IUsersStore>) => void
    flush: () => void
}

const initalState: IUsersStore = {
    selectedUser: null,
    users: [],
    companies: [],
    locations: [],
    companiesFetched: false,
    locationsFetched: false,
    refreshUsers: true,
}

const UsersStore = create<IUsersStore & IUsersStoreSetter>((set) => ({
    ...initalState,
    update: (newValue: any = {}) => set(() => ({ ...newValue })),
    flush: () => set(initalState),
}))

export default UsersStore
