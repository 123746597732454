import { useCallback, useMemo, useState } from "react"
import Icon from "@/components/base/Icon"
import { Box, IconButton, Menu, MenuItem } from "@mui/material"
import "dayjs/locale/en"
import "dayjs/locale/fr"
import { menuSx } from "./styles"
import { useTranslation } from "react-i18next"

export const ActionBtn = ({ index, duplicateCurrentItem, deleteCurrentItem, day }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const { t } = useTranslation()
    const open = useMemo(() => Boolean(anchorEl), [anchorEl])
    const handleClose = useCallback(() => {
        setAnchorEl(null)
    }, [setAnchorEl])

    const toggleMenu = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(!open ? event.currentTarget : null)
        },
        [setAnchorEl, open]
    )

    return (
        <Box>
            <IconButton onClick={toggleMenu}>
                <Icon name="threeDotsHorizontal" />
            </IconButton>
            <Menu
                elevation={6}
                id={"basic-menu-" + index}
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                sx={menuSx}
            >
                <MenuItem
                    onClick={() => {
                        handleClose()
                        duplicateCurrentItem(day, index)
                    }}
                    className="clickable"
                >
                    <Icon name="copy" /> {t("BUSINESSES.SCHEDULE.DUPLICATE")}
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleClose()
                        deleteCurrentItem(index)
                    }}
                    className="clickable"
                >
                    <Icon name="delete1" sx={{ color: "error.main" }} /> {t("USERS.DELETE.BUTTON")}
                </MenuItem>
            </Menu>
        </Box>
    )
}
