export const styleSx = {
    width: "100%",
    paddingRight: 0,

    "& .first-selected": {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        display: "block",
    },
}
