import { Box } from "@mui/material"
import { PropsWithChildren, ReactNode } from "react"
import useStyles from "./styles"

export interface PropsOverview {
    children?: PropsWithChildren<ReactNode>
    needReco?: any
}

const OverviewContainer = ({ children, needReco }: PropsOverview) => {
    const { classes } = useStyles()

    return (
        <Box
            className={classes.overviewContainer}
            data-testid="overview-container"
            sx={
                needReco
                    ? {
                          overflow: "hidden",
                      }
                    : {}
            }
        >
            {children}
        </Box>
    )
}

export default OverviewContainer
