import useStyles from "@/components/campaign/styles"
import { Box, Button, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import Icon from "../../Icon"
const CampaignSuccess = ({ onClose, message = "CAMPAIGN.SEND_SUCCESS", withNewRequest = true }) => {
    const { classes } = useStyles()
    const { t } = useTranslation()
    return (
        <Box className={`${classes.containerSuccess} campaign-success`}>
            <Box className="children-wrapper" data-e2e="success-campaign">
                <Stack className={`${classes.stepBloc} step-block`} gap={2}>
                    <Stack gap={2}>
                        <Stack textAlign={"center"} gap={1}>
                            <Icon name="success" sx={{ width: 240 }} />
                            <Typography variant="h5" sx={{ fontWeight: 500 }}>
                                {t(message)}
                            </Typography>
                        </Stack>
                        <Stack alignItems={"center"} gap={2}>
                            {withNewRequest && (
                                <Button sx={{ gap: 1, px: 2 }} className="primary" onClick={onClose}>
                                    <Icon name="plus" />
                                    {t("CAMPAIGN.SEND_NEW_REQUEST")}
                                </Button>
                            )}

                            <Link to={"/reputation"}>
                                <Typography component={"span"} className="primary" sx={{ fontWeight: 500 }}>
                                    {t("CAMPAIGN.CHECK_REVIEWS")}
                                </Typography>
                            </Link>
                        </Stack>
                    </Stack>
                </Stack>
            </Box>
        </Box>
    )
}

export default CampaignSuccess
