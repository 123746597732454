import Icon from "@/components/base/Icon"
import CustomTooltip from "@/components/base/Tooltip"
import { RenderInputEdit } from "@/components/list-table/cell-edit"
import usePhoneInputLogic from "@/hooks/phone-input/useLogic"
import useFormater from "@/hooks/useFormater"
import CampaignStore from "@/store/campaign"
import UiStore from "@/store/ui"
import { isValidEmail } from "@/utils"
import { Box, Button, IconButton, Stack } from "@mui/material"
import { CountryCode } from "libphonenumber-js"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Column } from "../partials/custom-table"

type ColumnProps = Column & {
    id: string
}

const RenderBtnAction = ({ toolTipDeleteText, handleDelete, handleEdit, index, toolTipEditText }) => {
    return (
        <Box className="table-action-wrapper">
            {handleDelete && (
                <CustomTooltip title={toolTipDeleteText}>
                    <IconButton onClick={() => handleDelete(index)} sx={{ width: "45px", height: "45px" }}>
                        <Icon name="delete1" sx={{ color: "error.main" }} />
                    </IconButton>
                </CustomTooltip>
            )}
            {handleEdit && (
                <CustomTooltip title={toolTipEditText}>
                    <IconButton
                        className="btn edit-btn"
                        onClick={() => handleEdit(index)}
                        sx={{ width: "45px", height: "45px" }}
                    >
                        <Icon name="edit" />
                    </IconButton>
                </CustomTooltip>
            )}
        </Box>
    )
}

type TableCampaignUploadedProps = {
    typeLabel: string
    isMobileView?: boolean
    campaignType?: "sms" | "email" | "text"
    countryCode?: CountryCode
}

const useTableCampaignUploaded = ({
    typeLabel,
    campaignType = "sms",
    isMobileView = false,
    countryCode = "CA",
}: TableCampaignUploadedProps) => {
    const { t } = useTranslation()
    const { pagination, contacts, update } = CampaignStore()
    const [editItem, setEditItem] = useState<any>(null)
    const { isPhoneValid } = usePhoneInputLogic()
    const [currentIndex, setCurrentIndex] = useState<number>(0)
    const { preferredLanguage } = UiStore()
    const { checkContactsIfExists, internationalPhone } = useFormater()

    const langOfCountryName = useMemo(() => {
        return preferredLanguage?.toLowerCase() || "fr-ca"
    }, [preferredLanguage])

    const handleChangePage = useCallback(
        (newPage: number) => {
            update({
                pagination: {
                    page_number: newPage,
                    rows: [...contacts].slice(
                        newPage * pagination.page_size,
                        newPage * pagination.page_size + pagination.page_size
                    ),
                    total_count: [...contacts].length,
                    page_size: 10,
                },
            })
        },
        [contacts, pagination?.page_size, update]
    )

    const generateIndex = useCallback(
        (index: number) => {
            return pagination?.page_number > 0 ? pagination?.page_size * pagination?.page_number + index : index
        },
        [pagination?.page_number, pagination?.page_size]
    )

    const handleDelete = useCallback(
        (index: number) => {
            const items = contacts || []
            const itemIndx = generateIndex(index)
            items.splice(itemIndx, 1)
            const pageNumber = items.length === pagination?.page_size ? 0 : pagination?.page_number
            update({
                contacts: items,
                pagination: {
                    total_count: items.length,
                    rows: items.slice(
                        pageNumber * pagination?.page_size,
                        pageNumber * pagination?.page_size + pagination?.page_size
                    ),
                    page_size: 10,
                    page_number: pageNumber,
                },
            })
        },
        [contacts, generateIndex, pagination?.page_number, pagination?.page_size, update]
    )

    const handleCancel = useCallback(() => {
        setEditItem(null)
        setCurrentIndex(0)
    }, [setEditItem])

    const handleEdit = useCallback(
        (index: number) => {
            const item = pagination?.rows?.[index]
            setEditItem(item)
            setCurrentIndex(generateIndex(index))
        },
        [generateIndex, pagination?.rows, setEditItem]
    )

    const handleSave = useCallback(
        (index: number) => {
            const items = contacts || []
            const itemIndx = generateIndex(index)
            delete editItem?.code
            if (items[itemIndx]) {
                items[itemIndx] = editItem
            }
            update({
                contacts: items,
                pagination: {
                    ...pagination,
                    rows: items.slice(
                        pagination?.page_number * pagination?.page_size,
                        pagination?.page_number * pagination?.page_size + pagination?.page_size
                    ),
                },
            })
            setEditItem(null)
        },
        [contacts, generateIndex, editItem, update, pagination]
    )

    const canUpdate = useMemo(() => {
        if (!editItem) {
            return true
        }
        // // Remove the current item from the contact list to allow the row to be editable
        const filteredContacts = contacts.filter((_, index) => ![currentIndex].includes(index))
        // The user cannot enter an email or phone number that already exists
        if (
            checkContactsIfExists({
                rows: filteredContacts,
                type: campaignType,
                value: editItem[typeLabel],
                typeLabel,
            })
        ) {
            return false
        }
        if (campaignType === "sms") {
            return isPhoneValid(editItem[typeLabel] || "") && !/^\+\d{1,4}$/.test(editItem[typeLabel] || "")
        }
        return editItem[t("LABEL.EMAIL")] && isValidEmail(editItem[t("LABEL.EMAIL")] || "")
    }, [editItem, contacts, checkContactsIfExists, campaignType, typeLabel, t, currentIndex, isPhoneValid])

    const columns: readonly ColumnProps[] = useMemo(() => {
        return [
            {
                id: t("CAMPAIGN.FIRSTNAME"),
                label: t("CAMPAIGN.FIRSTNAME"),
                width: "33.33%",
                hidden: isMobileView,
                component: ({ row, column }) => {
                    if (row.uid === editItem?.uid) {
                        return (
                            <Stack sx={{ width: "100%" }} key={`${row.uid}_${column.id}`} p={0}>
                                <RenderInputEdit
                                    value={editItem ? editItem[column.id] : ""}
                                    index={column.id}
                                    langOfCountryName={langOfCountryName}
                                    setData={setEditItem}
                                    type="text"
                                />
                            </Stack>
                        )
                    }
                    return <Box key={`${row.uid}_${column.id}`}>{row?.[t("CAMPAIGN.FIRSTNAME")]}</Box>
                },
            },
            {
                id: t("CAMPAIGN.LASTNAME"),
                label: t("CAMPAIGN.LASTNAME"),
                width: "33.33%",
                hidden: isMobileView,
                component: ({ row, column }) => {
                    if (row.uid === editItem?.uid) {
                        return (
                            <Stack sx={{ width: "100%" }} p={0}>
                                <RenderInputEdit
                                    value={editItem ? editItem[column.id] : ""}
                                    index={column.id}
                                    langOfCountryName={langOfCountryName}
                                    setData={setEditItem}
                                    type="text"
                                />
                            </Stack>
                        )
                    }
                    return <Box>{row?.[t("CAMPAIGN.LASTNAME")]}</Box>
                },
            },
            {
                id: typeLabel,
                label: typeLabel,
                width: "33.33%",
                hidden: isMobileView,
                component: ({ row, column }) => {
                    if (row.uid === editItem?.uid) {
                        return (
                            <Stack sx={{ width: "100%" }} p={0}>
                                <RenderInputEdit
                                    value={editItem ? editItem?.[column.id] : ""}
                                    index={column.id}
                                    langOfCountryName={langOfCountryName}
                                    setData={setEditItem}
                                    type={campaignType}
                                />
                            </Stack>
                        )
                    }
                    if (campaignType === "sms") {
                        return <Box>{internationalPhone(row?.[typeLabel], countryCode)}</Box>
                    }
                    return <Box>{row?.[typeLabel]}</Box>
                },
            },
            {
                id: "",
                label: "",
                width: "100%",
                hidden: !isMobileView,
                component: ({ row, rowIndex }) => {
                    if (row.uid === editItem?.uid) {
                        return (
                            <>
                                <Stack gap={1} marginY={1}>
                                    {Object.keys(row).map(
                                        (key: string, index: number) =>
                                            key !== "uid" && (
                                                <RenderInputEdit
                                                    value={editItem ? editItem[key] : ""}
                                                    key={`${String(index)}`}
                                                    index={key}
                                                    langOfCountryName={langOfCountryName}
                                                    setData={setEditItem}
                                                    type={key === typeLabel ? campaignType : "text"}
                                                />
                                            )
                                    )}
                                </Stack>
                                <Stack flexDirection={"row"} gap={1} justifyContent={"flex-end"}>
                                    <CustomTooltip disableTouchListener title={t("CAMPAIGN.BACK")} followCursor>
                                        <Button
                                            variant="outlined"
                                            className="btn btn-cancel"
                                            sx={{ boxShadow: "none", gap: 1 }}
                                            onClick={() => {
                                                handleCancel()
                                            }}
                                        >
                                            <Icon name="cancel" /> {t("CAMPAIGN.BACK")}
                                        </Button>
                                    </CustomTooltip>
                                    <CustomTooltip disableTouchListener title={t("CAMPAIGN.SAVE")} followCursor>
                                        <Button
                                            variant="contained"
                                            className="btn btn-save"
                                            sx={{ boxShadow: "none", gap: 1 }}
                                            onClick={() => handleSave(rowIndex)}
                                            disabled={!canUpdate}
                                        >
                                            <Icon name="save" /> {t("CAMPAIGN.SAVE")}
                                        </Button>
                                    </CustomTooltip>
                                </Stack>
                            </>
                        )
                    }
                    return (
                        <Stack flexDirection={"row"} alignItems={"center"}>
                            <Stack gap={1} flexGrow={1}>
                                <Box>{row?.[t("CAMPAIGN.FIRSTNAME")]}</Box>
                                <Box>{row?.[t("CAMPAIGN.LASTNAME")]}</Box>
                                <Box>{row?.[typeLabel]}</Box>
                            </Stack>
                            <Stack flexDirection={"row"} justifyContent={"end"}>
                                <RenderBtnAction
                                    toolTipDeleteText={t("CAMPAIGN.REMOVE")}
                                    handleDelete={handleDelete}
                                    handleEdit={handleEdit}
                                    index={rowIndex}
                                    toolTipEditText={t("CAMPAIGN.MODIFY")}
                                />
                            </Stack>
                        </Stack>
                    )
                },
            },
            {
                id: "",
                label: "",
                width: "175px",
                hidden: isMobileView,
                component: ({ row, rowIndex }) => {
                    if (editItem?.uid === row?.uid) {
                        return (
                            <Stack gap={1} flexDirection={"row"} p={0}>
                                <CustomTooltip disableTouchListener title={t("CAMPAIGN.BACK")} followCursor>
                                    <Button
                                        className="btn btn-cancel"
                                        onClick={() => {
                                            handleCancel()
                                        }}
                                    >
                                        <Icon name="cancel" />
                                    </Button>
                                </CustomTooltip>
                                <CustomTooltip disableTouchListener title={t("CAMPAIGN.SAVE")} followCursor>
                                    <Button
                                        className="btn btn-save"
                                        onClick={() => handleSave(rowIndex)}
                                        disabled={!canUpdate}
                                        variant="contained"
                                    >
                                        <Icon name="save" />
                                    </Button>
                                </CustomTooltip>
                            </Stack>
                        )
                    }
                    return (
                        <Stack flexDirection={"row"} justifyContent={"end"} p={0}>
                            <RenderBtnAction
                                toolTipDeleteText={t("CAMPAIGN.REMOVE")}
                                handleDelete={handleDelete}
                                handleEdit={handleEdit}
                                index={rowIndex}
                                toolTipEditText={t("CAMPAIGN.MODIFY")}
                            />
                        </Stack>
                    )
                },
            },
        ]
    }, [
        t,
        isMobileView,
        typeLabel,
        editItem,
        langOfCountryName,
        campaignType,
        internationalPhone,
        countryCode,
        handleDelete,
        handleEdit,
        canUpdate,
        handleCancel,
        handleSave,
    ])

    useEffect(() => {
        if (contacts?.length === 0) {
            setEditItem(null)
        } else {
            handleChangePage(pagination?.page_number || 0)
        }
    }, [contacts?.length, setEditItem])

    return {
        columns,
        page: pagination?.page_number,
        totalCount: pagination?.total_count,
        rowsPerPage: pagination?.page_size,
        rows: pagination?.rows || [],
        handleChangePage,
        handleDelete,
        handleEdit,
        currentIndex,
        editItem,
        canUpdate,
        handleCancel,
        handleSave,
    }
}

export default useTableCampaignUploaded
