import { UserApi } from "@/api"
import FormContainer from "@/components/base/page-container/form-container"
import { TitleWrapper } from "@/components/base/title-wrapper"
import UiPageSessionStore from "@/store/ui-page-session"
import { PageProps } from "@/types/page"
import { UserType } from "@/types/user"
import { useTranslation } from "react-i18next"
import UserForm from "../../../components/user/form"
import { useCallback } from "react"

const CreateUser = ({ onClose, open }: PageProps) => {
    const { updateState: setUiPageSession } = UiPageSessionStore()
    const { t } = useTranslation()

    const handleCallApi = useCallback((userObject: UserType) => {
        return UserApi.createUser(userObject)
    }, [])

    const handlePreviousButton = useCallback(() => {
        setUiPageSession({ showPage: "list" })
    }, [setUiPageSession])

    const onCancelForm = useCallback(() => {
        setUiPageSession({ showPage: "list", previousItem: null })
    }, [setUiPageSession])

    return (
        <FormContainer open={open} onClose={onClose}>
            <TitleWrapper
                title={t("USERS.CREATE.TITLE")}
                tooltipText={t("USERS.BACK_TOOLTIP")}
                handlePreviousButton={handlePreviousButton}
            />
            <UserForm callApi={handleCallApi} onCancelForm={onCancelForm} />
        </FormContainer>
    )
}

export default CreateUser
