import Icon from "@/components/base/Icon"
import { defaultSocialNetwork } from "@/hooks/businesses/edit-link/useLogic"
import IconNames from "@/types/icons"
import { isValidURL, isValidURLSocialNetwork } from "@/utils"
import { Box, Typography } from "@mui/material"
import { Fragment, useCallback } from "react"

const SocialNetwork = ({ linksNetwork }) => {
    const isSocialUrl = useCallback(
        (url: string) => {
            let valid = false
            for (const link of defaultSocialNetwork) {
                if (url && isValidURL(url) && isValidURLSocialNetwork(url, link.type)) {
                    valid = true
                    continue
                }
            }
            return valid
        },
        [defaultSocialNetwork]
    )
    return (
        <>
            {linksNetwork &&
                Object.keys(linksNetwork).length > 0 &&
                Object.keys(linksNetwork).map((linkKey, key) => (
                    <Fragment key={key}>
                        {linksNetwork[linkKey]?.value !== "" &&
                            linkKey !== "appointment" &&
                            isSocialUrl(linksNetwork[linkKey].value) && (
                                <Box className="line-wrapper" data-testid="linkNetwork">
                                    <Icon name={linksNetwork[linkKey].icon as IconNames} />
                                    <Typography component={"span"} className="link-url">
                                        {linksNetwork[linkKey].value}
                                    </Typography>
                                </Box>
                            )}
                    </Fragment>
                ))}
        </>
    )
}

export default SocialNetwork
