import { Box } from "@mui/material"
import { PropsWithChildren, useEffect } from "react"

import BaseAppBar from "@/components/base/app-bar"
import BaseMenu from "@/components/menu"
import useWindowSize from "@/hooks/useWindowSize"
import UiStore from "@/store/ui"
import useStyles from "./styles"

const Layout = ({ children }: PropsWithChildren<any>) => {
    const { classes } = useStyles()
    const [width] = useWindowSize()
    const { setShowMenu } = UiStore()
    const isBasePath = window.location.pathname === "/"

    useEffect(() => {
        setShowMenu(width >= 768)
    }, [width, setShowMenu])

    return (
        <Box className={classes.layout}>
            {!isBasePath && <BaseMenu />}
            <Box className="children-container">
                {!isBasePath && <BaseAppBar />}
                <Box className="children-wrapper">{children}</Box>
            </Box>
        </Box>
    )
}

export default Layout
