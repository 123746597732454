import { LocationsApi, ResellerApi } from "@/api"
import { useDebounce } from "@/hooks/useDebounce"
import useNotification from "@/hooks/useNotification"
import { CookiesService } from "@/services"
import useOverviewStore from "@/store/overview"
import UiPageSessionStore from "@/store/ui-page-session"
import UserSessionStore from "@/store/user-session"
import { isGoogleConnected } from "@/utils"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"

export type SearchPropsType = {
    inputLabel: string
    popoverTitle: string
    checkboxList: { label: string; name: string; checked: boolean; count: number }[]
    textSearch?: string
}

const useLogic = () => {
    const { profile } = UserSessionStore()
    const navigate = useNavigate()
    const { resetLoading } = useOverviewStore()
    const [isLoading, setIsLoading] = useState(true)
    const [fetchStatusLoading, setFetchStatusLoading] = useState(true)
    const [loadingCheckLicence, setLoadingCheckLicence] = useState(false)
    const [openAlert, setOpenAlert] = useState<boolean>(false)
    const [alert, setAlert] = useState<any>()
    const { updateState: setUiPageSession, menuPage } = UiPageSessionStore()
    const { t } = useTranslation()
    const { notif } = useNotification()
    const [searchParams, setSearchParams] = useSearchParams()
    const [metadata, setMetadata] = useState({
        page_number: 0,
        page_size: 10,
        total_count: 0,
    })
    const [filterValue, setFilterValue] = useState({
        missing_info_required: false,
        completion: "",
        name: "",
    })
    const [items, setItems] = useState([])

    const MESSAGE_TEXT = useMemo(() => {
        return {
            BUSINESS_CREATE_FAILED: t("BUSINESSES.CREATE.SAME_NAME"),
            BUSINESS_CREATE_SUCCESS: t("BUSINESSES.CREATE.SUCCESS"),
            BUSINESSES_SCORE_TITLE: t("BUSINESSES.SCORE.TITLE"),
        }
    }, [t])

    const [searchProps, setSearchProps] = useState<SearchPropsType>({
        inputLabel: "LISTINGS.SEARCH",
        popoverTitle: "BUSINESSES.SCORE.TITLE",
        checkboxList: [
            { label: "BUSINESSES.MISSING_INFO", name: "missing_info_required", checked: false, count: 0 },
            { label: "BUSINESSES.WEAK", name: "weak", checked: false, count: 0 },
            { label: "BUSINESSES.MEDIUM", name: "medium", checked: false, count: 0 },
            { label: "BUSINESSES.STRONG", name: "strong", checked: false, count: 0 },
        ],
        textSearch: "",
    })

    const textFilterValue = useDebounce(searchProps?.textSearch, 250)

    const checkSingleBusiness = useCallback(async () => {
        const response = await LocationsApi.fetchLocationsWithFields({ user_uid: profile.uid, fields: ["id"] })

        if (response?.data && response.data.length === 1) {
            return response?.data[0]?.id
        }
        return false
    }, [profile?.uid])

    const refreshListBusinesses = useCallback(
        async ({ page_number = 0, search = null, missing_info_required = false, completion = null }) => {
            const idSingleBusiness = await checkSingleBusiness()
            if (idSingleBusiness) {
                setUiPageSession({ showPage: "detail" })
                CookiesService.set({ isSingleBusiness: true })
                navigate(`/businesses/${idSingleBusiness}`)
            } else {
                CookiesService.set({ isSingleBusiness: false })
                setIsLoading(true)
                const response = await LocationsApi.fetchLocations({
                    page_number: page_number + 1,
                    page_size: 10,
                    user_uid: profile.uid,
                    search,
                    missing_info_required,
                    completion,
                    fields: [
                        "name",
                        "region",
                        "country",
                        "address",
                        "city",
                        "zip",
                        "locationState",
                        "timezone",
                        "logo",
                        "createdAt",
                        "serviceArea",
                        "timezone",
                    ],
                })

                if (response?.error) {
                    notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
                } else if (response?.data) {
                    // const paginated = paginate([...response.data], 1)
                    setItems(response?.data)
                    setAlert(null)
                    for (const location of response?.data || []) {
                        if (!isGoogleConnected(location)) {
                            setAlert({
                                title: t("BUSINESSES.RECONNECT_GOOGLE.TITLE"),
                                contents: t("BUSINESSES.RECONNECT_GOOGLE.WARNING"),
                            })
                            break
                        }
                    }

                    setMetadata((prev) => ({
                        ...prev,
                        page_number: page_number,
                        total_count: response?.metadata?._count,
                        page_size: response?.metadata?._limit,
                    }))
                }
                setIsLoading(false)
            }
        },
        [profile.uid, notif, t]
    )

    const handleChangePage = useCallback(
        (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
            refreshListBusinesses({ page_number: newPage, ...filterValue })
        },
        [refreshListBusinesses, filterValue]
    )

    const hanldeModalAlert = (value: boolean) => {
        setOpenAlert(value)
    }

    const fetchStatus = useCallback(async () => {
        setFetchStatusLoading(true)
        const response = await LocationsApi.fetchLocationsStatus({ user_uid: profile?.uid })
        if (response?.data) {
            const status = {
                missing_info_required: response?.data?.missingRequiredInfo ?? 0,
                weak: response?.data?.lowScore ?? 0,
                medium: response?.data?.midScore ?? 0,
                strong: response?.data?.highScore ?? 0,
            }
            setSearchProps((current) => ({
                ...current,
                checkboxList: current.checkboxList.map((item) => ({
                    ...item,
                    count: status[item.name],
                })),
            }))
        }
        setFetchStatusLoading(false)
    }, [profile?.uid])

    useEffect(() => {
        const onboarding = searchParams.get("onboarding")
        if (onboarding) {
            if (onboarding === "1") {
                notif({ message: MESSAGE_TEXT.BUSINESS_CREATE_SUCCESS, type: "SUCCESS" })
            }
            searchParams.delete("onboarding")
            setSearchParams(searchParams)
        }
    }, [MESSAGE_TEXT, searchParams, notif, setSearchParams])

    useEffect(() => {
        const checked = searchProps.checkboxList?.filter((list) => list.checked)
        const missing_info_required = !!checked.find((check) => check.name === "missing_info_required")
        const completion = []
        for (const check of checked) {
            if (check.name === "weak") {
                completion.push("0-33")
            } else if (check.name === "medium") {
                completion.push("34-66")
            } else if (check.name === "strong") {
                completion.push("67-100")
            }
        }
        setFilterValue((prev) => ({
            ...prev,
            missing_info_required,
            completion: completion.join(","),
            name: textFilterValue,
        }))
    }, [textFilterValue, searchProps.checkboxList])

    useEffect(() => {
        refreshListBusinesses({
            search: filterValue?.name,
            missing_info_required: filterValue?.missing_info_required,
            completion: filterValue?.completion,
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValue?.completion, filterValue?.missing_info_required, filterValue?.name])

    useEffect(() => {
        setUiPageSession({ refreshItems: true })
    }, [setUiPageSession])

    const handleCheckLicence = useCallback(async () => {
        setLoadingCheckLicence(true)
        const res = await ResellerApi.checkLicence({
            uid: profile.uid,
            reseller_uid: CookiesService.get("resellerUid"),
        })
        if (res?.has_price) {
            hanldeModalAlert(true)
        } else {
            setUiPageSession({ showPage: "create" })
        }
        setLoadingCheckLicence(false)
    }, [profile.uid, setUiPageSession])

    useEffect(() => {
        resetLoading()
    }, [])

    useEffect(() => {
        fetchStatus()
    }, [fetchStatus])

    return {
        metadata,
        searchProps,
        alert,
        isLoading,
        openAlert,
        items,
        loadingCheckLicence,
        fetchStatusLoading,
        handleChangePage,
        setUiPageSession,
        setSearchProps,
        hanldeModalAlert,
        handleCheckLicence,
    }
}

export default useLogic
