import useBusinessLogic from "@/hooks/businesses/useBusinessLogic"
import useLocationHours from "@/hooks/useLocationHours"
import useBusinessRegularHoursStore from "@/store/overview/regular-hours"
import { Paper } from "@mui/material"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import BusinessScheduleTable from "../../schedule/table"
import OverviewTitle from "../title"

const BusinessStandardSchedule = ({ isLoading, regularHoursRef }) => {
    const { t } = useTranslation()
    const { navigateTo } = useBusinessLogic()
    const { regularHourList } = useLocationHours()
    const { status } = useBusinessRegularHoursStore()
    const list = useMemo(() => (status === "OPEN" ? regularHourList : []), [status, regularHourList])

    return (
        <Paper
            elevation={2}
            className="clickable"
            id="special-hours"
            data-testid="special-hours"
            ref={regularHoursRef}
            onClick={() => !isLoading && navigateTo("edit-standard-schedule")}
        >
            <OverviewTitle
                title={t("BUSINESSES.SCHEDULE.STANDARD_OPENING")}
                // icon="calendar"
                isSecondTitle
                tooltipText={t("BUSINESSES.SCHEDULE.EDIT_BUSINESS_STANDARD_OPENING")}
            />
            {list?.length > 0 && <BusinessScheduleTable t={t} list={list} />}
        </Paper>
    )
}

export default BusinessStandardSchedule
