import { ExceptionalScheduleItemType } from "@/types/businesses"
import dayjs from "dayjs"

const parseSpecialHours = (specialHours, showAa: boolean) => {
    const specialHourArray: ExceptionalScheduleItemType[] = []

    if (specialHours && specialHours?.length > 0) {
        specialHours.map((value, key) => {
            const openTime = dayjs(value.openTime, "HH:mm")
            const closeTime = dayjs(value.closeTime, "HH:mm")

            const times = {
                start: openTime,
                startTxt: openTime.format(showAa ? "h:mm A" : "HH:mm"),
                end: closeTime,
                endTxt: closeTime.format(showAa ? "h:mm A" : "HH:mm"),
                startValid: true,
                endValid: true,
            }
            const indexExistingHour = specialHourArray.findIndex((day) => day?.startDate === value.startDate)

            if (indexExistingHour > -1) {
                specialHourArray[indexExistingHour].times.push({ ...times })
            } else {
                specialHourArray.push({
                    startDate: value.startDate,
                    endDate: value?.endDate ?? null,
                    isOpen: !value?.closed,
                    dateValid: true,
                    isAvailableAllDay: value.openTime === "00:00" && value.closeTime === "00:00",
                    times: value.openTime ? [times] : [],
                })
            }
        })
    }

    return [...specialHourArray]
}

export default parseSpecialHours
