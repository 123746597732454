import useNotification from "@/hooks/useNotification"
import { EditModelService } from "@/services"
import CampaignStore from "@/store/campaign"
import { zodResolver } from "@hookform/resolvers/zod"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useForm, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { z } from "zod"

export interface FormModelData {
    id: string
    title: string
    lang: string
    content: string
    created: string
    updated: string
}

interface PropsFormModel {
    defaultFormValue?: Partial<FormModelData>
    onCancel?: () => void
    fetchReviews?: () => void
}

const useLogic = ({ defaultFormValue, onCancel, fetchReviews }: PropsFormModel) => {
    const { t } = useTranslation()
    const { notif } = useNotification()
    const { businessSelect } = CampaignStore()
    const [loading, setLoading] = useState<boolean>(false)
    const schema = z.object({
        title: z.string().min(1, t("USERS.FIELD_REQUIRED")),
        subject: z.string().min(1, t("USERS.FIELD_REQUIRED")),
        content: z.string().min(1, t("USERS.FIELD_REQUIRED")),
        contentText: z.string().min(1, t("USERS.FIELD_REQUIRED")),
    })

    type SchemaValuesType = z.infer<typeof schema>

    const defaultValues: Partial<FormModelData> = useMemo(() => {
        if (defaultFormValue) {
            return { ...defaultFormValue, contentText: defaultFormValue.content }
        } else
            return {
                title: "",
                subject: "",
                content: "",
                contentText: "",
            }
    }, [defaultFormValue])

    const {
        control,
        formState: { errors },
        handleSubmit,
        watch,
        setValue,
        reset,
        trigger,
    } = useForm<SchemaValuesType>({
        defaultValues,
        mode: "onTouched",
        resolver: zodResolver(schema),
    })

    const formValue = useWatch({ control })

    useEffect(() => {
        if (formValue) {
            trigger()
        }
    }, [formValue, trigger])

    const onSubmit = useCallback(async () => {
        if (formValue?.title != "" && formValue?.subject != "" && formValue?.content != "") {
            let trimHtml = formValue.content.replace("<p>", "")?.trim()
            trimHtml = "<p>" + trimHtml

            const inputModel = {
                title: formValue.title.trim(),
                subject: formValue.subject.trim(),
                content: trimHtml,
                lang: businessSelect.lang,
            }
            setLoading(true)
            let response: any = {}
            if (defaultFormValue?.id) {
                response = await EditModelService.updateReview(businessSelect.business, defaultFormValue.id, inputModel)
            } else response = await EditModelService.createReview(businessSelect.business, inputModel)

            if (response?.error) {
                notif({ message: t("CAMPAIGN.MODEL.ERROR"), type: "ERROR" })
            } else {
                notif({ message: t("CAMPAIGN.MODEL.SUCCESS"), type: "SUCCESS" })
                fetchReviews()
                onCancel()
                reset()
            }
            setLoading(false)
        } else {
            notif({ message: t("CAMPAIGN.MODEL.ERROR"), type: "ERROR" })
        }
    }, [
        businessSelect?.business,
        businessSelect?.lang,
        formValue?.content,
        formValue?.subject,
        formValue?.title,
        t,
        defaultFormValue?.id,
        fetchReviews,
        notif,
        onCancel,
        reset,
    ])

    return {
        loading,
        control,
        formValue,
        errors,
        handleSubmit,
        watch,
        onSubmit,
        setValue,
    }
}

export default useLogic
