import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"

dayjs.extend(utc)
dayjs.extend(timezone)

export const getGmtOffset = () => {
    // Get the current time in the local timezone
    const localTime = dayjs()

    // Get the UTC offset in minutes and convert it to hours
    const offsetMinutes = localTime.utcOffset()
    const offsetHours = offsetMinutes / 60

    return offsetHours
}

export const getEtcGmtTimezone = (offsetHours: number) => {
    const sign = offsetHours > 0 ? "-" : "+"
    const absoluteOffset = Math.abs(offsetHours)
    return `Etc/GMT${sign}${absoluteOffset}`
}
