import Icon from "@/components/base/Icon"
import CustomTooltip from "@/components/base/Tooltip"
import BaseInput from "@/components/base/input"
import useFilterLogic from "@/hooks/businesses/list/useFilterLogic"
import { SearchPropsType } from "@/hooks/businesses/list/useLogic"
import {
    Box,
    Checkbox,
    Divider,
    FormControlLabel,
    IconButton,
    List,
    ListItem,
    Popover,
    Typography,
} from "@mui/material"
import { Dispatch, Fragment, SetStateAction, useState } from "react"
import { useTranslation } from "react-i18next"
import { baseInputSx, endAdornmentSx, iconBtnSx } from "./styles"

interface FilterProps {
    setWithSearch: Dispatch<SetStateAction<SearchPropsType>>
    withSearch: SearchPropsType
}

const Filter = ({ setWithSearch, withSearch }: FilterProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
    const [inputWidth, setInputWidth] = useState(0)
    const [checkedLength, setCheckedLength] = useState(0)
    const {
        handleCheckbox,
        handleClick,
        handleClose,
        handleInputRef,
        handleClearFilter,
        handleClearTextSearch,
        handleInputText,
    } = useFilterLogic({
        setAnchorEl,
        setInputWidth,
        setCheckedLength,
        setWithSearch,
        withSearch,
    })
    const { t } = useTranslation()

    const open = Boolean(anchorEl)
    const id = open ? "filter-popover" : undefined

    return (
        <>
            <BaseInput
                ref={handleInputRef}
                size="small"
                id="filter-business"
                sx={baseInputSx}
                value={withSearch.textSearch}
                onInput={handleInputText}
                hiddenLabel={true}
                placeholder={t(withSearch.inputLabel)}
                endAdornment={
                    <Box sx={endAdornmentSx({ showLength: !open && !!checkedLength })}>
                        {withSearch?.textSearch && (
                            <Fragment>
                                <CustomTooltip title={t("BUSINESSES.ERASE_TEXT")}>
                                    <IconButton
                                        sx={iconBtnSx}
                                        onClick={handleClearTextSearch}
                                        className="text-clear-icon"
                                    >
                                        <Icon name="close" />
                                    </IconButton>
                                </CustomTooltip>
                                <Divider
                                    orientation="vertical"
                                    flexItem
                                    sx={{ marginX: "10px", height: "24px", alignSelf: "center" }}
                                />
                            </Fragment>
                        )}
                        <div className="filter-btn-group">
                            <CustomTooltip title={t("BUSINESSES.FILTER_TOOLTIP_NO_SELECT")}>
                                <IconButton
                                    className="filter-btn"
                                    onClick={handleClick}
                                    sx={iconBtnSx}
                                    data-e2e="open-filter"
                                >
                                    <Icon name="filter" />
                                </IconButton>
                            </CustomTooltip>
                            {!open && !!checkedLength && (
                                <Typography component={"span"} sx={{ color: "inherit" }}>
                                    {checkedLength}
                                </Typography>
                            )}
                            {checkedLength > 0 && (
                                <CustomTooltip title={t("BUSINESSES.REMOVE_FILTER")}>
                                    <IconButton sx={iconBtnSx} onClick={handleClearFilter}>
                                        <Icon name="close" />
                                    </IconButton>
                                </CustomTooltip>
                            )}
                        </div>
                    </Box>
                }
            />
            <Popover
                disableRestoreFocus
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                elevation={8}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                slotProps={{
                    root: {
                        sx: { "& .MuiBackdrop-root": { opacity: "0 !important" } },
                    },
                    paper: {
                        sx: { width: inputWidth, boxSizing: "border-box", p: 1 },
                    },
                }}
            >
                <Typography sx={{ color: "action.active", p: 1 }}>{t(withSearch.popoverTitle)}</Typography>
                <List sx={{ py: 0 }}>
                    {withSearch?.checkboxList?.map((value, k) => (
                        <ListItem sx={{ py: 0, px: 1, boxShadow: "none" }} key={k} data-e2e="filter-option">
                            <FormControlLabel
                                onChange={handleCheckbox}
                                sx={{ flexGrow: 1 }}
                                control={<Checkbox data-e2e="filter-type" />}
                                label={t(value.label)}
                                name={value.name}
                                checked={value.checked}
                            />
                            <Typography component={"span"} sx={{ color: "action.active" }} data-filter="filter-active">
                                {value.count || 0}
                            </Typography>
                        </ListItem>
                    ))}
                </List>
            </Popover>
        </>
    )
}

export default Filter
