import { Box, Button, Chip } from "@mui/material"
import { MouseEventHandler, useMemo } from "react"
import { useTranslation } from "react-i18next"

interface AttributeChipsProps {
    expanded: boolean
    selected: any
    onClick?: MouseEventHandler
}

const AttributeChips = ({ expanded, selected, onClick }: AttributeChipsProps) => {
    const show = useMemo(() => !expanded && selected.length > 0, [expanded, selected])
    const { t } = useTranslation()
    return (
        <Box className="chips-wrapper" onClick={onClick}>
            {show && selected.map((selected, key) => <Chip color="primary" key={key} label={selected.display} />)}
            {show && <Button variant="text">{t("BUSINESSES.EDIT_ATTRIBUTES.EDIT")}</Button>}
        </Box>
    )
}

export default AttributeChips
