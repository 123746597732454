import { ApiService, LocationsApi, ResellerApi } from "@/api"
import useAuthFacebook from "@/hooks/oauth02/useAuthFacebook"
import useAuthInstagram from "@/hooks/oauth02/useAuthInstagram"
import useLocation from "@/hooks/useLocation"
import { BusinessService, CookiesService } from "@/services"
import useOverviewStore from "@/store/overview"
import useAttributesStore from "@/store/overview/attributes"
import useContactsStore from "@/store/overview/contacts"
import useLinksStore from "@/store/overview/links"
import OverviewDescriptionStore from "@/store/overview/long-description"
import useMainInfoStore from "@/store/overview/main-info/useMainInfoStore"
import useScoreStore from "@/store/overview/score/useScoreStore"
import UiPageSessionStore from "@/store/ui-page-session"
import UserSessionStore from "@/store/user-session"
import { openGoogleAccount } from "@/utils/auth"
import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import { MutableRefObject, useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import useBusinessLogic from "../useBusinessLogic"
dayjs.extend(customParseFormat)
interface ScrollToRef {
    ref: MutableRefObject<HTMLElement | null>
}
const useLogic = ({ businessId }) => {
    const navigate = useNavigate()
    const { navigateTo } = useBusinessLogic()
    const { profile } = UserSessionStore()
    const { t } = useTranslation()

    const [attributeListTxt, setAttributeListTxt] = useState("")
    const {
        attributIds,
        loadedAttributes,
        listAttributes,
        update: updateAttributes,
        flush: flushAttributes,
    } = useAttributesStore()
    const [mediasList, setMediasList] = useState([])
    const [totalMediaCount, setTotalMediaCount] = useState(0)
    const [openDisconnectFacebookModal, setOpenDisconnectFacebookModal] = useState(false)
    const [openConnectIgModal, setOpenConnectIgModal] = useState(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const openFacebookMenu = Boolean(anchorEl)
    const mainInfoRef = useRef(null)
    const descriptionRef = useRef(null)
    const attributRef = useRef(null)
    const categoriesRef = useRef(null)
    const servicesRef = useRef(null)
    const serviceAreaRef = useRef(null)
    const regularHoursRef = useRef(null)
    const specialHoursRef = useRef(null)
    const linksRef = useRef(null)
    const contactRef = useRef(null)
    const mediaRef = useRef(null)
    const {
        location,
        authUrl,
        needReconnexion,
        isLoading,
        updateLocation,
        fetchAttribute,
        resetLocation,
        setCategories,
    } = useLocation("location-detail")
    const isSingleBusiness = CookiesService.get("isSingleBusiness")
    const { websiteUrl, linksNetwork, update: updateLinks } = useLinksStore()
    const { update: updateContacts } = useContactsStore()
    const { update: updateDescription } = OverviewDescriptionStore()
    const { fetchCompanyInfo } = useMainInfoStore()

    const {
        missingItem,
        missings,
        chartDonutData,
        resetState: resetScoreState,
        series,
    } = useScoreStore({
        attributRef,
        categoriesRef,
        contactRef,
        descriptionRef,
        linksRef,
        mainInfoRef,
        mediaRef,
        regularHoursRef,
        serviceAreaRef,
        servicesRef,
        specialHoursRef,
    })

    const scrollTo = (missing: ScrollToRef) => {
        if (missing?.ref?.current) {
            const isMobile = window.innerWidth < 768
            missing.ref.current.style.scrollMarginTop = isMobile ? "70px" : "20px"
            missing.ref.current.scrollIntoView({ behavior: "smooth" })
        }
    }

    const { setLoading, loading } = useOverviewStore()

    const { updateState: setUiPageSession, menuPage, showPage, refreshItems, updatePreviousItem } = UiPageSessionStore()
    const { openFacebookAccount, isLoggedFacebook } = useAuthFacebook({ location })
    const [disconnectProvider, setDisconnectProvider] = useState("facebook")
    const [openDeleteBusiness, setOpenDeleteBusiness] = useState(false)
    const [deletingBusiness, setDeletingBusiness] = useState(false)
    const { isLoggedInstagram } = useAuthInstagram({ location })

    const faceboookMenuHandler = (event: React.MouseEvent<HTMLButtonElement>, provider = "facebook") => {
        setDisconnectProvider(provider)
        setAnchorEl(event.currentTarget)
    }
    const handleCloseFacebookMenu = () => {
        setAnchorEl(null)
    }

    const disconnectSocial = useCallback(() => {
        setOpenDisconnectFacebookModal(true)
        handleCloseFacebookMenu()
    }, [])

    const onCloseDisconnectFacebook = useCallback(() => setOpenDisconnectFacebookModal(false), [])
    const onCloseConnectIg = useCallback(() => setOpenConnectIgModal(false), [])

    const getAttributes = useCallback(async () => {
        let attributes = []
        if (businessId && location?.companyId) {
            setLoading("attribute", true)
            if (!loadedAttributes) {
                attributes = (await fetchAttribute(businessId)) || []
            } else {
                attributes = listAttributes
            }
            updateAttributes({ loadedAttributes: true, listAttributes: attributes })
            setLoading("attribute", false)
        }
        const locationAttrSelect = attributIds ? Object.keys(attributIds) : []

        return {
            listAttributes: attributes,
            locationAttrSelect,
        }
    }, [fetchAttribute, attributIds, businessId, location?.companyId, loadedAttributes, listAttributes])

    const processAttributes = useCallback(async () => {
        const { listAttributes, locationAttrSelect } = await getAttributes()

        if (attributIds) {
            const { attributeUsers, attributesTxt } = BusinessService.resolveAttributes({
                listAttributes,
                locationAttrSelect,
                attributIds: attributIds,
            })

            updateLinks({ linksNetwork: attributeUsers })

            setAttributeListTxt(attributesTxt)
        }
    }, [attributIds])

    const fetchLocationInfo = useCallback(
        async (uid = "", user_uid = "") => {
            setLoading("locationInfo", true)
            const response = await ResellerApi.fetchLocationInfo({ uid, user_uid })
            if (response?.status_code === 404) {
                navigate("/404")
            } else if (!response?.error) {
                fetchCompanyInfo(response.companyId, profile?.uid)
                fetchMedias(uid)
                updateLocation(response)
                updateAttributes({ attributIds: response.attributIds })
                updateLinks({ websiteUrl: response.websiteUrl })
                updateContacts({ email: response.email, additionalPhone: response.additionalPhone })
                updateDescription({ description: response.description })
                setCategories(response.categories, { isDraft: false })
                updatePreviousItem({ days: null })
            }
            setLoading("locationInfo", false)
        },
        [updateLocation, fetchCompanyInfo, profile?.uid]
    )

    const fetchMedias = useCallback(async (uid = "") => {
        setLoading("media", true)
        const response = await ResellerApi.getListMedia({ locationId: uid, perpage: 10 })
        if (!response?.error) {
            setMediasList([
                ...response.data.filter((media) => media.category === "" || media.category === "ADDITIONAL"),
            ])
            setTotalMediaCount(response.metadata.total)
        }
        setLoading("media", false)
    }, [])

    const handlePreviousButton = useCallback(() => {
        navigateTo("list")
        setUiPageSession({ previousItem: null, refreshItems: true })
        resetScoreState()
        setMediasList([])
        flushAttributes()
        resetLocation()
        navigate("/businesses")
    }, [])

    const handeleConnect = async (type) => {
        if (type === "facebook") {
            openFacebookAccount({
                companyId: location.companyId,
                user_id: profile.uid,
                location_id: location.id,
            })
        } else if (type === "instagram") {
            setOpenConnectIgModal(true)
        }
    }

    const handleConnectGoogle = async () => {
        const resp = await ResellerApi.getGBPAccount({
            company_uid: location?.companyId,
        })

        openGoogleAccount({
            companyId: location?.companyId,
            user_id: profile?.uid,
            gmb_access_id: location?.gmbAccessId,
            multiple_accounts: resp?.length ? true : false,
            bearer: ApiService.getToken()?.token,
        })
    }

    const openDeleteModal = (value = true) => {
        setOpenDeleteBusiness(value)
    }

    const onDeleteBusiness = useCallback(async () => {
        setDeletingBusiness(true)

        const resp = await LocationsApi.deleteLocation(location.id)
        if (!resp?.error) {
            openDeleteModal(false)
            setDeletingBusiness(false)

            navigate("/businesses")
        }
        setDeletingBusiness(false)
    }, [location])

    useEffect(() => {
        processAttributes()
    }, [processAttributes])

    useEffect(() => {
        if (profile.uid && businessId && menuPage === "businesses") {
            if (refreshItems) {
                fetchLocationInfo(businessId, profile.uid)
                setUiPageSession({ refreshItems: false })
            }
        }
    }, [showPage, refreshItems, businessId, location])

    useEffect(() => {
        if (businessId) {
            setUiPageSession({ refreshItems: true })
        }
    }, [businessId, setUiPageSession])

    return {
        location,
        linksNetwork,
        authUrl,
        isLoggedFacebook,
        isLoggedInstagram,
        isSingleBusiness,
        websiteUrl,
        needReconnexion,
        isLoading,
        navigateTo,
        handlePreviousButton,
        t,
        handeleConnect,
        disconnectSocial,
        openDisconnectFacebookModal,
        setOpenDisconnectFacebookModal,
        openConnectIgModal,
        onCloseDisconnectFacebook,
        anchorEl,
        faceboookMenuHandler,
        handleCloseFacebookMenu,
        handleConnectGoogle,
        onCloseConnectIg,
        openFacebookMenu,
        mainInfoRef,
        descriptionRef,
        attributRef,
        categoriesRef,
        serviceAreaRef,
        servicesRef,
        regularHoursRef,
        specialHoursRef,
        linksRef,
        contactRef,
        mediaRef,
        chartDonutData,
        missingItem,
        missings,
        scrollTo,
        series,
        mediasList,
        totalMediaCount,
        profile,
        loading,
        attributeListTxt,
        disconnectProvider,
        openDeleteModal,
        openDeleteBusiness,
        onDeleteBusiness,
        deletingBusiness,
    }
}

export default useLogic
