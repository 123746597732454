import { TitleWrapper } from "@/components/base/title-wrapper"
import EditServicesContainer from "@/components/business/form-container/edit/edit-services"
import FormAction from "@/components/business/form-container/edit/form-action"
import { FormLocationService } from "@/components/business/form-container/edit/form-location-service"
import useLogic from "@/hooks/businesses/edit-services/useLogic"
import { PageProps } from "@/types/page"
import { Box } from "@mui/material"

const EditServices = ({ onClose, open }: PageProps) => {
    const {
        primaryCategories,
        currency,
        primaryServicesFiltered,
        serviceIds,
        secondaryCategories,
        secondaryServicesFiltered,
        loading,
        formValid,
        t,
        setServiceIds,
        handleFormChange,
        handleSubmit,
        handlePreviousButton,
        handleCancelButton,
    } = useLogic()
    return (
        <EditServicesContainer open={open} onClose={onClose}>
            <TitleWrapper handlePreviousButton={handlePreviousButton} title={t("BUSINESSES.EDIT_SERVICES.TITLE")} />
            <form onSubmit={(e) => handleSubmit(e)}>
                <Box className="inputs-container">
                    {/* Category principal */}
                    <FormLocationService
                        title={primaryCategories?.label}
                        items={primaryCategories?.items}
                        currency={currency}
                        customServices={primaryServicesFiltered}
                        serviceIds={serviceIds}
                        setServiceIds={setServiceIds}
                        onFormChange={(data) => handleFormChange("primary", data, primaryCategories)}
                    />
                    {/* Category secondaire */}
                    {secondaryCategories?.map((category, index) => (
                        <FormLocationService
                            title={category?.label}
                            key={category?.categoryId}
                            items={category?.items}
                            currency={currency}
                            customServices={secondaryServicesFiltered.filter(
                                (service) => service?.categoryId === category?.categoryId
                            )}
                            serviceIds={serviceIds}
                            setServiceIds={setServiceIds}
                            onFormChange={(data) => handleFormChange("secondary", data, category)}
                        />
                    ))}
                </Box>
                <FormAction
                    isUpdating
                    handleCancel={handleCancelButton}
                    isLoading={loading}
                    isDisabled={loading || !formValid}
                />
            </form>
        </EditServicesContainer>
    )
}

export default EditServices
