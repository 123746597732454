import CrashAppComponent from "@/components/errors/crash-app/inde"
import BusinessesPage from "@/pages/businesses"
import BusinessInfo from "@/pages/businesses/overview-business"
import CampaignPage from "@/pages/campaign"
import CompaniesPage from "@/pages/companies"
import Error404 from "@/pages/errors/404"
import ListingsPage from "@/pages/listings"
import { LocalPresence } from "@/pages/local-presence"
import LoginPage from "@/pages/login"
import ForgotPasswordPage from "@/pages/password/forgot-password"
import ResetPasswordPage from "@/pages/password/reset-password"
import ReplyReviewPage from "@/pages/reply-review"
import ReputationPage from "@/pages/reputation"
import SocialPage from "@/pages/social"
import { StatisticReputation } from "@/pages/statistic-reputation"
import UsersPage from "@/pages/users"
import { captureException } from "@sentry/react"
import { useEffect } from "react"
import { RouteObject, useRouteError } from "react-router-dom"
import GlobalOutlet from "./global-outlet"
import PrivateOutlet from "./private-outlet"
import PublicOutlet from "./public-outlet"

function SentryRouteErrorFallback() {
    const routeError = useRouteError()

    useEffect(() => {
        captureException(routeError, { level: "fatal" })
    }, [routeError])

    return <CrashAppComponent />
}

const RoutesList: RouteObject[] = [
    {
        path: "/",
        element: <GlobalOutlet />,
        errorElement: <SentryRouteErrorFallback />,
        children: [
            {
                path: "*",
                element: <Error404 />,
            },
            {
                element: <PublicOutlet />,
                path: "/",
                children: [
                    {
                        path: "login",
                        element: <LoginPage />,
                    },
                    {
                        path: "forgot-password",
                        element: <ForgotPasswordPage handleSubmit={null} />,
                    },
                    {
                        path: "reset-password",
                        element: <ResetPasswordPage />,
                    },
                    {
                        path: "reply-review",
                        element: <ReplyReviewPage />,
                    },
                ],
            },
            {
                element: <PrivateOutlet />,
                path: "/",
                children: [
                    {
                        path: "reputation",
                        element: <ReputationPage />,
                    },
                    {
                        path: "users",
                        element: <UsersPage />,
                    },
                    {
                        path: "companies",
                        element: <CompaniesPage />,
                    },
                    {
                        path: "businesses",
                        element: <BusinessesPage />,
                    },
                    {
                        path: "businesses/:businessId",
                        element: <BusinessInfo />,
                    },
                    {
                        path: "listings",
                        element: <ListingsPage />,
                    },
                    {
                        path: "reputation",
                        element: <ReputationPage />,
                    },
                    {
                        path: "campaign",
                        element: <CampaignPage />,
                    },
                    {
                        path: "social",
                        element: <SocialPage />,
                    },
                    {
                        path: "statistics",
                        children: [
                            {
                                path: "local",
                                element: <LocalPresence />,
                            },
                            {
                                path: "reputation",
                                element: <StatisticReputation />,
                            },
                        ],
                    },
                ],
            },
        ],
    },
]

export default RoutesList
