import { ResellerService } from "@/services"
import NotificationStore from "@/store/notification"
import ResellerStore from "@/store/reseller"
import { GoogleOAuthProvider } from "@react-oauth/google"
import { useEffect, useState } from "react"

const AsyncGoogleOAuthProvider = ({ children }) => {
    const { resellerFetched } = ResellerStore()
    const [googleIdentityClientId, setGoogleIdentityClientId] = useState(null)
    const { update: updateNotifState } = NotificationStore()

    useEffect(() => {
        if (!resellerFetched) {
            const getReseller = async () => {
                try {
                    const { googleIdentityClientId: GOOGLE_IDENTITY_CLIENT_ID } =
                        (await ResellerService.getResellerInfos()) || {}
                    if (GOOGLE_IDENTITY_CLIENT_ID) {
                        setGoogleIdentityClientId(GOOGLE_IDENTITY_CLIENT_ID)
                    }
                    //TODO: redirect to 500 pages error
                } catch (error) {
                    //TODO: le code n'arrive jamais ici en cas d'erreur ?
                    console.error("Error fetching reseller info:", error)
                }
            }
            getReseller()
        }
        updateNotifState({ show: false })
    }, [resellerFetched, updateNotifState])

    if (!googleIdentityClientId) {
        return <></>
    }

    return <GoogleOAuthProvider clientId={googleIdentityClientId}>{children}</GoogleOAuthProvider>
}

export default AsyncGoogleOAuthProvider
