import CampaignSuccess from "@/components/base/success/campaign"
import ErrorComponent from "@/components/errors/400"
import useLogic from "@/hooks/reply-review/useLogic"
import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import Error404 from "../errors/404"

export const SuccessMessage = ({ redirectToCampaign, message }) => {
    return (
        <Stack
            sx={{
                height: "100%",
                "& .campaign-success": {
                    width: "100%",
                },
                "& .children-wrapper": {
                    height: "100%",
                    justifyContent: "center",
                },
            }}
            flexDirection={"column"}
            justifyContent={"center"}
        >
            <CampaignSuccess onClose={redirectToCampaign} message={message} withNewRequest={false} />
        </Stack>
    )
}

export const SendingReply = () => {
    const { t } = useTranslation()
    return (
        <Stack flexDirection={"column"} height={"100%"} justifyContent={"center"} alignItems={"center"} gap={1}>
            <CircularProgress size={60} />
            <Typography fontSize={24}>{t("REPUTATION.SENDING_ANSWER")}</Typography>
        </Stack>
    )
}

export const LocationNotFound = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    return (
        <Stack flexDirection={"column"} height={"100%"} justifyContent={"center"} alignItems={"center"} gap={1}>
            <Typography fontSize={24} textAlign={"center"}>
                {t("PAGE_ERRORS.LOCATION_NOT_FOUND.TITLE")}
            </Typography>
            <Typography color={"#68738D"} maxWidth={"530px"} textAlign={"center"}>
                {t("PAGE_ERRORS.LOCATION_NOT_FOUND.DESC")}
            </Typography>
            <Button
                variant="contained"
                onClick={() => {
                    navigate("/businesses")
                }}
            >
                <Typography component={"span"}>{t("PAGE_ERRORS.LOCATION_NOT_FOUND.BUTTON")}</Typography>
            </Button>
        </Stack>
    )
}

const ReplyReviewPage = () => {
    const {
        sendingReply,
        sendingSuccess,
        locationDisconnected,
        tokenInvalid,
        answerAlreadySent,
        internalError,
        redirectToCampaign,
    } = useLogic()

    return (
        <Box height={"100%"}>
            {sendingSuccess && (
                <SuccessMessage message={"REPUTATION.ANSWER_SENT"} redirectToCampaign={redirectToCampaign} />
            )}
            {answerAlreadySent && (
                <SuccessMessage message={"REPUTATION.ANSWER_ALREADY_SENT"} redirectToCampaign={redirectToCampaign} />
            )}
            {sendingReply && <SendingReply />}
            {tokenInvalid && <Error404 />}
            {locationDisconnected && <LocationNotFound />}
            {internalError && (
                <ErrorComponent
                    code="500"
                    message={"PAGE_ERRORS.INTERNAL_SERVER_ERROR.DESC"}
                    buttonText="PAGE_ERRORS.INTERNAL_SERVER_ERROR.BUTTON"
                    navigatePath="/"
                />
            )}
        </Box>
    )
}

export default ReplyReviewPage
