import { Paper, Skeleton, Stack } from "@mui/material"

export const SkeletonBlocHour = () => {
    return (
        <Paper elevation={2} sx={{ flex: 1, width: "100%" }}>
            <Skeleton variant="rectangular" width={100} height={20} />
            <Stack gap={1} width="100%">
                <Skeleton variant="rectangular" width="100%" height={16} />
                <Skeleton variant="rectangular" width="100%" height={16} />
                <Skeleton variant="rectangular" width="90%" height={16} />
            </Stack>
        </Paper>
    )
}
