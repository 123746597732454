import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()((theme) => ({
    drawer: { zIndex: 1 },
    container: {
        "& .inputs-wrapper": {
            "& .select": {
                "& label": {
                    maxWidth: "calc(100% - 32px)",
                },
            },
            "& .add-more": {
                gap: 8,
                padding: 6,
                "& svg": {
                    width: 20,
                    height: 20,
                },
            },
            "& .MuiPaper-root": {
                display: "flex",
                gap: 8,
                alignItems: "start",
                padding: 8,
                "@media (min-width: 768px)": {
                    padding: 16,
                },
                "& .MuiBox-root": {
                    display: "flex",
                    gap: 8,
                    flexWrap: "wrap",
                    "@media (min-width: 768px)": {
                        gap: 16,
                    },
                    "& > *": {
                        width: "100%",
                        "&:nth-of-type(2), &:nth-of-type(3)": {
                            width: "calc((100% - 8px) / 2)",
                            "@media (min-width: 768px)": {
                                width: "calc((100% - 32px) / 4)",
                            },
                        },
                        "&:nth-of-type(1)": {
                            "@media (min-width: 768px)": {
                                width: "calc((100% - 32px) / 2)",
                            },
                        },
                    },
                },
                "& button": {
                    padding: 0,
                    margin: " 16px 0",
                    "& svg": {
                        width: "24px",
                        height: "24px",
                    },
                },
            },
            "& .chip-wrapper": {
                display: "flex",
                gap: 8,
                flexWrap: "wrap",
            },
            "& .error": {
                textAlign: "center",
            },
            "& .wrapper": {
                display: "flex",
                flexDirection: "column",
                gap: 8,
            },
            "& .title": {
                display: "flex",
                gap: 8,
                alignItems: "center",
                "& h2": {
                    fontSize: 24,
                    color: theme.palette.common.black,
                    padding: "0 8px",
                    "& svg": {
                        color: theme.palette.action.active,
                    },
                },
            },
            "& .has-adornment .MuiInputBase-root": {
                gap: 8,
                "& svg": {
                    margin: "auto",
                    marginBottom: 8,
                },
            },
            "& .switch-button-wrapper": {
                display: "flex",
                alignItems: "center",
                gap: 8,
                "& .MuiIconButton-root": {
                    padding: 0,
                },
                "& svg": {
                    color: theme.palette.action.active,
                },
            },
            "& .inputs": {
                display: "flex",
                flexDirection: "column",
                gap: 8,
                "@media (min-width: 768px)": {
                    gap: 16,
                    flexDirection: "row",
                    flexWrap: "wrap",
                },
                "& > *": {
                    "@media (min-width: 768px)": {
                        width: "calc(50% - 8px)",
                        "&:nth-of-type(3)": {
                            width: "100%",
                        },
                    },
                },
            },
            "&.address": {
                "& .title-wrapper": {
                    display: "flex",
                    flexDirection: "column",
                    "@media (min-width: 768px)": {
                        alignItems: "center",
                        justifyContent: "space-between",
                        flexDirection: "row",
                    },
                },
                "& .inputs": {
                    "@media (min-width: 768px)": {
                        gap: 16,
                        flexDirection: "row",
                        flexWrap: "wrap",
                    },
                    "& > *": {
                        "@media (min-width: 768px)": {
                            width: "calc(50% - 8px)",
                            "&:nth-of-type(3), &:nth-of-type(4), &:nth-of-type(5)": {
                                width: "calc((100% - 32px) / 3)",
                            },
                        },
                    },
                    "&.half-each > *": {
                        "@media (min-width: 768px)": {
                            width: "calc(50% - 8px)",
                        },
                    },
                },
            },
        },
    },
}))

export default useStyles
