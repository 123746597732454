import { SxProps } from "@mui/material"

interface DefaultSx {
    noBottomBorder?: boolean
    showLabelStar?: boolean
    error?: boolean
}

const inputLabelSx = (formStateError?: boolean): SxProps => ({
    "&.MuiFormLabel-root": {
        color: "action.active",
    },
    "&.Mui-focused": {
        color: "action.active",
    },
    "&.Mui-error, &.Mui-focused.Mui-error": {
        color: "error.main",
    },
    "& .MuiFormLabel-asterisk": {
        color: formStateError ? "error.main" : "primary.main",
    },
})

const defaultSx = (props?: DefaultSx): SxProps => ({
    borderRadius: "8px",
    backgroundColor: "rgba(172, 182, 229, 0.16)",
    overflow: "hidden",
    "& .MuiFilledInput-input": {
        display: "flex",
        gap: "8px",
        alignItems: "center",
        span: {
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            // width: "100%",
            display: "block",
        },
    },
    "&.Mui-disabled": {
        backgroundColor: "rgba(172, 182, 229, 0.16)",
    },
    "&:hover:not(.Mui-disabled)": {
        backgroundColor: "rgba(172, 182, 229, 0.16)",
    },
    " &::after, &:hover:before, &::before, &:hover:not(.Mui-disabled, .Mui-error):before": {
        ...(!props?.noBottomBorder ? { borderBottom: "1px solid rgba(91, 125, 216, 0.12)" } : { borderBottom: "none" }),
    },
    "&.Mui-error": {
        "&::after, &:hover:before, &::before": {
            borderBottomColor: "error.main",
        },
    },
    "&.Mui-focused": {
        backgroundColor: "rgba(172, 182, 229, 0.16)",
        overflow: "hidden",
        borderRadius: "8px",
    },
    "& fieldset": {
        border: "none",
    },

    "& label, & label.Mui-focused": {
        color: "action.active",
    },
    "& label.Mui-error, & label.Mui-focused.Mui-error": {
        color: "error.main",
    },
    "& .MuiFilledInput-root, & .MuiFilledInput-root::after, & .MuiSelect-root::after,  & .MuiFilledInput-root.Mui-focused::after":
        {
            borderRadius: "8px",
            overflow: "hidden",
        },
    "& .MuiFormLabel-asterisk": {
        color: props.error ? "error.main" : "primary.main",
        display: "none",
        ...(props.showLabelStar && { display: "inline" }),
    },
    "& input": {
        "&:autofill": {
            backgroundClip: "text",
        },
        span: {
            display: "inline-block",
            width: "200px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
    },
    ".text-secondary": {
        color: "action.active",
    },
})

export { defaultSx, inputLabelSx }
