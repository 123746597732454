import { Divider, Paper, Skeleton, Stack } from "@mui/material"
import SkeletonCampaignOverviewsStatistique from "../statistique"
import useStyles from "../styles"

const SkeletonCampaignOverviewsItem = () => {
    const { classes } = useStyles()
    return (
        <Paper elevation={2} className={classes.paperOverviews}>
            <Stack flexDirection={"column"} gap={1} padding="16px 0px 0px;">
                <Stack flexDirection={"row"} justifyContent={"space-between"}>
                    <Skeleton variant="rectangular" width={"100%"} height={24} sx={{ minWidth: "72px" }} />
                </Stack>
                <Stack flexDirection="column" gap={0.5}>
                    <Skeleton variant="rectangular" width={"100%"} height={24} sx={{ minWidth: "70px" }} />
                    <Skeleton variant="rectangular" width={"100%"} height={14} sx={{ minWidth: "70px" }} />
                </Stack>
            </Stack>
            <Stack>
                <Divider className="separator" />
                <SkeletonCampaignOverviewsStatistique />
            </Stack>
        </Paper>
    )
}

export default SkeletonCampaignOverviewsItem
