export const ButtonBaseSx = {
    padding: "4px",
    justifyContent: "flex-start",
    "&:hover": {
        backgroundColor: "#EEE",
    },
}

export const PopperSx = {
    maxHeight: "130px",
    overflow: "auto",
    backgroundColor: "#fff",
    padding: "4px",
    width: "96px",
    borderRadius: "8px",
    boxShadow: "0px 8px 10px 1px #00041F29",
    display: "flex",
    flexDirection: "column",
    zIndex: 100,
}

export const TimeFieldSx = {
    width: "112px",
    heigth: "48px",
    borderRadius: "8px",
    overflow: "hidden",
    backgroundColor: "rgba(172, 182, 229, 0.16)",
    "body & .MuiFilledInput-root": {
        width: "112px",
        backgroundColor: "#f2f3fb",
        "&::after, &:hover:before, &::before": {
            borderBottom: "1px solid #5B7DD81F",
        },
        "&.Mui-error": {
            "&::after, &:hover:before, &::before": {
                borderBottom: "2px solid",
                borderBottomColor: "error.main",
            },
        },
        "& input": {
            padding: 1.5,
        },
    },
    ".MuiOutlinedInput-root": {
        backgroundColor: "#f2f3fb",
        "&.Mui-error": {
            "&::after, &:hover:before, &::before": {
                borderBottom: "2px solid",
                borderBottomColor: "error.main",
            },
            ".MuiOutlinedInput-notchedOutline": {
                borderBottom: "2px solid",
                borderBottomColor: "error.main",
                top: "30px",
            },
        },
    },
}
