import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()((theme) => ({
    list: {
        display: "flex",
        flexDirection: "column",
        gap: 16,
        margin: "auto",
        marginTop: 0,
        // height: "100%",
        width: "100%",
        "@media (min-width: 768px)": {
            gap: 24,
        },
    },
}))

export default useStyles
