import { TextField } from "@mui/material"
import { styled } from "@mui/material/styles"

export const CssTextField = styled(TextField)(({ theme }) => ({
    "& .MuiInputBase-root": {
        backgroundColor: "transparent",
    },
    "& label, & label.Mui-focused": {
        color: theme.palette.action.active,
    },
}))
