import Icon from "@/components/base/Icon"
import { Box, Chip, Paper, Stack, Typography } from "@mui/material"
import Chart from "react-apexcharts"
import { useTranslation } from "react-i18next"
import ScoreChip from "./chip"
import { chartWrapperSx, chipWrapperSx, missingInfoSx, titleWrapperSx } from "./styles"

const OverviewScore = ({
    scrollTo,
    chartDonutData,
    series = [],
    missingItem = [],
    missings = [],
    mandatoryList = [],
    isMock = false,
}: {
    scrollTo?: any
    chartDonutData?: object
    series?: number[]
    missingItem?: string[]
    missings?: any[]
    mandatoryList?: any[]
    isMock?: boolean
}) => {
    const { t } = useTranslation()
    const scoreText = missingItem?.length <= 1 ? t("BUSINESSES.SCORE.MISSING") : t("BUSINESSES.SCORE.MISSINGS")
    return (
        <Paper elevation={1} className="section description">
            <Box sx={titleWrapperSx}>
                <Icon name="checkCertified" />
                <Typography variant="h2" sx={{ fontSize: 24 }}>
                    {t("BUSINESSES.SCORE.TITLE")}
                </Typography>
            </Box>
            <Stack direction={"row"} alignItems={"center"} sx={chartWrapperSx} data-testid="missing-container">
                {!isMock && <Chart options={chartDonutData} series={series} type="donut" width={132} height={132} />}

                <Stack gap={1} sx={missingInfoSx}>
                    {missingItem?.length > 0 && (
                        <Typography component={"span"} sx={{ color: "action.active" }} data-testid="missing-item">
                            {missingItem.length} {scoreText}
                        </Typography>
                    )}
                    {!missingItem ||
                        (missingItem?.length == 0 && (
                            <>
                                <Typography
                                    component={"span"}
                                    sx={{ color: "action.active" }}
                                    data-testid="missing-item"
                                >
                                    {t("BUSINESSES.SCORE.COMPLETE_TITLE")}
                                </Typography>
                                <Typography
                                    component={"span"}
                                    sx={{ color: "action.active" }}
                                    data-testid="missing-item"
                                >
                                    {t("BUSINESSES.SCORE.COMPLETE_CONTENT")}
                                </Typography>
                            </>
                        ))}
                    <Box sx={chipWrapperSx} data-testid="missing-list">
                        {missings?.map((score) => {
                            if (
                                score?.missing != 0 &&
                                score?.items.some((item: string) => missingItem.includes(item)) &&
                                missingItem
                                    .filter((item) => score?.items.includes(item))
                                    .some((item: string) => mandatoryList.includes(item)) === false
                            ) {
                                return (
                                    <ScoreChip
                                        key={score?.id}
                                        icon={score?.icon}
                                        label={t(score?.label)}
                                        onClick={() => scrollTo(score)}
                                    />
                                )
                            } else if (
                                score?.missing != 0 &&
                                score?.items.some((item: string) => missingItem.includes(item)) &&
                                missingItem
                                    .filter((item) => score?.items.includes(item))
                                    .some((item: string) => mandatoryList.includes(item))
                            ) {
                                return (
                                    <Chip
                                        key={score?.id}
                                        label={t(score?.label)}
                                        color="error"
                                        variant="outlined"
                                        icon={<Icon name="alert-triangle" />}
                                        onClick={() => scrollTo(score)}
                                        sx={{ p: 0.5, "& .MuiChip-label": { p: "3px 6px" } }}
                                    />
                                )
                            }
                        })}
                    </Box>
                </Stack>
            </Stack>
        </Paper>
    )
}

export default OverviewScore
