import useBusinessRegularHoursStore from "@/store/overview/regular-hours"
import useBusinessSpecialHoursStore from "@/store/overview/special-hours"
import UiStore from "@/store/ui"
import UiPageSessionStore from "@/store/ui-page-session"
import { parseRegularHours, sortDateException } from "@/utils"
import parseSpecialHours from "@/utils/parseSpecialHours"
import cloneDeep from "lodash.clonedeep"
import { useEffect, useMemo, useState } from "react"

const useLocationHours = () => {
    const [regularHourList, setRegularHourList] = useState([])
    const [specialHoursList, setSpecialHoursList] = useState([])
    const { regularHours, status } = useBusinessRegularHoursStore()
    const { specialHours } = useBusinessSpecialHoursStore()
    const { preferredLanguage } = UiStore()
    const showAa = useMemo(() => preferredLanguage?.toLowerCase() === "en", [preferredLanguage])
    const { updatePreviousItem } = UiPageSessionStore()

    useEffect(() => {
        if (regularHours?.length === 0) {
            setRegularHourList([])
            updatePreviousItem({ days: null, regularHours: null, isOpenWithoutHour: true })
        } else if (regularHours?.length > 0) {
            const newRegularHourArray = parseRegularHours(regularHours, showAa)
            updatePreviousItem({
                days: cloneDeep(newRegularHourArray),
                regularHours: cloneDeep(newRegularHourArray),
                isOpenWithoutHour: false,
            })
            setRegularHourList(cloneDeep(newRegularHourArray))
        }

        if (location && Object.keys(location).length > 0 && (!specialHours || specialHours?.length === 0)) {
            setSpecialHoursList([])
        }
        if (location && Object.keys(location)?.length > 0 && specialHours) {
            const newSpecialHourArray = parseSpecialHours(specialHours, showAa)
            updatePreviousItem({
                specialDays: sortDateException(cloneDeep(newSpecialHourArray)),
            })
            setSpecialHoursList(cloneDeep(newSpecialHourArray))
        }
        updatePreviousItem({ status })
    }, [status, regularHours, specialHours, showAa, updatePreviousItem])

    return {
        regularHourList,
        specialHoursList,
    }
}
export default useLocationHours
