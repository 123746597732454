import UiPageSessionStore, { PageBusinesssType } from "@/store/ui-page-session"
import { useCallback } from "react"

const useBusinessLogic = () => {
    const { updateState: setUiPageSession } = UiPageSessionStore()

    const navigateTo = useCallback((page: PageBusinesssType) => setUiPageSession({ showPage: page }), [])

    return { navigateTo }
}

export default useBusinessLogic
