import useResponsive from "@/hooks/use-responsive"
import { SchedulerItemProps } from "@/types/scheduler"
import { Stack, Typography } from "@mui/material"
import { useCallback } from "react"

export const SchedulerItem = ({ date, events }: SchedulerItemProps) => {
    const { isMobile } = useResponsive()

    const getData = useCallback(
        (data) => {
            if (data && data.render) {
                return data.render({ ...data.renderParams, publishAt: date })
            }
            return null
        },
        [date]
    )

    return (
        <Stack
            key={date.day}
            gap={1}
            sx={{
                width: "100%",
                borderTop: "1px solid #E5EAF9",
                borderRight: "1px solid #E5EAF9",
                padding: isMobile ? 0 : "6px",
                gap: 1,
                minHeight: isMobile ? "64px" : "80px",
            }}
        >
            {date.isCurrentMonth && (
                <Stack
                    flexGrow={"1"}
                    justifyContent={isMobile ? "center" : "initial"}
                    paddingY={isMobile ? 3 : "initial"}
                    paddingX={isMobile ? 2 : "initial"}
                    gap={1}
                >
                    <Typography
                        variant="body1"
                        fontSize={12}
                        color={date.isCurrentDay ? "common.white" : "text-primary"}
                        sx={{
                            backgroundColor: date.isCurrentDay ? "primary.main" : "transparent",
                            width: "22px",
                            height: "22px",
                            borderRadius: "38px",
                            gap: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {date.day}
                    </Typography>
                    {getData(events[`${date.year}-${date.month + 1}-${date.day}`])}
                </Stack>
            )}
        </Stack>
    )
}
